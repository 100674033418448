import { Form, TextField } from "rentpost/components/form"
import { AddIntegrationComponentProps } from "."
import { AddIntegrationFormFields } from "../../useIntegrations"
import { FormProps } from "rentpost/components/form/Form"
import { MiniRoleCard } from "rentpost/components/other"
import { Button, CircularProgress } from "@mui/material"
import AutoCompleteSelectField from "rentpost/components/form/AutoCompleteSelectField"
import _ from "lodash"
import * as yup from "yup"

type IntegrationTypeOption = {
   label: string
   value: string
   icon: React.ReactNode | undefined
}

const integrationOptions: IntegrationTypeOption[] = [
   {
      label: "Custom",
      value: "Custom",
      icon: undefined,
   },
]

const validationSchema = yup.object().shape({
   type: yup.string().required("Integration Type is required"),
   name: yup.string().when("type", {
      is: "Custom",
      then: yup.string().required("Integration Name is required"),
   }),
   description: yup.string().when("type", {
      is: "Custom",
      then: yup.string().required("Integration Description is required"),
   }),
   roleId: yup.string().when("type", {
      is: "Zapier",
      then: yup.string().required("Role is required"),
      otherwise: yup.string(),
   }),
})

const AddIntegrationForm: React.FC<AddIntegrationComponentProps> = (props) => {
   // ----------
   // Form Props
   // ----------
   const initialValues: AddIntegrationFormFields = {
      name: "",
      description: "",
      roleId: "",
      type: "",
   }

   const formProps: FormProps = {
      initialValues,
      onSubmit: (value: AddIntegrationFormFields) => {
         props.onSubmit(value)
      },
      validationSchema,
      error: props.error,
      loading: props.loading,
      showErrorHint: true,
   }

   return (
      <Form {...formProps}>
         {({ values, dirty, isValid }) => {
            const type = values.type
            return (
               <>
                  <AutoCompleteSelectField
                     options={integrationOptions}
                     name="type"
                     label="Integration Service"
                     placeholder="Select Integration Service"
                     renderOption={(props, option: IntegrationTypeOption) => (
                        <li {...props} key={option.value}>
                           <IntegrationOption {...option} />
                        </li>
                     )}
                     className="integration-type-select"
                  />
                  {_.toLower(type) === "custom" && (
                     <>
                        <TextField name="name" placeholder="eg. Gmail" label="Integration Name" />
                        <TextField
                           name="description"
                           placeholder="eg. Send email notifications to tenants"
                           label="Integration Description"
                        />
                     </>
                  )}
                  {_.toLower(type) !== "custom" && (
                     <MiniRoleCard roles={props.availableRoles} name="roleId" label="Role" />
                  )}
                  <div className="modal-action-buttons">
                     <Button
                        type="button"
                        variant="outlined"
                        color="secondary"
                        onClick={props.onClose}
                        autoFocus
                     >
                        Cancel
                     </Button>
                     <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={props.loading || !dirty || !isValid}
                        endIcon={props.loading ? <CircularProgress size={"100%"} /> : null}
                     >
                        Add Integration
                     </Button>
                  </div>
               </>
            )
         }}
      </Form>
   )
}

const IntegrationOption: React.FC<IntegrationTypeOption> = (props) => {
   const { icon, label } = props
   return (
      <div className="integration-option">
         <div className="icon">{icon}</div>
         <div className="label">{label}</div>
      </div>
   )
}

export default AddIntegrationForm
