import { useOutletContext } from "react-router-dom"
import PersonalInfoForm from "./PersonalInfoForm"
import useOnboarding from "../useOnboarding"
import { ComponentProps } from "react"
import Skeleton from "../Skeleton"

const PersonalInfo: React.FC = () => {
   const {
      personalInfo: { error, loading, personalInfo, updatePersonalInfo },
   } = useOutletContext() as ReturnType<typeof useOnboarding>

   if (personalInfo === null) return <Skeleton />

   const personalInfoFormProps: ComponentProps<typeof PersonalInfoForm> = {
      loading: loading.personalInfo || loading.updatePersonalInfo,
      error: error.updatePersonalInfo,
      initialValues: personalInfo,
      onSubmit: updatePersonalInfo,
   }

   return (
      <div className="page-content">
         <h2>Let's personalize this a bit</h2>
         <p className="description">
            What is your name and best contact number, should we need to reach you?
         </p>
         <PersonalInfoForm {...personalInfoFormProps} />
      </div>
   )
}
export default PersonalInfo
