import { Button } from "@mui/material"
import SVG from "rentpost/assets/svg"
import { AccountsInfoProps } from "./useMerge"
import styled from "@emotion/styled"
import { MergeComponentProps } from "./MergeComponent"
import { Avatar, AvatarProps } from "rentpost/components/other"
import { motion } from "framer-motion"
import { color, screen } from "rentpost/styles"

type UserAccountInfo = AccountsInfoProps["primaryUser"]
interface Props extends Omit<MergeComponentProps, "isMergeSuccessful"> {
   accountsInfo: AccountsInfoProps
}

const MergeIllustrationComponent: React.FC<Props> = (props) => {
   const {
      triggerMergingDirection,
      loading,
      accountsInfo: { primaryUser, secondaryUser },
      mergingDirection,
   } = props

   const directionFactor = mergingDirection === "secondary-to-primary" ? 0 : -50

   // --------------------
   // Accounts Information
   // --------------------
   const primaryUserRoles = EmptyRolesFallback({
      roles: primaryUser.roles,
      displayName: primaryUser.firstName + " " + primaryUser.lastName,
   })
   const secondaryUserRoles = EmptyRolesFallback({
      roles: secondaryUser.roles,
      displayName: secondaryUser.firstName + " " + secondaryUser.lastName,
   })
   const defaultDirectionResult: UserAccountInfo = {
      id: primaryUser.id,
      email: primaryUser.email,
      firstName: primaryUser.firstName,
      lastName: primaryUser.lastName,
      roles: primaryUserRoles?.concat(secondaryUserRoles ?? []) ?? [],
   }
   const swapDirectionResult: UserAccountInfo = {
      id: secondaryUser.id,
      email: secondaryUser.email,
      firstName: secondaryUser.firstName,
      lastName: secondaryUser.lastName,
      roles: secondaryUserRoles?.concat(primaryUserRoles ?? []) ?? [],
   }

   // ------------------
   // Conditional Render
   // ------------------
   const classNames = mergingDirection + (loading ? " loading" : "")

   return (
      <Holder className={classNames}>
         <div className="account-items-group merged-to-account-group">
            <AccountInfoItem {...primaryUser} />
         </div>
         <div className="account-items-group merged-account-group">
            <AccountInfoItem {...secondaryUser} />
         </div>
         <div className="account-items-group result-account-group">
            <motion.div
               className="account-role-avatar"
               animate={{ y: `${directionFactor}%` }}
               initial={{ y: "0%" }}
            >
               <AccountInfoItem {...defaultDirectionResult} />
               <AccountInfoItem {...swapDirectionResult} />
            </motion.div>
         </div>
         <Button
            className="swap-button"
            color="secondary"
            variant="outlined"
            onClick={triggerMergingDirection}
            endIcon={<SVG.form.swap />}
         >
            Swap
         </Button>
         <BackgroundLinesSVG />
      </Holder>
   )
}

// ------------------------------
// Account Info Item  - Component
// ------------------------------
const AccountInfoItem: React.FC<UserAccountInfo> = ({ roles, firstName, lastName, email }) => {
   const displayName = firstName + " " + lastName
   const mappedRoles: AvatarProps["roles"] = EmptyRolesFallback({
      roles,
      displayName,
   })?.map(({ avatarImage }) => ({
      displayName,
      email,
      avatarImage,
   })) as AvatarProps["roles"]

   return (
      <div className="account-info-item">
         <Avatar
            roles={mappedRoles}
            maxAvatarsToShow={2}
            isAvatarsGroup
            className="account-role-avatar"
         />
         <p className="account-info-email">{email}</p>
      </div>
   )
}

// ----------------------
// Background Lines - SVG
// ----------------------
const BackgroundLinesSVG = () => {
   return (
      <div className="lines-background-holder">
         <svg viewBox="0 0 314 196" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g className="secondary-line-group-original">
               <path
                  d="M7.06836 189.996C149.162 189.996 178.237 94.0571 313.013 94.0571"
                  strokeWidth="14"
                  className="secondary-line"
                  id="secondary-line"
               />
               <path
                  d="M313.621 93.6294C170.799 93.6294 158.544 188.575 5.74707 188.575"
                  stroke="white"
                  strokeWidth="2"
                  strokeDasharray="10 10"
               />
               <path
                  d="M313.621 93.6294C170.799 93.6294 158.544 188.575 5.74707 188.575"
                  stroke="#fff"
                  strokeWidth="20"
                  className="screen-line"
               />
            </g>
            <g className="primary-line-group">
               <path
                  d="M0.324615 7.35547C143.146 7.35547 160.638 93.6635 313.436 93.6635"
                  strokeWidth="14"
                  className="primary-line"
                  id="primary-line"
               />
               <path
                  d="M0.324615 7.35547C143.146 7.35547 160.638 93.6635 313.436 93.6635"
                  stroke="white"
                  strokeWidth="2"
                  strokeDasharray="10 10"
               />
               <path
                  d="M0.324615 7.35547C143.146 7.35547 160.638 93.6635 313.436 93.6635"
                  stroke="#fff"
                  strokeWidth="20"
                  className="screen-line"
               />
            </g>
            <g className="secondary-line-group-clone">
               <path
                  d="M7.06836 189.996C149.162 189.996 178.237 94.0571 313.013 94.0571"
                  strokeWidth="14"
                  className="secondary-line"
                  id="secondary-line"
               />
               <path
                  d="M313.621 93.6294C170.799 93.6294 158.544 188.575 5.74707 188.575"
                  stroke="white"
                  strokeWidth="2"
                  strokeDasharray="10 10"
               />
               <path
                  d="M313.621 93.6294C170.799 93.6294 158.544 188.575 5.74707 188.575"
                  stroke="#fff"
                  strokeWidth="20"
                  className="screen-line"
               />
            </g>
         </svg>
      </div>
   )
}

// ----------------
// Helper functions
// ----------------
const EmptyRolesFallback = ({
   roles,
   displayName,
}: {
   roles: UserAccountInfo["roles"]
   displayName: string
}): UserAccountInfo["roles"] => {
   return roles && roles?.length >= 1
      ? roles
      : [
           {
              displayName,
              avatarImage: null,
           },
        ]
}

// ----------------
// Styled Component
// ----------------
const Holder = styled.div`
   /* CSS Vars */
   --space: 20px;
   --line-gray: #e3e3e3;
   --line-green: ${color.green};
   --loading-animation-duration: 2s;

   width: 100%;
   height: 300px;
   position: relative;
   transition: transform var(--loading-animation-duration) ease-out;
   box-sizing: border-box;

   .account-items-group {
      position: absolute;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      padding-inline: 5px;
      gap: 20px;
      padding-block: 4px;
      height: 87px;
   }

   .account-info-item {
      display: flex;
      flex-direction: column;
      align-items: start;
      gap: 0.8em;
      height: 93px;
      margin: 0;
      padding: 0;

      .account-role-avatar {
         pointer-events: none;
         .avatar-item {
            box-shadow: 0px 5px 9px 0px rgba(0, 0, 0, 0.4);
         }
      }

      .account-info-email {
         white-space: nowrap;
         overflow: hidden;
         text-overflow: ellipsis;
         max-width: 150px;
         margin: 0;
         padding: 0;
      }
   }

   .swap-button {
      position: absolute;
      top: calc(var(--space) * 6);
      left: var(--space);
      opacity: 1;
      transition: opacity 0.3s ease-out;
      border-color: currentColor;
      padding-block: 0.2em;
   }

   .lines-background-holder {
      position: absolute;
      top: 53px;
      left: 59px;
      width: 303px;
      overflow: hidden;
      z-index: -1;

      svg {
         width: 100%;
         path {
            transition: fill 0.3s ease-out;
         }

         .screen-line {
            stroke-dasharray: 330;
            stroke-dashoffset: 330;
         }
      }
   }

   /* account info items */
   .merged-to-account-group {
      top: 24px;
      left: 0;
      offset-path: url(#primary-line);
      transform-origin: 0% 0%;
      offset-rotate: 0deg;
   }

   .merged-account-group {
      top: 14px;
      left: 0;
      offset-path: url(#secondary-line);
      transform-origin: 0% 0%;
      offset-rotate: 0deg;
   }

   .result-account-group {
      top: 112px;
      right: 0;
      transition: transform var(--loading-animation-duration) ease-out;

      .account-info-email {
         max-width: 200px;
      }
   }

   /* Animate Lines according to the direction */
   &.secondary-to-primary {
      svg {
         .primary-line {
            stroke: var(--line-green);
         }

         .secondary-line {
            stroke: var(--line-gray);
         }

         .secondary-line-group-clone {
            display: none;
         }
      }
   }

   &.primary-to-secondary {
      svg {
         .primary-line {
            stroke: var(--line-gray);
         }

         .secondary-line {
            stroke: var(--line-green);
         }

         .secondary-line-group-original {
            display: none;
         }
      }
   }

   /* Animate when loading */
   @keyframes trace-path {
      0% {
         offset-distance: 0%;
         transform: scale(1);
      }

      95% {
         opacity: 0;
      }

      100% {
         offset-distance: 100%;
         transform: scale(0.5);
         opacity: 0;
      }
   }

   @keyframes remove-lines {
      0% {
         stroke-dashoffset: -330;
      }

      100% {
         stroke-dashoffset: 0;
      }
   }

   @keyframes remove-lines-reverse {
      0% {
         stroke-dashoffset: 330;
      }

      100% {
         stroke-dashoffset: 0;
      }
   }

   @keyframes pulse {
      0% {
         opacity: 1;
         transform: scale(1.3);
      }

      50% {
         opacity: 0.7;
         transform: scale(1.25);
      }

      100% {
         opacity: 1;
         transform: scale(1.3);
      }
   }

   &.loading {
      transform: translateX(-30%) scale(1.1);

      .merged-to-account-group,
      .merged-account-group {
         animation: trace-path linear var(--loading-animation-duration) forwards;
      }

      .secondary-line-group-original .screen-line,
      .secondary-line-group-clone .screen-line {
         animation: remove-lines var(--loading-animation-duration) linear forwards;
      }

      .primary-line-group .screen-line {
         animation: remove-lines-reverse var(--loading-animation-duration) linear forwards;
      }
      .swap-button {
         opacity: 0;
      }

      .result-account-group {
         transform: scale(1.3);
         animation: pulse 1.3s ease-out infinite;
         animation-delay: var(--loading-animation-duration);
      }
   }

   /* -------------- */
   /* Responsiveness */
   /* -------------- */
   ${screen.onlyMobile} {
      position: relative;

      .lines-background-holder {
         transform: scaleX(0.8);
         left: 1em;
      }

      .result-account-group {
         .account-info-item {
            align-items: flex-end;
         }
      }
   }
`
export default MergeIllustrationComponent
