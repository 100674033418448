import { ModalProps, Modal } from "layouts"
import styled from "@emotion/styled"
import { CountDownSpinner } from "rentpost/components/other"
import { Button } from "@mui/material"
import { useEffect, useState } from "react"
import { color } from "rentpost/styles"
import { useNavigate } from "react-router-dom"
import ROUTE from "routes/ROUTE"

interface Props {
   activeModal: string | false
   onClose: () => void
   logoutReason: "missingToken" | "expiredToken"
}

const ModalLogout: React.FC<Props> = ({ activeModal, onClose, logoutReason }) => {
   const [startCountingDown, setStartCountingDown] = useState<boolean>(false)
   const navigate = useNavigate()

   const title = logoutReason === "missingToken" ? "User Identification Error" : "Session Expired"

   const description =
      logoutReason === "missingToken"
         ? "We found an issue with identifying your identity, please try logging in again."
         : "Your session has expired, please try logging in again."

   const reason =
      logoutReason === "missingToken"
         ? "Couldn't get your identity correctly"
         : "Your session has expired"

   useEffect(() => {
      if (activeModal === "logout") {
         setTimeout(() => setStartCountingDown(true), 1000)
      }
   }, [activeModal])

   const logout = () => {
      navigate(ROUTE.LOGOUT + `?reason=${reason}`)
      onClose()
   }

   const props: ModalProps = {
      visible: activeModal === "logout",
      onClose: logout,
      canIgnore: false,
      renderComponent: {
         show: true,
         component: () => (
            <Holder>
               <h2>{title}</h2>
               <p>{description}</p>
               <div className="counter-holder">
                  <CountDownSpinner
                     onTimeout={logout}
                     timeoutAmount={15}
                     timeoutStart={startCountingDown}
                     className="counter-timer"
                  />
                  <span className="counter-description">
                     You will be logged out in <strong>15 seconds</strong>
                  </span>
               </div>
               <Button onClick={logout} color="primary" variant="contained">
                  Login
               </Button>
            </Holder>
         ),
      },
   }

   return <Modal {...props} />
}

const Holder = styled.div`
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;

   width: 21em;

   h2 {
      font-size: 1.5rem;
      font-weight: 500;
      margin-bottom: 1rem;
      color: ${color.red};
   }

   p {
      font-size: 1rem;
      margin-bottom: 1rem;
      text-align: center;
      max-width: 18em;
   }

   .counter-holder {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 1em;
      background-color: #f5f5f5;
      padding: 0.6em 0.8em;
      border-radius: 0.9em;
      .counter-timer {
         margin: 0.5rem;
      }

      .counter-description {
         font-size: 0.875rem;
         font-weight: 400;
         padding: 0.9em 1.1em;
      }

      strong {
         font-weight: 500;
      }
   }
`

export type { Props as ModalLogoutProps }
export default ModalLogout
