import { useMutation, useLazyQuery } from "@apollo/client"
import { gql } from "@apollo/client"
import usePOEToken from "hooks/usePOEToken"
import bypassMockError from "utils/bypassMockError"
import { useEffect, useState } from "react"
import { GetPersonalInfo, UpdatePersonalInfo, UpdatePersonalInfoVariables } from "./__generated__"
import { useErrorBoundary } from "react-error-boundary"
import { MissingDataError } from "configs/error/customErrors"
import _ from "lodash"
import { useNavigate } from "react-router-dom"

interface PersonalInfo {
   firstName: string
   lastName: string
   phone: string
   managerId: string
}

export interface PersonalInfoFormFields {
   firstName: string
   lastName: string
   phone: string
}

// ======
// QUERY
// ======
const GetPersonalInfoQuery = gql`
   query GetPersonalInfo {
      user {
         firstName
         lastName
         phone
         id
      }
      managers {
         id
      }
      role {
         isOnboardingRequired
      }
   }
`

// ========
// MUTATION
// ========
const UpdatePersonalInfoMutation = gql`
   mutation UpdatePersonalInfo($userInput: UpdateUserInput!, $managerInput: UpdateManagerInput!) {
      updateUser(input: $userInput) {
         id
         firstName
         lastName
         phone
      }
      updateManager(input: $managerInput) {
         id
      }
   }
`

// ======================
// USE PERSONAL INFO HOOK
// ======================
const usePersonalInfo = ({
   changeStep,
   setStepCompleted,
}: {
   changeStep: (step: number) => void
   setStepCompleted: (currentStep: number) => void
}) => {
   const { showBoundary } = useErrorBoundary()
   const [personalInfo, setPersonalInfo] = useState<PersonalInfo | null>(null)
   const [isCompleted, setIsCompleted] = useState(false)
   const { poeToken, refreshPOEToken } = usePOEToken()
   const navigate = useNavigate()

   // ==========
   // OPERATIONS
   // ==========
   const [runGetPersonalInfo, { loading: personalInfoLoading, error: personalInfoError }] =
      useLazyQuery<GetPersonalInfo>(GetPersonalInfoQuery)

   const [
      runUpdatePersonalInfo,
      { loading: updatePersonalInfoLoading, error: updatePersonalInfoError },
   ] = useMutation<UpdatePersonalInfo, UpdatePersonalInfoVariables>(UpdatePersonalInfoMutation)

   // ==================
   // HANDLERS & EFFECTS
   // ==================
   const getPersonalInfo = async () => {
      const { data } = await runGetPersonalInfo({
         context: {
            showErrorModal: true,
         },
      })
      if (!data?.user || !data?.managers) {
         showBoundary(
            new MissingDataError(
               `User response data has (${data?.user}) value`,
               "OperationName: getPersonalInfo - usePersonalInfo.ts"
            )
         )
         return
      }

      if (!data?.role?.isOnboardingRequired) {
         navigate("/login", { replace: true })
      }

      data.user &&
         data.managers &&
         setPersonalInfo({
            firstName: data.user.firstName || "",
            lastName: data.user.lastName || "",
            phone: data.user.phone,
            managerId: data.managers[0].id,
         })
   }

   const updatePersonalInfo = async (value: PersonalInfoFormFields) => {
      // Check if the value is the same as the current personalInfo state to avoid unnecessary API calls
      if (_.isEqual(personalInfo, value)) {
         changeStep(1)
         return
      }
      try {
         await runUpdatePersonalInfo({
            context: {
               poeToken: poeToken.current,
            },

            variables: {
               userInput: {
                  firstName: value.firstName,
                  lastName: value.lastName,
                  phone: "+" + value.phone?.replace("+", "")?.replace(" ", ""),
               },
               managerInput: {
                  id: personalInfo?.managerId || "",
                  legalEntity: {
                     typeKey: "natural_person",
                     firstName: value.firstName,
                     lastName: value.lastName,
                     phone: "+" + value.phone?.replace("+", "")?.replace(" ", ""),
                  },
               },
            },
         })
         refreshPOEToken()
         await getPersonalInfo()
         changeStep(1)
      } catch (error) {}
   }

   // Check for the form completion status whenever the personalInfo state changes
   useEffect(() => {
      if (personalInfo?.firstName && personalInfo?.lastName && personalInfo?.phone) {
         setIsCompleted(true)
      } else {
         setIsCompleted(false)
      }
   }, [personalInfo])

   // Check if the personalInfo state is completed and set the step as completed
   useEffect(() => {
      if (isCompleted) {
         setStepCompleted(0)
      }
   }, [isCompleted])

   // ======
   // RETURN
   // ======
   return {
      personalInfo,
      isCompleted,
      error: {
         updatePersonalInfo: updatePersonalInfoError,
         personalInfo: personalInfoError,
      },
      loading: {
         personalInfo: personalInfoLoading,
         updatePersonalInfo: updatePersonalInfoLoading,
      },
      getPersonalInfo,
      updatePersonalInfo,
   }
}

export default usePersonalInfo
