import { useMutation } from "@apollo/client"
import { gql } from "@apollo/client"
import usePOEToken from "hooks/usePOEToken"
import bypassMockError from "utils/bypassMockError"
import { VerifyEmail, VerifyEmailVariables, ResendEmailVerification } from "./__generated__"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import ROUTE from "routes/ROUTE"

// ========
// MUTATION
// ========

const VerifyEmailMutation = gql`
   mutation VerifyEmail($code: String!) {
      verifyEmail(code: $code)
   }
`
const ResendEmailVerificationMutation = gql`
   mutation ResendEmailVerification {
      sendVerificationEmail
   }
`

export { VerifyEmailMutation, ResendEmailVerificationMutation }
// =====================
// USE VERIFY EMAIL HOOK
// =====================
const useVerifyEmail = () => {
   const [attemptCount, setAttemptCount] = useState<number>(0)
   const navigate = useNavigate()
   const [
      runVerifyEmailManually,
      { loading: verifyEmailManuallyLoading, error: verifyEmailManuallyError },
   ] = useMutation<VerifyEmail, VerifyEmailVariables>(VerifyEmailMutation)

   const [runVerifyEmailByCode, { loading: verifyFromLinkLoading }] = useMutation<
      VerifyEmail,
      VerifyEmailVariables
   >(VerifyEmailMutation)

   const [
      runResendEmailVerification,
      { loading: resendEmailVerificationLoading, error: resendEmailVerificationError },
   ] = useMutation<ResendEmailVerification>(ResendEmailVerificationMutation)

   const { poeToken, refreshPOEToken } = usePOEToken()

   // ---------------------
   // Verify Email Manually
   // ---------------------
   const verifyEmailManually = (code: string) => {
      runVerifyEmailManually({
         context: {
            poeToken: poeToken.current,
         },
         variables: {
            code,
         },
      })
         .then(refreshPOEToken)
         .catch((err) => {
            bypassMockError(err)
         })
   }

   // --------------------
   // Verify Email By Code
   // --------------------
   const verifyEmailFromLink = (code: string) => {
      runVerifyEmailByCode({
         context: {
            poeToken: poeToken.current,
         },
         variables: {
            code,
         },
      })
         .then(refreshPOEToken)
         .catch((err) => {
            bypassMockError(err)
            // Direct user to the manual verification page if the code is invalid
            navigate(ROUTE.VERIFY.ROOT + ROUTE.VERIFY.EMAIL)
         })
   }

   // -------------------------
   // Resend Email Verification
   // -------------------------
   const resendEmailVerification = () => {
      runResendEmailVerification({
         context: {
            poeToken: poeToken.current,
         },
      })
         .then(({ data }) => {
            data && setAttemptCount(attemptCount + 1)
            refreshPOEToken()
         })
         .catch((err) => {
            bypassMockError(err)
         })
   }

   return {
      verifyEmailManually,
      verifyEmailFromLink,
      resendEmailVerification,
      attemptCount,
      verifyEmailManuallyLoading,
      verifyEmailManuallyError,
      verifyFromLinkLoading,
      resendEmailVerificationLoading,
      resendEmailVerificationError,
   }
}

export default useVerifyEmail
