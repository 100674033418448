import { FallbackProps } from "react-error-boundary"
import { useNavigate } from "react-router-dom"
import ROUTE from "routes/ROUTE"
import PageErrorAuthFailed from "components/webComponents/PageErrorAuthFailed"
import { useEffect } from "react"
import { sentryTag } from "configs/sentry"

const AuthErrorLayout: React.FC<FallbackProps> = ({ resetErrorBoundary, error }) => {
   const navigate = useNavigate()
   const gotoLogin = () => {
      navigate(ROUTE.LOGIN)
      resetErrorBoundary()
   }

   useEffect(() => {
      // Set the tag to show that the AuthErrorLayout has been shown
      sentryTag("error-layout-shown", "Auth Error Layout")
   }, [])

   return <PageErrorAuthFailed onClickLogin={gotoLogin} message={error.message} />
}

export default AuthErrorLayout
