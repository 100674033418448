import { useEffect, useState } from "react"
import { RoleCard, RoleCardProps, RoleType } from "rentpost/components/other"
import { GetRoleDetails } from "./__generated__"
import ModalUpdateAvatar from "pages/Profile/Roles/ModalUpdateAvatar"
import useLogin from "pages/Login/useLogin"
import { GetAvailableRoles } from "pages/Login/__generated__"

interface Props {
   onAvatarChange: (image: string) => void
   roleData: GetRoleDetails
}

const RoleCardWithAvatarSelector = (props: Props) => {
   const { onAvatarChange, roleData } = props

   const [image, setImage] = useState<string | null>(null)
   const [showUpdateAvatarModal, setShowUpdateAvatarModal] = useState<boolean>(false)
   const { getAddressFromRoleData, getAvatarImageFromRoleData } = useLogin()

   // Set image on change
   useEffect(() => {
      image && onAvatarChange(image)
   }, [image])

   const role = roleData.roles[0]

   const roleCardProps: RoleCardProps = {
      role: {
         id: role.id,
         type: role.type as RoleType,
         address: getAddressFromRoleData(role as GetAvailableRoles["roles"][0]),
         email: role.email,
         avatarImage: image || "",
         displayName: role.displayName,
         companyName: role.companyName,
         badges: role.badges,
      },
      onAvatarClick: () => setShowUpdateAvatarModal(true),
      showEditAvatar: true,
      isOnboardingRequired: false,
   }

   const modalUpdateAvatarProps = {
      visible: showUpdateAvatarModal,
      onClose: () => setShowUpdateAvatarModal(false),
      currentAvatarImageURL:
         image || getAvatarImageFromRoleData(role as GetAvailableRoles["roles"][0]) || undefined,
      displayName: role.displayName,
      roleID: role.id,
      roleType: role.type as RoleType,
      onSave: (avatarImage: string) => {
         setImage(avatarImage)
         setShowUpdateAvatarModal(false)
      },
      loading: false,
   }

   // ===
   // JSX
   // ===
   return (
      <>
         <RoleCard {...roleCardProps} />
         <ModalUpdateAvatar {...modalUpdateAvatarProps} />
      </>
   )
}

export default RoleCardWithAvatarSelector
