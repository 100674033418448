import { BrowserRouter } from "react-router-dom"
import RootApp from "layouts/RootApp"
import ApolloProvider from "configs/ApolloProvider"
import { Provider } from "react-redux"
import store from "redux/store"

// --------------------
// App Holder Component
// --------------------

// - - - - Adding providers - - - - //
// 1react router
// 2-apollo graphql
// 3-redux
// 4-root app
// - - - - - - - - - - - - - - - - //

// TODO : check all forms (showErrorHint) state and make sure all are used correctly with respect to (errorModal)

const App = () => {
   return (
      <BrowserRouter>
         <ApolloProvider>
            <Provider store={store}>
               <RootApp />
            </Provider>
         </ApolloProvider>
      </BrowserRouter>
   )
}

export default App
