import { Form, PasswordField, TextField, SubmitButton, Checkbox } from "components/form"
import * as yup from "yup"
import styled from "@emotion/styled"
import { font, color } from "rentpost/styles"
import { ApolloError } from "@apollo/client"
import { Link } from "react-router-dom"
import ROUTE from "routes/ROUTE"

interface LoginFieldsProp {
   username: string
   password: string
   rememberMe: boolean
}

const initialValues: LoginFieldsProp = {
   username: "",
   password: "",
   rememberMe: false,
}

const validationSchema = yup.object({
   username: yup.string().required("Email or Username is required!"),
   password: yup.string().required("Password is required!"),
})

interface LoginFormProps {
   onSubmit: (values: LoginFieldsProp) => void
   error: ApolloError | undefined
   loading: boolean
}

const LoginForm = (props: LoginFormProps) => {
   const { onSubmit, error, loading } = props

   return (
      <Holder>
         <Form
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            error={error}
            loading={loading}
            showErrorHint
         >
            <TextField name="username" label="Username or Email" />
            <PasswordField name="password" label="Password" />
            <span className="remember-login">
               <Checkbox name="rememberMe" label="Remember me" />
               <Link
                  to={ROUTE.SIGN_IN_ASSISTANCE.ROOT + ROUTE.SIGN_IN_ASSISTANCE.PASSWORD}
                  style={{ fontStyle: "italic", whiteSpace: "nowrap" }}
               >
                  forgot password?
               </Link>
            </span>
            <SubmitButton>Sign in</SubmitButton>
         </Form>
      </Holder>
   )
}

const Holder = styled.div`
   width: 100%;
   .remember-login {
      display: flex;
      justify-content: space-between;
      align-items: center;
      a {
         ${font.thin};
         color: ${color.black};
      }
   }
`

export type { LoginFieldsProp, LoginFormProps }
export default LoginForm
