import styled from "@emotion/styled"
import { Hint, SingleFieldForm } from "rentpost/components/form"
import { TwoFactorMethod, UseTwoFactorAuthProps } from "../useTwoFactorAuth"
import useTriggerHint from "hooks/useTriggerHint"
import { Button } from "@mui/material"
import { useEffect, useState } from "react"
import { color, space } from "rentpost/styles"
import useLogout from "pages/Logout/useLogout"
import { ApolloError } from "@apollo/client"
import TwoFactorAuthMethodSelection from "./TwoFactorAuthMethodSelection"
import getApolloErrorDetails from "utils/getApolloErrorDetails"

interface Props {
   verifyTwoFactorAuth: UseTwoFactorAuthProps["verifyTwoFactorAuth"]
   verifyTwoFactorAuthError: ApolloError | undefined
   verifyTwoFactorAuthLoading: boolean
   resendTwoFactorAuthCode: UseTwoFactorAuthProps["resendTwoFactorAuthCode"]
   resendTwoFactorAuthCodeError: ApolloError | undefined
   resendTwoFactorAuthCodeLoading: boolean
   twoFactorAuthMethod: UseTwoFactorAuthProps["twoFactorAuthMethod"]
   setTwoFactorAuthMethod: UseTwoFactorAuthProps["setTwoFactorAuthMethod"]
   attemptCount: UseTwoFactorAuthProps["attemptCount"]
   settings: UseTwoFactorAuthProps["settings"]
   onClose: () => void
}

const ValidationForm: React.FC<Props> = (props) => {
   const {
      verifyTwoFactorAuth,
      verifyTwoFactorAuthError,
      verifyTwoFactorAuthLoading,
      resendTwoFactorAuthCode,
      resendTwoFactorAuthCodeError,
      resendTwoFactorAuthCodeLoading,
      attemptCount,
      setTwoFactorAuthMethod,
      twoFactorAuthMethod,
      settings,
      onClose,
   } = props

   const [triggerVerify2FA_Hint, onFocusVerify2FA] = useTriggerHint(verifyTwoFactorAuthError)
   const [resetForm, setResetForm] = useState<boolean>(false)
   const { gotoLogoutPage } = useLogout()

   // ========
   // Handlers
   // ========
   const handleSubmit = (value: { field: any }) => {
      verifyTwoFactorAuth(value.field as string)
   }

   const [attemptDelay, setAttemptDelay] = useState<number>(0)
   // Start counting the delay after each attempt to resend email, delay is 120 seconds, render the count down
   // if the delay is not 0
   if (attemptDelay > 0) {
      setTimeout(() => {
         // Decrease the delay by 1 second every second
         setAttemptDelay(attemptDelay - 1)
      }, 1000)
   }

   // If the attempt count is less than 5, reset the delay to 120 seconds after each attempt
   useEffect(() => {
      if (attemptCount < 5 && attemptCount !== 0) {
         setAttemptDelay(120)
      }
   }, [attemptCount])

   const handlerResendTwoFactorAuthCode = () => {
      resendTwoFactorAuthCode()
      setResetForm(true)
      setTimeout(() => {
         // Wait for the form to reset before hiding the hint (500 MS - is the duration of animation)
         setResetForm(false)
      }, 500)
   }

   const logout = () => {
      onClose()
      gotoLogoutPage()
   }

   const twoFactorAuthMethodSelectionProps = {
      value: twoFactorAuthMethod as TwoFactorMethod,
      onChange: setTwoFactorAuthMethod,
      hideOption:
         settings.twoFactorAuthMethod === "SMS" ? TwoFactorMethod.Whatsapp : TwoFactorMethod.SMS,
   }

   const isEmailMethod = settings.twoFactorAuthMethod === "Email"

   // ===
   // JSX
   // ===
   return (
      <Holder>
         <div className="content">
            <h2>Verify (2FA) Code</h2>
            {settings.twoFactorAuthMethod === "Email" ? (
               <p className="main-description">
                  Please enter the 6-digit code we just sent you in your email
               </p>
            ) : (
               <p className="main-description">
                  {`Please enter the 6-digit code we just sent you via ${settings.twoFactorAuthMethod} on number ending in ${settings.twoFactorAuthPhoneLastFourDigits}`}
               </p>
            )}
            {verifyTwoFactorAuthError && (
               <Hint
                  type="error"
                  text={getApolloErrorDetails(verifyTwoFactorAuthError).errorMessage}
                  canHide
                  triggerAutoHide={triggerVerify2FA_Hint}
               />
            )}
            <SingleFieldForm
               type="otp"
               onSubmit={handleSubmit}
               otpFieldSettings={{ numberOfCharacters: 6, gap: 3.2 }}
               loading={verifyTwoFactorAuthLoading}
               onFormFocus={onFocusVerify2FA}
               resetForm={resetForm}
            />
            <div className="resend">
               {attemptDelay > 0 && (
                  <Hint
                     text="A code has been resent. Check your phone."
                     type="success"
                     canHide
                     autoHideTimeout={5000}
                     triggerAutoHide
                     key={attemptCount}
                  />
               )}
               {resendTwoFactorAuthCodeError && (
                  <Hint
                     type="error"
                     text={getApolloErrorDetails(resendTwoFactorAuthCodeError).errorMessage}
                     canHide
                  />
               )}
               <div className="gray-container">
                  <p>
                     If you didn't receive a text message, don't worry. You can request a new one
                     here{!isEmailMethod ? ", or try a different method." : "."}
                  </p>
                  {!isEmailMethod && (
                     <TwoFactorAuthMethodSelection {...twoFactorAuthMethodSelectionProps} />
                  )}
                  <Button
                     variant="outlined"
                     color="secondary"
                     style={{ borderRadius: "50em", width: "100%", marginTop: "1rem" }}
                     onClick={handlerResendTwoFactorAuthCode}
                     disabled={
                        resendTwoFactorAuthCodeLoading || attemptCount >= 5 || attemptDelay !== 0
                     }
                  >
                     {attemptDelay === 0
                        ? resendTwoFactorAuthCodeLoading
                           ? "Resending..."
                           : "Resend Code"
                        : attemptDelay + " second until next attempt"}
                  </Button>
               </div>
               <p className="logout-message">
                  If you no longer have access to this{" "}
                  {`${isEmailMethod ? "email address" : "phone number"}`}, please reach out to{" "}
                  <a href="mailto:help@rentpost.com"> RentPost support</a> for manual verification
                  assistance. Alternatively, you can <a onClick={logout}>logout</a> and sign into
                  another account.
               </p>
            </div>
         </div>
      </Holder>
   )
}

const Holder = styled.div`
   display: grid;
   place-content: center;

   .configure-2fa {
      position: absolute;
      right: ${space.xxl};
      top: ${space.m};
   }

   .content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      max-width: 30em;
   }

   .gray-container {
      margin-block: ${space.l};
   }

   .logout-message {
      text-align: center;
      color: ${color.grayDark};
      padding: ${space.m} ${space.l};
      border-radius: ${space.m};
      background-color: #fdfef0;
      a {
         cursor: pointer;
         color: ${color.violet};
         text-decoration: underline;
      }
   }

   .two-factor-auth-method-selection {
      font-size: 0.8em;
      width: 100%;
   }
`

export default ValidationForm
