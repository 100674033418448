import store from "redux/store"
import getPASETOFooter from "./getPASETOFooter"

const secondaryAuthToken = {
   setToken: (token: string) => {
      store.dispatch({ type: "secondaryAuthToken/setToken", payload: token })
   },

   getToken: () => {
      return store.getState().secondaryAuthToken.value
   },

   getUserID: () => {
      const token = secondaryAuthToken.getToken()
      return token ? getPASETOFooter(token).userId : null
   },

   removeToken: () => {
      store.dispatch({ type: "secondaryAuthToken/setToken", payload: null })
   },
}

export default secondaryAuthToken
