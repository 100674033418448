import MultiSectionsLayout from "rentpost/layouts/MultiSectionsLayout"
import Illustration from "../Illustration"
import styled from "@emotion/styled"
import { Hint } from "rentpost/components/form"
import useTwoFactorAuth, { TwoFactorMethod } from "../useTwoFactorAuth"
import { Button, CircularProgress } from "@mui/material"
import { useEffect, useState } from "react"
import { color, font, size, space } from "rentpost/styles"
import { parsePhoneNumber } from "libphonenumber-js"
import { Modal, ModalProps } from "layouts"
import CustomSwitch from "./CustomSwitch"
import { useNavigate } from "react-router-dom"
import TwoFactorAuthMethodSelection from "./TwoFactorAuthMethodSelection"
import { AnimateWithVariants } from "rentpost/components/functional"
import useSettings from "hooks/useSettings"
import { BackButton } from "rentpost/components/other"
import ROUTE from "routes/ROUTE"
import getApolloErrorDetails from "utils/getApolloErrorDetails"

const SetupTwoFactorAuth: React.FC = () => {
   return (
      <MultiSectionsLayout
         illustrationSection={<Illustration />}
         mainSection={<MainSection />}
         htmlTitle="Setup (2FA) | RentPost"
      />
   )
}

// ---------------------
// MainSection Component
// ---------------------
const MainSection: React.FC = () => {
   const { settings, getUserInformation, userData } = useTwoFactorAuth()
   const navigate = useNavigate()
   const { getSettings } = useSettings()

   // ==================
   // Handlers & Helpers
   // ==================
   const goBack = () => navigate(-1)

   useEffect(() => {
      getUserInformation()
      getSettings()
   }, [])

   const showActive = settings?.twoFactorAuthEnabled === true && userData?.email
   const showInactive = settings?.twoFactorAuthEnabled === false && userData?.email

   // ===
   // JSX
   // ===
   return (
      <Holder>
         <BackButton onClick={goBack} />
         <AnimateWithVariants className="content" animate="pageAnimate">
            {showActive && <Active2FA {...{ userData }} />}
            {showInactive && <Inactive2FA {...{ userData }} />}
         </AnimateWithVariants>

         <p className="description-2fa">
            Two-factor authentication (2FA) is an extra layer of security that helps protect your
            account from unauthorized access. With 2FA enabled, you will be asked to enter a code
            from your phone, whatsapp or email.
         </p>
      </Holder>
   )
}

const MethodInformation = ({
   twoFactorAuthMethod,
   userData,
   is2FAEnabled,
}: {
   twoFactorAuthMethod: keyof typeof TwoFactorMethod
   is2FAEnabled: boolean
   userData: {
      phoneNumber?: any
      email?: string
   } | null
}) => {
   const navigate = useNavigate()
   const goToAccount = () => navigate(ROUTE.PROFILE.ACCOUNT)

   const phoneNumber = userData?.phoneNumber
   const email = userData?.email

   const isPhoneNumberDependant =
      phoneNumber && (twoFactorAuthMethod === "SMS" || twoFactorAuthMethod === "Whatsapp")

   return (
      <div className="information-holder">
         <span className={"information " + (is2FAEnabled ? "active" : "")}>
            {isPhoneNumberDependant ? parsePhoneNumber(phoneNumber).formatInternational() : email}
         </span>

         <Button variant="outlined" color="primary" className="update-button" onClick={goToAccount}>
            Update
         </Button>
      </div>
   )
}

const Inactive2FA = ({
   userData,
}: {
   userData: {
      phoneNumber?: any
      email?: string
   } | null
}) => {
   const {
      enableTwoFactorAuth,
      loading: { enableTwoFactorAuthLoading },
      error: { enableTwoFactorAuthError },
      twoFactorAuthMethod,
      setTwoFactorAuthMethod,
      getUserInformation,
   } = useTwoFactorAuth()

   const navigate = useNavigate()
   const goToAccount = () => navigate(ROUTE.PROFILE.ACCOUNT)

   const isSMS = twoFactorAuthMethod === "SMS"
   const isWhatsapp = twoFactorAuthMethod === "Whatsapp"
   const needToAddPhoneNumber = !userData?.phoneNumber && (isSMS || isWhatsapp)

   const methodInformationProps = {
      twoFactorAuthMethod,
      userData,
      is2FAEnabled: false,
   }

   // =======
   // Effects
   // =======

   // Get user information on mount
   useEffect(() => {
      getUserInformation()
   }, [])

   return (
      <>
         <h1>Enable two-factor-authentication (2FA)</h1>
         <p className="main-description">
            To activate Two Factor Authentication (2FA), you must select the method for receiving
            the 2FA code. After selection, a code will be sent to you for verification.
         </p>
         {enableTwoFactorAuthError && (
            <Hint
               text={getApolloErrorDetails(enableTwoFactorAuthError).errorMessage}
               type="error"
               canHide
            />
         )}
         <TwoFactorAuthMethodSelection
            value={twoFactorAuthMethod as TwoFactorMethod}
            onChange={setTwoFactorAuthMethod}
         />
         <MethodInformation {...methodInformationProps} />
         {needToAddPhoneNumber && (
            <Hint text="Please add your phone number to enable 2FA" type="warning" canHide />
         )}
         <Button
            variant="contained"
            color="primary"
            onClick={needToAddPhoneNumber ? goToAccount : enableTwoFactorAuth}
            fullWidth
            disabled={enableTwoFactorAuthLoading}
            style={{ borderRadius: "50px", marginTop: "2em" }}
            endIcon={enableTwoFactorAuthLoading ? <CircularProgress size={"100%"} /> : null}
         >
            {`${needToAddPhoneNumber ? "Add Phone Number" : "Enable & Verify"}`}
         </Button>
      </>
   )
}

const Active2FA = ({
   userData,
}: {
   userData: {
      phoneNumber?: any
      email?: string
   } | null
}) => {
   const {
      error: { disableTwoFactorAuthError, updateTwoFactorAuthMethodError },
      loading: { disableTwoFactorAuthLoading, updateTwoFactorAuthMethodLoading },
      twoFactorAuthMethod,
      disableTwoFactorAuth,
      settings,
      setTwoFactorAuthMethod,
      updateTwoFactorAuthMethod,
      setActiveModal,
      activeModal,
      getUserInformation,
   } = useTwoFactorAuth()

   const [twoFactorAuthSwitchState, setTwoFactorAuthSwitchState] = useState(true)
   const methodHasChanged = settings?.twoFactorAuthMethod !== twoFactorAuthMethod

   const methodInformationProps = {
      twoFactorAuthMethod,
      userData,
      is2FAEnabled: true,
   }
   // ===========
   // Modal Props
   // ===========
   const modalDisable2FAProps = {
      activeModal,
      disableTwoFactorAuth,
      disableTwoFactorAuthLoading,
      onClose: () => setActiveModal(false),
   }

   // =======
   // Effects
   // =======

   // Get user information on mount
   useEffect(() => {
      getUserInformation()
   }, [])

   // Show disableTwoFactorAuth modal when twoFactorAuthSwitchState is set to false
   useEffect(() => {
      twoFactorAuthSwitchState === false && setActiveModal("disableTwoFactorAuth")
   }, [twoFactorAuthSwitchState])

   // Set twoFactorAuthSwitchState to true when disableTwoFactorAuth modal is closed and 2FA is still enabled
   useEffect(() => {
      activeModal === false && setTwoFactorAuthSwitchState(true)
   }, [activeModal])

   return (
      <>
         <h1>Configure two-factor-authentication (2FA)</h1>
         <p className="main-description">
            You have the flexibility to select the method for receiving your 2FA code, and the
            option to turn off 2FA if needed.
         </p>
         {(updateTwoFactorAuthMethodError || disableTwoFactorAuthError) && (
            <Hint
               type="error"
               text={
                  getApolloErrorDetails(updateTwoFactorAuthMethodError || disableTwoFactorAuthError)
                     .errorMessage
               }
               canHide
            />
         )}
         <TwoFactorAuthMethodSelection
            value={twoFactorAuthMethod as TwoFactorMethod}
            onChange={setTwoFactorAuthMethod}
         />
         <MethodInformation {...methodInformationProps} />
         <div className="toggle-2fa">
            <h3>Toggle 2FA</h3>
            <CustomSwitch
               setChecked={setTwoFactorAuthSwitchState}
               checked={twoFactorAuthSwitchState}
            />
         </div>
         <Button
            variant="contained"
            color="primary"
            onClick={updateTwoFactorAuthMethod}
            fullWidth
            disabled={!methodHasChanged || updateTwoFactorAuthMethodLoading || !methodHasChanged}
            style={{ borderRadius: "50px", marginTop: "2em" }}
            endIcon={updateTwoFactorAuthMethodLoading ? <CircularProgress size={"100%"} /> : null}
         >
            Confirm Changes
         </Button>
         <ModalDisableTwoFactorAuth {...modalDisable2FAProps} />
      </>
   )
}
// =================
// Modal Disable 2FA
// =================
interface ModalDisableTwoFactorAuthProps {
   activeModal: string | false
   onClose: () => void
   disableTwoFactorAuth: () => void
   disableTwoFactorAuthLoading: boolean
}
const ModalDisableTwoFactorAuth: React.FC<ModalDisableTwoFactorAuthProps> = ({
   activeModal,
   onClose,
   disableTwoFactorAuth,
   disableTwoFactorAuthLoading,
}) => {
   const props: ModalProps = {
      visible: activeModal === "disableTwoFactorAuth",
      onClose,
      title: "Disable 2FA",
      description: "Are you sure you want to disable 2FA?",
      type: "warning",
      actionButtons: {
         accept: {
            label: "Yes",
            onClick: disableTwoFactorAuth,
         },
         cancel: {
            label: "No",
         },
      },
      loading: disableTwoFactorAuthLoading,
      canIgnore: true,
   }

   return <Modal {...props} />
}

const Holder = styled.div`
   display: grid;
   place-content: center;
   .configure-2fa {
      position: absolute;
      right: ${space.xxl};
      top: ${space.m};
   }

   .content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-top: ${space.m};

      .information-holder {
         position: relative;
         width: 100%;
         display: flex;
         align-items: center;
         justify-content: space-between;

         .information {
            font-size: 1.4em;
            max-width: 65%;
            overflow: hidden;
            white-space: nowrap;
            display: block;
            text-overflow: ellipsis;
            ${font.regular};
            color: ${color.grayDark};
            &.active {
               color: ${color.green};
            }
         }
      }

      .toggle-2fa {
         display: flex;
         flex-direction: row;
         align-items: center;
         justify-content: space-between;
         width: 100%;

         h3 {
            white-space: nowrap;
            padding-block: 0.2em 1em;
         }

         .MuiSwitch-root {
            justify-self: flex-end;
         }
      }
   }

   .description-2fa {
      font-size: ${size.xs};
      text-align: center;
      opacity: 0.7;
      color: ${color.grayDark};
   }

   .separation-line {
      height: 1px;
      width: 100%;
      margin-top: ${space.m};
   }
`
export default SetupTwoFactorAuth
