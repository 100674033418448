import SVG from "assets/svg"
import { AuthorizedDevice } from "./UseSecurity"

interface Props {
   operatingSystem: AuthorizedDevice["operatingSystem"] | undefined
   colored?: boolean
   className?: string
}

const OperatingSystemIcon: React.FC<Props> = (props) => {
   // ------------------------------------
   // Operating system svg icon - Monotone
   // ------------------------------------
   let operatingSystemIconMonotone: React.ReactElement | null = null
   // operatingSystemName is equal or similar to operatingSystem.name
   const operatingSystemName = props.operatingSystem?.name || ""
   const similarTo = (name: string): boolean =>
      operatingSystemName.toLowerCase().includes(name.toLowerCase())
   switch (true) {
      case similarTo("windows"):
         operatingSystemIconMonotone = (
            <SVG.operatingSystem.windowsMonotone className={props.className ?? ""} />
         )
         break

      case similarTo("mac"):
         operatingSystemIconMonotone = (
            <SVG.operatingSystem.macOSMonotone className={props.className ?? ""} />
         )
         break

      case similarTo("linux"):
         operatingSystemIconMonotone = (
            <SVG.operatingSystem.linuxMonotone className={props.className ?? ""} />
         )
         break

      case similarTo("chrome"):
         operatingSystemIconMonotone = (
            <SVG.operatingSystem.chromeOSMonotone className={props.className ?? ""} />
         )
         break

      case similarTo("android"):
         operatingSystemIconMonotone = (
            <SVG.operatingSystem.androidMonotone className={props.className ?? ""} />
         )
         break

      case similarTo("ios"):
         operatingSystemIconMonotone = (
            <SVG.operatingSystem.appleMonotone className={props.className ?? ""} />
         )
         break

      case similarTo("blackberry"):
         operatingSystemIconMonotone = (
            <SVG.operatingSystem.blackberry className={props.className ?? ""} />
         )
         break

      case similarTo("fire"):
         operatingSystemIconMonotone = (
            <SVG.operatingSystem.fireOSMonotone className={props.className ?? ""} />
         )
         break

      case similarTo("playstation"):
         operatingSystemIconMonotone = (
            <SVG.operatingSystem.playstationMonotone className={props.className ?? ""} />
         )
         break

      default:
         operatingSystemIconMonotone = null
   }

   // -----------------------------------
   // Operating system svg icon - Colored
   // -----------------------------------
   let operatingSystemIconColored: React.ReactElement | null = null
   switch (true) {
      case similarTo("windows"):
         operatingSystemIconColored = (
            <SVG.operatingSystem.windows className={props.className ?? ""} />
         )
         break

      case similarTo("mac"):
         operatingSystemIconColored = (
            <SVG.operatingSystem.macOS className={props.className ?? ""} />
         )
         break

      case similarTo("linux"):
         operatingSystemIconColored = (
            <SVG.operatingSystem.linux className={props.className ?? ""} />
         )
         break

      case similarTo("chrome"):
         operatingSystemIconColored = (
            <SVG.operatingSystem.chromeOS className={props.className ?? ""} />
         )
         break

      case similarTo("android"):
         operatingSystemIconColored = (
            <SVG.operatingSystem.android className={props.className ?? ""} />
         )
         break

      case similarTo("ios"):
         operatingSystemIconColored = (
            <SVG.operatingSystem.apple className={props.className ?? ""} />
         )
         break

      case similarTo("blackberry"):
         operatingSystemIconColored = (
            <SVG.operatingSystem.blackberry className={props.className ?? ""} />
         )
         break

      case similarTo("fire"):
         operatingSystemIconColored = (
            <SVG.operatingSystem.fireOS className={props.className ?? ""} />
         )
         break

      case similarTo("playstation"):
         operatingSystemIconColored = (
            <SVG.operatingSystem.playstation className={props.className ?? ""} />
         )
         break

      default:
         operatingSystemIconColored = null
   }
   return props.colored ? operatingSystemIconColored : operatingSystemIconMonotone
}

export default OperatingSystemIcon
