import QueueLink from "apollo-link-queue"
import store from "redux/store"

const queueLink = new QueueLink()

// -------------------------
// Check Verification Status
// -------------------------
// @ts-ignore - this makes tsc happy
store.subscribe(() => {
   const activeModal = store.getState().modal.activeModal
   // PAUSE
   if (activeModal === "twoFactorAuth" || activeModal === "twoFactorAuthSetup") {
      queueLink.close()
   }

   // RESUME
   activeModal === false && queueLink.open()
})

export default queueLink
