const ROUTE = {
   INDEX: "/",
   LOGIN: "/login",
   LOGOUT: "/logout",
   SIGN_UP: "/signup",
   START: "/start",
   MERGE: "/merge",
   REGISTER: "/register",
   VERIFY: {
      ROOT: "/verify",
      EMAIL: "/email",
      SETUP_2FA: "/2fa/setup",
   },
   SIGN_IN_ASSISTANCE: {
      ROOT: "/login-assistance",
      PASSWORD: "/password",
   },
   PROFILE: {
      ACCOUNT: "/account",
      INTEGRATIONS: "/integrations",
      SECURITY: "/security",
      OVERVIEW: "/overview",
      ROLES: "/roles",
   },
   SSO: {
      ROOT: "/sso",
      ZENDESK: "/zendesk",
   },
   ONBOARDING: {
      ROOT: "/onboarding",
      PERSONAL_INFO: "personal-info",
      COMPANY_INFO: "company-info",
      UNIT_AND_TENANT: "unit-and-tenant",
   },
}

export default ROUTE
