import * as Sentry from "@sentry/react"
import obfuscate from "./obfuscate"
import _ from "lodash"

interface GraphQlContextProps {
   label: string
   operationName: string
   statusCode: number
   operationBody: object | string
   payload: object
   headers: object
   ErrorMessage: string
}

const graphQlSentryContext = ({
   label,
   operationName,
   statusCode,
   operationBody,
   payload,
   headers,
   ErrorMessage,
}: GraphQlContextProps) => {
   const obfuscatedPayload = obfuscate(payload)
   const obfuscatedHeaders = obfuscate(headers)

   // Remove undefined and null values using lodash and stringify the payload values of the first level
   const readyPayload = _.mapValues(obfuscatedPayload, (value) => {
      if (typeof value === "object") {
         return JSON.stringify(value)
      } else {
         return value
      }
   })
   const readyHeaders = _.mapValues(obfuscatedHeaders, (value) => {
      if (typeof value === "object") {
         return JSON.stringify(value)
      } else {
         return value
      }
   })

   // Add context to Sentry
   return Sentry.setContext(label, {
      ErrorMessage,
      operationName,
      statusCode,
      operationBody,
      payload: readyPayload,
      headers: readyHeaders,
   })
}

export { graphQlSentryContext }
