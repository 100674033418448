import TenantForm from "./TenantForm"
import UnitForm from "./UnitForm"
import UnitDetails from "./UnitDetails"
import SideMenu from "rentpost/src/layouts/SideMenu"
import { ComponentProps, useEffect, useState } from "react"
import { Button, CircularProgress } from "@mui/material"
import SVG from "assets/svg"
import useOnboarding from "../useOnboarding"
import { useOutletContext } from "react-router-dom"
import { OwnerType, TenantFormFields, Unit, UnitFormFields } from "./useUnitAndTenant"
import Skeleton from "../Skeleton"
import { Hint } from "components/form"
import getApolloErrorDetails from "utils/getApolloErrorDetails"
import useCompleteOnboarding from "./useCompleteOnboarding"

const UnitAndTenant: React.FC = () => {
   const [visible, setVisible] = useState(false)
   const [selectedUnitId, setSelectedUnitId] = useState<string | null>(null)
   const [selectedTenantId, setSelectedTenantId] = useState<string | null>(null)
   const [sideMenuContent, setSideMenuContent] = useState<
      "add_unit" | "add_tenant" | "update_unit" | "update_tenant" | null
   >(null)
   const {
      unitAndTenant: {
         error,
         loading,
         addTenant,
         updateTenant,
         addUnit,
         updateUnit,
         units,
         availableOwners: availableOwnersInitialValue,
      },
      companyInfo: { companyInfo },
      goBack,
   } = useOutletContext() as ReturnType<typeof useOnboarding>
   const {
      handleCompleteOnboarding,
      loading: { setCompanyPermissionLoading, setOnboardingCompletedLoading },
      error: { setCompanyPermissionError, setOnboardingCompletedError },
   } = useCompleteOnboarding({
      companyLegalEntityId: companyInfo?.legalEntityId,
      unitOwnerLegalEntityId: units?.[0]?.owner.legalEntityId,
   })
   const onCloseSideMenu = () => {
      setVisible(false)
      setSelectedUnitId(null)
      setSelectedTenantId(null)
   }

   const showAddTenant = (unitId: string) => {
      setSelectedUnitId(unitId)
      setVisible(true)
      setSideMenuContent("add_tenant")
   }

   const showUpdateTenant = (unitId: string, tenantId: string) => {
      setSelectedUnitId(unitId)
      setSelectedTenantId(tenantId)
      setVisible(true)
      setSideMenuContent("update_tenant")
   }

   const showUpdateUnit = (unitId: string) => {
      setSelectedUnitId(unitId)
      setVisible(true)
      setSideMenuContent("update_unit")
   }

   const unit = units?.[0]
   const availableOwners = availableOwnersInitialValue as OwnerType[]

   const unitDetailsProps = (): ComponentProps<typeof UnitDetails> => {
      return {
         unit: unit as Unit,
         onEditClick: showUpdateUnit,
         onTenantEditClick: showUpdateTenant,
         onAddTenantClick: showAddTenant,
      }
   }

   const tenantFormProps = (): ComponentProps<typeof TenantForm> => {
      const tenant = unit?.tenants.find(({ id }) => id === selectedTenantId)
      const initialValues: TenantFormFields = {
         id: tenant?.id || undefined,
         unitId: selectedUnitId as string,
         email: tenant?.email || "",
         firstName: tenant?.firstName || "",
         lastName: tenant?.lastName || "",
         phone: tenant?.phone || "",
         rentAmount: tenant?.rentAmount || "",
         rentDueDay: `${tenant?.rentDueDay || ""}`,
         startDate: tenant?.startDate || null,
         endDate: tenant?.endDate || null,
         typeKey: tenant?.typeKey || "natural_person",
      }
      return {
         onCancel: () => setVisible(false),
         initialValues,
         loading:
            loading.addTenantLoading ||
            loading.updateTenantLoading ||
            loading.getUnitsAndTenantsLoading,
         error: error.addTenantError || error.updateTenantError,
         onSubmit: (tenant) => (tenant.id ? updateTenant(tenant) : addTenant(tenant)),
      }
   }

   const unitFormProps = (): ComponentProps<typeof UnitForm> => {
      const initialValues: UnitFormFields = {
         id: unit?.id || undefined,
         typeKey: unit?.typeKey || "apartment",
         desiredRentAmount: unit?.desiredRentAmount || "",
         bathrooms: unit?.bathrooms || 1,
         bedrooms: unit?.bedrooms || 1,
         sqft: `${unit?.sqft || ""}`,
         address: {
            id: unit?.address.id || undefined,
            countryKey: unit?.address.countryKey || companyInfo?.address?.countryKey || "",
            state: unit?.address.state || "",
            city: unit?.address.city || "",
            street: unit?.address.street || "",
            aptSuiteEtc: unit?.address.aptSuiteEtc || "",
            postalCode: unit?.address.postalCode || "",
         },
         arePetsAllowed: unit?.arePetsAllowed || false,
         owner: {
            id: unit?.owner.id || "",
         },
      }
      return {
         loading:
            loading.addUnitLoading ||
            loading.addOwnerLoading ||
            loading.addBankAccountLoading ||
            loading.updateUnitLoading ||
            loading.getUnitsAndTenantsLoading ||
            loading.getAvailableOwnersLoading ||
            loading.getBankAccountsLoading,
         error: error.addUnitError || error.updateUnitError,
         initialValues,
         availableOwners,
         onSubmit: (unit) => (unit.id ? updateUnit(unit) : addUnit(unit)),
         onCancel: () => setVisible(false),
      }
   }

   const sideMenuProps = (): ComponentProps<typeof SideMenu> => {
      const title = () => {
         switch (sideMenuContent) {
            case "add_unit":
               return "Add Unit"
            case "add_tenant":
               return "Add Tenant"
            case "update_unit":
               return "Update Unit"
            case "update_tenant":
               return "Update Tenant"
            default:
               return "Add Unit"
         }
      }
      return {
         onClose: onCloseSideMenu,
         visible,
         render: () => {
            switch (sideMenuContent) {
               case "update_unit":
               case "add_unit":
                  return <UnitForm {...unitFormProps()} />
               case "update_tenant":
               case "add_tenant":
                  return <TenantForm {...tenantFormProps()} />
               default:
                  return null
            }
         },
         title: title(),
      }
   }

   const onAddUnitClick = () => {
      setVisible(true)
      setSideMenuContent("add_unit")
   }

   const disabled =
      units?.length === 0 || setCompanyPermissionLoading || setCompanyPermissionLoading

   const completeOnboardingLoading = setCompanyPermissionLoading || setOnboardingCompletedLoading

   useEffect(() => {
      const isAddUnitSuccess = !loading.addUnitLoading && !error.addUnitError
      const isUpdateUnitSuccess = !loading.updateUnitLoading && !error.updateUnitError
      const isAddTenantSuccess = !loading.addTenantLoading && !error.addTenantError
      const isUpdateTenantSuccess = !loading.updateTenantLoading && !error.updateTenantError
      if (isAddUnitSuccess || isUpdateUnitSuccess || isAddTenantSuccess || isUpdateTenantSuccess) {
         onCloseSideMenu()
      }
   }, [loading.getUnitsAndTenantsLoading])
   const isCompleteOnboardingHasError = setCompanyPermissionError || setOnboardingCompletedError

   if (unit === undefined && loading.getUnitsAndTenantsLoading) return <Skeleton />

   return (
      <div className="page-content">
         <h2>Add a Unit</h2>
         <p className="description">
            You can obviously add more later, but let's start with one at least so you'll have some
            data in your account.
         </p>
         {isCompleteOnboardingHasError && (
            <Hint
               type="error"
               text={
                  getApolloErrorDetails(setCompanyPermissionError || setOnboardingCompletedError)
                     .errorMessage
               }
               canHide
            />
         )}
         {units && units.length >= 1 ? (
            <UnitDetails {...unitDetailsProps()} />
         ) : (
            <Button onClick={onAddUnitClick} variant="outlined" color="secondary">
               + Add Unit
            </Button>
         )}
         <SideMenu {...sideMenuProps()} />
         <div className="action-buttons-holder page-buttons">
            <Button
               variant="text"
               color="secondary"
               startIcon={<SVG.form.arrowLeft />}
               className="back-button"
               onClick={goBack}
            >
               Back
            </Button>
            <Button
               variant="contained"
               color="primary"
               endIcon={completeOnboardingLoading ? <CircularProgress size={"100%"} /> : null}
               disabled={disabled}
               className="next-button"
               onClick={handleCompleteOnboarding}
            >
               Finish Setup
            </Button>
         </div>
      </div>
   )
}
export default UnitAndTenant
