import PageMaintenanceElement from "components/webComponents/PageErrorMaintenance"
import { sentryTag } from "configs/sentry"
import { useEffect } from "react"

const MaintenanceErrorLayout: React.FC = () => {
   useEffect(() => {
      // Set the tag to show that the MaintenanceErrorLayout has been shown
      sentryTag("error-layout-shown", "Maintenance Error Layout")
   }, [])
   return <PageMaintenanceElement />
}

export default MaintenanceErrorLayout
