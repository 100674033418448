import { createSlice, PayloadAction } from "@reduxjs/toolkit"

const secondaryAuthTokenSlice = createSlice({
   name: "secondaryAuthToken",
   initialState: { value: null as string | null },
   reducers: {
      // ------------------------
      // OPEN ERROR MODAL REDUCER
      // ------------------------
      setToken: (state, action: PayloadAction<string | null>) => {
         state.value = action.payload
      },
   },
})

export const { setToken } = secondaryAuthTokenSlice.actions
export default secondaryAuthTokenSlice.reducer
