import { decode } from "base-64"
import { RoleType } from "rentpost/components/other"

interface footer {
   email: string | null
   userId: string | null
   roleId: string | null
   expiration: string
   roleType: RoleType | null
   zendeskMessengerJWT: string | null
}

const getPASETOFooter = (token: string): footer => {
   const footer = decode(token.split(".")[3])
   return footer && JSON.parse(footer)
}

export default getPASETOFooter
