import { useMutation } from "@apollo/client"
import { gql } from "@apollo/client"
import {
   ResetPassword,
   ResetPasswordVariables,
   SendPasswordReset,
   SendPasswordResetVariables,
} from "./__generated__"
import usePOEToken from "hooks/usePOEToken"
import bypassMockError from "utils/bypassMockError"
import { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"

type ActivePageType =
   | "email-form"
   | "email-sent-successfully"
   | "password-form"
   | "password-updated-successfully"

interface ResetPasswordFieldsProps {
   password: string
   passwordConfirm: string
}

interface SendResetPasswordFieldsProps {
   email: string
}

// ========
// MUTATION
// ========
const SendPasswordResetMutation = gql`
   mutation SendPasswordReset($email: Email!) {
      sendPasswordReset(email: $email)
   }
`

const ResetPasswordMutation = gql`
   mutation ResetPassword($input: ResetPasswordInput!) {
      resetPassword(input: $input)
   }
`

// =======================
// USE RESET PASSWORD HOOK
// =======================
const useResetPassword = () => {
   // ---------------
   // States & Hooks
   // ---------------
   const { poeToken, refreshPOEToken } = usePOEToken()
   const [activePage, setActivePage] = useState<ActivePageType>("email-form")
   const [searchParams] = useSearchParams()
   const uid = searchParams.get("uid") as string | null
   // ----------
   // Operations
   // ----------
   const [
      runSendPasswordReset,
      { loading: sendPasswordResetLoading, error: sendPasswordResetError },
   ] = useMutation<SendPasswordReset, SendPasswordResetVariables>(SendPasswordResetMutation)

   const [runResetPassword, { loading: resetPasswordLoading, error: resetPasswordError }] =
      useMutation<ResetPassword, ResetPasswordVariables>(ResetPasswordMutation)

   // --------
   // Handlers
   // --------
   const sendResetPassword = ({ email }: SendResetPasswordFieldsProps) => {
      runSendPasswordReset({
         context: {
            poeToken: poeToken.current,
            canWorkWithoutAuthorization: true,
         },
         variables: { email },
      })
         .then(() => {
            refreshPOEToken()
            setActivePage("email-sent-successfully")
         })
         .catch((e) => {
            bypassMockError(e)
         })
   }

   const resetPassword = (input: ResetPasswordFieldsProps) => {
      if (!uid) {
         return
      }
      runResetPassword({
         context: {
            poeToken: poeToken.current,
            canWorkWithoutAuthorization: true,
         },
         variables: {
            input: {
               resetUid: uid,
               ...input,
            },
         },
      })
         .then(() => {
            refreshPOEToken()
            setActivePage("password-updated-successfully")
         })
         .catch((e) => {
            bypassMockError(e)
         })
   }

   // -------
   // Effects
   // -------
   useEffect(() => {
      // If uid is present, then we are in the update password form
      uid && setActivePage("password-form")
   }, [uid])

   return {
      // States & Hooks
      activePage,

      // Operations
      sendResetPassword,
      resetPassword,

      // Loading
      loading: {
         sendPasswordResetLoading,
         resetPasswordLoading,
      },

      // Error
      error: {
         sendPasswordResetError,
         resetPasswordError,
      },
   }
}

export type { ActivePageType, ResetPasswordFieldsProps, SendResetPasswordFieldsProps }
export default useResetPassword
