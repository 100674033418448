import React from "react"
import { createComponent } from "@lit-labs/react"
import { PageErrorAuthFailedElement as Component } from "web-components"

const PageErrorAuthFailed = createComponent({
   tagName: "page-error-auth-failed",
   elementClass: Component,
   react: React,
   displayName: "PageErrorAuthFailed",
   events: {
      onClickLogin: "click-login",
   },
})

export default PageErrorAuthFailed
