const hideLoadingPage = () => {
   const loadingPage = document.querySelector("#initial-loading-page") as HTMLDivElement

   try {
      loadingPage.classList.remove("loading")
      loadingPage.classList.add("hide-page")
   } catch (error) {}
   setTimeout(() => {
      // Wait for 1.5 seconds before removing the loading page from the DOM, to avoid destroying it before the animation ends
      loadingPage.style.display = "none"
   }, 1500)
   window.sessionStorage.setItem("initial-loading-page-shown", "true")
}
export default hideLoadingPage
