import SVG from "assets/svg"
import styled from "@emotion/styled"
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom"
import { Helmet } from "react-helmet"
import useOnboarding from "./useOnboarding"
import { FooterLinks } from "rentpost/src/components/form"
import { color, screen, space } from "rentpost/src/styles"
import { maxWidth, padding, size } from "rentpost/src/styles/size"
import UserMenuComponent from "../../components/webComponents/UserMenu"
import { Button, IconButton } from "@mui/material"
import ROUTE from "routes/ROUTE"
import { NavigationSteps } from "rentpost/src/components/other"
import useSlowEffect from "hooks/useSlowEffect"
import AnimatedRoute from "components/functional/AnimatedRoute"
import { useCallback, useEffect, useState } from "react"
import hideLoadingPage from "utils/hideLoadingPage"
import { Modal } from "layouts"

const Onboarding = () => {
   const outletContext = useOnboarding()
   const { pathname } = useLocation()
   // get the last part of the pathname, and use it to determine which page to show in the navigation bar, convert to pascel case
   const onboardingPortalName = pathname.split("/").pop()?.replace(/-/g, " ")
   const onboardingPortalNamePascalCase = onboardingPortalName?.replace(/\w\S*/g, (w) =>
      w.replace(/^\w/, (c) => c.toUpperCase())
   )

   const navigationStepsProps: React.ComponentProps<typeof NavigationSteps> = {
      ...outletContext.navigationStepsState,
      clickable: true,
      collapsibleOnScroll: true,
   }

   // Load the main content of the page
   useSlowEffect(async () => {
      try {
         await outletContext.companyInfo.getCompanyInfo()
         await outletContext.companyInfo.getCountriesAndStatesList()
         await outletContext.personalInfo.getPersonalInfo()
         await outletContext.unitAndTenant.getAvailableOwners()
         await outletContext.unitAndTenant.getUnitsAndTenants()
         await outletContext.unitAndTenant.getBankAccounts()
      } catch (error) {}
   })

   // Set the activeStep index on session storage to persist the active step on page refresh
   useEffect(() => {
      sessionStorage.setItem(
         "onboardingStepState",
         JSON.stringify({
            steps: [
               {
                  completed: outletContext.navigationStepsState.steps[0].completed,
               },
               {
                  completed: outletContext.navigationStepsState.steps[1].completed,
               },
               {
                  completed: outletContext.navigationStepsState.steps[2].completed,
               },
            ],
            activeStep: outletContext.navigationStepsState.activeStep,
         })
      )
      hideLoadingPage()
   }, [outletContext.navigationStepsState])

   return (
      <Holder>
         <Helmet>
            <title>{onboardingPortalNamePascalCase} | RentPost</title>
         </Helmet>
         <Header
            goBack={outletContext.goBack}
            isFirstStep={outletContext.navigationStepsState.activeStep === 0}
         />
         <div className="page_holder">
            <NavigationSteps {...navigationStepsProps} className="navigation_steps_container" />
            <AnimatedRoute nestedKey={location.pathname}>
               <Outlet
                  context={{ ...outletContext } satisfies ReturnType<typeof useOnboarding>}
                  key={location.pathname}
               />
            </AnimatedRoute>
         </div>
         <footer data-testid="footer">
            <div className="footer-container">
               <span className="copy-rights">
                  Copyright © {new Date().getFullYear()} RentPost, Inc. All rights reserved.
               </span>
               <FooterLinks />
            </div>
         </footer>
      </Holder>
   )
}

// ================
// HEADER COMPONENT
// ================
const Header: React.FC<{ goBack: () => void; isFirstStep: boolean }> = ({
   goBack,
   isFirstStep,
}) => {
   const [showExitWarningModal, setShowExitWarningModal] = useState<boolean>(false)
   const handleExitOnboarding = () => setShowExitWarningModal(true)

   const UserMenu = useCallback(
      () => (
         <UserMenuComponent>
            <Link to={ROUTE.LOGIN} slot="not-logged-in">
               <Button variant="outlined" color="secondary" size="small">
                  Log In
               </Button>
            </Link>
         </UserMenuComponent>
      ),
      []
   )

   return (
      <nav className="header">
         <div className="desktop-and-tablet">
            <div className="navigation__bar-container">
               <SVG.logo.wordColored
                  className="navigation__bar-logo"
                  onClick={handleExitOnboarding}
               />
               <div className="navigation__bar-right__side__component">
                  <UserMenu />
               </div>
            </div>
         </div>
         <div className="mobile">
            <IconButton
               color="secondary"
               onClick={goBack}
               className="back-button"
               disabled={isFirstStep}
            >
               <SVG.form.arrowLeft />
            </IconButton>
            <SVG.logo.wordColored className="logo" onClick={handleExitOnboarding} />
            <UserMenu />
         </div>
         <ExitOnboardingWarningModal
            visible={showExitWarningModal}
            onClose={() => setShowExitWarningModal(false)}
         />
      </nav>
   )
}

const ExitOnboardingWarningModal: React.FC<{ visible: boolean; onClose: () => void }> = ({
   visible,
   onClose,
}) => {
   const navigate = useNavigate()
   const navigateToIndex = () => navigate(ROUTE.INDEX)
   return (
      <Modal
         visible={visible}
         onClose={onClose}
         title="Exit Onboarding"
         type="warning"
         description="You are going to exit the onboarding process. Are you sure you want to do this?"
         actionButtons={{
            accept: { label: "Yes, Exit", onClick: navigateToIndex },
            cancel: { label: "Cancel", onClick: onClose },
         }}
         canIgnore
      />
   )
}

// ================
// STYED COMPONENTS
// ================

const Holder = styled.div`
   display: flex;
   flex-direction: column;
   overflow-x: clip;
   scrollbar-gutter: stable;
   margin-top: 5em;
   --padding-inline: ${space.l};

   .page_holder {
      max-width: ${maxWidth.onboardingForm};
      min-width: min(${maxWidth.onboardingForm}, 100%);
      margin: 0 auto;
      min-height: calc(100dvh - 9.4em);
      padding-inline: var(--padding-inline);
      padding-block: ${space.l};
      box-sizing: border-box;

      p.description {
         text-align: center;
         font-size: ${size.m};
         color: ${color.grayLight};
      }

      h2 {
         text-align: center;
         margin-bottom: ${space.xxs};
      }

      .navigation_steps_container {
         z-index: 2;
      }

      .action-buttons-holder {
         display: flex;
         gap: 1em;
         justify-content: flex-end;

         &.page-buttons {
            margin-top: ${space.m};
            justify-content: space-between;
         }
      }

      .page-content {
         margin-top: ${space.l};
         display: flex;
         flex-direction: column;
         gap: ${space.s};
      }
   }

   .header {
      position: fixed;
      z-index: 100;
      top: 0;
      left: 0;
      background-color: ${color.white};
      width: 100vw;
      border-bottom: 1px solid #f3f3f3;

      .desktop-and-tablet {
         .navigation__bar-container {
            display: flex;
            align-items: center;
            box-sizing: border-box;
            max-width: ${maxWidth.profilePages};
            padding-inline: var(--padding-inline);
            margin: 0 auto;
            height: 5em;
            .navigation__bar-logo {
               width: 10em;
               padding-right: ${space.xl};
               cursor: pointer;
            }

            .navigation__bar-right__side__component {
               margin-left: auto;
               isolation: unset;
               z-index: 1000;

               font-size: 1rem;
               span.address {
                  color: red !important;
               }
            }
         }
      }

      .mobile {
         display: flex;
         justify-content: space-between;
         align-items: center;
         padding: 0 ${padding.inline};
         height: 5em;
         .back-button {
            svg {
               width: 1.2em;
               fill: currentColor;
            }
         }
         .logo {
            width: 9em;
            padding: ${space.m};
            cursor: pointer;
         }
      }
   }

   .skeleton-holder {
      display: flex;
      flex-direction: column;
      gap: ${space.m};
      margin-top: ${space.l};
      .skeleton-title {
         width: 70%;
         align-self: center;
         height: 3em;
      }

      .skeleton-description {
         width: 100%;
      }
   }

   footer {
      display: flex;
      justify-content: center;
      border-top: 1px solid #f3f3f3;
      background-color: ${color.white};
      .footer-container {
         flex: 1;
         padding: 1.4em var(--padding-inline);
         max-width: ${maxWidth.profilePages};
         box-sizing: border-box;
         display: flex;
         flex-wrap: wrap;
         justify-content: space-between;
         align-items: center;

         .copy-rights {
            font-size: ${size.xs};
         }

         @media screen and (max-width: 600px) {
            justify-content: space-around;
         }
      }
   }

   ${screen.tabletAndMobile} {
      margin-top: 4em;
      .profile_page_holder {
         min-height: calc(100dvh - 7.6em);
         margin: initial;
      }

      @media screen and (max-width: 770px) {
         margin-top: 3em;
         footer {
            .footer-container {
               gap: 0.4em;
               justify-content: space-around;
            }
         }
      }
   }

   ${screen.onlyMobile} {
      --padding-inline: ${space.m};

      .action-buttons-holder.page-buttons {
         .next-button {
            width: 100%;
         }
         .back-button {
            display: none;
         }
      }

      .action-buttons-holder {
         align-items: center;
         justify-content: center !important;
      }

      .page_holder {
         padding-top: 3.5em;
      }
   }
`

export default Onboarding
