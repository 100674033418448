import {
   Form,
   Hint,
   HorizontalLine,
   PasswordField,
   PhoneNumberField,
   SubmitButton,
   TextField,
} from "rentpost/components/form"
import * as yup from "yup"
import { ApolloError } from "@apollo/client"
import { AnimateWithVariants } from "rentpost/components/functional"
import { useSearchParams } from "react-router-dom"

interface Props {
   loading: boolean
   error: ApolloError | undefined
   initialValues: AccountFieldProps
   handleSubmit: (values: AccountFieldProps) => void
   className?: string
   isSubmissionSuccessful: boolean
}

interface AccountFieldProps {
   firstName: string
   lastName: string
   email: string
   phone: string | null
   password: string | null
   passwordConfirm: string | null
}

const validationSchema = yup.object({
   firstName: yup
      .string()
      .required("First name is required!")
      .min(3, "First name must be at least 3 characters!"),
   lastName: yup
      .string()
      .required("Last name is required!")
      .min(3, "Last name must be at least 3 characters!"),
   email: yup.string().email("Invalid email address").required("Email is required!"),
   password: yup
      .string()
      .nullable()
      .min(8, "Password must be at least 8 characters!")
      .matches(/[A-Z]/, "Password must contain one uppercase!")
      .matches(/[a-z]/, "Password must contain one lowercase!")
      .matches(/[0-9]/, "Password must contain one number!")
      .matches(
         /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
         "Password must contain one special character!"
      ),
   otherwise: yup.string(),

   passwordConfirm: yup
      .string()
      .when("password", {
         is: (val: string | null) => val && val.length > 0,
         then: yup.string().required("Password confirmation is required!"),
         otherwise: yup.string().nullable(),
      })
      .oneOf([yup.ref("password"), null], "Passwords must match!"),
})

const AccountForm: React.FC<Props> = (props) => {
   const { loading, error, initialValues, handleSubmit, className, isSubmissionSuccessful } = props

   const [searchParams] = useSearchParams()
   const isEmailMissing = searchParams.get("email-missing") === "1"

   return (
      <div className={className}>
         <h3>Account Details & Credentials</h3>
         {isEmailMissing && (
            <Hint
               type="error"
               text="Your user account is missing an email address.  An email address is required for a properly functioning account.  Failure to provide a valid email address could result in you getting locked out of your account, should you need to reset your password.  Please update this now!"
               canHide={false}
            />
         )}
         <Form
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            error={error}
            loading={loading}
            showErrorHint
            successHint={
               isSubmissionSuccessful && !loading ? "Account updated successfully!" : undefined
            }
         >
            {({ values }) => {
               return (
                  <>
                     <TextField name="firstName" label="First Name" />
                     <TextField name="lastName" label="Last Name" />
                     <TextField name="email" label="Email" autoFocus={isEmailMissing} />
                     <PhoneNumberField name="phone" label="Phone Number" />
                     <HorizontalLine />
                     <PasswordField name="password" label="New Password" isNewPassword />
                     <AnimateWithVariants
                        animate={values.password ? "expand" : "collapseWithOpacity"}
                        initial="collapseWithOpacity"
                     >
                        <PasswordField name="passwordConfirm" label="Confirm New Password" />
                     </AnimateWithVariants>
                     <SubmitButton> Save & Update</SubmitButton>
                  </>
               )
            }}
         </Form>
      </div>
   )
}

export type { AccountFieldProps }
export default AccountForm
