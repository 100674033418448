import { LoadingSpinner } from "rentpost/src/components/other"
import useSSO from "./useSSO"
import { color } from "rentpost/src/styles"

const ZendeskSSO: React.FC = () => {
   useSSO()
   return (
      <div
         style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100svh",
         }}
      >
         <LoadingSpinner type="drops" color={color.grayLight} scale={1.1} />
      </div>
   )
}
export default ZendeskSSO
