import styled from "@emotion/styled"
import SVG from "assets/svg"
import patternBackground from "assets/images/pattern-background-2fa.png"
import { color, size, space } from "rentpost/styles"
import { LogoLink } from "components/other"
import { useNavigate } from "react-router-dom"
import ROUTE from "routes/ROUTE"

const Illustration = () => {
   const navigate = useNavigate()
   const gotoHome = () => navigate(ROUTE.INDEX)
   return (
      <Holder>
         <div className="desktop">
            <LogoLink />
            <SVG.MergeAccountIllustration className="illustration-svg" />
            <img src={patternBackground} alt="pattern-background" />
         </div>
         <div className="tablet-and-mobile" onClick={gotoHome}>
            <div className="logo">
               <SVG.logo.wordColored className="colored" />
               <SVG.logo.word className="white" />
            </div>
            <div className="image-holder">
               <SVG.MergeAccountIllustration className="illustration-svg" />
               <img src={patternBackground} alt="pattern-background" />
            </div>
         </div>
      </Holder>
   )
}

const Holder = styled.div`
   .desktop {
      position: relative;
      isolation: isolate;
      height: 100%;
      width: 26vw;
      overflow: hidden;
      background: linear-gradient(180deg, #90e4ff 0%, rgba(255, 153, 190, 0.31) 100%);
      .logo {
         position: absolute;
         top: ${space.xl};
         left: ${space.xl};
         width: 11em;
         z-index: 3;
         fill: ${color.white};
      }

      .illustration-svg {
         position: absolute;
         top: 57%;
         left: 50%;
         transform: translate(-50%, -50%);
         font-size: ${size.l};
         width: 10em;
         z-index: 2;
      }
      img {
         position: absolute;
         bottom: 0;
         height: 100%;
      }
   }

   /* -------------- */
   /* Responsiveness */
   /* -------------- */

   .tablet-and-mobile {
      display: flex;

      .image-holder {
         background: linear-gradient(180deg, #90e4ff 0%, rgba(255, 153, 190, 0.31) 100%);
         opacity: calc(1 - var(--animation-factor) + 0);

         .illustration-svg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: ${size.l};
            width: 6em;
            z-index: 2;
         }

         img {
            position: absolute;
            top: 0;
            width: 100%;
         }
      }
   }
`
export default Illustration
