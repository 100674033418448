import styled from "@emotion/styled"
import { ReactNode, useEffect, useState } from "react"
import SVG from "rentpost/assets/svg"
import { motion } from "framer-motion"
import { color, font, screen, space } from "rentpost/styles"
import { NavLink } from "react-router-dom"
import { FooterLinks } from "rentpost/components/form"
import { maxWidth } from "rentpost/styles/size"

// @ts-ignore - no types available
import env from "configs/env"

type LinkType = {
   label: string
   to: string
   disabled?: boolean
   icon?: ReactNode
   onClick?: () => void
}

interface Props {
   links: LinkType[]
   rightSideComponent?: JSX.Element
}

const NavigationBar: React.FC<Props> = (props) => {
   const { links, rightSideComponent } = props

   const [activeLinkIndex, setActiveLinkIndex] = useState<number>(0)
   const [isMobileMenuOpen, setIsMobileMenuOpen] = useState<boolean>(false)

   const handleLinkClick = (index: number) => {
      // make sure the link is not disabled, if it is, do nothing
      if (links[index].disabled) return
      links[index].onClick?.()
   }

   const handleToggleMobileMenu = () => {
      setIsMobileMenuOpen((prev) => !prev)
   }

   const gotoHomePage = () => {
      location.assign(
         env.TARGET_ENV === "stage" ? "https://stage.rentpost.com" : "https://www.rentpost.com/"
      )
   }

   // Configure body overflow when mobile menu is open
   useEffect(() => {
      if (isMobileMenuOpen) {
         document.body.style.overflow = "hidden"
      } else {
         document.body.style.overflow = "auto"
      }
   }, [isMobileMenuOpen])

   // ===
   // JSX
   // ===
   return (
      <Holder>
         <div className="desktop">
            <div className="navigation__bar-container">
               <SVG.logo.wordColored className="navigation__bar-logo" onClick={gotoHomePage} />
               <div className="navigation__bar-links-holder">
                  {links.map((link, index) => {
                     const { label, disabled, icon, to } = link

                     const activeClass = ({
                        isActive,
                        isPending,
                     }: {
                        isActive: boolean
                        isPending: boolean
                     }) => {
                        useEffect(() => {
                           isActive && setActiveLinkIndex(index)
                        }, [isActive])
                        return (
                           "navigation__bar-link " +
                           (isActive ? "active " : "") +
                           (isPending ? "pending " : "") +
                           (disabled ? "disabled" : "")
                        )
                     }
                     const onClick = () => {
                        handleLinkClick(index)
                     }
                     return (
                        <NavLink to={to} className={activeClass} onClick={onClick} key={index}>
                           {icon && <span className="navigation__bar-link-icon">{icon}</span>}
                           <span className="navigation__bar-link-label">{label}</span>
                           {index === activeLinkIndex ? (
                              <motion.span
                                 className="navigation__bar-link-underline"
                                 layoutId="navigation__bar-link-underline"
                              />
                           ) : null}
                        </NavLink>
                     )
                  })}
               </div>
               {rightSideComponent && (
                  <div className="navigation__bar-right__side__component">{rightSideComponent}</div>
               )}
            </div>
         </div>
         <div className="tablet-and-mobile">
            <div
               className={"menu-button " + (isMobileMenuOpen ? "open" : "")}
               onClick={handleToggleMobileMenu}
            >
               <div className="line line-1" />
               <div className="line line-2" />
               <div className="line line-3" />
            </div>
            <SVG.logo.wordColored className="navigation__bar-logo" onClick={gotoHomePage} />
            {rightSideComponent && (
               <div className="navigation__bar-right__side__component">{rightSideComponent}</div>
            )}
            <menu className={"menu-holder " + (isMobileMenuOpen ? "open" : "")}>
               <div className="menu-items">
                  {links.map((link, index) => {
                     const { label, disabled, icon, to } = link

                     const activeClass = ({
                        isActive,
                        isPending,
                     }: {
                        isActive: boolean
                        isPending: boolean
                     }) => {
                        useEffect(() => {
                           isActive && setActiveLinkIndex(index)
                        }, [isActive])
                        return (
                           "navigation__bar-link " +
                           (isActive ? "active " : "") +
                           (isPending ? "pending " : "") +
                           (disabled ? "disabled" : "")
                        )
                     }
                     const onClick = () => {
                        handleLinkClick(index)
                        setTimeout(() => {
                           // Wait for the menu to close before setting the state to false, this is to prevent the menu from closing immediately after clicking a link (400 MS - animation duration)
                           setIsMobileMenuOpen(false)
                        }, 400)
                     }
                     return (
                        <motion.div
                           animate={{
                              transition: {
                                 delay: isMobileMenuOpen ? index * 0.06 + 0.1 : index * 0.05,
                                 duration: 0.3,
                              },
                              x: isMobileMenuOpen ? "0%" : "-80%",
                              opacity: isMobileMenuOpen ? 1 : 0,
                           }}
                           initial={{
                              x: "-80%",
                              opacity: 0,
                           }}
                           key={index}
                        >
                           <NavLink to={to} className={activeClass} onClick={onClick} key={index}>
                              {index === activeLinkIndex ? (
                                 <motion.span
                                    className="navigation__bar-link-active-line"
                                    layoutId="navigation__bar-link-active-line"
                                 />
                              ) : null}
                              {icon && <span className="navigation__bar-link-icon">{icon}</span>}
                              <span className="navigation__bar-link-label">{label}</span>
                           </NavLink>
                        </motion.div>
                     )
                  })}
               </div>
               <footer data-testid="footer">
                  <div className="footer-container">
                     <span className="copy-rights">
                        Copyright © {new Date().getFullYear()} RentPost, Inc. All rights reserved.
                     </span>
                     <FooterLinks />
                  </div>
               </footer>
            </menu>
         </div>
      </Holder>
   )
}

const Holder = styled.nav`
   position: fixed;
   z-index: 100;
   top: 0;
   left: 0;
   background-color: ${color.white};
   width: 100vw;
   border-bottom: 1px solid #f3f3f3;

   .desktop {
      .navigation__bar-container {
         display: flex;
         align-items: center;
         box-sizing: border-box;
         max-width: ${maxWidth.profilePages};
         padding-inline: var(--padding-inline);
         margin: 0 auto;
         .navigation__bar-logo {
            width: 10em;
            padding-right: ${space.xl};
            cursor: pointer;
         }

         .navigation__bar-links-holder {
            display: flex;
            gap: 2.8em;
            list-style: none;
            padding-inline: 7px;
            margin: 0;
            overflow: hidden;
            position: relative;
            .navigation__bar-link {
               text-decoration: none;
               position: relative;
               display: flex;
               align-items: center;
               color: ${color.grayLight};
               padding: 1.9em 0.2em;
               margin-inline: -0.2em;
               cursor: pointer;
               user-select: none;
               text-align: center;

               * {
                  pointer-events: none;
               }

               .navigation__bar-link-icon {
                  margin-right: ${space.s};
                  svg {
                     width: 0.8em;
                     fill: inherit;
                  }
               }

               .navigation__bar-link-underline {
                  position: absolute;
                  bottom: 0em;
                  left: 0;
                  width: 100%;
                  height: 0.35em;
                  background-color: ${color.green};
                  border-radius: 3px 3px 0 0;
               }

               .navigation__bar-link-label {
                  font-size: 18px;
                  ${font.regular};
                  color: inherit;
               }

               &:hover {
                  color: ${color.grayDark};
               }

               &.active {
                  color: ${color.green} !important;
               }

               &.disabled {
                  color: #c4c4c4;
                  cursor: not-allowed !important;
                  pointer-events: none;
               }
            }
         }

         .navigation__bar-right__side__component {
            margin-left: auto;
            isolation: unset;
            z-index: 1000;

            font-size: 1rem;
            span.address {
               color: red !important;
            }
         }
      }
   }

   .tablet-and-mobile {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-inline: var(--padding-inline);
      height: 4.5em;

      .menu-button {
         height: 4em;
         gap: 0.3em;
         display: flex;
         flex-direction: column;
         justify-content: center;
         align-items: center;
         cursor: pointer;
         z-index: 1000;
         .line {
            width: 2.2em;
            height: 0.25em;
            background-color: ${color.green};
            border-radius: 3px;
            transition: all 0.3s ease-in-out;
         }
         &.open {
            .line-1 {
               transform: translateY(0.57em) rotate(45deg);
            }
            .line-2 {
               opacity: 0;
            }
            .line-3 {
               transform: translateY(-0.57em) rotate(-45deg);
            }
         }
      }

      .navigation__bar-logo {
         width: 10em;
         cursor: pointer;
      }

      .menu-holder {
         position: fixed;
         z-index: 100;
         top: 4.5em;
         left: 0;
         width: 100%;
         height: calc(100dvh - 4.5em);
         background-color: #f9f9f9;
         border-top: 1px solid #f3f3f3;
         display: flex;
         flex-direction: column;
         transform: translateX(-100%);
         transition: transform 0.5s ease-in-out;
         box-shadow: 0 0.5em 0.4em 0 rgba(0, 0, 0, 0.1);
         pointer-events: none;
         margin: 0;
         padding: 0;

         &.open {
            transform: translateX(0%);
            pointer-events: all;
         }

         .menu-items {
            display: flex;
            flex-direction: column;
            justify-content: center;
            flex: 1;
            gap: 2em;
            margin: 0 49px;
            padding-left: 1.9em;
            border-left: 2px solid #f3f3f3;
            .navigation__bar-link {
               position: relative;
               display: flex;
               align-items: center;
               color: ${color.grayLight};
               padding: 0.6em 1.9em;
               margin-inline: -10px;
               cursor: pointer;
               user-select: none;
               text-align: center;
               text-decoration: none;

               * {
                  pointer-events: none;
               }

               .navigation__bar-link-active-line {
                  position: absolute;
                  top: -4%;
                  left: -24px;
                  height: 100%;
                  width: 7px;
                  background-color: ${color.green};
                  border-radius: 50px;
               }

               .navigation__bar-link-label {
                  font-size: 26px;
                  ${font.regular};
                  color: inherit;
               }

               &:hover {
                  color: ${color.grayDark};
               }

               &.active {
                  color: ${color.green} !important;
               }

               &.disabled {
                  color: #c4c4c4;
                  cursor: not-allowed !important;
                  pointer-events: none;
               }
            }

            ${screen.onlyMobile} {
               margin: 0 33px;
            }
         }

         footer {
            .footer-container {
               display: flex;
               flex-wrap: wrap;
               justify-content: space-between;
               padding: 1.4em 5%;
               align-items: center;
               border-top: 1px solid #f3f3f3;
               background-color: #f9f9f9;

               .copy-rights {
                  color: ${color.grayDark};
               }

               @media screen and (max-width: 770px) {
                  justify-content: space-around;
               }
            }
         }
      }
   }
`

export default NavigationBar
export type { Props as NavigationBarProps }
