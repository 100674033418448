import SVG from "assets/svg"
import { AuthorizedDevice } from "./UseSecurity"
import styled from "@emotion/styled"
import { color } from "rentpost/styles"
import OperatingSystemIcon from "./OperatingSystemIcon"

interface Props {
   type: AuthorizedDevice["type"]
   operatingSystem: AuthorizedDevice["operatingSystem"]
   className?: string
}

const DeviceIcon: React.FC<Props> = (props) => {
   const { type, operatingSystem } = props

   // --------------------
   // Device type svg icon
   // --------------------
   let deviceIcon = <SVG.device.laptop />
   switch (type) {
      case "Desktop":
         deviceIcon = <SVG.device.laptop />
         break

      case "Smartphone":
      case "FeaturePhone":
      case "PortableMediaPlayer":
         deviceIcon = <SVG.device.phone />
         break

      case "Tablet":
      case "Phablet":
         deviceIcon = <SVG.device.tablet />
         break

      case "SmartDisplay":
      case "Tv":
         deviceIcon = <SVG.device.tv />
         break

      case "Console":
      case "SmartSpeaker":
         deviceIcon = <SVG.device.console />
         break

      case "Wearable":
      case "CarBrowser":
      case "Camera":
      case "Unknown":
      case "Peripheral":
         deviceIcon = <SVG.device.unknown />
         break

      default:
         deviceIcon = <SVG.device.unknown />
   }

   // ===
   // JSX
   // ===
   return (
      <Holder className={type + " device-item__type " + (props.className || "")}>
         <div className="device-item__type__icon">{deviceIcon}</div>
         <div className="device-item__type__operating-system">
            {<OperatingSystemIcon {...{ operatingSystem }} />}
         </div>
      </Holder>
   )
}

const Holder = styled.div`
   position: relative;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   width: 100%;
   min-width: 10em;
   font-size: 1em;
   --color-gray: #4a4a4a;

   .device-item__type__icon {
      position: relative;
      z-index: 1;

      svg {
         height: 6.5em;
         max-width: 10em;
         fill: var(--color-gray);
      }
   }

   .device-item__type__operating-system {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 3;

      svg {
         width: 1.6em;
         fill: ${color.white};
      }
   }

   /* Types custom CSS */
   &.Desktop {
      min-width: 10em;
      svg {
         height: 6.5em;
      }
   }

   &.Smartphone,
   &.FeaturePhone,
   &.PortableMediaPlayer {
      .device-item__type__icon {
         svg {
            height: 5em;
         }
      }

      .device-item__type__operating-system {
         svg {
            width: 1.2em;
         }
      }
   }

   &.Tablet,
   &.Phablet {
      .device-item__type__icon {
         svg {
            height: 5em;
         }
      }
   }

   &.SmartDisplay,
   &.Tv {
      .device-item__type__icon {
         svg {
            height: 5em;
         }
      }
      .device-item__type__operating-system {
         top: 45%;
      }
   }

   &.Console,
   &.SmartSpeaker {
      .device-item__type__icon {
         svg {
            height: 5em;
         }
      }

      .device-item__type__operating-system {
         top: 35%;
         left: 40%;
         svg {
            width: 1.2em;
         }
      }
   }

   &.Wearable,
   &.CarBrowser,
   &.Camera,
   &.Unknown,
   &.Peripheral {
      .device-item__type__icon {
         svg {
            height: 4em;
            fill: var(--color-gray);
         }
      }

      .device-item__type__operating-system {
         display: none;
      }
   }
   @container (max-width: 550px) {
      min-width: initial;
   }

   @container (max-width: 390px) {
      font-size: 0.8em;
   }
`

export default DeviceIcon
