import styled from "@emotion/styled"
import { Hint, SingleFieldForm } from "rentpost/components/form"
import { UseTwoFactorAuthProps } from "../useTwoFactorAuth"
import useTriggerHint from "hooks/useTriggerHint"
import { Button } from "@mui/material"
import { useEffect, useState } from "react"
import { space } from "rentpost/styles"
import { ApolloError } from "@apollo/client"
import getApolloErrorDetails from "utils/getApolloErrorDetails"

interface Props {
   verifyTwoFactorAuth: UseTwoFactorAuthProps["verifyTwoFactorAuth"]
   resendTwoFactorAuthCode: UseTwoFactorAuthProps["resendTwoFactorAuthCode"]
   disableTwoFactorAuth: UseTwoFactorAuthProps["disableTwoFactorAuth"]
   verifyTwoFactorAuthError: ApolloError | undefined
   disableTwoFactorAuthError: ApolloError | undefined
   resendTwoFactorAuthCodeError: ApolloError | undefined
   verifyTwoFactorAuthLoading: boolean
   disableTwoFactorAuthLoading: boolean
   resendTwoFactorAuthCodeLoading: boolean
   attemptCount: UseTwoFactorAuthProps["attemptCount"]
   settings: UseTwoFactorAuthProps["settings"]
   onClose: () => void
}

const TwoFactorAuthSetupModalComponent: React.FC<Props> = (props) => {
   const {
      verifyTwoFactorAuth,
      resendTwoFactorAuthCode,
      disableTwoFactorAuth,
      verifyTwoFactorAuthError,
      disableTwoFactorAuthError,
      resendTwoFactorAuthCodeError,
      verifyTwoFactorAuthLoading,
      disableTwoFactorAuthLoading,
      resendTwoFactorAuthCodeLoading,
      attemptCount,
      settings,
      onClose,
   } = props

   const [triggerVerify2FA_Hint, onFocusVerify2FA] = useTriggerHint(verifyTwoFactorAuthError)
   const [resetForm, setResetForm] = useState<boolean>(false)
   const [isDisabling, setIsDisabling] = useState<boolean>(false)

   // ========
   // Handlers
   // ========
   const handleSubmit = (value: { field: any }) => {
      verifyTwoFactorAuth(value.field as string)
   }

   const handleDisableTwoFactorAuth = () => {
      setIsDisabling(true)
      disableTwoFactorAuth()
   }

   const [attemptDelay, setAttemptDelay] = useState<number>(0)
   // Start counting the delay after each attempt to resend email, delay is 120 seconds, render the count down
   // if the delay is not 0
   if (attemptDelay > 0) {
      setTimeout(() => {
         // Decrease the delay by 1 second every second
         setAttemptDelay(attemptDelay - 1)
      }, 1000)
   }

   // If the attempt count is less than 5, reset the delay to 120 seconds after each attempt
   useEffect(() => {
      if (attemptCount < 5 && attemptCount !== 0) {
         setAttemptDelay(120)
      }

      if (attemptCount === 5) {
         disableTwoFactorAuth()
         onClose()
      }
   }, [attemptCount])

   // If the user successfully disabled 2FA, close the modal
   useEffect(() => {
      if (!disableTwoFactorAuthLoading && isDisabling && !disableTwoFactorAuthError) {
         setIsDisabling(false)
         onClose()
      }
   }, [disableTwoFactorAuthLoading])

   const handlerResendTwoFactorAuthCode = () => {
      resendTwoFactorAuthCode()
      setResetForm(true)
      setTimeout(() => {
         // Wait for the form to reset before setting the resetForm to false
         setResetForm(false)
      }, 500)
   }

   // ===
   // JSX
   // ===
   return (
      <Holder>
         <div className="content">
            <h2>Confirm two factor authentication (2FA)</h2>
            {settings.twoFactorAuthMethod === "Email" ? (
               <p className="main-description">
                  Please enter the 6-digit code we just sent you in your email.
               </p>
            ) : (
               <p className="main-description">
                  {`Please enter the 6-digit code we just sent you via ${settings.twoFactorAuthMethod} on number ending in ${settings.twoFactorAuthPhoneLastFourDigits}.`}
               </p>
            )}
            {verifyTwoFactorAuthError && (
               <Hint
                  type="error"
                  text={getApolloErrorDetails(verifyTwoFactorAuthError).errorMessage}
                  canHide
                  triggerAutoHide={triggerVerify2FA_Hint}
               />
            )}
            <SingleFieldForm
               type="otp"
               onSubmit={handleSubmit}
               otpFieldSettings={{ numberOfCharacters: 6, gap: 3.2 }}
               loading={verifyTwoFactorAuthLoading}
               onFormFocus={onFocusVerify2FA}
               resetForm={resetForm}
            />
            <div className="resend">
               {attemptDelay > 0 && (
                  <Hint
                     text="A code has been resent. Check your phone."
                     type="success"
                     autoHideTimeout={5000}
                     triggerAutoHide
                     canHide
                     key={attemptCount}
                  />
               )}
               {(resendTwoFactorAuthCodeError || disableTwoFactorAuthError) && (
                  <Hint
                     type="error"
                     text={
                        getApolloErrorDetails(
                           resendTwoFactorAuthCodeError || disableTwoFactorAuthError
                        ).errorMessage
                     }
                     canHide
                  />
               )}
               <div className="gray-container">
                  <p>
                     If you didn't receive a text message, don't worry. You can request a new one
                     here.
                  </p>
                  <Button
                     variant="outlined"
                     color="secondary"
                     style={{ borderRadius: "50em", width: "100%", marginTop: "1rem" }}
                     onClick={handlerResendTwoFactorAuthCode}
                     disabled={
                        resendTwoFactorAuthCodeLoading || attemptCount >= 5 || attemptDelay !== 0
                     }
                  >
                     {attemptDelay === 0
                        ? resendTwoFactorAuthCodeLoading
                           ? "Resending..."
                           : "Resend Code"
                        : attemptDelay + " second until next attempt"}
                  </Button>
               </div>
               <Button
                  variant="text"
                  color="error"
                  onClick={handleDisableTwoFactorAuth}
                  className="cancel-2fa-button"
                  disabled={isDisabling && disableTwoFactorAuthLoading}
               >
                  Cancel 2FA Setup
               </Button>
            </div>
         </div>
      </Holder>
   )
}

const Holder = styled.div`
   display: grid;
   place-content: center;

   .configure-2fa {
      position: absolute;
      right: ${space.xxl};
      top: ${space.m};
   }

   .content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      max-width: 30em;
   }

   .gray-container {
      margin-block: ${space.l};
   }

   .cancel-2fa-button {
      margin-top: 2em;
      border-color: currentColor;
      width: 100%;
      border-radius: 10em;
   }
`

export default TwoFactorAuthSetupModalComponent
