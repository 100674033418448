import { useMutation } from "@apollo/client"
import { SSO, SSOVariables } from "./__generated__"
import { gql } from "@apollo/client"
import usePOEToken from "hooks/usePOEToken"
import bypassMockError from "utils/bypassMockError"
import { useSearchParams } from "react-router-dom"
import useSlowEffect from "hooks/useSlowEffect"

export enum SSOConsumer {
   Zendesk = "Zendesk",
}
// ========
// MUTATION
// ========
const SSOMutation = gql`
   mutation SSO($SSOConsumer: SSOConsumer!, $redirectUrl: Url!) {
      sso(consumer: $SSOConsumer, redirectUrl: $redirectUrl)
   }
`

// ===============
// USE SIGNUP HOOK
// ===============
const useSSO = () => {
   const [runSSO] = useMutation<SSO, SSOVariables>(SSOMutation)
   const [searchParams, setSearchParams] = useSearchParams()
   const returnToParam = searchParams.get("return_to")

   // POE Token hook
   const { poeToken, refreshPOEToken } = usePOEToken()
   const sso = () => {
      runSSO({
         context: {
            poeToken: poeToken.current,
            showErrorModal: true,
         },
         variables: {
            redirectUrl: returnToParam,
            SSOConsumer: SSOConsumer.Zendesk,
         },
      })
         .then(async (res) => {
            const redirectURL = res.data?.sso
            window.location.href = redirectURL
            refreshPOEToken()
         })
         .catch(bypassMockError)
   }

   useSlowEffect(sso)
}
export default useSSO
