import AuthErrorLayout from "layouts/errorFallback/AuthErrorLayout"
import PageNotFoundErrorLayout from "layouts/errorFallback/PageNotFoundErrorLayout"
import UnexpectedErrorLayout from "layouts/errorFallback/UnexpectedErrorLayout"
import React, { useEffect } from "react"
import {
   ErrorBoundary as ErrorBoundarySource,
   ErrorBoundaryProps,
   FallbackProps,
   useErrorBoundary,
} from "react-error-boundary"
import ThemeProviderComp from "rentpost/styles/ThemeProvider"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import MUITheme from "rentpost/styles/MUITheme"
import { useLocation } from "react-router-dom"
import hideLoadingPage from "utils/hideLoadingPage"
import GonePageErrorLayout from "layouts/errorFallback/GonePageErrorLayout"
import MaintenanceErrorLayout from "layouts/errorFallback/MaintenanceErrorLayout"
import MissingDataErrorLayout from "layouts/errorFallback/MissingDataErrorLayout"

type Props = {
   children: React.ReactNode
}

// ----------------------
// Error Holder Component
// ----------------------
const RootErrorBoundary: React.FC<Props> = ({ children }) => {
   // We need to make sure that the error boundary is reset when the location changes, WITHOUT RE-RENDERING THE WHOLE APP WITH EVERY LOCATION CHANGE, so we use the `resetKeys` prop of the ErrorBoundary and pass the `pathname` as the reset key
   const { pathname } = useLocation()

   // OnError callback
   const onError: ErrorBoundaryProps["onError"] = (error) => {
      console.table(error)
      hideLoadingPage() // hide the loading page when an error occurs
   }

   return (
      <ErrorBoundarySource
         FallbackComponent={FallbackComponent}
         onError={onError}
         resetKeys={[pathname]}
      >
         <WrapWithAsyncErrors>{children}</WrapWithAsyncErrors>
      </ErrorBoundarySource>
   )
}

// ------------------
// React Error Finder
// ------------------
const WrapWithAsyncErrors: React.FC<Props> = ({ children }) => {
   const { showBoundary } = useErrorBoundary()

   useEffect(() => {
      // -------------------------------------
      //  Asynchronous Error Boundary Handling
      // -------------------------------------
      window.addEventListener("unhandledrejection", (error) => {
         showBoundary(error.reason)
      })

      return () => {
         window.removeEventListener("error", () => {})
      }
   }, [])

   return <>{children}</>
}

// ------------------------
// Error Fallback Component
// ------------------------
const FallbackComponent: React.FC<FallbackProps> = (props) => {
   const errorType = props.error?.name
   const theme = createTheme(MUITheme)

   const ErrorComponent = () => {
      switch (errorType) {
         case "AuthError":
            return <AuthErrorLayout {...props} />

         case "PageNotFoundError":
            return <PageNotFoundErrorLayout {...props} />

         case "LinkGoneError":
            return <GonePageErrorLayout {...props} />

         case "MaintenanceError":
            return <MaintenanceErrorLayout />

         case "MissingDataError":
            return <MissingDataErrorLayout {...props} />

         default:
            return <UnexpectedErrorLayout {...props} />
      }
   }
   return (
      <ThemeProvider theme={theme}>
         <ThemeProviderComp>{ErrorComponent()}</ThemeProviderComp>
      </ThemeProvider>
   )
}

export default RootErrorBoundary
