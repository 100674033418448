export const LIST_UNIT_TYPES = {
   "Single-family": {
      condo: " Condo",
      farm: "Farm",
      house: "House",
      houseboat: "Houseboat",
      lot: "Lot",
      mobile: "Mobile Home",
      "single-family": "Single-family",
      townhouse: "Townhouse",
   },

   "Multi-family": {
      apartment: "Apartment",
      duplex: "Duplex",
      loft: "Loft",
      "multi-family": "Multi-family",
      studio: "Studio",
      tic: "TIC",
      triplex: "Triplex",
   },

   Commercial: {
      commercial: "Commercial",
      industrial: "Industrial",
      office: "Office",
      retail: "Retail",
      suite: "Suite",
      warehouse: "Warehouse",
   },
} as const

export const LIST_LEGAL_ENTITY_TYPES = {
   natural_person: "Natural Person",
   sole_proprietorship: "Sole Proprietorship",
   association_estate_trust: "Association Estate Trust",
   corporation: "Corporation",
   government_agency: "Government Agency",
   international_organization: "International Organization",
   limited_liability_company: "Limited Liability Company",
   partnership: "Partnership",
   tax_exempt_organization: "Tax Exempt Organization",
} as const

export const LIST_COUNTRIES = () => {
   const countriesInLocalStorage = localStorage.getItem("countries_and_states_list")
   const countries = countriesInLocalStorage ? JSON.parse(countriesInLocalStorage).countries : []
   return countries
}

export const LIST_STATES = () => {
   const countriesInLocalStorage = localStorage.getItem("countries_and_states_list")
   const states = countriesInLocalStorage ? JSON.parse(countriesInLocalStorage).states : []
   return states
}

export type UnitTypeKey =
   | "condo"
   | "farm"
   | "house"
   | "houseboat"
   | "lot"
   | "mobile"
   | "single-family"
   | "townhouse"
   | "apartment"
   | "duplex"
   | "loft"
   | "multi-family"
   | "studio"
   | "tic"
   | "triplex"
   | "commercial"
   | "industrial"
   | "office"
   | "retail"
   | "suite"
   | "warehouse"
export type LegalEntityTypeKey = keyof typeof LIST_LEGAL_ENTITY_TYPES
