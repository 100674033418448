import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import ROUTE from "routes/ROUTE"
import auth from "utils/auth"

const IndexPage: React.FC = () => {
   const navigate = useNavigate()
   // Check if user is logged in before rendering any page
   useEffect(() => {
      // If user is logged in and token is not expired, redirect to overview page else redirect to login page
      const hasValidAccessToken = auth.getAccessToken() && !auth.isTokenExpired()
      hasValidAccessToken
         ? navigate(ROUTE.PROFILE.OVERVIEW, { replace: true })
         : navigate(ROUTE.LOGIN, { replace: true })
   }, [])
   return <></>
}

export default IndexPage
