import { PageNotFoundError } from "configs/error/customErrors"
import { useEffect } from "react"
import { useErrorBoundary } from "react-error-boundary"

const PageNotFoundRouter = () => {
   const { showBoundary } = useErrorBoundary()

   useEffect(() => {
      showBoundary(new PageNotFoundError("Page Not Found"))
   }, [])
   return null
}

export default PageNotFoundRouter
