import styled from "@emotion/styled"
import { color, font } from "rentpost/styles"

interface CustomSwitchProps {
   checked: boolean
   setChecked: (value: boolean) => void
}
const CustomSwitch = ({ checked, setChecked }: CustomSwitchProps) => {
   return (
      <SwitchContainer>
         <SwitchText color={checked ? color.green : color.grayDark}>
            {checked ? "Active" : "Disabled"}
         </SwitchText>
         <SwitchInput type="checkbox" checked={checked} onChange={() => setChecked(!checked)} />
         <span />
      </SwitchContainer>
   )
}

const SwitchContainer = styled.label`
   display: flex;
   align-items: center;
   justify-content: space-between;
   cursor: pointer;
   user-select: none;
`

const SwitchText = styled.span`
   ${font.regular};
   margin-right: 19px;
   color: ${(props: { color: string }) => props.color};
`

const SwitchInput = styled.input`
   position: absolute;
   opacity: 0;
   width: 0;
   height: 0;

   &:checked + span {
      background-color: ${color.green};
   }

   &:not(:checked) + span {
      background-color: ${color.grayDark};
   }

   & + span {
      position: relative;
      display: inline-block;
      width: 60px;
      height: 34px;
      background-color: ${color.grayDark};
      border-radius: 34px;
      transition: background-color 0.2s;
      font-size: 16px;
      font-weight: bold;
      text-align: center;
      line-height: 34px;
      color: #f4f3f4;
   }

   &:checked + span {
      background-color: ${color.green};
   }

   &:checked + span::after {
      transform: translateX(22px);
   }

   & + span::after {
      content: "";
      position: absolute;
      top: 4px;
      left: 6px;
      width: 26px;
      height: 26px;
      border-radius: 50%;
      background-color: #f4f3f4;
      transition: transform 0.2s;
   }
`

export default CustomSwitch
