import RELEASE from "./release"
type envProps = {
   GRAPHQL: string
   BASE_URL: string
}

// DEVELOPMENT
const development: envProps = {
   GRAPHQL: "https://api.rentpost.test/graphql",
   BASE_URL: "https://account.rentpost.test:3000",
}

// PRODUCTION
const production: envProps = {
   GRAPHQL: "https://api.rentpost.com/graphql",
   BASE_URL: "https://account.rentpost.com",
}

// STAGE
const stage: envProps = {
   GRAPHQL: "https://api.stage.rentpost.com/graphql",
   BASE_URL: "https://account.stage.rentpost.com",
}

// SENTRY DSN
const SENTRY_DSN =
   "https://c13c9c32c7a748c287f6d0c4b904a845@o201402.ingest.sentry.io/4504681964830720"

// -----------
// ENVIRONMENT
// -----------
const env = {
   development,
   production,
   stage,
}[import.meta.env.MODE]

export default {
   ...env,
   // **_O_ TODO ::: ADD SENTRY DSN ::: */
   TARGET_ENV: import.meta.env.VITE_APP_MODE,
   NODE_ENV: import.meta.env.VITE_NODE_ENV,
   SENTRY_DSN,
   RELEASE,
} as envProps & {
   TARGET_ENV: "development" | "production" | "stage"
   NODE_ENV: string
   SENTRY_DSN: string
   RELEASE: string
}
