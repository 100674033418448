import { ApolloError } from "@apollo/client"
import { MiniRoleCardProps } from "rentpost/components/other"
import { AddIntegrationFormFields } from "../../useIntegrations"
import AddIntegrationForm from "./AddIntegrationForm"
import styled from "@emotion/styled"
import { border } from "rentpost/styles"

interface AddIntegrationComponentProps {
   onSubmit: (value: AddIntegrationFormFields) => void
   onClose: () => void
   availableRoles: MiniRoleCardProps[]
   loading: boolean
   error: ApolloError | undefined
}

const AddIntegration: React.FC<AddIntegrationComponentProps> = (props) => {
   return (
      <Holder>
         <h2>Add Integration</h2>
         <p className="main-description">
            To add an integration, you need to give it a name, a description, then choose a role
            from your RentPost account that the integration will have access to.
         </p>
         <AddIntegrationForm {...props} />
      </Holder>
   )
}
const Holder = styled.div`
   display: flex;
   flex-direction: column;
   .integration-type-select {
      .MuiAutocomplete-popper {
         box-shadow: 0 0.1em 0.5em rgba(0, 0, 0, 0.2);
         border-radius: 0.5em;
         overflow: hidden;
         & > * > * {
            width: 100%;
            max-height: 14em;
         }

         & > * > * > * {
            ${border.bottom};
         }
      }
   }

   .integration-option {
      display: flex;
      align-items: center;
      margin: 0.5em 0;
      .icon {
         width: 2em;
         height: 2em;
         margin-right: 0.5em;
      }
      .label {
         font-size: 0.9em;
      }
   }
`

export type { AddIntegrationComponentProps }
export default AddIntegration
