import { useEffect, useState } from "react"
const useTriggerHint = (parameter: any) => {
   const [triggerAutoHide, setTriggerAutoHide] = useState<boolean>(false)

   // Refresh the triggerAutoHide state to false when the parameter changes, so each time the parameter changes, the hint will be shown again
   useEffect(() => {
      if (parameter) {
         setTriggerAutoHide(false)
      }
   }, [parameter])

   const onFocus = () => parameter && setTriggerAutoHide(true)
   return [triggerAutoHide, onFocus] as [boolean, () => void]
}

export default useTriggerHint
