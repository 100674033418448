import styled from "@emotion/styled"
import { AccountsInfoProps, MergingDirection } from "./useMerge"
import { Hint } from "rentpost/components/form"
import { Button, CircularProgress } from "@mui/material"
import MergeIllustrationComponent from "./MergeIllustrationComponent"
import { ComponentProps, useEffect, useState } from "react"
import Modal, { ModalProps } from "rentpost/layouts/Modal"
import { useNavigate } from "react-router-dom"
import ROUTE from "routes/ROUTE"

interface Props {
   mergingDirection: MergingDirection
   mergeUser: () => void
   triggerMergingDirection: () => void
   accountsInfo: AccountsInfoProps
   loading: boolean
   isMergeSuccessful: boolean | null
}
interface MergeModalProps {
   visible: boolean
   onClose: () => void
   mergeUser: () => void
   loading: boolean
   accountsInfo: {
      mergedAccount: AccountsInfoProps["primaryUser"]
      mergeToAccount: AccountsInfoProps["secondaryUser"]
   }
}

const MergeComponent: React.FC<Props> = (props) => {
   const {
      accountsInfo,
      mergeUser,
      mergingDirection,
      loading,
      triggerMergingDirection,
      isMergeSuccessful,
   } = props
   const [totalLoading, setTotalLoading] = useState<boolean>(false)
   const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
   const navigate = useNavigate()

   useEffect(() => {
      if (loading) {
         setTotalLoading(true)
      } else if (isMergeSuccessful === true) {
         // if merging completed successfully Wait for 1 second, then redirecting to logout page
         setTimeout(() => {
            // Wait for 1 second before redirecting to logout page, so the user can see the success message and animation
            navigate(ROUTE.LOGOUT)
         }, 1000)
      }
   }, [loading])

   const mergedAccount =
      mergingDirection === "secondary-to-primary"
         ? accountsInfo?.secondaryUser
         : accountsInfo?.primaryUser

   const mergeToAccount =
      mergingDirection === "secondary-to-primary"
         ? accountsInfo?.primaryUser
         : accountsInfo?.secondaryUser

   // follow same props of MergeIllustrationComponent
   const MergeIllustrationComponentProps: Omit<
      ComponentProps<typeof MergeIllustrationComponent>,
      "isMergeSuccessful"
   > = {
      loading: totalLoading,
      mergingDirection,
      accountsInfo,
      triggerMergingDirection,
      mergeUser,
   }

   const mergeConfirmationModalProps: MergeModalProps = {
      visible: isModalVisible,
      onClose: () => setIsModalVisible(false),
      mergeUser,
      loading: totalLoading,
      accountsInfo: {
         mergedAccount,
         mergeToAccount,
      },
   }

   return (
      <Holder>
         <h1>Choose the merging account</h1>
         <p className="main-description">
            Account with username/email <b>{mergedAccount?.email}</b> will be merged into account
            with username/email <b>{mergeToAccount?.email}</b>
         </p>
         <Hint
            text="Warning!"
            type="warning"
            collapsibleDescription={
               <p>
                  After merging takes place <b>{mergeToAccount?.email}</b> will be your
                  username/email to use, and <b>{mergedAccount?.email}</b> will be merged and
                  removed, you can still swap merge results from the <b>Swap</b> button
               </p>
            }
         />
         <MergeIllustrationComponent {...MergeIllustrationComponentProps} />

         <Button
            variant="contained"
            disabled={totalLoading}
            endIcon={totalLoading ? <CircularProgress size={"100%"} /> : null}
            onClick={() => setIsModalVisible(true)}
            fullWidth
            style={{ paddingBlock: "0.7em" }}
         >
            {loading ? "Merging accounts..." : "Merge accounts"}
         </Button>
         <MergeConfirmationModal {...mergeConfirmationModalProps} />
      </Holder>
   )
}

const MergeConfirmationModal: React.FC<MergeModalProps> = (props) => {
   const {
      visible,
      onClose,
      mergeUser,
      loading,
      accountsInfo: { mergeToAccount, mergedAccount },
   } = props

   const modalProps: ModalProps = {
      visible,
      onClose,
      title: "Merge accounts",
      type: "warning",
      canIgnore: true,
      description: (
         <p>
            Please note that <b>{mergeToAccount?.email}</b> will be your username/email to use, and{" "}
            <b>{mergedAccount?.email}</b> username/email will be merged and removed, are you sure
            you want to merge accounts?`
         </p>
      ),
      actionButtons: {
         accept: {
            label: "Merge Accounts",
            onClick: mergeUser,
         },
         cancel: {
            label: "Cancel",
            onClick: onClose,
         },
      },
      loading,
   }

   return <Modal {...modalProps} />
}

const Holder = styled.div`
   display: flex;
   flex-direction: column;
   gap: 0.5em;
`

export type { Props as MergeComponentProps }
export default MergeComponent
