import { FallbackProps } from "react-error-boundary"
import { useNavigate } from "react-router-dom"
import ROUTE from "routes/ROUTE"
import PageErrorGone from "components/webComponents/PageErrorGoneElement"
import { sentryTag } from "configs/sentry"
import { useEffect } from "react"

const GonePageErrorLayout: React.FC<FallbackProps> = ({ resetErrorBoundary, error }) => {
   const navigate = useNavigate()
   const gotoLogin = () => {
      navigate(ROUTE.LOGIN)
      resetErrorBoundary()
   }

   useEffect(() => {
      // Set the tag to show that the GonePageErrorLayout has been shown
      sentryTag("error-layout-shown", "Gone Error Layout")
   }, [])

   return (
      <PageErrorGone
         onButtonClick={gotoLogin}
         message={error.message}
         title="Invalid Link"
         buttonLabel="Login"
      />
   )
}

export default GonePageErrorLayout
