import { useMutation, useLazyQuery } from "@apollo/client"
import { gql } from "@apollo/client"
import usePOEToken from "hooks/usePOEToken"
import auth from "utils/auth"
import { Login, LoginVariables, GetAvailableRoles } from "../Login/__generated__"
import useLogin, { LoginMutation, GetAvailableRolesQuery } from "../Login/useLogin"
GetUserQuery
import getPASETOFooter from "utils/getPASETOFooter"
import bypassMockError from "utils/bypassMockError"
import { useEffect, useState } from "react"
import { MergeUser, MergeUserVariables } from "./__generated__"
import secondaryAuthToken from "utils/secondaryAuth"
import { GetUserQuery } from "pages/Profile/useProfile"
import { GetUser } from "pages/Profile/__generated__"
import { MergeLoginFormFields } from "./MergeLoginForm"
import { RoleCardProps } from "rentpost/components/other"

type MergingDirection = "secondary-to-primary" | "primary-to-secondary"
type UserRolesType = Pick<RoleCardProps["role"], "avatarImage" | "displayName">
type UserType = {
   id: string
   firstName: string
   lastName: string
   email: string
   roles: UserRolesType[] | null
}
interface AccountsInfoProps {
   primaryUser: UserType
   secondaryUser: UserType
}
// ========
// MUTATION
// ========
const MergeUserMutation = gql`
   mutation MergeUser($token: MergeUserInput!) {
      mergeUser(input: $token) {
         id
      }
   }
`
export { MergeUserMutation }

// ===============
// USE LOGIN HOOK
// ===============
const useMerge = () => {
   // =========
   // MUTATIONS
   // =========
   const [runLogin, { loading: loginLoading, error: loginError }] = useMutation<
      Login,
      LoginVariables
   >(LoginMutation)
   const [runMergeUser, { loading: mergeUserLoading, error: mergeUserError }] = useMutation<
      MergeUser,
      MergeUserVariables
   >(MergeUserMutation)
   const { getAvatarImageFromRoleData } = useLogin()
   // =======
   // QUERIES
   // =======
   const [runGetAvailableRoles, { loading: getAvailableRolesLoading }] =
      useLazyQuery<GetAvailableRoles>(GetAvailableRolesQuery)
   const [runGetUser, { loading: getUserLoading }] = useLazyQuery<GetUser>(GetUserQuery)

   // ======
   // STATES
   // ======
   const [primaryUserRoles, setPrimaryUserRoles] = useState<UserRolesType[] | null>(null)
   const [secondaryUserRoles, setSecondaryUserRoles] = useState<UserRolesType[] | null>(null)
   const [primaryUserInfo, setPrimaryUserInfo] = useState<Omit<UserType, "roles"> | null>(null)
   const [secondaryUserInfo, setSecondaryUserInfo] = useState<Omit<UserType, "roles"> | null>(null)
   const [accountsInfo, setAccountsInfo] = useState<AccountsInfoProps | null>(null)

   const [isUsingSameUsername, setIsUsingSameUsername] = useState<boolean | null>(null)
   const [isMergeSuccessful, setIsMergeSuccessful] = useState<boolean | null>(null)

   // Merging direction
   const [mergingDirection, setMergingDirection] =
      useState<MergingDirection>("secondary-to-primary")
   const triggerMergingDirection = () => {
      setMergingDirection((prev) =>
         prev === "secondary-to-primary" ? "primary-to-secondary" : "secondary-to-primary"
      )
   }

   // POE Token hook
   const { poeToken, refreshPOEToken } = usePOEToken()
   const primaryUserAuthToken = auth.getAccessToken()
   const secondaryUserAuthToken = secondaryAuthToken.getToken()

   // ================
   // HELPER FUNCTIONS
   // ================
   const mapRoles = (roles: GetAvailableRoles["roles"]): UserRolesType[] => {
      return roles
         .map((role) => {
            // Ignore roles with status other than "Active"
            if (role.status !== "Active") return null
            const { displayName } = role
            return {
               displayName,
               avatarImage: getAvatarImageFromRoleData(role),
            } as UserRolesType
         })
         .filter((item) => item) as UserRolesType[]
   }
   // ==========
   // OPERATIONS
   // ==========

   const getRoles = async (token: string) => {
      return runGetAvailableRoles({
         context: {
            poeToken: poeToken.current,
            showErrorModal: true,
            authorization: token,
         },
      })
         .then((data) => {
            const roles = data.data?.roles
            if (roles) {
               // check if the token is the primary user token or the secondary user token
               token === primaryUserAuthToken
                  ? setPrimaryUserRoles(mapRoles(roles))
                  : setSecondaryUserRoles(mapRoles(roles))
            }
         })
         .catch((e) => bypassMockError(e))
   }

   const getUserInfo = async (token: string) => {
      return runGetUser({
         context: {
            poeToken: poeToken.current,
            showErrorModal: true,
            authorization: token,
         },
      })
         .then((data) => {
            const user = data.data?.user
            if (user) {
               // check if the token is the primary user token or the secondary user token
               token === primaryUserAuthToken
                  ? setPrimaryUserInfo({
                       id: user.id,
                       firstName: user.firstName as string,
                       lastName: user.lastName as string,
                       email: user.email,
                    })
                  : setSecondaryUserInfo({
                       id: user.id,
                       firstName: user.firstName as string,
                       lastName: user.lastName as string,
                       email: user.email,
                    })
            }
         })
         .catch((e) => bypassMockError(e))
   }

   const login = (value: MergeLoginFormFields) => {
      runLogin({
         context: {
            poeToken: poeToken.current,
         },
         variables: {
            input: value,
         },
      })
         .then(async (data) => {
            // set secondaryAuthToken
            const secondaryUserToken = data.data?.login.token

            if (!secondaryUserToken) return // quit if there is no token

            const primaryUserToken = auth.getAccessToken() as string
            const secondaryUserId = secondaryUserToken && getPASETOFooter(secondaryUserToken).userId
            const primaryUserId = primaryUserToken && getPASETOFooter(primaryUserToken).userId

            // make sure that the secondary user is not the same as the primary user, if so, return an error
            if (secondaryUserId === primaryUserId) {
               return setIsUsingSameUsername(true)
            } else {
               // if the secondary user is not the same as the primary user, then set the secondaryAuthToken, get the roles and user info for both users
               secondaryAuthToken.setToken(secondaryUserToken)
               await getUserInfo(secondaryUserToken)
               await getUserInfo(primaryUserToken)
               await getRoles(secondaryUserToken)
               await getRoles(primaryUserToken)
            }
         })
         .catch((e) => bypassMockError(e))
   }

   const mergeUser = () => {
      const primaryToken =
         mergingDirection === "secondary-to-primary" ? primaryUserAuthToken : secondaryUserAuthToken
      const secondaryToken =
         mergingDirection === "secondary-to-primary" ? secondaryUserAuthToken : primaryUserAuthToken
      runMergeUser({
         context: {
            poeToken: poeToken.current,
            showErrorModal: true,
            authorization: primaryToken,
         },
         variables: {
            token: {
               mergedUserAuthToken: {
                  token: secondaryToken as string,
               },
            },
         },
      })
         .then((data) => {
            data && setIsMergeSuccessful(true)
            refreshPOEToken()
         })
         .catch((e) => bypassMockError(e))
   }

   useEffect(() => {
      if (primaryUserInfo && secondaryUserInfo) {
         setAccountsInfo({
            primaryUser: {
               ...primaryUserInfo,
               roles: primaryUserRoles,
            },
            secondaryUser: {
               ...secondaryUserInfo,
               roles: secondaryUserRoles,
            },
         })
      }
   }, [primaryUserInfo, primaryUserRoles, secondaryUserInfo, secondaryUserRoles])
   // ======
   // RETURN
   // ======
   return {
      login,
      mergeUser,
      loading: {
         loginLoading,
         mergeUserLoading,
         getAvailableRolesLoading,
         getUserLoading,
      },
      error: {
         mergeUserError,
         loginError,
      },
      accountsInfo,
      isUsingSameUsername,
      isMergeSuccessful,
      mergingDirection,
      triggerMergingDirection,
   }
}
export type { MergingDirection, AccountsInfoProps }
export default useMerge
