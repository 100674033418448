import { ApolloLink } from "@apollo/client"
import { getMainDefinition } from "@apollo/client/utilities"
import { graphQlSentryBreadcrumb } from "configs/sentry"
import auth from "utils/auth"
import store from "redux/store"
import header from "./headers"
import ROUTE from "routes/ROUTE"
import { directToLogoutPage } from "utils/redirectTo"

interface DefinitionType {
   kind: string
   operation?: string
}

// ====================
// POE TOKEN MIDDLEWARE
// ====================
const poeTokenMiddleware = new ApolloLink((operation, forward) => {
   const { kind, operation: operationType }: DefinitionType = getMainDefinition(operation?.query)
   if (kind === "OperationDefinition" && operationType === "mutation") {
      operation.setContext(({ headers = {} }) => ({
         headers: {
            ...headers,
            POE: operation.getContext().poeToken,
         },
      }))
   }

   return forward(operation)
})

// ==========================================
// ADD SENTRY BREADCRUMB MIDDLEWARE TO APOLLO
// ==========================================
const sentrySuccessQueryBreadcrumbMiddleware = new ApolloLink((operation, forward) => {
   return forward(operation).map((result) => {
      const operationName = operation?.operationName
      // Add graphql success queries to as Sentry Breadcrumb
      graphQlSentryBreadcrumb({ operationName, statusCode: 200 })
      return result
   })
})

// =======================================
// ADD AUTHENTICATION MIDDLEWARE TO APOLLO
// =======================================
const authMiddleware = new ApolloLink((operation, forward) => {
   // check that the operation is not a mutation "login"
   const { kind, operation: operationType }: DefinitionType = getMainDefinition(operation?.query)

   // check if the operation can work without authorization, this is used for mutations that can run without a token (eg. resendInvite - that works in the start page)
   const canWorkWithoutAuthorization = operation.getContext().canWorkWithoutAuthorization
   const isSSOOperation = operation.operationName === "SSO"

   if (kind === "OperationDefinition" && operationType === "mutation" && !isSSOOperation) {
      const operationName = operation?.operationName
      if (operationName === "Login") {
         return forward(operation)
      }
   }
   const authorizationToken = operation.getContext().authorization || auth.getAccessToken()
   if (authorizationToken) {
      operation.setContext(({ headers = {} }) => ({
         headers: {
            ...headers,
            authorization: `Bearer ${authorizationToken}`,
         },
      }))
   }

   // ---------------------------
   // Expired authorization token
   // ---------------------------
   if (authorizationToken && auth.isTokenExpired()) {
      // if mutation open logout modal, the direct to logout page
      if (kind === "OperationDefinition" && operationType === "mutation") {
         store.dispatch({ type: "modal/openLogoutModal", payload: "missingToken" })
      } else {
         directToLogoutPage("Session expired. Please sign in again.", true)
      }
      // want to return an empty observable so that the mutation doesn't run to avoid polluting the server requests
      // @ts-ignore
      return forward({})
   }

   // ---------------------------
   // Missing authorization token
   // ---------------------------

   if (!authorizationToken && !canWorkWithoutAuthorization) {
      // if mutation open logout modal, the direct to logout page
      if (kind === "OperationDefinition" && operationType === "mutation") {
         store.dispatch({ type: "modal/openLogoutModal", payload: "expiredToken" })
      } else {
         directToLogoutPage("Unauthorized access. Please sign in again.", true)
      }
      // want to return an empty observable so that the operation doesn't run to avoid polluting the server requests
      // @ts-ignore
      return forward({})
   }

   // -------------------------
   // Valid authorization token
   // -------------------------
   return forward(operation)
})

// =====================================
// ADD CURRENT URL TO WITH EVERY REQUEST
// =====================================
const currentURLMiddleware = new ApolloLink((operation, forward) => {
   const currentLocation: string = location.href
   operation.setContext(({ headers = {} }) => ({
      headers: {
         ...headers,
         "X-RP-Current-URL": currentLocation,
         "X-RP-Module": "account",
      },
   }))
   return forward(operation)
})

// ==============================
// CHECK FOR VERIFICATION HEADERS
// ==============================
const checkVerificationMiddleware = new ApolloLink((operation, forward) => {
   // look for response header "X-RP-Verification-Required" has value of 'true', then direct to email verification page
   return forward(operation).map((result) => {
      const {
         emailVerificationRequiredHeader,
         twoFactorAuthRequiredHeader,
         twoFactorAuthMethodHeader,
         twoFactorPhoneLastFourDigitsHeader,
         twoFactorAuthSetupHeader,
      } = header(operation)

      if (emailVerificationRequiredHeader === "true") {
         location.replace(ROUTE.VERIFY.ROOT + ROUTE.VERIFY.EMAIL)
      }

      // if 2FA is required, open 2FA Modal
      if (twoFactorAuthRequiredHeader === "true") {
         store.dispatch({ type: "modal/openTwoFactorAuthModal" })
         store.dispatch({
            type: "settings/setSettings",
            payload: {
               twoFactorAuthEnabled: true,
               twoFactorAuthMethod: twoFactorAuthMethodHeader,
               twoFactorAuthPhoneLastFourDigits: twoFactorPhoneLastFourDigitsHeader,
            },
         })
      }

      // if 2FA is in setup , open 2FA setup Modal
      if (twoFactorAuthSetupHeader === "true") {
         store.dispatch({ type: "modal/openTwoFactorAuthSetupModal" })
         store.dispatch({
            type: "settings/setSettings",
            payload: {
               twoFactorAuthEnabled: false,
               twoFactorAuthMethod: twoFactorAuthMethodHeader,
               twoFactorAuthPhoneLastFourDigits: twoFactorPhoneLastFourDigitsHeader,
            },
         })
      }

      return result
   })
})

// =======================
// CHECK FOR LOGOUT HEADER
// =======================
const checkLogoutMiddleware = new ApolloLink((operation, forward) => {
   // look for response header "X-RP-Logout-Required" has value of 'true', then direct to email verification page
   return forward(operation).map((result) => {
      const { logoutHeader } = header(operation)
      const isInLogoutPage: boolean = location.pathname === ROUTE.LOGOUT
      const isLogoutOperation: boolean = operation.operationName === "Logout"
      if (logoutHeader && !isInLogoutPage && !isLogoutOperation) {
         directToLogoutPage("")
      }
      return result
   })
})

export {
   poeTokenMiddleware,
   sentrySuccessQueryBreadcrumbMiddleware,
   authMiddleware,
   checkVerificationMiddleware,
   checkLogoutMiddleware,
   currentURLMiddleware,
}
