import {
   Form,
   HiddenField,
   HorizontalLine,
   PhoneNumberField,
   TextField,
} from "rentpost/components/form"
import { Button, CircularProgress } from "@mui/material"
import * as yup from "yup"
import { ApolloError } from "@apollo/client"
import SVG from "assets/svg"
import { CompanyInfoFormFields } from "./useCompanyInfo"
import { LIST_LEGAL_ENTITY_TYPES, LegalEntityTypeKey } from "../sharedData"
import _ from "lodash"
import SelectField from "rentpost/src/components/form/SelectField"

interface Props {
   loading: boolean
   error: ApolloError | undefined
   onSubmit: (values: CompanyInfoFormFields) => void
   initialValues: CompanyInfoFormFields
   goBack: () => void
}

const validationSchema = yup.object({
   typeKey: yup.string().required("Company type is required!"),
   email: yup.string().email("Invalid email address").required("Email is required!"),
   address: yup.object().shape({
      countryKey: yup.string().required("Country is required!"),
      state: yup.string().required("State is required!"),
      city: yup.string().required("City is required!"),
      street: yup.string().required("Street is required!"),
      aptSuiteEtc: yup.string(),
      postalCode: yup.string().required("Postal code is required!"),
   }),
   firstName: yup.string().when("typeKey", {
      is: (companyType: LegalEntityTypeKey) =>
         companyType === "natural_person" || companyType === "sole_proprietorship",
      then: yup
         .string()
         .required("First name is required!")
         .min(3, "First name must be at least 3 characters!"),
      otherwise: yup.string().nullable(),
   }),
   lastName: yup.string().when("typeKey", {
      is: (companyType: LegalEntityTypeKey) =>
         companyType === "natural_person" || companyType === "sole_proprietorship",
      then: yup
         .string()
         .required("Last name is required!")
         .min(3, "Last name must be at least 3 characters!"),

      otherwise: yup.string().nullable(),
   }),
   companyName: yup.string().when("typeKey", {
      is: (companyType: LegalEntityTypeKey) =>
         companyType !== "natural_person" && companyType !== "sole_proprietorship",
      then: yup.string().required("Company name is required!"),
      otherwise: yup.string().nullable(),
   }),
   phone: yup.string().required("Phone number is required!"),
   phoneExt: yup.string(),
})

const BusinessInfoForm: React.FC<Props> = (props) => {
   const { loading, error, onSubmit, initialValues } = props
   const legalEntityTypeOptions = _.flatMap(LIST_LEGAL_ENTITY_TYPES, (label, value) => {
      return { value, label }
   })
   const countryKeyOptions = JSON.parse(localStorage.getItem("countries_and_states_list") || "{}")
      .countries as { value: string; label: string }[]

   const stateKeyOptions = JSON.parse(localStorage.getItem("countries_and_states_list") || "{}")
      .states as { value: string; label: string }[]
   return (
      <Form
         initialValues={initialValues}
         validationSchema={validationSchema}
         onSubmit={onSubmit}
         error={error}
         loading={loading}
         showErrorHint
      >
         {({ isValid, values }) => {
            const disabled = !isValid || loading
            const companyType = values.typeKey

            return (
               <>
                  <HiddenField name="id" />
                  <SelectField
                     label="Company Type"
                     name="typeKey"
                     options={legalEntityTypeOptions}
                  />

                  {companyType === "natural_person" || companyType === "sole_proprietorship" ? (
                     <>
                        <TextField label="First Name" name="firstName" />
                        <TextField label="Last Name" name="lastName" />
                        <PhoneNumberField label="Phone" name="phone" />
                     </>
                  ) : (
                     <>
                        <TextField label="Company Name" name="companyName" />
                        <div
                           style={{
                              display: "flex",
                              gap: "1em",
                              alignItems: "center",
                           }}
                        >
                           <div style={{ flex: 2 }}>
                              <PhoneNumberField label="Phone" name="phone" />
                           </div>
                           <div style={{ flex: 1 }}>
                              <TextField label="Ext." name="phoneExt" />
                           </div>
                        </div>
                     </>
                  )}
                  <TextField label="Email" name="email" />
                  <HorizontalLine />
                  <h3>Address</h3>
                  <SelectField
                     label="Country"
                     name="address.countryKey"
                     placeholder="United States"
                     options={countryKeyOptions}
                     highlight
                  />
                  <TextField label="Street" name="address.street" />
                  <TextField label="Apt/Suite/Etc" name="address.aptSuiteEtc" />
                  <TextField label="City" name="address.city" />
                  {values.address?.countryKey === "US" ? (
                     <SelectField
                        label="State"
                        name="address.state"
                        placeholder="California"
                        options={stateKeyOptions}
                     />
                  ) : (
                     <TextField label="State" name="address.state" />
                  )}
                  <TextField label="Postal Code" name="address.postalCode" />
                  <div className="action-buttons-holder page-buttons">
                     <Button
                        variant="text"
                        color="secondary"
                        startIcon={<SVG.form.arrowLeft />}
                        className="back-button"
                        onClick={props.goBack}
                     >
                        Back
                     </Button>
                     <Button
                        variant="contained"
                        color="primary"
                        endIcon={
                           loading ? <CircularProgress size={"100%"} /> : <SVG.form.arrowRight />
                        }
                        disabled={disabled}
                        className="next-button"
                        type="submit"
                     >
                        Next: Unit & Tenant
                     </Button>
                  </div>
               </>
            )
         }}
      </Form>
   )
}

export default BusinessInfoForm
