import { useMutation, useLazyQuery, ApolloClient, useApolloClient } from "@apollo/client"
import { gql } from "@apollo/client"
import usePOEToken from "hooks/usePOEToken"
import bypassMockError from "utils/bypassMockError"
import { useEffect, useState } from "react"
import { DateTime } from "luxon"

// @ts-ignore - tsc doesn't know about the env file
import {
   GetAuthorizedDevices,
   GetCurrentAuthToken,
   RevokeAuthToken,
   RevokeAuthTokenVariables,
} from "./__generated__"
import useLogout from "pages/Logout/useLogout"
import useSlowEffect from "hooks/useSlowEffect"

type DeviceType =
   | "Camera"
   | "CarBrowser"
   | "Console"
   | "Desktop"
   | "FeaturePhone"
   | "Peripheral"
   | "Phablet"
   | "PortableMediaPlayer"
   | "SmartDisplay"
   | "SmartSpeaker"
   | "Smartphone"
   | "Tablet"
   | "Tv"
   | "Unknown"
   | "Wearable"

interface AuthorizedDevice {
   id: string
   type: DeviceType
   isCurrentDevice: boolean
   name: string
   operatingSystem: {
      name: string | null
      version: string | null
   }
   sessions: {
      id: string
      operatingSystem:
         | {
              id: string | null
              name: string | null
              version: string | null
           }
         | undefined
      expiresAt: string
      lastActiveAt: string
      client:
         | {
              id: string | null
              name: string | null
              type: string | null
              version: string | null
           }
         | undefined
      actions: {
         id: string
         name: string
         createdAt: string
      }[]
      isCurrentSession: boolean
   }[]
}

// ========
// MUTATION
// ========
const RevokeAuthTokenMutation = gql`
   mutation RevokeAuthToken($id: ID!) {
      revokeAuthToken(id: $id)
   }
`

// =====
// QUERY
// =====
const GetAuthorizedDevicesQuery = gql`
   query GetAuthorizedDevices {
      authorizedDevices {
         id
         name
         type
         operatingSystems {
            id
            name
            version {
               value
            }
         }

         ## Sessions
         authTokens {
            id
            expiresAt
            createdAt

            ## Client
            userClient {
               id
               type
               name
               version {
                  value
               }
            }

            ## Operating System
            userOperatingSystem {
               id
               name
               version {
                  value
               }
            }

            ## Actions
            actions(limit: 3, offset: 0) {
               id
               displayName
               createdAt
            }
         }
      }
   }
`
const GetCurrentAuthTokenQuery = gql`
   query GetCurrentAuthToken {
      currentAuthToken {
         id

         userDevice {
            id
         }
      }
   }
`

export { GetAuthorizedDevicesQuery, GetCurrentAuthTokenQuery, RevokeAuthTokenMutation }

const useSecurity = () => {
   // ======
   // STATES
   // ======
   const [authorizedDevices, setAuthorizedDevices] = useState<AuthorizedDevice[] | null>(null)
   const [revokeDeviceTokensLoading, setRevokeDeviceTokensLoading] = useState<boolean>(false)

   // ============
   // HELPER HOOKS
   // ============
   const { poeToken, refreshPOEToken } = usePOEToken()
   const { gotoLogoutPage } = useLogout()
   const client = useApolloClient()

   // ==========
   // OPERATIONS
   // ==========
   const [
      runGetAuthorizedDevices,
      { data: getAuthorizedDevicesData, loading: authorizedDevicesLoading },
   ] = useLazyQuery<GetAuthorizedDevices>(GetAuthorizedDevicesQuery)

   const [
      runGetCurrentAuthToken,
      { data: getCurrentAuthTokenData, loading: currentAuthTokenLoading },
   ] = useLazyQuery<GetCurrentAuthToken>(GetCurrentAuthTokenQuery)

   const [runRevokeAuthToken, { loading: revokeAuthTokenLoading }] = useMutation<
      RevokeAuthToken,
      RevokeAuthTokenVariables
   >(RevokeAuthTokenMutation)

   const runRevokeDeviceTokens = async (authTokens: string[]) => {
      const operationArray = authTokens
         .map((authToken, index) => {
            // get the middle 5 characters of the authToken and use it as an alias
            const alias = `token_${authToken.slice(20, 25)}_${index}`
            return `${alias}: revokeAuthToken(id: "${authToken}")`
         })
         .join("\n ")
      const RevokeDeviceTokensMutation = gql`
         ## // @ts-nocheck
         mutation RevokeDeviceTokens {
            ${operationArray}
         }
      `
      setRevokeDeviceTokensLoading(true)
      await client.mutate({
         mutation: RevokeDeviceTokensMutation,
         context: {
            showErrorModal: true,
            poeToken: poeToken.current,
         },
      })
   }

   // =======
   // MAPPERS
   // =======
   const mapAuthorizedDevices = (
      authorizedDevices: GetAuthorizedDevices["authorizedDevices"],
      currentAuthTokenData: GetCurrentAuthToken
   ): AuthorizedDevice[] => {
      const { currentAuthToken } = currentAuthTokenData

      const convertTime = (time: string) => {
         const dateTimeFromISO = DateTime.fromISO(time).toFormat("MM/dd/yyyy  (hh:mm a)")
         return dateTimeFromISO
      }

      const mappedAuthorizedDevices: AuthorizedDevice[] = authorizedDevices
         .map((device) => {
            const { __typename, authTokens: sessions, ...rest } = device
            const isCurrentDevice = currentAuthToken?.userDevice?.id === device.id
            const operatingSystem = {
               name: device.operatingSystems?.[0]?.name,
               version: device.operatingSystems?.[0]?.version?.value,
            }

            // Sessions mapping
            const mappedSessions: AuthorizedDevice["sessions"] = sessions.map((session) => {
               const { userClient, userOperatingSystem, actions, expiresAt, ...rest } = session
               const lastActiveAt = actions[0]?.createdAt
               const isCurrentSession = currentAuthToken?.id === session.id

               return {
                  client: {
                     id: userClient?.id,
                     name: userClient?.name,
                     type: userClient?.type,
                     version: userClient?.version?.value,
                  },
                  operatingSystem: {
                     id: userOperatingSystem?.id,
                     name: userOperatingSystem?.name,
                     version: userOperatingSystem?.version?.value,
                  },
                  actions: actions.map((action) => {
                     const { __typename, displayName, createdAt, ...rest } = action
                     return {
                        name: displayName,
                        createdAt: convertTime(createdAt),
                        ...rest,
                     }
                  }),
                  isCurrentSession,
                  lastActiveAt: convertTime(lastActiveAt),
                  expiresAt: convertTime(session.expiresAt),
                  ...rest,
               } as AuthorizedDevice["sessions"][0]
            })

            return {
               isCurrentDevice,
               sessions: mappedSessions,
               operatingSystem,
               ...rest,
            } as AuthorizedDevice
         })
         .filter((device) => device.sessions.length >= 1)

      return mappedAuthorizedDevices
   }

   // ========
   // HANDLERS
   // ========
   const getAuthorizedDevices = () => {
      runGetAuthorizedDevices({
         context: {
            showErrorModal: true,
         },
      }).catch((e) => bypassMockError(e))
   }

   const getCurrentAuthToken = () => {
      runGetCurrentAuthToken({
         context: {
            showErrorModal: true,
         },
      }).catch((e) => bypassMockError(e))
   }

   const revokeAuthToken = (authTokenId: string) => {
      runRevokeAuthToken({
         variables: { id: authTokenId },
         context: {
            showErrorModal: true,
            poeToken: poeToken.current,
         },
      })
         .then(() => {
            refreshPOEToken()

            // make sure that the authToken is not the current one, if it is, redirect to logout page, else refetch authorized devices
            const currentDevice = authorizedDevices?.find((device) => device.isCurrentDevice)
            const currentSession = currentDevice?.sessions.find(
               (session) => session.isCurrentSession
            )
            const isCurrentAuthToken = currentSession?.id === authTokenId

            if (isCurrentAuthToken) {
               return gotoLogoutPage()
            }

            getAuthorizedDevices()
         })
         .catch((e) => bypassMockError(e))
   }

   const revokeDeviceTokens = (deviceId: string) => {
      const authTokens = authorizedDevices
         ?.find((device) => device.id === deviceId)
         ?.sessions.map((session) => session.id) as string[]

      runRevokeDeviceTokens(authTokens)
         .then(() => {
            refreshPOEToken()

            // make sure that it is not the current device, if it is, redirect to logout page, else refetch authorized devices
            const isCurrentDevice = authorizedDevices?.find(
               (device) => device.id === deviceId
            )?.isCurrentDevice
            if (isCurrentDevice) {
               return gotoLogoutPage()
            }
         })
         .catch((e) => bypassMockError(e))
         .finally(() => {
            getAuthorizedDevices()
            setRevokeDeviceTokensLoading(false)
         })
   }

   // =======
   // EFFECTS
   // =======
   // Get devices and sessions on mount
   useSlowEffect(() => {
      getAuthorizedDevices()
      getCurrentAuthToken()
   })

   // Map authorized devices, when data is fetched successfully
   useEffect(() => {
      if (!getAuthorizedDevicesData || !getCurrentAuthTokenData) return

      const { authorizedDevices } = getAuthorizedDevicesData
      const mappedAuthorizedDevices = mapAuthorizedDevices(
         authorizedDevices,
         getCurrentAuthTokenData
      )
      setAuthorizedDevices(mappedAuthorizedDevices)
   }, [getAuthorizedDevicesData, getCurrentAuthTokenData])

   return {
      getAuthorizedDevices,
      getCurrentAuthToken,
      revokeAuthToken,
      revokeDeviceTokens,
      loading: {
         getAuthorizedDevices: authorizedDevicesLoading,
         getCurrentAuthToken: currentAuthTokenLoading,
         revokeAuthToken: revokeAuthTokenLoading,
         revokeDeviceTokens: revokeDeviceTokensLoading,
      },
      authorizedDevices,
   }
}

export type { AuthorizedDevice }
export default useSecurity
