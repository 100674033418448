import { useMutation, useLazyQuery } from "@apollo/client"
import { gql } from "@apollo/client"
import usePOEToken from "hooks/usePOEToken"
import bypassMockError from "utils/bypassMockError"
import { useEffect, useState } from "react"
import {
   GetUser,
   RemoveUser,
   RemoveUserVariables,
   UpdateUser,
   UpdateUserVariables,
   RemoveRole,
   RemoveRoleVariables,
   GetTimezones,
} from "./__generated__"

import { GetAvailableRoles, SelectRole, SelectRoleVariables } from "../Login/__generated__"
import { GetAvailableRolesQuery, SelectRoleMutation } from "../Login/useLogin"

import { DisableTwoFactorAuth, EnableTwoFactorAuth } from "../Verify/TwoFactorAuth/__generated__"
import {
   DisableTwoFactorAuthMutation,
   EnableTwoFactorAuthMutation,
} from "../Verify/TwoFactorAuth/useTwoFactorAuth"

import { UploadAvatarMutation } from "pages/Register/useRegister"
import { UploadAvatar, UploadAvatarVariables } from "pages/Register/__generated__"
import _ from "lodash"
import getPASETOFooter from "utils/getPASETOFooter"
import auth from "utils/auth"
import { RoleType } from "rentpost/components/other/RoleCard"
import dataUrlToFile from "utils/dataURLToFile"

// @ts-ignore - tsc doesn't know about the env file
import env from "configs/env"
import useSlowEffect from "hooks/useSlowEffect"
import useSettings from "hooks/useSettings"
import { useErrorBoundary } from "react-error-boundary"
import { MissingDataError } from "configs/error/customErrors"
import { useLocation, useNavigate } from "react-router-dom"
import ROUTE from "routes/ROUTE"

// =========
// FRAGMENTS
// =========
const UserFragment = gql`
   fragment UserFragment on User {
      id
      firstName
      lastName
      email
      phone
      timezone
      createdAt
   }
`

// ========
// MUTATION
// ========

const UpdateUserMutation = gql`
   ${UserFragment}
   mutation UpdateUser($input: UpdateUserInput!) {
      updateUser(input: $input) {
         ...UserFragment
      }
   }
`
const RemoveUserMutation = gql`
   mutation RemoveUser($reason: String!) {
      removeUser(reason: $reason)
   }
`
const RemoveRoleMutation = gql`
   mutation RemoveRole($id: ID!) {
      unlinkRole(id: $id)
   }
`

// ======
// QUERY
// ======

const GetUserQuery = gql`
   ${UserFragment}
   query GetUser {
      user {
         ...UserFragment
      }
   }
`
const GetTimezonesQuery = gql`
   query GetTimezones {
      timezones
   }
`
export { RemoveUserMutation, GetUserQuery }
// ================
// USE PROFILE HOOK
// ================
const useProfile = (props?: { runInitialQueries: boolean }) => {
   // =====
   // STATE
   // =====
   const [isUserUpdatedSuccessfully, setIsUserUpdatedSuccessfully] = useState<boolean>(false)
   const [hasNoRoles, setHasNoRoles] = useState<boolean>(false)
   const [userData, setUserData] = useState<GetUser["user"] | undefined>(undefined)
   const [timezone, setTimezone] = useState<string>("")
   const { showBoundary } = useErrorBoundary()
   const location = useLocation()
   const navigate = useNavigate()
   const { poeToken, refreshPOEToken } = usePOEToken()
   const {
      settings: { twoFactorAuthEnabled, twoFactorAuthMethod },
      getSettings,
   } = useSettings()

   // ==========
   // OPERATIONS
   // ==========
   const [
      runUpdateUser,
      { loading: updateUserLoading, error: updateUserError, reset: updateUserMutationReset },
   ] = useMutation<UpdateUser, UpdateUserVariables>(UpdateUserMutation)

   const [runRemoveUser, { loading: removeUserLoading, error: removeUserError }] = useMutation<
      RemoveUser,
      RemoveUserVariables
   >(RemoveUserMutation)

   const [runRemoveRole, { loading: removeRoleLoading, error: removeRoleError }] = useMutation<
      RemoveRole,
      RemoveRoleVariables
   >(RemoveRoleMutation)

   const [runSelectRole, { loading: selectRoleLoading, error: selectRoleError }] = useMutation<
      SelectRole,
      SelectRoleVariables
   >(SelectRoleMutation)

   const [runUploadAvatar, { loading: uploadAvatarLoading, error: uploadAvatarError }] =
      useMutation<UploadAvatar, UploadAvatarVariables>(UploadAvatarMutation)

   const [runGetUser, { loading: getUserLoading, error: getUserError }] =
      useLazyQuery<GetUser>(GetUserQuery)

   const [runGetTimezones, { loading: getTimezonesLoading, data: timezonesData }] =
      useLazyQuery<GetTimezones>(GetTimezonesQuery)

   const [
      runGetAvailableRoles,
      {
         loading: getAvailableRolesLoading,
         error: getAvailableRolesError,
         data: availableRolesData,
      },
   ] = useLazyQuery<GetAvailableRoles>(GetAvailableRolesQuery)

   const [
      runDisableTwoFactorAuth,
      { loading: disableTwoFactorAuthLoading, error: disableTwoFactorAuthError },
   ] = useMutation<DisableTwoFactorAuth>(DisableTwoFactorAuthMutation)

   const [
      runEnableTwoFactorAuth,
      { loading: enableTwoFactorAuthLoading, error: enableTwoFactorAuthError },
   ] = useMutation<EnableTwoFactorAuth>(EnableTwoFactorAuthMutation)

   // =======
   // HANDLER
   // =======
   const getUser = () => {
      runGetUser({ context: { showErrorModal: true } })
         .then((data) => {
            if (!data?.data?.user) {
               showBoundary(
                  new MissingDataError(
                     `User response data has (${data?.data?.user}) value`,
                     "OperationName: getUser - useProfile.ts"
                  )
               )
               return
            }
            setUserData(data?.data?.user)
            setTimezone(data?.data?.user?.timezone || "")
         })
         .catch(bypassMockError)
   }

   const getRoles = () => {
      runGetAvailableRoles({ context: { showErrorModal: true } })
         .then((data) => {
            if (!data?.data?.roles) {
               showBoundary(
                  new MissingDataError(
                     `Roles response data has (${data?.data?.roles}) value`,
                     "OperationName: getRoles - useProfile.ts"
                  )
               )
               return
            }
            if (availableRolesData?.roles === null || availableRolesData?.roles?.length === 0) {
               setHasNoRoles(true)
               return
            }
         })
         .catch(bypassMockError)
   }

   const updateUser = (input: UpdateUserVariables["input"]) => {
      const { firstName, lastName, email, phone, password, passwordConfirm, timezone } = input

      // --------------------
      // TIMEZONE ONLY UPDATE
      // --------------------
      if (timezone) {
         return runUpdateUser({
            context: { poeToken: poeToken.current },
            variables: {
               input: {
                  timezone,
               },
            },
         })
            .then(({ data }) => {
               refreshPOEToken()
               setTimezone(data?.updateUser?.timezone || "")
            })
            .catch(bypassMockError)
      }

      // ---------------------------------
      // FULL UPDATE OPERATION - user data
      // ---------------------------------

      // check that the input data is not similar to the current user data, if so, don't return the old properties
      const unRepeatedInput = {
         ...(firstName === userData?.firstName ? {} : { firstName }),
         ...(lastName === userData?.lastName ? {} : { lastName }),
         ...(email === userData?.email ? {} : { email }),
         ...(phone === userData?.phone || !phone
            ? {}
            : { phone: "+" + input.phone?.replace("+", "")?.replace(" ", "") }),
         ...(!password ? {} : { password }),
         ...(!passwordConfirm ? {} : { passwordConfirm }),
      }

      // We only show success message if any of the fields are updated, BUT not timezone field, to avoid showing success message when user only changes timezone from the /overview page

      // clear isUserUpdatedSuccessfully state
      setIsUserUpdatedSuccessfully(false)

      // UpdateUser operation
      const updateUserOperation = (needToEnable2FA?: boolean) => {
         runUpdateUser({
            context: { poeToken: poeToken.current },
            variables: {
               input: {
                  // remove empty fields
                  ..._.pickBy(unRepeatedInput, (value: any) => value !== "" && value !== null),
               },
            },
         })
            .then(({ data }) => {
               refreshPOEToken()
               if (needToEnable2FA) {
                  runEnableTwoFactorAuth({
                     context: { poeToken: poeToken.current },
                  })
                     .then(() => {
                        refreshPOEToken()
                        setUserData(data?.updateUser)
                        setIsUserUpdatedSuccessfully(true) // set isUserUpdatedSuccessfully state to true, to show success message
                     })
                     .catch(bypassMockError)
               } else {
                  setUserData(data?.updateUser)
                  setIsUserUpdatedSuccessfully(true) // set isUserUpdatedSuccessfully state to true, to show success message
               }
            })
            .catch(bypassMockError)
      }

      // Check if 2fa is enabled && method has changed, if so, disable 2fa then update user, then enable 2fa again
      const isPhoneNumberUsedFor2FA =
         twoFactorAuthEnabled &&
         (twoFactorAuthMethod === "SMS" || twoFactorAuthMethod === "Whatsapp")
      const isPhoneNumberChanged = phone !== userData?.phone
      const needToDisable2FA = isPhoneNumberUsedFor2FA && isPhoneNumberChanged
      if (needToDisable2FA) {
         runDisableTwoFactorAuth({
            context: { poeToken: poeToken.current },
         })
            .then(() => {
               refreshPOEToken()
               updateUserOperation(true)
            })
            .catch(bypassMockError)
      }
      // if 2fa is not enabled, just update user
      else {
         updateUserOperation()
      }
   }

   const removeUser = (reason: string) => {
      runRemoveUser({
         context: {
            poeToken: poeToken.current,
            twoFactorAuthRequiredOperation: true,
         },
         variables: {
            reason,
         },
      })
         .then(() => {
            refreshPOEToken()
         })
         .catch(bypassMockError)
   }

   const removeRole = async (roleID: string) => {
      // select role first to get authorization token for removing role
      runSelectRole({
         context: { poeToken: poeToken.current },
         variables: { id: roleID },
      })
         .then(({ data }) => {
            const authorization = data?.assumeRole.token
            refreshPOEToken()
            // remove role
            runRemoveRole({
               context: { poeToken: poeToken.current, authorization },
               variables: { id: roleID },
            })
               .then(() => {
                  refreshPOEToken()
                  getRoles()
               })
               .catch(bypassMockError)
         })
         .catch(bypassMockError)
   }

   const uploadAvatar = async (roleID: string, avatarImageURL: string) => {
      // select role first to get authorization token for uploading avatar
      const file = await dataUrlToFile(avatarImageURL, `avatar_${Date.now()}}`)
      runSelectRole({
         context: { poeToken: poeToken.current },
         variables: { id: roleID },
      })
         .then(({ data }) => {
            const authorization = data?.assumeRole.token
            refreshPOEToken()
            // upload avatar
            runUploadAvatar({
               context: { poeToken: poeToken.current, authorization },
               variables: { file },
            })
               .then(() => {
                  refreshPOEToken()
                  getRoles()
               })
               .catch(bypassMockError)
         })
         .catch(bypassMockError)
   }

   const getTimezones = () => {
      runGetTimezones({ context: { showErrorModal: true } })
         .then((data) => {
            if (!data?.data?.timezones) {
               showBoundary(
                  new MissingDataError(
                     `Timezones response data has (${data?.data?.timezones}) value`,
                     "OperationName: getTimezones - useProfile.ts"
                  )
               )
               return
            }
            setTimezone(data?.data?.timezones[0] || "")
         })
         .catch(bypassMockError)
   }

   const selectRole = (id: string, type: RoleType, isOnboardingRequired: boolean) => {
      const environment = env.TARGET_ENV
      const baseURL = (() => {
         switch (environment) {
            case "development":
               return "https://pro.rentpost.test"
            case "stage":
               return "https://pro.stage.rentpost.com"
            case "production":
               return "https://pro.rentpost.com"
            default:
               return "https://pro.rentpost.com"
         }
      })()
      const selectedRolePortalURL = (() => {
         switch (type) {
            case "tenant":
               return `${baseURL}/myrent`
            case "manager":
               return `${baseURL}/mypost`
            case "owner":
               return `${baseURL}/myplace`
            case "employee":
               return `${baseURL}/control`
            default:
               return ""
         }
      })()
      const onboardingURL = (() => {})()
      runSelectRole({
         context: {
            poeToken: poeToken.current,
            showErrorModal: true,
         },
         variables: {
            id,
         },
      })
         .then(({ data }) => {
            if (data?.assumeRole?.token) {
               const token = data.assumeRole.token
               const expiration = getPASETOFooter(token).expiration
               auth.setAccessToken(token, expiration)
            }
            refreshPOEToken()

            if (isOnboardingRequired) {
               navigate(ROUTE.ONBOARDING.ROOT + "/" + ROUTE.ONBOARDING.PERSONAL_INFO)
            } else {
               window.location.href = selectedRolePortalURL
            }
         })
         .catch(bypassMockError)
   }
   // ============
   // Run on mount
   // ============
   useSlowEffect(() => {
      const hasUserToken = auth.getUserID()
      if (props?.runInitialQueries && hasUserToken) {
         getUser()
         getRoles()
         getTimezones()
         getSettings()
      }
   })

   useEffect(() => {
      // reset isUserUpdatedSuccessfully state when the route changes to avoid showing success message when user navigates to another page after updating user data
      setIsUserUpdatedSuccessfully(false)
      updateUserMutationReset()
   }, [location.pathname])

   return {
      // state
      twoFactorAuthEnabled,
      isUserUpdatedSuccessfully,
      hasNoRoles,
      timezone,

      // operations
      getUser,
      getRoles,
      updateUser,
      removeUser,
      uploadAvatar,
      removeRole,
      selectRole,
      // loading
      loading: {
         updateUserLoading,
         removeUserLoading,
         getUserLoading,
         getAvailableRolesLoading,
         selectRoleLoading,
         uploadAvatarLoading,
         removeRoleLoading,
         getTimezonesLoading,
         disableTwoFactorAuthLoading,
         enableTwoFactorAuthLoading,
      },

      // error
      error: {
         updateUserError,
         removeUserError,
         getUserError,
         getAvailableRolesError,
         uploadAvatarError,
         selectRoleError,
         removeRoleError,
         disableTwoFactorAuthError,
         enableTwoFactorAuthError,
      },

      // data
      data: {
         userData,
         getAvailableRolesData: availableRolesData,
         getTimezonesData: timezonesData,
      },
   }
}

export default useProfile
