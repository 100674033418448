import React from "react"
import { createComponent } from "@lit-labs/react"
import { PageErrorUnexpectedElement as Component } from "web-components"

const PageErrorUnexpectedElement = createComponent({
   tagName: "page-error-unexpected",
   elementClass: Component,
   react: React,
   displayName: "PageErrorUnexpected",
   events: {
      onTryAgain: "click-retry",
   },
})

export default PageErrorUnexpectedElement
