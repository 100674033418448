import { ApolloProvider, ApolloClient, InMemoryCache } from "@apollo/client"
import createApolloClient from "./createApolloClient"
import React, { useState, useEffect } from "react"
import { persistCache, SessionStorageWrapper } from "apollo3-cache-persist"

type Props = {
   children?: React.ReactNode
}

const GraphQLProvider: React.FC<Props> = ({ children }) => {
   const [apolloClient, setClient] = useState<ApolloClient<any> | undefined>(undefined)

   const initializeClient = async () => {
      const cache = new InMemoryCache({})
      const client = createApolloClient(cache)

      // instantiating ApolloClient, else queries might run before the cache is persisted
      await persistCache({
         cache,
         storage: new SessionStorageWrapper(window.sessionStorage),
      })

      setClient(client)
   }

   useEffect(() => {
      if (!apolloClient) {
         initializeClient()
      }

      return () => {
         // nothing to clean up
      }
   }, [])
   if (apolloClient === undefined) return null
   return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
}

export default GraphQLProvider
