import React from "react"
import { createComponent } from "@lit-labs/react"
import { UserMenu as Component } from "web-components"

const UserMenu = createComponent({
   tagName: "user-menu",
   elementClass: Component,
   react: React,
   displayName: "UserMenu",
})

export default UserMenu
