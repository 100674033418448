import { AuthorizedDevice } from "./UseSecurity"
import styled from "@emotion/styled"
import ClientIcon from "./ClientIcon"
import OperatingSystemIcon from "./OperatingSystemIcon"
import { Button } from "@mui/material"
import DeviceIcon from "./DeviceIcon"
import { color, font, size } from "rentpost/styles"
import { useEffect, useState } from "react"
import Modal, { ModalProps } from "rentpost/layouts/Modal"
import { BackButton, KeyValueInfo, KeyValueList } from "rentpost/components/other"
import { CircularProgress } from "@mui/material"
import { padding } from "rentpost/styles/size"
import { AnimatePresence, motion } from "framer-motion"

interface Props {
   selectedDevice: AuthorizedDevice | undefined
   onRevokeSession: (sessionId: string) => void
   onRevokeAllDeviceSessions: (deviceId: string) => void
   onGoBack: () => void
   revokeSessionLoading: boolean
   revokeDeviceLoading: boolean
}

interface RevokeSessionModalProps {
   visible: boolean
   onClose: () => void
   onAgree: () => void
   loading: boolean
}

interface SessionComponentProps {
   session: AuthorizedDevice["sessions"][0]
   deviceName: string
   onLogout: (sessionId: string) => void
   loading: boolean
}

const SessionsList: React.FC<Props> = (props) => {
   const {
      selectedDevice,
      onRevokeSession,
      onRevokeAllDeviceSessions,
      revokeSessionLoading,
      revokeDeviceLoading,
      onGoBack,
   } = props

   if (!selectedDevice) {
      // If there is no selected device, render nothing and go back
      onGoBack()
      return null
   }

   // ================
   // States & Effects
   // ================
   const [revokeSessionId, setRevokeSessionId] = useState<string | null>(null)
   const [revokeDeviceId, setRevokeDeviceId] = useState<string | null>(null)
   const [showRevokeSessionModal, setShowRevokeSessionModal] = useState(false)

   useEffect(() => {
      const isCurrentDeviceGoingToBeRevoked =
         revokeDeviceId === selectedDevice.id && selectedDevice.isCurrentDevice

      const isCurrentSessionGoingToBeRevoked = selectedDevice.sessions.find(
         (session) => session.id === revokeSessionId
      )?.isCurrentSession

      if (isCurrentDeviceGoingToBeRevoked || isCurrentSessionGoingToBeRevoked) {
         setShowRevokeSessionModal(true)
         return
      }

      if (revokeDeviceId) {
         onRevokeAllDeviceSessions(revokeDeviceId)
         onGoBack()
         return
      }

      if (revokeSessionId) {
         onRevokeSession(revokeSessionId)
         return
      }
   }, [revokeSessionId, revokeDeviceId])

   // =====
   // Props
   // =====
   const revokeSessionModalProps: RevokeSessionModalProps = {
      visible: showRevokeSessionModal,
      onClose: () => {
         setShowRevokeSessionModal(false)
         setRevokeSessionId(null)
         setRevokeDeviceId(null)
      },
      onAgree: () => {
         if (revokeSessionId) {
            onRevokeSession(revokeSessionId as string)
            return
         }
         if (revokeDeviceId) {
            onRevokeAllDeviceSessions(revokeDeviceId as string)
            onGoBack()
            return
         }
      },
      loading: revokeSessionLoading || revokeDeviceLoading,
   }

   // ====================
   // Rendering Conditions
   // ====================
   const classNames = props.revokeDeviceLoading ? " revoke-device-loading" : ""

   // ===
   // JSX
   // ===
   return (
      <Holder className={classNames}>
         <div className="action-buttons">
            <BackButton onClick={onGoBack} />
            <Button
               variant="outlined"
               color="error"
               onClick={() => setRevokeDeviceId(selectedDevice.id)}
               disabled={props.revokeDeviceLoading}
               endIcon={
                  props.revokeDeviceLoading ? (
                     <CircularProgress size={"100%"} color="error" />
                  ) : null
               }
               style={{ borderColor: "currentcolor" }}
               size="small"
            >
               Remove
            </Button>
         </div>
         <div className="device-information">
            <DeviceIcon
               type={selectedDevice?.type}
               operatingSystem={selectedDevice.operatingSystem}
               className="device-information__icon"
            />
            <div className="device-information__sessions-information">
               {selectedDevice.isCurrentDevice && (
                  <span className="is-current-device">Current Device</span>
               )}
               <span className="gray-dark">{`(${selectedDevice.sessions.length}) Sessions on`}</span>
               <span>{selectedDevice.name} </span>
               <span>{`${selectedDevice.operatingSystem.name} (${selectedDevice.operatingSystem.version}) ${selectedDevice.type}`}</span>
            </div>
         </div>
         <div className="sessions-holder">
            <AnimatePresence>
               {selectedDevice.sessions.map((session) => (
                  <motion.div
                     initial={{ opacity: 1, x: "0%" }}
                     exit={{ opacity: 0, x: "-100%" }}
                     key={session.id}
                  >
                     <SessionComponent
                        key={session.id}
                        session={session}
                        deviceName={`${selectedDevice.operatingSystem.name} (${selectedDevice.operatingSystem.version}) ${selectedDevice.type}`}
                        onLogout={onRevokeSession}
                        loading={revokeSessionLoading && revokeSessionId === session.id}
                     />
                  </motion.div>
               ))}
            </AnimatePresence>
         </div>
         <RevokeSessionModal {...revokeSessionModalProps} />
      </Holder>
   )
}

// =================
// Session Component
// =================
const SessionComponent: React.FC<SessionComponentProps> = (props) => {
   const { session, deviceName, onLogout, loading } = props

   const classNames =
      "session-item" +
      (session.isCurrentSession ? " current-session" : "") +
      (loading ? " loading" : "")

   return (
      <div className={classNames}>
         <div className="client-information">
            <ClientIcon clientName={session.client?.name} className="client-icon" />
            <div className="client-name">
               <h4>
                  {session.client?.name || ""} {session.client?.type || ""}{" "}
               </h4>
               {session.isCurrentSession && <span className="is-current-session">Current</span>}
            </div>
            <div className="operating-system">
               <OperatingSystemIcon
                  operatingSystem={session.operatingSystem}
                  className="operating-system-icon"
               />
               <p>{deviceName}</p>
            </div>
         </div>
         <div className="date-information">
            <KeyValueInfo label="Last Active" value={session.lastActiveAt} />
            <KeyValueInfo label="Expires" value={session.expiresAt} />
         </div>
         <KeyValueList
            label="Recent Actions"
            list={session.actions.map(({ name, createdAt }) => ({
               key: name,
               value: createdAt,
            }))}
         />
         <Button
            variant="outlined"
            color="error"
            onClick={() => onLogout(session.id)}
            className="logout-button"
            size="small"
            disabled={loading}
            endIcon={loading ? <CircularProgress size={"100%"} color="error" /> : null}
         >
            Logout
         </Button>
      </div>
   )
}

// ============================
// Revoke Current Session Modal
// ============================
const RevokeSessionModal: React.FC<RevokeSessionModalProps> = (props) => {
   const { visible, onClose, onAgree, loading } = props
   const readyProps: ModalProps = {
      onClose,
      visible,
      title: "Please Confirm!",
      loading,
      type: "warning",
      description: "Are you sure you want to logout from this device, this will end your session",
      actionButtons: {
         accept: {
            label: "Yes, Logout",
            onClick: onAgree,
         },
         cancel: {
            label: "Cancel",
            onClick: onClose,
         },
      },
   }
   return <Modal {...readyProps} />
}

const Holder = styled.div`
   display: flex;
   flex-direction: column;
   gap: ${padding.block};
   --background-color: #f8f8f8;

   background-color: var(--background-color);
   border-radius: 1em;
   padding: ${padding.block} ${padding.inline};
   box-sizing: border-box;
   transition: opacity 0.2s ease-in-out;

   .action-buttons {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 1em;

      & > * {
         margin-block: 0;
      }
   }

   .device-information {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 1.2em;

      .device-information__sessions-information {
         display: flex;
         flex-direction: column;
         align-items: flex-start;
         justify-self: flex-start;
         flex: 1;
         .is-current-device {
            font-size: ${size.s};
            color: var(--background-color);
            ${font.regular};
            background-color: ${color.green};
            padding: 0.2em 1em;
            border-radius: 3em;
            margin-block: 0.6em;
            white-space: nowrap;
         }
         span {
            font-size: ${size.m};
            ${font.regular};
            color: ${color.violet};
         }
         .gray-dark {
            ${font.thin};
            color: ${color.grayDark};
         }
      }

      .device-information__icon {
         max-width: 10em;
         &.Smartphone {
            max-width: 3em;
         }
      }

      &.revoke-device-loading {
         opacity: 0.5;
      }
   }

   .sessions-holder {
      display: flex;
      flex-direction: column;
      gap: ${padding.block};
      box-sizing: border-box;
      position: relative;
      container-type: inline-size;

      .session-item {
         --holder-background-color: #efefef;

         width: 100%;
         display: flex;
         flex-direction: column;
         flex-wrap: wrap;
         box-sizing: border-box;
         gap: 1em;
         background-color: #efefef;
         border-radius: 1em;
         padding: 1em var(--padding-inline);
         position: relative;
         transition: all 0.2s ease-in-out;

         .client-information {
            display: grid;
            grid-template-columns: 3.2em auto;
            align-items: center;
            gap: 0em 1.1em;
            width: calc(100% - 9em);

            .client-icon {
               grid-column: 1;
               grid-row: 1 / 3;
               width: 3.2em;
               height: 4.4em;
            }

            .client-name {
               grid-column: 2;
               grid-row: 1;
               display: flex;
               align-items: baseline;
               justify-content: flex-start;
               width: 100%;
               overflow: hidden;
               gap: 0.7em;

               h4 {
                  margin-bottom: -0.2em;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
               }

               .is-current-session {
                  font-size: ${size.xs};
                  color: var(--background-color);
                  ${font.regular};
                  background-color: ${color.green};
                  padding: 0.3em 1em;
                  border-radius: 3em;
                  white-space: nowrap;
                  width: max-content;
               }
            }

            .operating-system {
               grid-column: 2;
               grid-row: 2;
               display: flex;
               align-items: center;
               overflow: hidden;

               .operating-system-icon {
                  width: 1.7em;
                  height: 1.5em;
                  fill: ${color.grayLight};
                  margin-right: 0.5em;
               }

               p {
                  color: ${color.grayLight};
                  width: 100%;
                  font-size: ${size.xs};
                  line-height: 1;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
               }
            }
         }

         .date-information {
            display: flex;
            flex-wrap: wrap;
            gap: 0.7em;
            width: 100%;
         }

         .logout-button {
            width: max-content;
            position: absolute;
            right: 1.5em;
         }

         &.current-session {
            --holder-background-color: #edf0efc7;
            background-color: #edf0efc7;
            border: 2px solid #75d3a9cb;
         }

         &.loading {
            opacity: 0.5;
            background: repeating-linear-gradient(
               45deg,
               #efefef,
               #efefef 10px,
               #ffdddd 10px,
               #ffdddd 20px
            );
         }

         @container (max-width: 550px) {
            gap: 1em;
            .client-information {
               width: 100%;
            }
            .logout-button {
               position: relative;
               right: auto;
               margin-top: 0.2em;
               width: 100%;
            }
         }
      }
   }

   &.revoke-device-loading {
      opacity: 0.5;
   }
`

export default SessionsList
