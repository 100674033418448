import { useOutletContext } from "react-router-dom"
import useProfile from "../useProfile"
import MarketingPage from "./MarketingPage"
import useIntegrations, { Integration } from "./useIntegrations"
import IntegrationModal, { IntegrationModalProps } from "./IntegrationModal"
import { useEffect, useState } from "react"
import { AvailableIntegrationsProps } from "./IntegrationsManager/AvailableIntegrations"
import IntegrationsManager from "./IntegrationsManager"
import { AnimateWithVariants } from "rentpost/components/functional"

const IntegrationsPage = () => {
   const {
      data: { getAvailableRolesData },
   } = useOutletContext() as ReturnType<typeof useProfile>

   const {
      integrations,
      loading,
      error,
      newAPIToken,
      isFirstLoad,
      addCustomAPIKey,
      addZapierAPIKey,
      addIntegration,
      removeIntegration,
      revokeAPIKey,
      newIntegration,
   } = useIntegrations()

   // ----------------
   // States & Effects
   // ----------------
   const [integrationIdForNewKey, setIntegrationIdForNewKey] = useState<string | null>(null)
   const [activeModal, setActiveModal] = useState<IntegrationModalProps["activeModal"] | null>(null)
   const [showMarketingPage, setShowMarketingPage] = useState(true)

   // Handle the showing of the marketing page
   useEffect(() => {
      const hasIntegrations = integrations && integrations?.length > 0
      const modalIsClosed = activeModal === null

      // Hide marketing page
      if (hasIntegrations && modalIsClosed) {
         setShowMarketingPage(false)
      }

      // Show marketing page
      if (!hasIntegrations && modalIsClosed) {
         setShowMarketingPage(true)
      }
   }, [integrations, activeModal])

   // Add a new custom API Key after adding a new (custom) integration
   useEffect(() => {
      const isCustomIntegration = newIntegration?.type === "custom"

      if (isCustomIntegration && newIntegration) {
         setIntegrationIdForNewKey(newIntegration?.id as string)
         setActiveModal("add-custom-api-key")
      }
   }, [newIntegration])

   // Show generated API Key
   useEffect(() => {
      newAPIToken && setActiveModal("show-api-key")
   }, [newAPIToken])

   // -----
   // PROPS
   // -----
   const marketingPageProps = {
      onAddIntegrationClick: () => setActiveModal("add-integration"),
   }

   const integrationModalProps: IntegrationModalProps = {
      availableRoles:
         getAvailableRolesData?.roles.filter(
            (role) => role.type === "manager" || role.type === "employee"
         ) || [],
      loading,
      error,
      addCustomAPIKey,
      addZapierAPIKey,
      addIntegration,
      newAPIToken,
      activeModal,
      setActiveModal,
      integrationIdForNewKey,
      newAPIKeyIntegrationType: newIntegration?.type,
      newAPIKeyIntegrationName: newIntegration?.name,
   }

   const integrationsManagerProps: AvailableIntegrationsProps = {
      integrations: integrations as Integration[],
      loading,
      revokeAPIKey,
      removeIntegration,
      onAddNewCustomKey: (integrationId: string) => {
         setIntegrationIdForNewKey(integrationId)
         setActiveModal("add-custom-api-key")
      },
      onAddNewZapierKey: () => setActiveModal("add-zapier-api-key"),
      onAddNewIntegration: () => setActiveModal("add-integration"),
   }

   return (
      <>
         {!isFirstLoad && (
            <>
               {showMarketingPage && (
                  <AnimationHolder>
                     <MarketingPage {...marketingPageProps} />
                  </AnimationHolder>
               )}
               {!showMarketingPage && integrations?.length && (
                  <AnimationHolder>
                     <IntegrationsManager {...integrationsManagerProps} />
                  </AnimationHolder>
               )}
               <IntegrationModal {...integrationModalProps} />
            </>
         )}
      </>
   )
}

// ================
// ANIMATION HOLDER
// ================
const AnimationHolder: React.FC<{ children: React.ReactNode }> = ({ children }) => {
   return (
      <AnimateWithVariants animate="fadeIn" initial="fadeOut" exit="fadeOut">
         {children}
      </AnimateWithVariants>
   )
}

export default IntegrationsPage
