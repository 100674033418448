import { useLazyQuery } from "@apollo/client"
import gql from "graphql-tag"
import { GetSettings } from "./__generated__"
import bypassMockError from "utils/bypassMockError"
import auth from "utils/auth"
import { useDispatch, useSelector } from "react-redux"
import { setSettings } from "redux/slices/settingsSlice"
import { TwoFactorMethod } from "pages/Verify/TwoFactorAuth/useTwoFactorAuth"
import { RootState } from "redux/store"
import { useErrorBoundary } from "react-error-boundary"
import { MissingDataError } from "configs/error/customErrors"

// =====
// QUERY
// =====
const GetSettingsQuery = gql`
   query GetSettings($subjectId: ID!) {
      settings(subjectId: $subjectId) {
         name
         value
      }
   }
`

const mapSettingsToState = (settings: GetSettings["settings"]) => {
   const twoFactorAuthEnabled: boolean =
      settings.find((s) => s.name === "2faEnabled")?.value === "1"
   const twoFactorAuthMethodSetting = settings.find(
      (s: { name: string; value: string }) => s.name === "2faMethod"
   )?.value

   let twoFactorAuthMethod: keyof typeof TwoFactorMethod = "SMS"
   switch (twoFactorAuthMethodSetting) {
      case "sms":
         twoFactorAuthMethod = "SMS"
         break
      case "email":
         twoFactorAuthMethod = "Email"
         break
      case "whatsapp":
         twoFactorAuthMethod = "Whatsapp"
         break
      default:
         twoFactorAuthMethod = "SMS"
         break
   }

   return {
      twoFactorAuthEnabled,
      twoFactorAuthMethod,
   }
}

const useSettings = () => {
   const [runGetSettings] = useLazyQuery<GetSettings>(GetSettingsQuery)
   const dispatch = useDispatch()
   const settings = useSelector((state: RootState) => state.settingsSlice)
   const { showBoundary } = useErrorBoundary()

   const getSettings = (userId: string) => {
      return runGetSettings({
         variables: {
            subjectId: userId,
         },
      })
         .then((res) => {
            if (!res?.data?.settings) {
               showBoundary(
                  new MissingDataError(
                     `Settings response data has (${res?.data?.settings}) value`,
                     "OperationName: getSettings - useSettings.ts"
                  )
               )
               return
            }
            const settings = res.data?.settings
            settings && dispatch(setSettings(mapSettingsToState(settings)))
            return settings
         })
         .catch((e) => bypassMockError(e))
   }

   // Get user ID from access token
   const userID = auth.getUserID()

   return {
      getSettings: () => userID && getSettings(userID),
      settings,
   }
}

export { mapSettingsToState }
export default useSettings
