import React from "react"
import { createComponent } from "@lit-labs/react"
import { PageErrorGoneElement as Component } from "web-components"

const PageErrorGoneElement = createComponent({
   tagName: "page-error-gone",
   elementClass: Component,
   react: React,
   displayName: "PageErrorGone",
   events: {
      onButtonClick: "click-action-button",
   },
})

export default PageErrorGoneElement
