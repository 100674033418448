import { ModalProps, Modal } from "layouts"
import styled from "@emotion/styled"
import { CountDownTimer } from "rentpost/components/other"
import { color } from "rentpost/styles"
import useLogout from "pages/Logout/useLogout"

interface Props {
   activeModal: string | false
   onClose: () => void
   timeoutAmount: number
}

const ModalLoginSuspended: React.FC<Props> = ({ activeModal, onClose, timeoutAmount }) => {
   const { gotoLogoutPage } = useLogout()

   const logout = () => {
      onClose()
      gotoLogoutPage()
   }

   const props: ModalProps = {
      visible: activeModal === "loginSuspended",
      onClose: logout,
      canIgnore: false,
      renderComponent: {
         show: true,
         component: () => (
            <Holder>
               <h2>Too many failed 2fa attempts. User account suspended for</h2>
               <CountDownTimer
                  timeoutStart
                  onTimeout={logout}
                  timeoutAmount={timeoutAmount}
                  className="counter-timer"
               />
               <p className="box">You will be able to login again after that time interval.</p>
            </Holder>
         ),
      },
   }

   return <Modal {...props} />
}

const Holder = styled.div`
   display: flex;
   flex-direction: column;
   gap: 1em;
   width: 21em;

   h2 {
      font-size: 1.3rem;
      font-weight: 500;
      margin-bottom: 1rem;
      color: ${color.red};
      text-align: center;
   }

   p {
      font-size: 1em;
      margin-bottom: 1em;
      text-align: center;
      max-width: 19em;
      color: ${color.grayDark};
      padding: 1em 2.5em;
   }
   .counter-timer {
      font-size: 3.4em;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
   }
`

export type { Props as ModalLogoutProps }
export default ModalLoginSuspended
