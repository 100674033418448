import high from './high.png'
import medium from './medium.png'
import mobile from './mobile.png'
import placeholder from './placeholder.png'

export default {
   high,
   medium,
   mobile,
   placeholder
}