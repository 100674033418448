import { ModalProps, Modal } from "layouts"
import { useNavigate } from "react-router-dom"
import ROUTE from "routes/ROUTE"

interface ModalErrorProps {
   errorMessage: string | undefined
   onClose: () => void
   activeModal: string | false
}
const ModalError: React.FC<ModalErrorProps> = ({ errorMessage, onClose, activeModal }) => {
   const title = errorMessage || "Error Occurred"
   const navigate = useNavigate()
   const retry = () => {
      window.location.reload()
      onClose()
   }

   const logout = () => {
      navigate(ROUTE.LOGOUT)
      onClose()
   }

   const props: ModalProps = {
      visible: activeModal === "error",
      onClose,
      title,
      description: "Please try again",
      type: "error",
      actionButtons: {
         accept: {
            label: "Try again",
            onClick: retry,
         },

         cancel: {
            label: "Logout",
            onClick: logout,
         },
      },
      canIgnore: true,
   }

   return <Modal {...props} />
}

export type { ModalErrorProps }
export default ModalError
