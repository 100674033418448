import RegisterForm, { RegisterFormFieldsType } from "./RegisterForm"
import { useSearchParams } from "react-router-dom"
import { MultiSectionsLayout } from "layouts"
import Illustration from "../Signup/Illustration"
import styled from "@emotion/styled"
import SVG from "assets/svg"
import { color, screen } from "styles"
import useRegister from "./useRegister"
import { GetRoleDetails } from "./__generated__"
import { useEffect, useState } from "react"
import RoleCardWithAvatarSelector from "./RoleCardWithAvatarSelection"
import hideLoadingPage from "utils/hideLoadingPage"
import { LoadingSpinner, RoleType } from "rentpost/src/components/other"
import InvalidInviteKey from "./InvalidaInvieKey"

const Register = () => {
   const [avatar, setAvatar] = useState<string | null>(null)
   const [searchParams] = useSearchParams()
   const initialInviteKey = searchParams.get("invite")

   const {
      getRoleData,
      initialLogin,
      initialLoginError,
      initialLoginLoading,
      inviteKey,
      setIsValidKey,
      getRoleLoading,
      isValidKey,
      setInviteKey,
   } = useRegister({ avatar })

   // Check if the invite key is valid when the page is loaded
   useEffect(() => {
      if (!initialInviteKey) {
         setIsValidKey(false)
         return // Return early if the invite key is not set
      }
      setInviteKey(initialInviteKey)
   }, [])

   // Run the initial login when the invite key is set
   useEffect(() => {
      inviteKey && initialLogin(inviteKey)
   }, [inviteKey])

   const isReadyToRender = (isValidKey === false && !getRoleLoading) || (isValidKey && getRoleData)

   // Hide loading page when role data is fetched and ready to render
   isReadyToRender && hideLoadingPage()

   // ===
   // JSX
   // ===
   return (
      <MultiSectionsLayout
         htmlTitle="Register | RentPost"
         illustrationSection={<Illustration />}
         mainSection={
            <>
               {isReadyToRender ? (
                  inviteKey && getRoleData ? (
                     <MainSection
                        roleDate={getRoleData}
                        avatar={avatar}
                        setAvatar={setAvatar}
                        inviteKey={inviteKey}
                     />
                  ) : isValidKey === false ? (
                     <InvalidInviteKey
                        setInviteKey={setInviteKey}
                        loading={initialLoginLoading || getRoleLoading}
                        error={initialLoginError}
                     />
                  ) : null
               ) : (
                  <div
                     style={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                     }}
                  >
                     <LoadingSpinner type="drops" color={color.green} />
                  </div>
               )}
            </>
         }
      />
   )
}

const MainSection = (props: {
   roleDate: GetRoleDetails
   avatar: string | null
   inviteKey: string
   setAvatar: (avatar: string | null) => void
}) => {
   const roles = props.roleDate?.roles[0]
   const displayName = roles?.displayName
   const roleType = roles?.type as RoleType
   const email = roles?.email
   const firstName = displayName?.split(" ")[0]
   const lastName = displayName?.split(" ")[1]
   const registerInitialValues: RegisterFormFieldsType = {
      firstName,
      lastName,
      email,
      password: "",
      passwordConfirm: "",
   }
   const label =
      roleType === "manager" || roleType === "owner"
         ? "Let's start managing your property"
         : "Let's get you setup for your rental"
   return (
      <Holder>
         <SVG.signUpIllustrationSVG.sun className="sun-illustration" />
         <div className="container">
            <h1>{label}</h1>
            <RoleCardWithAvatarSelector
               onAvatarChange={props.setAvatar}
               roleData={props.roleDate}
            />
            <RegisterForm
               registerFormInitialValue={registerInitialValues}
               avatar={props.avatar}
               inviteKey={props.inviteKey}
            />
         </div>
      </Holder>
   )
}

const Holder = styled.div`
   width: 100%;

   svg.sun-illustration {
      position: absolute;
      top: -1em;
      right: 0em;
      width: max(35em, 40vw);
      z-index: -2;
      ${screen.showOnDesktopOnly};
   }

   & > .container {
      display: flex;
      flex-direction: column;
      gap: 0.5em;
   }
`
export default Register
