import AvailableIntegrations, { AvailableIntegrationsProps } from "./AvailableIntegrations"

const IntegrationsManager: React.FC<AvailableIntegrationsProps> = (props) => {
   return (
      <>
         <h1> Integrations</h1>
         <p className="main-description">
            Integrations are a great way to connect Rentpost to other apps you use. You can use
            integrations to automate your workflow and save time.
         </p>
         <AvailableIntegrations {...props} />
      </>
   )
}

export default IntegrationsManager
