import styled from "@emotion/styled"
import useResetPassword from "./useResetPassword"
import MultiSectionsLayout from "rentpost/layouts/MultiSectionsLayout"
import Illustration from "pages/Login/Illustration"
import EmailForm from "./EmailForm"
import PasswordForm from "./PasswordForm"
import { AnimatePresence } from "framer-motion"
import { color, font, screen, size, space } from "rentpost/styles"

type MainSectionProps = ReturnType<typeof useResetPassword>

const ResetPassword = () => {
   const resetPasswordHook = useResetPassword()
   const props = {
      illustrationSection: <Illustration />,
      mainSection: <MainSection {...resetPasswordHook} />,
      htmlTitle: "Reset Password | RentPost",
   }

   return <MultiSectionsLayout {...props} />
}

const MainSection: React.FC<MainSectionProps> = (props) => {
   const {
      activePage,
      loading: { resetPasswordLoading, sendPasswordResetLoading },
      error: { resetPasswordError, sendPasswordResetError },
      resetPassword,
      sendResetPassword,
   } = props

   // =====
   // Props
   // =====
   const emailFormProps = {
      onSubmit: sendResetPassword,
      loading: sendPasswordResetLoading,
      error: sendPasswordResetError,
      activePage,
   }

   const passwordFormProps = {
      onSubmit: resetPassword,
      loading: resetPasswordLoading,
      error: resetPasswordError,
      activePage,
   }

   const showEmailForm = activePage === "email-form" || activePage === "email-sent-successfully"
   const showPasswordForm =
      activePage === "password-form" || activePage === "password-updated-successfully"

   return (
      <Holder>
         <AnimatePresence mode="wait">
            {showEmailForm && <EmailForm {...emailFormProps} />}
            {showPasswordForm && <PasswordForm {...passwordFormProps} />}
         </AnimatePresence>
      </Holder>
   )
}

const Holder = styled.div`
   display: grid;
   place-content: center;
   padding: ${space.xxl};
   min-width: 25em;

   * {
      max-width: 28.6em;
   }
   h1 {
      color: ${color.violet};
      margin-bottom: 0;
   }
   .description {
      color: ${color.grayLight};
      margin-bottom: ${space.s};
      font-size: ${size.m};
      ${font.thinItalic};
   }

   .success-holder {
      display: flex;
      flex-direction: column;
      align-items: center;
      h1 {
         text-align: center;
      }
      .icon {
         align-self: center;
         width: 10em;
      }

      /* Password Updated Animation */
      &.password {
         --animation-tween-duration: 0.4s;

         .text {
            opacity: 0;
            &.first {
               animation: fade-in var(--animation-tween-duration) ease-in-out
                  calc(var(--animation-tween-duration) * 1) forwards;
            }

            &.second {
               animation: fade-in var(--animation-tween-duration) ease-in-out
                  calc(var(--animation-tween-duration) * 2) forwards;
            }

            &.third {
               animation: fade-in var(--animation-tween-duration) ease-in-out
                  calc(var(--animation-tween-duration) * 3) forwards;
            }

            &.fourth {
               animation: fade-in var(--animation-tween-duration) ease-in-out
                  calc(var(--animation-tween-duration) * 4) forwards;
            }
         }

         .check-mark {
            opacity: 0;
            animation: fade-in var(--animation-tween-duration) ease-in-out
               calc(var(--animation-tween-duration) * 5) forwards;
         }

         @keyframes fade-in {
            from {
               opacity: 0;
            }
            to {
               opacity: 1;
            }
         }
      }
   }

   /* Responsiveness */
   ${screen.tabletAndMobile} {
      padding: ${space.m};
      .content-holder {
         gap: 1em;
         align-items: center;
      }

      .get-started {
         position: relative;
         right: initial;
         top: initial;
         align-self: center;
         flex-direction: column;
         gap: 0;

         button {
            margin-block: 0.2em;
            margin-bottom: 2em;
         }
      }
   }
`
export default ResetPassword
