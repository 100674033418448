import styled from "@emotion/styled"
import useProfile from "../useProfile"
import { border, screen } from "rentpost/styles"
import RolesList from "./RolesList"
import { useOutletContext } from "react-router-dom"
import { useState } from "react"
import RemoveRoleModal from "./ModalRemoveRole"
import RightSection from "./RightSection"
import UploadAvatarModal from "./ModalUpdateAvatar"
import NoRoles from "pages/Login/NoRoles"
import { RoleType } from "rentpost/components/other"
import useLogin from "pages/Login/useLogin"
import { padding } from "rentpost/styles/size"

type RoleDetails = { roleType: RoleType; roleId: string }

const RolesPage = () => {
   const { data, hasNoRoles, loading, removeRole, uploadAvatar, selectRole, error } =
      useOutletContext() as ReturnType<typeof useProfile>
   const [roleToRemove, setRoleToRemove] = useState<RoleDetails | undefined>(undefined)
   const [roleToUploadAvatar, setRoleToUploadAvatar] = useState<string | undefined>(undefined)
   const { getAvatarImageFromRoleData } = useLogin()

   // ==============
   // Roles Handlers
   // ==============
   const handleRemoveRole = () => {
      if (!roleToRemove) return
      removeRole(roleToRemove.roleId)
   }
   const handleUploadAvatar = (avatarImageURL: string) => {
      if (!roleToUploadAvatar) return
      uploadAvatar(roleToUploadAvatar, avatarImageURL)
   }

   // ===============
   // Component Props
   // ===============
   const rolesData = data?.getAvailableRolesData

   // Roles List Props
   const rolesListProps = {
      rolesData,
      removeRole: (roleId: string, roleType: RoleType) => setRoleToRemove({ roleId, roleType }),
      uploadAvatar: (roleId: string) => setRoleToUploadAvatar(roleId),
      selectRole,
      removeRoleLoading: loading.removeRoleLoading || loading.getAvailableRolesLoading,
      uploadAvatarLoading: loading.uploadAvatarLoading || loading.getAvailableRolesLoading,
      className: "roles-list",
      selectRoleLoading: loading.selectRoleLoading,
   }

   // Remove Role Modal Props
   const removeRoleModalProps = {
      visible: !!roleToRemove,
      onClose: () => setRoleToRemove(undefined),
      onRemoveRole: handleRemoveRole,
      roleType: roleToRemove?.roleType as string,
      loading: loading.removeRoleLoading || loading.getAvailableRolesLoading,
      error: error.removeRoleError,
   }

   // Upload Avatar Modal Props
   const selectedRole = rolesData?.roles.find((role) => role.id === roleToUploadAvatar)
   const uploadAvatarModalProps = {
      visible: !!roleToUploadAvatar,
      onClose: () => setRoleToUploadAvatar(undefined),
      currentAvatarImageURL: getAvatarImageFromRoleData(selectedRole),
      roleType: selectedRole?.type as RoleType,
      displayName: selectedRole?.displayName as string,
      roleID: roleToUploadAvatar as string,
      onSave: handleUploadAvatar,
      loading: loading.uploadAvatarLoading || loading.getAvailableRolesLoading,
   }

   const showRolesList = rolesData && !hasNoRoles
   const showNoRoles = hasNoRoles

   return (
      <Holder>
         {showRolesList && (
            <h1>
               You have {rolesData?.roles.length ? rolesData?.roles.length + 1 : ""} associated
               roles
            </h1>
         )}
         {showRolesList && <RolesList {...rolesListProps} />}
         {showRolesList && <RightSection className="right-section" />}
         {showNoRoles && <NoRoles />}
         <RemoveRoleModal {...removeRoleModalProps} />
         <UploadAvatarModal {...uploadAvatarModalProps} />
      </Holder>
   )
}

const Holder = styled.div`
   display: grid;
   grid-template-columns: 3fr 2fr;
   gap: 0em 3em;
   width: 100%;

   h1 {
      grid-column: 1 / 3;
      margin-bottom: 0em;
   }

   .right-section {
      .right-section-group {
         display: flex;
         flex-direction: column;
         align-items: flex-end;
         padding-block: ${padding.block};
      }

      .right-section-group:first-of-type {
         margin: 0;
         padding-bottom: 2em;
         ${border.bottom};
      }
   }

   @media screen and (max-width: 1000px) {
      grid-template-columns: 1fr 0.8fr;
      gap: 1em 3em;
   }

   @media screen and (max-width: 800px) {
      display: flex;
      flex-direction: column;
      gap: 0.6em;

      .right-section {
         ${border.top};
      }

      .right-section-group:first-of-type {
         border-bottom: none !important;
      }

      .right-section-group:nth-child(2) {
         background: #f6f6f6;
         padding: ${padding.block} ${padding.inline};
         margin-inline: calc(${padding.inline} * -1);
         align-items: start;

         ${screen.onlyMobile} {
            button {
               width: 100%;
            }
         }
      }
   }
`

export default RolesPage
