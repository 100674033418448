// @ts-ignore - this makes tsc happy
import env from "configs/env"
import getPASETOFooter from "utils/getPASETOFooter"
import auth from "utils/auth"

export const setZendeskJWT = () => {
   if (env.TARGET_ENV === "development") return // Show Zendesk in production & stage only
   try {
      const accessToken = auth.getAccessToken()
      const jwt = accessToken && getPASETOFooter(accessToken).zendeskMessengerJWT
      // @ts-ignore - this makes tsc happy
      zE("messenger", "loginUser", function (callback) {
         callback(jwt)
         console.log("Zendesk JWT set successfully.")
      })
   } catch (error) {
      console.error("Error Setting Zendesk JWT", error)
   }
}

const initZendesk = () => {
   if (env.TARGET_ENV === "development") return // Show Zendesk in production & stage only
   const accessToken = auth.getAccessToken()
   const jwt = accessToken && getPASETOFooter(accessToken).zendeskMessengerJWT
   const script = document.createElement("script")
   script.src = `https://static.zdassets.com/ekr/snippet.js?key=98923f0a-053f-49a9-b190-866086692d02`
   // script.async = true
   script.id = "ze-snippet"
   document.body.appendChild(script)
   script.onload = () => {
      console.log("Zendesk loaded successfully.")
      setZendeskJWT()
   }
}

export default initZendesk
