import { useMutation, useLazyQuery } from "@apollo/client"
import { gql } from "@apollo/client"
import usePOEToken from "hooks/usePOEToken"
import bypassMockError from "utils/bypassMockError"
import {
   AddOwner,
   AddOwnerVariables,
   AddTenant,
   AddTenantVariables,
   AddUnit,
   AddUnitVariables,
   UpdateTenant,
   UpdateTenantVariables,
   UpdateUnit,
   UpdateUnitVariables,
   GetUnitsAndTenants,
   GetAvailableOwners,
   AddBankAccount,
   AddBankAccountVariables,
   GetBankAccounts,
   AddOwner_addOwner,
} from "./__generated__"
import { useState } from "react"
import { useErrorBoundary } from "react-error-boundary"
import { MissingDataError } from "configs/error/customErrors"
import { LegalEntityTypeKey, UnitTypeKey } from "../sharedData"
import _ from "lodash"
import { Dayjs } from "dayjs"

import { getDate, getDateString } from "utils/dateValue"

export interface Unit {
   id: string
   typeKey: UnitTypeKey

   address: {
      id: string
      countryKey: string
      state: string
      city: string
      street: string
      aptSuiteEtc: string
      postalCode: string
   }

   desiredRentAmount: string
   bathrooms: number
   bedrooms: number
   sqft: number
   arePetsAllowed: boolean
   escrowBankChartOfAccountId: string

   tenants: {
      id: string
      typeKey: LegalEntityTypeKey
      firstName?: string
      lastName?: string
      companyName?: string
      email: string
      phone: string
      phoneExt?: string
      startDate: Dayjs
      endDate: Dayjs
      rentDueDay: string
      rentAmount: string
   }[]

   owner: OwnerType
}

export interface OwnerType {
   id: string
   legalEntityId: string
   displayName: string
   typeKey: LegalEntityTypeKey
}

export interface UnitFormFields {
   id?: string // for update
   typeKey: UnitTypeKey

   address: {
      id?: string // for update
      countryKey: string
      state: string
      city: string
      street: string
      aptSuiteEtc: string
      postalCode: string
   }

   desiredRentAmount: string
   bathrooms: number
   bedrooms: number
   sqft: string
   arePetsAllowed: boolean

   owner: {
      id?: string // for select present owner
      typeKey?: LegalEntityTypeKey
      firstName?: string
      lastName?: string
      companyName?: string
   }
}

export interface TenantFormFields {
   id?: string // for update
   unitId: string
   typeKey: LegalEntityTypeKey
   firstName?: string
   lastName?: string
   companyName?: string
   email: string
   phone: string
   phoneExt?: string
   startDate: Dayjs | null
   endDate: Dayjs | null
   rentDueDay: string
   rentAmount: string
}

// =========
// FRAGMENTS
// =========

export const AddressFragment = gql`
   fragment AddressFragment on Address {
      id
      country {
         key
      }
      state
      city
      street
      aptSuiteEtc
      postalCode
   }
`
const OwnerFragment = gql`
   fragment OwnerFragment on Owner {
      id
      legalEntity {
         type {
            key
         }
         id
         displayName
      }
   }
`
// =======
// QUERIES
// =======
const GetUnitsAndTenantsQuery = gql`
   ${AddressFragment}
   query GetUnitsAndTenants {
      units {
         id
         type {
            key
         }
         desiredRentAmount {
            value
         }
         bathrooms
         bedrooms
         sqft
         arePetsAllowed
         escrowChartOfAccount {
            id
         }

         address {
            ...AddressFragment
         }

         tenants {
            id
            legalEntity {
               type {
                  key
               }
               firstName
               lastName
               companyName
               email
               phone
               phoneExt
            }
            startDate
            endDate
            rentDueDay
            rentAmount {
               value
            }
         }

         owner {
            id
            legalEntity {
               type {
                  key
               }
               id
               displayName
            }
         }
      }
   }
`
const GetAvailableOwnersQuery = gql`
   ${OwnerFragment}
   query GetAvailableOwners {
      owners {
         ...OwnerFragment
      }
   }
`
const GetBankAccountsQuery = gql`
   query GetBankAccounts {
      bankAccounts {
         accountHolderLegalEntity {
            id
         }
         chartOfAccount {
            id
         }
      }
   }
`

// ========
// MUTATION
// ========
const AddUnitMutation = gql`
   mutation AddUnit($input: AddUnitInput!) {
      addUnit(input: $input) {
         id
      }
   }
`
const UpdateUnitMutation = gql`
   mutation UpdateUnit($unit: UpdateUnitInput!, $address: UpdateAddressInput!) {
      updateUnit(input: $unit) {
         id
      }
      updateAddress(input: $address) {
         id
      }
   }
`
const AddOwnerMutation = gql`
   ${OwnerFragment}
   mutation AddOwner($input: AddOwnerInput!) {
      addOwner(input: $input) {
         ...OwnerFragment
      }
   }
`
const AddTenantMutation = gql`
   mutation AddTenant($input: AddTenantInput!) {
      addTenant(input: $input) {
         id
      }
   }
`
const UpdateTenantMutation = gql`
   mutation UpdateTenant($input: UpdateTenantInput!) {
      updateTenant(input: $input) {
         id
      }
   }
`
const AddBankAccountMutation = gql`
   mutation AddBankAccount($input: AddBankAccountInput!) {
      addTrustBankAccount(input: $input) {
         chartOfAccount {
            id
         }
      }
   }
`
// ========================
// USE UNIT AND TENANT HOOK
// ========================
const useUnitAndTenant = () => {
   const { showBoundary } = useErrorBoundary()
   // So we currently only use one unit, but we can add multiple units in the future so we keep it as an array
   const [units, setUnits] = useState<Unit[] | null>(null)
   const [bankAccounts, setBankAccounts] = useState<GetBankAccounts["bankAccounts"] | null>(null)
   const [availableOwners, setAvailableOwners] = useState<OwnerType[] | null>(null)
   const { poeToken, refreshPOEToken } = usePOEToken()

   // ==========
   // OPERATIONS
   // ==========
   const [
      runGetUnitsAndTenants,
      { loading: getUnitsAndTenantsLoading, error: getUnitsAndTenantsError },
   ] = useLazyQuery<GetUnitsAndTenants>(GetUnitsAndTenantsQuery)
   const [
      runGetAvailableOwners,
      { loading: getAvailableOwnersLoading, error: getAvailableOwnersError },
   ] = useLazyQuery<GetAvailableOwners>(GetAvailableOwnersQuery)
   const [runGetBankAccounts, { loading: getBankAccountsLoading, error: getBankAccountsError }] =
      useLazyQuery<GetBankAccounts>(GetBankAccountsQuery)
   const [runAddUnit, { loading: addUnitLoading, error: addUnitError }] = useMutation<
      AddUnit,
      AddUnitVariables
   >(AddUnitMutation)
   const [runUpdateUnit, { loading: updateUnitLoading, error: updateUnitError }] = useMutation<
      UpdateUnit,
      UpdateUnitVariables
   >(UpdateUnitMutation)
   const [runAddOwner, { loading: addOwnerLoading, error: addOwnerError }] = useMutation<
      AddOwner,
      AddOwnerVariables
   >(AddOwnerMutation)
   const [runAddTenant, { loading: addTenantLoading, error: addTenantError }] = useMutation<
      AddTenant,
      AddTenantVariables
   >(AddTenantMutation)
   const [runUpdateTenant, { loading: updateTenantLoading, error: updateTenantError }] =
      useMutation<UpdateTenant, UpdateTenantVariables>(UpdateTenantMutation)

   const [runAddBankAccount, { loading: addBankAccountLoading, error: addBankAccountError }] =
      useMutation<AddBankAccount, AddBankAccountVariables>(AddBankAccountMutation)

   // ================
   // MAPPER FUNCTIONS
   // ================
   const mapUnitAndTenant = (data: GetUnitsAndTenants): Unit[] => {
      const units = data?.units?.map((unit) => {
         const readyUnit: Unit = {
            id: unit.id,
            typeKey: unit.type?.key as UnitTypeKey,
            desiredRentAmount: unit.desiredRentAmount?.value || "",
            bathrooms: unit.bathrooms || 0,
            bedrooms: unit.bedrooms || 0,
            sqft: unit.sqft || 0,
            arePetsAllowed: unit.arePetsAllowed,
            escrowBankChartOfAccountId: unit.escrowChartOfAccount?.id || "",
            address: {
               id: unit.address.id,
               countryKey: unit.address.country?.key || "",
               state: unit.address.state || "",
               city: unit.address.city || "",
               street: unit.address.street || "",
               aptSuiteEtc: unit.address.aptSuiteEtc || "",
               postalCode: unit.address.postalCode || "",
            },
            tenants: unit.tenants.map((tenant) => ({
               id: tenant.id,
               typeKey: tenant.legalEntity.type.key as LegalEntityTypeKey,
               firstName: tenant.legalEntity.firstName || "",
               lastName: tenant.legalEntity.lastName || "",
               companyName: tenant.legalEntity.companyName || "",
               email: tenant.legalEntity.email,
               phone: tenant.legalEntity.phone,
               phoneExt: tenant.legalEntity.phoneExt || "",
               startDate: getDate(tenant.startDate),
               endDate: getDate(tenant.endDate),
               rentDueDay: `${tenant.rentDueDay || ""}`,
               rentAmount: tenant.rentAmount.value,
            })),
            owner: {
               id: unit.owner.id,
               legalEntityId: unit.owner.legalEntity.id || "",
               displayName: unit.owner.legalEntity.displayName || "",
               typeKey: unit.owner.legalEntity.type.key as LegalEntityTypeKey,
            },
         }
         return readyUnit
      })
      return units
   }
   const mapAvailableOwners = (ownersData: GetAvailableOwners["owners"]): OwnerType[] => {
      const owners = ownersData.map((owner) => ({
         id: owner.id,
         displayName: owner.legalEntity.displayName,
         legalEntityId: owner.legalEntity.id,
         typeKey: owner.legalEntity.type.key as LegalEntityTypeKey,
      }))
      return owners
   }

   // ==============
   // ACTION RUNNERS
   // ==============
   const getSelectedOwner = async (unit: UnitFormFields): Promise<OwnerType | undefined> => {
      let selectedOwner = availableOwners?.find((owner) => owner.id === unit.owner.id)
      if (unit.owner.id === "add_new_owner") {
         // if owner is not present, add owner first
         const { data } = await addOwner(unit.owner)
         const newOwner = data?.addOwner as AddOwner_addOwner
         selectedOwner = mapAvailableOwners([newOwner])[0]
         refreshPOEToken()
         await getAvailableOwners() // refresh available owners after adding new owner
      }
      if (selectedOwner === undefined) {
         showBoundary(
            new MissingDataError(
               `Selected owner is not found in available owners`,
               "OperationName: getSelectedOwner - useUnitAndTenant.ts"
            )
         )
      }
      return selectedOwner
   }

   const getChartOfAccountId = async (selectedOwner: OwnerType): Promise<string | undefined> => {
      let chartOfAccountId = ""

      const isBankAccountPresent = bankAccounts?.find(
         (account) => account.accountHolderLegalEntity?.id === selectedOwner.legalEntityId
      )
      if (!isBankAccountPresent) {
         const { data } = await addBankAccount(selectedOwner.legalEntityId)
         chartOfAccountId = data?.addTrustBankAccount?.chartOfAccount?.id as string
         refreshPOEToken()
         await getBankAccounts() // refresh bank accounts after adding new bank account
      } else {
         chartOfAccountId = bankAccounts?.find(
            (account) => account.accountHolderLegalEntity?.id === selectedOwner.legalEntityId
         )?.chartOfAccount?.id as string
      }
      return chartOfAccountId
   }
   // ==================
   // HANDLERS & EFFECTS
   // ==================
   const getUnitsAndTenants = async () => {
      const { data } = await runGetUnitsAndTenants({
         context: {
            showErrorModal: true,
         },
      })
      if (!data?.units) {
         showBoundary(
            new MissingDataError(
               `Units response data has (${data?.units}) value`,
               "OperationName: getUnitsAndTenants - useUnitAndTenant.ts"
            )
         )
         return
      }
      setUnits(mapUnitAndTenant(data))
   }
   const getAvailableOwners = async () => {
      const { data } = await runGetAvailableOwners({
         context: {
            showErrorModal: true,
         },
      })
      if (!data?.owners) {
         showBoundary(
            new MissingDataError(
               `Owners response data has (${data?.owners}) value`,
               "OperationName: getAvailableOwners - useUnitAndTenant.ts"
            )
         )
         return
      }
      setAvailableOwners(mapAvailableOwners(data.owners))
   }
   const getBankAccounts = async () => {
      const { data } = await runGetBankAccounts({
         context: {
            showErrorModal: true,
         },
      })
      if (!data?.bankAccounts) {
         showBoundary(
            new MissingDataError(
               `Bank Accounts response data has (${data?.bankAccounts}) value`,
               "OperationName: getBankAccounts - useUnitAndTenant.ts"
            )
         )
         return
      }
      setBankAccounts(data.bankAccounts)
   }
   const addBankAccount = async (legalEntityId: string) => {
      return runAddBankAccount({
         context: {
            poeToken: poeToken.current,
         },
         variables: {
            input: {
               accountHolderLegalEntityId: legalEntityId,
               nickname: "Operating Account",
               typeKey: "checking",
            },
         },
      })
   }
   const addOwner = async (owner: UnitFormFields["owner"]) => {
      return runAddOwner({
         context: {
            poeToken: poeToken.current,
         },
         variables: {
            input: {
               sendInvite: false,
               operatingReserve: { value: "0" },
               legalEntity: {
                  typeKey: owner.typeKey as string,
                  ...(owner.firstName && { firstName: owner.firstName }),
                  ...(owner.lastName && { lastName: owner.lastName }),
                  ...(owner.companyName && { companyName: owner.companyName }),
               },
            },
         },
      })
   }
   const addTenant = async (tenant: TenantFormFields) => {
      const isTenantNaturalPerson =
         tenant.typeKey === "natural_person" || tenant.typeKey === "sole_proprietorship"
      try {
         await runAddTenant({
            context: {
               poeToken: poeToken.current,
            },
            variables: {
               input: {
                  sendInvite: false,
                  unitId: tenant.unitId,
                  legalEntity: {
                     typeKey: tenant.typeKey,
                     ...(isTenantNaturalPerson && { firstName: tenant.firstName }),
                     ...(isTenantNaturalPerson && { lastName: tenant.lastName }),
                     ...(!isTenantNaturalPerson && { companyName: tenant.companyName }),
                     ...(tenant.email && { email: tenant.email }),
                     ...(tenant.phone && {
                        phone: "+" + tenant.phone?.replace("+", "")?.replace(" ", ""),
                     }),
                     phoneExt: tenant.phoneExt,
                  },
                  startDate: getDateString(tenant.startDate),
                  endDate: getDateString(tenant.endDate),
                  rentDueDay: parseInt(tenant.rentDueDay),
                  rentAmount: {
                     value: tenant.rentAmount,
                  },
               },
            },
         })
         refreshPOEToken()
         await getUnitsAndTenants()
      } catch (error) {}
   }
   const updateTenant = async (tenant: TenantFormFields) => {
      const currentTenantData = units
         ?.find((u) => u.id === tenant.unitId)
         ?.tenants.find((t) => t.id === tenant.id) as Unit["tenants"][0]
      if (_.isEqual(currentTenantData, tenant) || !currentTenantData) {
         return // exit the function if no changes are made
      }
      const isTenantNaturalPerson =
         tenant.typeKey === "natural_person" || tenant.typeKey === "sole_proprietorship"
      try {
         await runUpdateTenant({
            context: {
               poeToken: poeToken.current,
            },
            variables: {
               input: {
                  id: tenant.id as string,
                  legalEntity: {
                     typeKey: tenant.typeKey,
                     ...(isTenantNaturalPerson && {
                        firstName: tenant.firstName,
                     }),
                     ...(isTenantNaturalPerson && {
                        lastName: tenant.lastName,
                     }),
                     ...(!isTenantNaturalPerson && {
                        companyName: tenant.companyName,
                     }),
                     ...(currentTenantData.email !== tenant.email && { email: tenant.email }),
                     ...(currentTenantData.phone !== tenant.phone &&
                        tenant.phone && {
                           phone: "+" + tenant.phone?.replace("+", "")?.replace(" ", ""),
                        }),
                  },
                  rentDueDay: parseInt(tenant.rentDueDay),
                  ...(currentTenantData.startDate !== tenant.startDate && {
                     startDate: getDateString(tenant.startDate),
                  }),
                  ...(currentTenantData.endDate !== tenant.endDate && {
                     endDate: getDateString(tenant.endDate),
                  }),
                  ...(currentTenantData.rentAmount !== tenant.rentAmount && {
                     rentAmount: {
                        value: tenant.rentAmount,
                     },
                  }),
               },
            },
         })
         refreshPOEToken()
         await getUnitsAndTenants()
      } catch (error) {}
   }
   const addUnit = async (unit: UnitFormFields) => {
      const {
         address: { id, ...addressFields },
      } = unit
      try {
         const selectedOwner = await getSelectedOwner(unit)

         const chartOfAccountId = await (selectedOwner && getChartOfAccountId(selectedOwner))

         await (chartOfAccountId &&
            selectedOwner &&
            runAddUnit({
               context: {
                  poeToken: poeToken.current,
               },
               variables: {
                  input: {
                     typeKey: unit.typeKey,
                     ownerId: selectedOwner?.id,
                     address: addressFields,
                     desiredRentAmount: {
                        value: unit.desiredRentAmount,
                     },
                     bathrooms: unit.bathrooms,
                     bedrooms: unit.bedrooms,
                     sqft: parseInt(unit.sqft),
                     arePetsAllowed: unit.arePetsAllowed,
                     escrowBankChartOfAccountId: chartOfAccountId,
                     operatingBankChartOfAccountId: chartOfAccountId,
                  },
               },
            }))
         refreshPOEToken()
         await getUnitsAndTenants()
      } catch (error) {}
   }
   const updateUnit = async (unit: UnitFormFields) => {
      const { address } = unit
      const currentUintData = units?.find((u) => u.id === unit.id)
      if (_.isEqual(currentUintData, unit) || !currentUintData) {
         return // exit the function if no changes are made
      }
      const selectedOwner = await getSelectedOwner(unit)
      if (!selectedOwner) {
         return // exit the function if error occurs
      }
      // Check for bank account and add if not present (if present, add bank account)
      let chartOfAccountId = await getChartOfAccountId(selectedOwner)
      if (!chartOfAccountId) {
         return // exit the function if error occurs
      }
      runUpdateUnit({
         context: {
            poeToken: poeToken.current,
         },
         variables: {
            unit: {
               id: unit.id as string,
               ...(currentUintData?.typeKey !== unit.typeKey && { typeKey: unit.typeKey }),
               ...(currentUintData?.owner.id !== selectedOwner.id && { ownerId: selectedOwner.id }),

               ...(currentUintData?.desiredRentAmount !== unit.desiredRentAmount && {
                  desiredRentAmount: { value: unit.desiredRentAmount },
               }),

               ...(currentUintData?.bathrooms !== unit.bathrooms && {
                  bathrooms: unit.bathrooms,
               }),
               ...(currentUintData?.bedrooms !== unit.bedrooms && {
                  bedrooms: unit.bedrooms,
               }),
               ...(currentUintData?.sqft !== parseInt(unit.sqft) && {
                  sqft: parseInt(unit.sqft),
               }),
               ...(currentUintData?.arePetsAllowed !== unit.arePetsAllowed && {
                  arePetsAllowed: unit.arePetsAllowed,
               }),
               ...(currentUintData?.escrowBankChartOfAccountId !== chartOfAccountId && {
                  escrowBankChartOfAccountId: chartOfAccountId,
                  operatingBankChartOfAccountId: chartOfAccountId,
               }),
            },
            address: {
               id: address.id as string,
               subjectId: unit.id as string,
               ...(currentUintData?.address.countryKey !== address.countryKey && {
                  countryKey: address.countryKey,
               }),
               ...(currentUintData?.address.state !== address.state && { state: address.state }),
               ...(currentUintData?.address.city !== address.city && { city: address.city }),
               ...(currentUintData?.address.street !== address.street && {
                  street: address.street,
               }),
               ...(currentUintData?.address.aptSuiteEtc !== address.aptSuiteEtc && {
                  aptSuiteEtc: address.aptSuiteEtc,
               }),
               ...(currentUintData?.address.postalCode !== address.postalCode && {
                  postalCode: address.postalCode,
               }),
            },
         },
      })
         .then(() => {
            refreshPOEToken()
            getUnitsAndTenants()
         })
         .catch(bypassMockError)
   }

   return {
      units,
      availableOwners,
      getUnitsAndTenants,
      getAvailableOwners,
      getBankAccounts,
      addTenant,
      updateTenant,
      addUnit,
      updateUnit,

      loading: {
         getUnitsAndTenantsLoading,
         getAvailableOwnersLoading,
         getBankAccountsLoading,
         addOwnerLoading,
         addTenantLoading,
         updateTenantLoading,
         addBankAccountLoading,
         addUnitLoading,
         updateUnitLoading,
      },

      error: {
         getUnitsAndTenantsError,
         getAvailableOwnersError,
         getBankAccountsError,
         addOwnerError,
         addTenantError,
         updateTenantError,
         addUnitError,
         addBankAccountError,
         updateUnitError,
      },
   }
}

export default useUnitAndTenant
