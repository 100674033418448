import { Button } from "@mui/material"
import { useNavigate } from "react-router-dom"
import ROUTE from "routes/ROUTE"

interface Props {
   className?: string
}

const RightSection: React.FC<Props> = ({ className }) => {
   const navigate = useNavigate()
   const gotoAddNewRole = () => navigate(ROUTE.START)
   return (
      <div className={className}>
         <div className="right-section-group">
            <p>
               These roles listed are currently associated with your account. User accounts can have
               as many roles as needed. When sent an invite, or creating a new account, you can
               choose to associate a role with your existing user.
            </p>
            <p>
               After signing into your account, all of your roles can be accessed from your user
               menu at the top right, allowing you to jump from one role to another. This is very
               helpful if you’re managing multiple RentPost accounts, have multiple rental
               properties, etc.
            </p>
         </div>

         <div className="right-section-group">
            <p>
               You can add a new role to your account at any time. This is helpful if you’re
               managing multiple RentPost accounts, have multiple rental properties, etc.
            </p>
            <Button variant="outlined" color="secondary" onClick={gotoAddNewRole}>
               Add New Role
            </Button>
         </div>
      </div>
   )
}

export default RightSection
