import { useSearchParams } from "react-router-dom"
import useVerifyEmail from "./useVerifyEmail"
import { useState } from "react"
import useSlowEffect from "hooks/useSlowEffect"

const useVerifyFromLink = () => {
   const [searchParams, setSearchParams] = useSearchParams()
   const code = searchParams.get("code")
   const [showVerificationPage, setShowVerificationPage] = useState<boolean>(false)
   const { verifyEmailFromLink, verifyFromLinkLoading } = useVerifyEmail()

   useSlowEffect(() => {
      if (code) {
         verifyEmailFromLink(code)
      } else {
         setShowVerificationPage(true)
      }
   })

   return {
      loading: verifyFromLinkLoading,
      showVerificationPage,
   }
}

export default useVerifyFromLink
