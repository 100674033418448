import { sentryInit } from "configs/sentry"
import ReactDOM from "react-dom/client"
import App from "./App"
import "./index.css"

// ---------------------------------
// Initialize SENTRY to track errors
// ---------------------------------
sentryInit()

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(<App />)
