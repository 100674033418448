import pic01 from './pic01';
import pic02 from './pic02';
import pic03 from './pic03';
import pic04 from './pic04';
import pic05 from './pic05';
import pic06 from './pic06';
import pic07 from './pic07';
import pic08 from './pic08';
import pic09 from './pic09';
import pic10 from './pic10';
import pic11 from './pic11';
import pic12 from './pic12';
import pic13 from './pic13';
import pic14 from './pic14';
import pic15 from './pic15';
import pic16 from './pic16';
import pic17 from './pic17';
import pic18 from './pic18';
import pic19 from './pic19';
import pic20 from './pic20';
import pic21 from './pic21';
import pic22 from './pic22';
import pic23 from './pic23';
import pic24 from './pic24';
import pic25 from './pic25';
import pic26 from './pic26';
import pic27 from './pic27';
import pic28 from './pic28';
import pic29 from './pic29';
import pic30 from './pic30';
import pic31 from './pic31';
import pic32 from './pic32';
import pic33 from './pic33';
import pic34 from './pic34';
import pic35 from './pic35';
import pic36 from './pic36';
import pic37 from './pic37';

export const optimizedImages = {pic01,pic02,pic03,pic04,pic05,pic06,pic07,pic08,pic09,pic10,pic11,pic12,pic13,pic14,pic15,pic16,pic17,pic18,pic19,pic20,pic21,pic22,pic23,pic24,pic25,pic26,pic27,pic28,pic29,pic30,pic31,pic32,pic33,pic34,pic35,pic36,pic37};