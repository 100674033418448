import { MultiSectionsLayout } from "layouts"
import Illustration from "./Illustration"
import { AnimatePresence } from "framer-motion"
import { AnimateWithVariants } from "rentpost/components/functional"
import { useEffect, useState } from "react"
import MergeLoginForm from "./MergeLoginForm"
import useMerge, { AccountsInfoProps } from "./useMerge"
import MergeComponent from "./MergeComponent"
import hideLoadingPage from "utils/hideLoadingPage"

const Merge = () => {
   return (
      <MultiSectionsLayout
         mainSection={<MainSection />}
         htmlTitle="Merge | RentPost"
         illustrationSection={<Illustration />}
      />
   )
}

const MainSection = () => {
   const [showMergeLoginForm, setShowMergeLoginForm] = useState<boolean>(true)
   const {
      login,
      mergeUser,
      loading: { getAvailableRolesLoading, getUserLoading, loginLoading, mergeUserLoading },
      isUsingSameUsername,
      isMergeSuccessful,
      accountsInfo,
      error: { loginError },
      mergingDirection,
      triggerMergingDirection,
   } = useMerge()

   // --------
   // Handlers
   // --------
   useEffect(() => {
      hideLoadingPage()
   }, [])

   const mergeLoginProps = {
      onSubmit: login,
      loading: loginLoading || getAvailableRolesLoading || getUserLoading,
      isUsingSameUsername,
      error: loginError,
   }

   const mergeComponentProps = {
      mergingDirection,
      mergeUser,
      triggerMergingDirection,
      accountsInfo: accountsInfo as AccountsInfoProps,
      loading: mergeUserLoading,
      isMergeSuccessful,
   }
   // --------------------
   // Rendering Conditions
   // --------------------
   useEffect(() => {
      if (getAvailableRolesLoading || getUserLoading) {
         return
      }
      if (isUsingSameUsername) {
         setShowMergeLoginForm(true)
      }

      if (accountsInfo?.primaryUser.roles && accountsInfo?.secondaryUser.roles) {
         setShowMergeLoginForm(false)
      }
   }, [accountsInfo])
   // ===
   // JSX
   // ===
   return (
      <>
         <AnimatePresence mode="wait">
            {showMergeLoginForm && (
               <AnimateWithVariants
                  animate="fadeIn"
                  initial="fadeOut"
                  exit="fadeOut"
                  className="content-holder"
                  key={"login-form"}
               >
                  <h1>Sign into the account you want to merge</h1>
                  <p className="main-description">
                     Use the login credentials of the <strong>other account</strong> that you want
                     to merge, with the account you're currently signed in as.
                  </p>
                  <MergeLoginForm {...mergeLoginProps} />
               </AnimateWithVariants>
            )}
            {!showMergeLoginForm && (
               <AnimateWithVariants
                  animate="fadeIn"
                  initial="fadeOut"
                  className="content-holder"
                  key={"role-select"}
                  style={{
                     display: "flex",
                     flexDirection: "column",
                     width: "100%",
                  }}
               >
                  <MergeComponent {...mergeComponentProps} />
               </AnimateWithVariants>
            )}
         </AnimatePresence>
      </>
   )
}

export default Merge
