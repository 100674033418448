import { FallbackProps } from "react-error-boundary"
import { useNavigate } from "react-router-dom"
import auth from "utils/auth"
import PageErrorNotFoundElement from "components/webComponents/PageErrorNotFoundElement"
import ROUTE from "routes/ROUTE"
import { sentryTag } from "configs/sentry"
import { useEffect } from "react"

const PageNotFoundErrorLayout: React.FC<FallbackProps> = ({ resetErrorBoundary }) => {
   // get the current year
   const navigate = useNavigate()

   // navigate to the home page
   const goHome = () => {
      const hasAccessToken = auth.getAccessToken()!!
      hasAccessToken ? navigate(ROUTE.PROFILE.OVERVIEW) : navigate(ROUTE.LOGIN)
      resetErrorBoundary()
   }
   useEffect(() => {
      // Set the tag to show that the PageNotFoundErrorLayout has been shown
      sentryTag("error-layout-shown", "Page Not Found Error Layout")
   }, [])
   return <PageErrorNotFoundElement onGoHome={goHome} goBackLabel="Go Home" />
}

export default PageNotFoundErrorLayout
