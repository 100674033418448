import { useMutation, useLazyQuery } from "@apollo/client"
import { gql } from "@apollo/client"
import bypassMockError from "utils/bypassMockError"
import usePOEToken from "hooks/usePOEToken"
import {
   DisableTwoFactorAuth,
   EnableTwoFactorAuth,
   GetUserInformation as GetUser,
   VerifyTwoFactorAuth,
   VerifyTwoFactorAuthVariables,
   ResendTwoFactorAuthCode,
   UpdateSettings,
   UpdateSettingsVariables,
} from "./__generated__"
import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"
import { RootState } from "redux/store"
import { RemoveUser, RemoveUserVariables } from "pages/Profile/__generated__"
import { RemoveUserMutation } from "pages/Profile/useProfile"
import auth from "utils/auth"
import secondaryAuthToken from "utils/secondaryAuth"
import useSettings from "hooks/useSettings"
import ROUTE from "routes/ROUTE"

export enum TwoFactorMethod {
   Email = "Email",
   SMS = "SMS",
   Whatsapp = "Whatsapp",
}

// ========
// MUTATION
// ========
const VerifyTwoFactorAuthMutation = gql`
   mutation VerifyTwoFactorAuth($code: String!) {
      verifyTwoFactorAuthCode(code: $code)
   }
`
const EnableTwoFactorAuthMutation = gql`
   mutation EnableTwoFactorAuth($method: TwoFactorMethod!) {
      enableTwoFactorAuth(method: $method)
   }
`
const DisableTwoFactorAuthMutation = gql`
   mutation DisableTwoFactorAuth {
      disableTwoFactorAuth
   }
`
const ResendTwoFactorAuthCodeMutation = gql`
   mutation ResendTwoFactorAuthCode($method: TwoFactorMethod) {
      requestTwoFactorAuthCode(method: $method)
   }
`
const UpdateSettingsMutation = gql`
   mutation UpdateSettings($setting: SetSettingInput!) {
      setSetting(input: $setting) {
         name
         value
      }
   }
`

// =======
// QUERIES
// =======
const GetUserQuery = gql`
   query GetUserInformation {
      user {
         phone
         email
      }
   }
`
export {
   VerifyTwoFactorAuthMutation,
   EnableTwoFactorAuthMutation,
   DisableTwoFactorAuthMutation,
   ResendTwoFactorAuthCodeMutation,
   GetUserQuery,
}

// ========================
// USE TWO FACTOR AUTH HOOK
// ========================
const useTwoFactorAuth = () => {
   // ------
   // States
   // ------
   const [isVerified, setIsVerified] = useState<boolean>(false)
   const [attemptCount, setAttemptCount] = useState<number>(0)
   const [userData, setUserData] = useState<{ phoneNumber: any; email: string } | null>(null)
   const is2FaSetupPage = useLocation().pathname === ROUTE.VERIFY.ROOT + ROUTE.VERIFY.SETUP_2FA
   const [activeModal, setActiveModal] = useState<
      "verifyTwoFactorAuth" | "disableTwoFactorAuth" | "updatePhoneNumber" | false
   >(false)

   // get the two factor auth data from the redux store
   const graphql_2FA_RequiredOperation = useSelector(
      (state: RootState) => state.graphql_2FA_RequiredOperation
   )
   const { getSettings, settings } = useSettings()
   const [twoFactorAuthMethod, setTwoFactorAuthMethod] = useState<keyof typeof TwoFactorMethod>(
      settings.twoFactorAuthMethod
   )

   useEffect(() => {
      setTwoFactorAuthMethod(settings.twoFactorAuthMethod)
   }, [settings.twoFactorAuthMethod])

   // ------------
   // Helper Hooks
   // ------------
   const { poeToken, refreshPOEToken } = usePOEToken()
   const navigate = useNavigate()

   // Close modal
   const closeModal = () => setActiveModal(false)

   // -----------------
   // APOLLO OPERATIONS
   // -----------------

   // verify 2fa mutation
   const [
      runVerifyTwoFactorAuth,
      {
         loading: verifyTwoFactorAuthLoading,
         error: verifyTwoFactorAuthError,
         data: verifyTwoFactorAuthData,
      },
   ] = useMutation<VerifyTwoFactorAuth, VerifyTwoFactorAuthVariables>(VerifyTwoFactorAuthMutation)

   // enable 2fa mutation
   const [
      runEnableTwoFactorAuth,
      { loading: enableTwoFactorAuthLoading, error: enableTwoFactorAuthError },
   ] = useMutation<EnableTwoFactorAuth>(EnableTwoFactorAuthMutation)

   // disable 2fa mutation
   const [
      runDisableTwoFactorAuth,
      { loading: disableTwoFactorAuthLoading, error: disableTwoFactorAuthError },
   ] = useMutation<DisableTwoFactorAuth>(DisableTwoFactorAuthMutation)

   // resend 2fa code mutation
   const [
      runResendTwoFactorAuthCode,
      { loading: resendTwoFactorAuthCodeLoading, error: resendTwoFactorAuthCodeError },
   ] = useMutation<ResendTwoFactorAuthCode>(ResendTwoFactorAuthCodeMutation)

   // update 2fa method mutation
   const [
      runUpdateTwoFactorAuthMethod,
      { loading: updateTwoFactorAuthMethodLoading, error: updateTwoFactorAuthMethodError },
   ] = useMutation<UpdateSettings, UpdateSettingsVariables>(UpdateSettingsMutation)

   // get user phone number query
   const [runGetUser, { loading: getUserInformationLoading, error: getUserInformationError }] =
      useLazyQuery<GetUser>(GetUserQuery)

   // -------------------------------------
   // 🚧🚧🚧 2FA REQUIRED OPERATIONS 🚧🚧🚧
   // -------------------------------------
   const [
      runRemoveUser,
      { data: removeUserData, loading: removeUserLoading, error: removeUserError },
   ] = useMutation<RemoveUser, RemoveUserVariables>(RemoveUserMutation)

   // ------------
   // 2FA HANDLERS
   // ------------

   // verify 2fa
   const verifyTwoFactorAuth = (code: string) => {
      runVerifyTwoFactorAuth({
         context: {
            poeToken: poeToken.current,
            skipQueue: true,
            // if there is a secondaryAuthToken, use it, otherwise use the access token
            authorization: secondaryAuthToken.getToken() || auth.getAccessToken(),
         },
         variables: {
            code,
         },
      })
         .then(async ({ data }) => {
            await getSettings()
            refreshPOEToken()
            if (data?.verifyTwoFactorAuthCode) {
               // if operationName is undefined, it means that the user is trying to verify 2fa normally
               if (!graphql_2FA_RequiredOperation.operationName) {
                  setIsVerified(true)
                  setActiveModal(false) // close modal after verification is successful
               }

               // when there is a 2fa-required-operation
               if (graphql_2FA_RequiredOperation.operationName) {
                  setIsVerified(true)
                  graphql_2FA_RequiredOperation.operationName === "RemoveUser" && removeUser()
               }

               // Direct to previous page after Enabling 2FA (ONLY IF ON 2FA SETUP PAGE)
               if (is2FaSetupPage) {
                  navigate(-1)
               }
            }
         })
         .catch(bypassMockError)
   }

   // enable 2fa
   const enableTwoFactorAuth = () => {
      return runEnableTwoFactorAuth({
         context: {
            poeToken: poeToken.current,
         },
         variables: {
            method: twoFactorAuthMethod,
         },
      })
         .then(refreshPOEToken)
         .catch(bypassMockError)
   }

   // disable 2fa
   const disableTwoFactorAuth = () => {
      return runDisableTwoFactorAuth({
         context: {
            poeToken: poeToken.current,
            skipQueue: true,
         },
      })
         .then(async ({ data }) => {
            refreshPOEToken()
            await getSettings()
            if (data?.disableTwoFactorAuth) {
               // Direct to previous page after Disabling 2FA (ONLY IF ON 2FA SETUP PAGE)
               is2FaSetupPage && navigate(-1)
            }
         })
         .catch(bypassMockError)
         .finally(() => {
            getSettings()
            closeModal() // close modal
         })
   }

   // resend 2fa code
   const resendTwoFactorAuthCode = () => {
      runResendTwoFactorAuthCode({
         context: {
            poeToken: poeToken.current,
            skipQueue: true,
            // if there is a secondaryAuthToken, use it, otherwise use the access token
            authorization: secondaryAuthToken.getToken() || auth.getAccessToken(),
         },
         variables: {
            method: twoFactorAuthMethod,
         },
      })
         .then(({ data }) => {
            refreshPOEToken()
            data && setAttemptCount(attemptCount + 1)
         })
         .catch(bypassMockError)
   }

   // get user phone number
   const getUserInformation = () => {
      runGetUser()
         .then(({ data }) => {
            if (!data?.user) return
            const { phone, email } = data?.user
            setUserData({ phoneNumber: phone, email })
         })
         .catch(bypassMockError)
   }

   // Update Method
   const updateTwoFactorAuthMethod = () => {
      const value =
         twoFactorAuthMethod === "SMS"
            ? "sms"
            : twoFactorAuthMethod === "Whatsapp"
            ? "whatsapp"
            : "email"

      runUpdateTwoFactorAuthMethod({
         context: {
            poeToken: poeToken.current,
         },
         variables: {
            setting: {
               subjectId: auth.getUserID() as string,
               name: "2faMethod",
               value,
            },
         },
      })
         .then(() => {
            refreshPOEToken()
            getSettings()
            is2FaSetupPage && navigate(-1)
         })
         .catch(bypassMockError)
   }

   // -------------------------------------
   // 🚧🚧🚧 2FA REQUIRED OPERATIONS 🚧🚧🚧
   // -------------------------------------
   const removeUser = async () => {
      runRemoveUser({
         context: {
            poeToken: poeToken.current,
         },
         // @ts-ignore - typescript is not happy with the schema
         variables: {
            reason: graphql_2FA_RequiredOperation.variables?.reason,
         },
      })
         .then(() => {
            refreshPOEToken()
            closeModal()
            navigate(ROUTE.LOGOUT + "?reason=Your account has been deleted.")
         })
         .catch(bypassMockError)
   }

   const twoFactorAuthRequiredOperationsLoading = removeUserLoading
   const twoFactorAuthRequiredOperationsStatus = removeUserData?.removeUser

   const verifyOperationStatus = graphql_2FA_RequiredOperation.operationName
      ? verifyTwoFactorAuthData?.verifyTwoFactorAuthCode && twoFactorAuthRequiredOperationsStatus
      : verifyTwoFactorAuthData?.verifyTwoFactorAuthCode

   // -------------
   // RETURN VALUES
   // -------------
   return {
      // Handlers
      verifyTwoFactorAuth,
      enableTwoFactorAuth,
      disableTwoFactorAuth,
      resendTwoFactorAuthCode,
      setActiveModal,
      setTwoFactorAuthMethod,
      getUserInformation,
      updateTwoFactorAuthMethod,

      // Data
      verifyOperationStatus,
      isVerified,
      attemptCount,
      activeModal,
      twoFactorAuthMethod,
      settings,
      userData,

      // Loading
      loading: {
         verifyTwoFactorAuthLoading:
            verifyTwoFactorAuthLoading || twoFactorAuthRequiredOperationsLoading,
         enableTwoFactorAuthLoading,
         disableTwoFactorAuthLoading,
         resendTwoFactorAuthCodeLoading,
         getUserInformationLoading,
         updateTwoFactorAuthMethodLoading,
      },

      // Errors
      error: {
         verifyTwoFactorAuthError,
         enableTwoFactorAuthError,
         disableTwoFactorAuthError,
         resendTwoFactorAuthCodeError,
         getUserInformationError,
         updateTwoFactorAuthMethodError,
      },
   }
}

type UseTwoFactorAuthProps = ReturnType<typeof useTwoFactorAuth>
export type { UseTwoFactorAuthProps }
export default useTwoFactorAuth
