import { Form, TextField } from "rentpost/components/form"
import { AddCustomAPIKeyFormFields, Environment } from "../../useIntegrations"
import { MiniRoleCard } from "rentpost/components/other"
import { FormProps } from "rentpost/components/form/Form"
import { AddCustomApiKeyComponentProps } from "."
import { Button, CircularProgress } from "@mui/material"
import SelectField from "rentpost/components/form/SelectField"
import * as yup from "yup"

// @ts-ignore - typescript complains about this import
import env from "configs/env"

const AddAPIKeyForm: React.FC<AddCustomApiKeyComponentProps> = (props) => {
   // ----------
   // Form Props
   // ----------
   const initialValues: AddCustomAPIKeyFormFields = {
      environment:
         env.TARGET_ENV === "development"
            ? Environment.dev
            : Environment.prod
            ? Environment.prod
            : Environment.stage,
      integrationId: props.integrationId,
      description: "",
      roleId: "",
   }

   const validationSchema = yup.object().shape({
      environment: yup.string().required("Environment is required"),
      description: yup.string().required("Description is required"),
      roleId: yup.string().required("Role is required"),
   })

   const formProps: FormProps = {
      initialValues,
      onSubmit: (value: AddCustomAPIKeyFormFields) => {
         props.onSubmit(value)
      },
      validationSchema,
      error: props.error,
      loading: props.loading,
      showErrorHint: true,
   }

   const environmentOptions = [
      ...(env.TARGET_ENV === "production"
         ? [{ value: Environment.prod, label: "Production" }]
         : []),
      ...(env.TARGET_ENV === "development"
         ? [{ value: Environment.dev, label: "Development" }]
         : []),
      ...(env.TARGET_ENV === "stage" ? [{ value: Environment.stage, label: "Stage" }] : []),
      // { value: Environment.sandbox, label: "Sandbox" },  -- this is not implemented yet.
   ]

   return (
      <Form {...formProps}>
         {({ isValid, dirty }) => (
            <>
               <MiniRoleCard roles={props.availableRoles} name="roleId" label="Role" />
               <div />
               <SelectField
                  options={environmentOptions}
                  name="environment"
                  placeholder="eg. Production"
                  label="Environment"
               />
               <TextField
                  name="description"
                  placeholder="eg. Send email notifications to tenants"
                  label="Description for this Key"
               />
               <div className="modal-action-buttons">
                  <Button
                     type="button"
                     variant="outlined"
                     color="secondary"
                     onClick={props.onClose}
                  >
                     Cancel
                  </Button>
                  <Button
                     type="submit"
                     variant="contained"
                     color="primary"
                     disabled={props.loading || !dirty || !isValid}
                     endIcon={props.loading ? <CircularProgress size={"100%"} /> : null}
                  >
                     Create Key
                  </Button>
               </div>
            </>
         )}
      </Form>
   )
}

export default AddAPIKeyForm
