import SVG from "assets/svg"
import { IntegrationIconKeys, IntegrationTypeEnum } from "../useIntegrations"
import styled from "@emotion/styled"
import { Avatar, Card, RoleCardProps } from "rentpost/components/other"
import { color, size } from "rentpost/styles"

interface Props {
   name: string
   description: string
   onClick: (integrationId: string) => void
   numberOfActiveKeys: number
   integrationType: IntegrationTypeEnum
   associatedRoles: RoleCardProps["role"][]
}

const IntegrationCard: React.FC<Props> = (props) => {
   const { name, description, integrationType, onClick, numberOfActiveKeys } = props
   const isPredefined = integrationType === IntegrationTypeEnum.zapier
   const iconName = name.split(" ")[0].toLowerCase() as IntegrationIconKeys
   const IconComponent = isPredefined ? SVG.integrations[iconName] : undefined
   const isZapier = integrationType === IntegrationTypeEnum.zapier

   const roleInfoForAvatar = props.associatedRoles?.map(({ displayName, avatarImage }) => ({
      displayName,
      avatarImage,
   }))

   return (
      <Card
         content={
            <Holder>
               <p className="description">{description}</p>
               {isZapier && (
                  <div className="active-keys-holder">
                     <Avatar roles={roleInfoForAvatar} isAvatarsGroup />
                     <p>{roleInfoForAvatar.length} connected roles</p>
                  </div>
               )}
               {!isZapier && (
                  <div className="active-keys-holder">
                     <svg viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                           fillRule="evenodd"
                           clipRule="evenodd"
                           d="M9.98069 20.4472C15.4929 20.4472 19.9614 15.9786 19.9614 10.4665C19.9614 4.95428 15.4929 0.485771 9.98069 0.485771C4.46851 0.485771 0 4.95428 0 10.4665C0 15.9786 4.46851 20.4472 9.98069 20.4472ZM8.09245 14.5861V10.3273C7.80703 10.0973 7.74878 10.0281 7.63763 9.89599C7.61369 9.86754 7.5873 9.83617 7.55565 9.79964L5.39497 12.9875L5.77747 15.0522L8.09245 14.5861ZM9.17144 15.2858V10.9617C9.67857 11.1667 10.2256 11.2757 10.7894 11.2757C11.3537 11.2757 11.9008 11.1667 12.4084 10.9612V12.8942L11.8409 13.492L12.4084 14.0897L11.8409 14.6875L12.4084 15.2858L10.6453 16.6707L9.17144 15.2858ZM8.93137 4.31944C10.1522 3.45678 11.9288 3.52098 13.0785 4.67065C13.7108 5.30294 14.0269 6.13107 14.0269 6.95919C14.0269 7.78786 13.7102 8.61653 13.079 9.24882C11.815 10.5129 9.76543 10.5134 8.50193 9.24882C8.03257 8.77946 7.73962 8.20219 7.61823 7.59688C8.92274 7.66216 10.279 7.1259 10.7441 6.04367C11.4918 6.08737 11.8883 5.1972 11.3623 4.67119C11.0241 4.33292 10.4225 4.32753 10.1166 4.81955C10.702 5.93901 9.343 6.95973 7.8227 6.95973C6.75773 6.95973 5.39497 6.38571 5.39497 5.34124C5.39497 4.4473 6.48205 3.72275 7.8227 3.72275C8.07195 3.72275 8.30717 3.75512 8.53376 3.80152C8.12914 4.09177 7.99426 4.25254 7.76767 4.53523C6.81276 4.55358 6.20421 5.01754 6.20421 5.34124C6.20421 5.65361 6.77392 6.09492 7.67164 6.14186C7.87395 5.36606 8.298 4.76236 8.93137 4.31944Z"
                        />
                     </svg>
                     <p>{numberOfActiveKeys} active API Keys</p>
                  </div>
               )}
            </Holder>
         }
         label={name}
         linkIcon="arrow"
         linkLabel="Manage Integration"
         icon={IconComponent && <IconComponent />}
         onClick={() => onClick(name)}
      />
   )
}
const Holder = styled.div`
   display: flex;
   flex-direction: column;

   .description {
      font-size: ${size.m};
      color: ${color.grayLight};
      margin-top: -0.7em;
   }

   .active-keys-holder {
      display: flex;
      align-content: center;
      gap: 0.7em;
      color: ${color.grayDark};

      p {
         font-size: 1.05em;
         line-height: 2.4em;
         margin: 0;
         padding: 0;
      }

      svg {
         width: 2.1em;
         fill: ${color.green};
      }
   }
`
export type { Props as IntegrationCardProps }
export default IntegrationCard
