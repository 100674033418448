import { useEffect } from "react"

// This hook is used to delay the execution of a callback function, so ping requests returns 'X-RP-2fa-Required: true' header, the queuer can take action and show the 2FA modal.

const useSlowEffect = (callback: () => void, delay?: number) => {
   useEffect(() => {
      const id = setTimeout(callback, delay ?? 200)
      return () => clearTimeout(id)
   }, [])
}

export default useSlowEffect
