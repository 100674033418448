import styled from "@emotion/styled"
import useProfile from "../useProfile"
import { useState } from "react"
import useSecurity, { AuthorizedDevice } from "./UseSecurity"
import DeviceItem from "./DeviceItem"
import SecurityCard from "../Overview/SecurityCard"
import SessionsTable from "./SessionsList"
import { AnimatePresence, motion } from "framer-motion"
import { useOutletContext } from "react-router-dom"

interface DeviceListProps {
   devices: AuthorizedDevice[]
   onDeviceSelect: (deviceId: string) => void
   revokingDeviceId: string | null
   revokingDeviceLoading?: boolean
}

const SecurityPage = () => {
   const { authorizedDevices, loading, revokeAuthToken, revokeDeviceTokens } = useSecurity()
   const { twoFactorAuthEnabled: isTwoFactorAuthEnabled } = useOutletContext() as ReturnType<
      typeof useProfile
   >

   // ================
   // Stats & Handlers
   // ================
   const [selectedDeviceId, setSelectedDeviceId] = useState<string | null>(null)
   const [revokingDeviceId, setRevokingDeviceId] = useState<string | null>(null)

   // ===============
   // Component Props
   // ===============
   const deviceListProps = {
      devices: authorizedDevices || [],
      onDeviceSelect: setSelectedDeviceId,
      loading: loading.getAuthorizedDevices,
      revokingDeviceId,
      revokingDeviceLoading: loading.revokeDeviceTokens || loading.getAuthorizedDevices,
   }

   const sessionsTableProps: React.ComponentProps<typeof SessionsTable> = {
      selectedDevice: authorizedDevices?.find(
         (device) => device.id === selectedDeviceId
      ) as AuthorizedDevice,
      onRevokeSession: revokeAuthToken,
      onRevokeAllDeviceSessions: (deviceId) => {
         setRevokingDeviceId(deviceId)
         revokeDeviceTokens(deviceId)
      },
      onGoBack: () => setSelectedDeviceId(null),
      revokeSessionLoading: loading.revokeAuthToken || loading.getAuthorizedDevices,
      revokeDeviceLoading: false,
   }

   // ===
   // JSX
   // ===
   return (
      <Holder>
         <h1>Authorized Devices</h1>
         <p className="main-description">
            You’re signed in on these devices or have been in the last 28 days. There might be
            multiple activity sessions from the same device.
         </p>
         <div className="left-section">
            {authorizedDevices && (
               <AnimatePresence mode="popLayout">
                  {!selectedDeviceId && (
                     <motion.div
                        animate={{ opacity: 1 }}
                        initial={{ opacity: 0 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.4 }}
                        key="devices-list"
                     >
                        <DevicesList {...deviceListProps} />
                     </motion.div>
                  )}
                  {selectedDeviceId && (
                     <motion.div
                        animate={{ x: 0 }}
                        initial={{ x: "-100%" }}
                        exit={{ x: "-100%" }}
                        transition={{
                           duration: 0.4,
                           type: "spring",
                        }}
                        key="sessions-table"
                     >
                        <SessionsTable {...sessionsTableProps} />
                     </motion.div>
                  )}
               </AnimatePresence>
            )}
         </div>
         <div className="right-section">
            <SecurityCard is2FAEnabled={isTwoFactorAuthEnabled as boolean} />
         </div>
      </Holder>
   )
}

// ============
// Devices List
// ============
const DevicesList: React.FC<DeviceListProps> = (props) => {
   const handleClick = (deviceId: string) => {
      props.onDeviceSelect(deviceId)
   }

   return (
      <div className="devices-list">
         {props.devices?.map((device) => (
            <DeviceItem
               key={device.id}
               device={device}
               onClick={handleClick}
               loading={!!(props.revokingDeviceLoading && props.revokingDeviceId === device.id)}
            />
         ))}
      </div>
   )
}

const Holder = styled.div`
   display: grid;
   grid-template-columns: 1fr 24em;
   gap: 0em 2em;

   h1,
   p.main-description {
      grid-column: 1 / 3;
   }
   .main-description {
      margin-bottom: 2em;
   }

   .left-section {
      position: relative;
      overflow: hidden;
      width: 100%;
      box-sizing: border-box;
      padding-right: 1px;
      .devices-list {
         position: relative;
         display: flex;
         flex-direction: column;
         gap: 2em;
      }
   }

   /* Responsiveness */

   @media (max-width: 900px) {
      display: flex;
      flex-direction: column;
      gap: 2em;

      .main-description {
         margin-bottom: 0em;
      }
   }
`

export default SecurityPage
