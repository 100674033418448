import { RoleCardProps, RoleType } from "rentpost/components/other/RoleCard"
import RoleCard from "rentpost/components/other/RoleCard"
import styled from "@emotion/styled"
import { Fragment, useEffect, useState } from "react"
import { AnimatePresence } from "framer-motion"
import { variants } from "rentpost/styles/animation"
import { AnimateWithVariants } from "rentpost/components/functional"
import { screen } from "rentpost/styles"

interface Props {
   multiRoles: RoleCardProps[]
   handleChooseRole: (roleID: string, roleType: RoleType, isOnboardingRequired: boolean) => void
   resetSelectedRole?: boolean
}

const MultiRoleSelect: React.FC<Props> = (props) => {
   const { multiRoles, handleChooseRole } = props
   const [selectedRoleIndex, setSelectedRoleIndex] = useState<number | null>(null)

   const handleSelectRole = (roleId: string, roleType: RoleType, isOnboardingRequired: boolean) => {
      handleChooseRole(roleId, roleType, isOnboardingRequired)
      setSelectedRoleIndex(multiRoles.findIndex(({ role }) => role.id === roleId))
   }

   useEffect(() => {
      props.resetSelectedRole && setSelectedRoleIndex(null)
   }, [props.resetSelectedRole])

   return (
      <Holder>
         <AnimatePresence>
            {multiRoles.map(({ role, ...rest }, index) => {
               const animate: keyof typeof variants =
                  selectedRoleIndex === null || index === selectedRoleIndex
                     ? "fadeIn"
                     : "collapseInHeigh"

               const loading = selectedRoleIndex === null ? false : index === selectedRoleIndex
               return (
                  <Fragment key={role.id}>
                     <AnimateWithVariants
                        animate={animate}
                        initial="fadeOut"
                        style={{ overflow: "hidden", marginTop: "0.8em" }}
                     >
                        <RoleCard
                           key={role.id}
                           role={role}
                           onGoToPortal={handleSelectRole}
                           loading={loading}
                           {...rest}
                        />
                     </AnimateWithVariants>
                  </Fragment>
               )
            })}
         </AnimatePresence>
      </Holder>
   )
}

const Holder = styled.div`
   margin-top: 2em;
   position: relative;
   display: flex;
   flex-direction: column;
   font-size: 1rem;
   overflow: hidden;
   width: 100%;

   /* -------------- */
   /* Responsiveness */
   /* -------------- */
   ${screen.tabletAndMobile} {
      font-size: 1em;
      margin-bottom: 2em;
   }

   ${screen.onlyMobile} {
      font-size: 0.9em;
   }
`

export default MultiRoleSelect
