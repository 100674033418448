import { useEffect, useState } from "react"
import { NavigationSteps } from "rentpost/src/components/other"
import { useNavigate } from "react-router-dom"
import ROUTE from "routes/ROUTE"
import usePersonalInfo from "./PersonalInfo/usePersonalInfo"
import useCompanyInfo from "./BusinessInfo/useCompanyInfo"
import useUnitAndTenant from "./UnitAndTenant/useUnitAndTenant"
import useCompleteOnboarding from "./UnitAndTenant/useCompleteOnboarding"

const useOnboarding = () => {
   // We will use the session storage to persist the active step and the completed steps of the onboarding process when the user refreshes the page
   const initialStepState = JSON.parse(
      sessionStorage.getItem("onboardingStepState") ||
         JSON.stringify({
            steps: [
               {
                  completed: false,
               },
               {
                  completed: false,
               },
               {
                  completed: false,
               },
            ],
            activeStep: 0,
         })
   ) as Pick<React.ComponentProps<typeof NavigationSteps>, "steps" | "activeStep">

   const navigationStepsInitialState: Pick<
      React.ComponentProps<typeof NavigationSteps>,
      "steps" | "activeStep"
   > = {
      steps: [
         {
            label: "Personal Info",
            completed: initialStepState.steps[0].completed,
            onClick: () => handleChangeActiveStep(0),
         },
         {
            label: "Business Info",
            completed: initialStepState.steps[1].completed,
            onClick: () => handleChangeActiveStep(1),
         },
         {
            label: "Add a Unit",
            completed: initialStepState.steps[2].completed,
            onClick: () => handleChangeActiveStep(2),
         },
      ],
      activeStep: initialStepState.activeStep,
   }

   // =======================
   // STATES & HOOKS
   // =======================
   const [navigationStepsState, setNavigationStepsState] = useState(navigationStepsInitialState)
   const navigate = useNavigate()

   // =======================
   // HANDLERS
   // =======================
   const handleChangeActiveStep = (activeStep: number) => {
      setNavigationStepsState((rest) => {
         return {
            ...rest,
            activeStep,
         }
      })
   }

   const handleCompleteStep = (currentStep: number) => {
      setNavigationStepsState((rest) => {
         return {
            ...rest,
            steps: rest.steps.map((stepDetails, index) => {
               if (index === currentStep) {
                  return {
                     ...stepDetails,
                     completed: true,
                  }
               }
               return stepDetails
            }),
         }
      })
   }

   const handleGoToPreviousStep = () => {
      navigationStepsState.activeStep > 0 &&
         setNavigationStepsState((prev) => {
            return {
               ...prev,
               activeStep: prev.activeStep - 1,
            }
         })
   }

   // =======================
   // HOOKS
   // =======================
   const personalInfo = usePersonalInfo({
      changeStep: handleChangeActiveStep,
      setStepCompleted: handleCompleteStep,
   })
   const companyInfo = useCompanyInfo({
      changeStep: handleChangeActiveStep,
      setStepCompleted: handleCompleteStep,
   })
   const unitAndTenant = useUnitAndTenant()
   // =======================
   // EFFECTS
   // =======================

   // Navigate to the active step URL when the active step changes
   useEffect(() => {
      let stepURL = ""
      switch (navigationStepsState.activeStep) {
         case 0:
            stepURL = ROUTE.ONBOARDING.PERSONAL_INFO
            break
         case 1:
            stepURL = ROUTE.ONBOARDING.COMPANY_INFO
            break
         case 2:
            stepURL = ROUTE.ONBOARDING.UNIT_AND_TENANT
            break
         default:
            break
      }
      const activeURL = ROUTE.ONBOARDING.ROOT + "/" + stepURL
      navigate(activeURL)
   }, [navigationStepsState.activeStep])
   return {
      navigationStepsState,
      goBack: handleGoToPreviousStep,
      personalInfo,
      companyInfo,
      unitAndTenant,
   }
}

export default useOnboarding
