import { gql } from "@apollo/client"
import { useMutation } from "@apollo/client"
import {
   SetCompanyPermission,
   SetCompanyPermissionVariables,
   SetOnboardingCompleted,
} from "./__generated__"
import usePOEToken from "hooks/usePOEToken"
// @ts-ignore - This makes typescript happy!
import env from "configs/env"
import { useErrorBoundary } from "react-error-boundary"
import { MissingDataError } from "configs/error/customErrors"

interface Props {
   companyLegalEntityId: string | undefined
   unitOwnerLegalEntityId: string | undefined
}

// ========
// MUTATION
// ========
const SetCompanyPermissionMutation = gql`
   mutation SetCompanyPermission($input: PermissionInput!) {
      setCompanyPermission(input: $input) {
         roleType
      }
   }
`
const SetOnboardingCompletedMutation = gql`
   mutation SetOnboardingCompleted {
      setOnboardingCompleted {
         id
      }
   }
`

const useCompleteOnboarding = ({ companyLegalEntityId, unitOwnerLegalEntityId }: Props) => {
   const { poeToken, refreshPOEToken } = usePOEToken()
   const [
      runSetCompanyPermission,
      { loading: setCompanyPermissionLoading, error: setCompanyPermissionError },
   ] = useMutation<SetCompanyPermission, SetCompanyPermissionVariables>(
      SetCompanyPermissionMutation
   )
   const [
      runSetOnboardingCompleted,
      { loading: setOnboardingCompletedLoading, error: setOnboardingCompletedError },
   ] = useMutation<SetOnboardingCompleted>(SetOnboardingCompletedMutation)

   const { showBoundary } = useErrorBoundary()

   const setCompanyPermission = async () => {
      const isPropertyManagementFeatureRequired = companyLegalEntityId !== unitOwnerLegalEntityId
      return runSetCompanyPermission({
         context: {
            poeToken: poeToken.current,
         },
         variables: {
            input: {
               canModify: isPropertyManagementFeatureRequired,
               canView: isPropertyManagementFeatureRequired,
               roleType: "manager",
               component: "property-management",
            },
         },
      })
   }
   const setOnboardingCompleted = async () => {
      return runSetOnboardingCompleted({
         context: {
            poeToken: poeToken.current,
         },
      })
   }
   const handleCompleteOnboarding = async () => {
      if (!companyLegalEntityId || !unitOwnerLegalEntityId) {
         showBoundary(
            new MissingDataError("companyLegalEntityId or unitOwnerLegalEntityId is missing")
         )
      }
      try {
         await setCompanyPermission()
         refreshPOEToken()
         await setOnboardingCompleted()
         // Go to manager portal after onboarding completion
         switch (env.TARGET_ENV) {
            case "development":
               window.location.href = "https://pro.rentpost.test/mypost"
               break
            case "stage":
               window.location.href = "https://pro.stage.rentpost.com/mypost"
               break
            case "production":
               window.location.href = "https://pro.rentpost.com/mypost"
               break
            default:
               window.location.href = "https://pro.rentpost.com/mypost"
               break
         }
      } catch (error) {}
   }

   return {
      handleCompleteOnboarding,
      loading: {
         setCompanyPermissionLoading,
         setOnboardingCompletedLoading,
      },
      error: {
         setCompanyPermissionError,
         setOnboardingCompletedError,
      },
   }
}

export default useCompleteOnboarding
