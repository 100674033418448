import { Modal, ModalProps, MultiSectionsLayout } from "layouts"
import LoginForm, { LoginFieldsProp, LoginFormProps } from "./LoginForm"
import styled from "@emotion/styled"
import { color, space, screen } from "styles"
import Illustration from "./Illustration"
import { Button } from "@mui/material"

import { AnimatePresence } from "framer-motion"
import { AnimateWithVariants } from "rentpost/components/functional"
import useLogin from "./useLogin"
import { useNavigate, useSearchParams } from "react-router-dom"
import auth from "utils/auth"
import { useState } from "react"
import { Hint, MultiRoleSelect } from "components/form"
import useLogout from "pages/Logout/useLogout"
import { LoadingSpinner, RoleType } from "rentpost/components/other"
import useSlowEffect from "hooks/useSlowEffect"
import NoRoles from "./NoRoles"
import ROUTE from "routes/ROUTE"
import getApolloErrorDetails from "utils/getApolloErrorDetails"

// @ts-ignore - env is not defined
import hideLoadingPage from "utils/hideLoadingPage"

const Login = () => {
   return (
      <MultiSectionsLayout
         mainSection={<MainSection />}
         htmlTitle="Login | RentPost"
         illustrationSection={<Illustration />}
      />
   )
}

const MainSection = () => {
   const [searchParams, setSearchParams] = useSearchParams()
   const reasonQueryParam = searchParams.get("reason")
   const {
      multiRoles,
      selectRole,
      login,
      getRoleDetailsLoading,
      getRoleDetailsError,
      loginLoading,
      loginError,
      hasNoRoles,
      selectRoleError,
      selectRoleLoading,
      showStartOnboardingModal,
      setShowStartOnboardingModal,
      getRoles,
   } = useLogin()
   const { logout } = useLogout()
   const handleChooseRole = (roleID: string, roleType: RoleType, isOnboardingRequired: boolean) => {
      selectRole(roleID, roleType, isOnboardingRequired)
   }
   const [tokenContainsUserID, setTokenContainsUserID] = useState<boolean>(false)

   // --------
   // Handlers
   // --------
   const checkForUserID = () => {
      const userID = auth.getUserID()
      setTokenContainsUserID(!!userID)
      if (!userID) {
         hideLoadingPage()
         return
      }
      getRoles() // Get roles for user
   }
   const handleLogout = () => {
      logout()
      setSearchParams(undefined)
   }

   // Check for user ID in token on mount
   useSlowEffect(checkForUserID)

   const navigate = useNavigate()

   const loginFormProps: LoginFormProps = {
      onSubmit: (values: LoginFieldsProp) => login(values),
      error: loginError || getRoleDetailsError,
      loading: loginLoading || getRoleDetailsLoading || selectRoleLoading,
   }

   multiRoles && multiRoles.length > 0 && hideLoadingPage()

   // --------------------
   // Rendering conditions
   // --------------------
   const authTokenIsValid = auth.getAccessToken() && !auth.isTokenExpired()
   const showLoginForm = (!multiRoles && !tokenContainsUserID && !hasNoRoles) || !authTokenIsValid
   const showRoleSelect = multiRoles && authTokenIsValid
   const showLoadingSpinner = !showLoginForm && !showRoleSelect && !hasNoRoles
   const showNoRoles = hasNoRoles
   // ===
   // JSX
   // ===
   return (
      <Holder>
         {reasonQueryParam && !auth.getAccessToken() && (
            <Hint
               text={reasonQueryParam}
               type="warning"
               className="logout-reason-message-hint"
               canHide
            />
         )}
         {showLoadingSpinner && <LoadingSpinner type="glowing" color={color.green} scale={1} />}
         <AnimatePresence mode="wait">
            {showLoginForm && (
               <AnimationHolder key={"login-form"}>
                  <h1>Sign In</h1>
                  <p className="main-description">Welcome back! Please enter your login details.</p>
                  <LoginForm {...loginFormProps} />
                  <div className="get-started">
                     <p>Don't have an account? </p>
                     <Button
                        variant="outlined"
                        style={{ borderRadius: "50em" }}
                        onClick={() => navigate(ROUTE.START)}
                     >
                        Get Started
                     </Button>
                  </div>
               </AnimationHolder>
            )}
            {showRoleSelect && (
               <AnimationHolder key={"role-select"}>
                  <div className="title-and-manage">
                     <h1>Select Role</h1>
                     <Button
                        onClick={() => navigate(ROUTE.PROFILE.ROLES)}
                        className="manage-roles-button"
                        variant="outlined"
                        color="secondary"
                     >
                        Manage
                     </Button>
                  </div>
                  <p className="main-description">
                     Choose a role associated with your account that you’d like to access. You can
                     always access another via your user menu.
                  </p>
                  {!!selectRoleError && (
                     <Hint
                        type="error"
                        text={getApolloErrorDetails(selectRoleError).errorMessage}
                        canHide
                     />
                  )}
                  {multiRoles && (
                     <MultiRoleSelect
                        multiRoles={multiRoles}
                        handleChooseRole={handleChooseRole}
                        resetSelectedRole={!!selectRoleError}
                     />
                  )}
                  <Button
                     variant="outlined"
                     style={{ borderRadius: "50em" }}
                     onClick={handleLogout}
                     className="logout-button"
                  >
                     Use another account
                  </Button>
               </AnimationHolder>
            )}
            {showNoRoles && (
               <AnimationHolder key={"no-roles"}>
                  <NoRoles />
               </AnimationHolder>
            )}
         </AnimatePresence>
         <ModalStartOnboarding
            visible={showStartOnboardingModal}
            OnClose={() => setShowStartOnboardingModal(false)}
         />
      </Holder>
   )
}

const AnimationHolder = ({ children }: { children: React.ReactNode }) => {
   return (
      <AnimateWithVariants animate="fadeIn" initial="fadeOut" className="content-holder">
         {children}
      </AnimateWithVariants>
   )
}

const ModalStartOnboarding = ({ visible, OnClose }: { visible: boolean; OnClose: () => void }) => {
   const navigate = useNavigate()
   let actionSelected: "go_to_overview" | "start_onboarding" = "go_to_overview"
   const goToOverview = () => navigate(ROUTE.PROFILE.OVERVIEW)
   const startOnboarding = () =>
      navigate(ROUTE.ONBOARDING.ROOT + "/" + ROUTE.ONBOARDING.PERSONAL_INFO)

   const props: ModalProps = {
      onClose: () => {
         actionSelected === "go_to_overview" ? goToOverview() : startOnboarding()
         OnClose()
      },
      canIgnore: true,
      visible,
      title: "Welcome to RentPost",
      description: "Let's get started by setting up your account, shall we?",
      type: "success",
      actionButtons: {
         accept: {
            label: "Get Started",
            onClick: () => {
               actionSelected = "start_onboarding"
               OnClose()
            },
         },
         cancel: {
            label: "Not now",
            onClick: () => {
               actionSelected = "go_to_overview"
               OnClose()
            },
         },
      },
   }
   return <Modal {...props} />
}

const Holder = styled.div`
   display: flex;
   flex-direction: column;
   place-content: center;

   .logout-reason-message-hint {
      max-width: 31.8em;
      margin-bottom: ${space.xs};
   }

   .get-started {
      display: flex;
      justify-content: end;
      align-items: center;
      position: absolute;
      z-index: 1;
      right: 3%;
      top: 1em;
      margin-right: ${space.s};
      gap: ${space.l};
   }

   .content-holder {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .role-select {
         position: relative;
         display: flex;
         flex-direction: column;
         align-items: center;
         margin-top: ${space.xl};
         border-radius: ${space.xs};
         overflow: hidden;
      }

      .title-and-manage {
         display: flex;
         justify-content: space-between;
         align-items: center;
         width: 100%;

         .manage-roles-button {
            margin: 0;
            border-radius: 5em;
         }
      }
   }

   .logout-button {
      width: 100%;
      margin-top: ${space.l};
   }

   /* Responsiveness */
   ${screen.tabletAndMobile} {
      .get-started {
         position: relative;
         right: initial;
         top: initial;
         align-self: center;
         flex-direction: column;
         gap: 0;

         button {
            margin-block: 0.2em;
            margin-bottom: 2em;
         }
      }
   }
`

export default Login
