import { useEffect } from "react"
import secondaryAuthToken from "utils/secondaryAuth"
import ROUTE from "./ROUTE"
import { useLocation } from "react-router-dom"
import auth from "utils/auth"
import useLogout from "pages/Logout/useLogout"
import { sentryTag } from "configs/sentry"

const useRouteChange = () => {
   const location = useLocation()
   const { gotoLogoutPage } = useLogout()

   // =====================
   // On every route change
   // =====================
   useEffect(() => {
      // if there is a secondary authToken in redux store and the page is not `/merge`, then run clear secondary authToken
      if (secondaryAuthToken.getToken() && location.pathname !== ROUTE.MERGE) {
         secondaryAuthToken.removeToken()
      }

      // Make sure these pages running ONLY when the authToken is there, otherwise go to login then redirect to the page afterward
      const pagesNeedUserAuthToken = [
         ROUTE.MERGE,
         ROUTE.VERIFY.ROOT,
         ROUTE.PROFILE.OVERVIEW,
         ROUTE.PROFILE.ACCOUNT,
         ROUTE.PROFILE.ROLES,
         ROUTE.PROFILE.SECURITY,
         ROUTE.PROFILE.INTEGRATIONS,
         ROUTE.SSO.ROOT,
         ROUTE.ONBOARDING.ROOT,
      ]
      const pagesNeedRoleSelection = [ROUTE.ONBOARDING.ROOT]
      const isAutTokenMissingOrExpired =
         !auth.getAccessToken() || auth.isTokenExpired() || !auth.getUserID()
      const isPageNeedAuthToken = !!pagesNeedUserAuthToken.find((page) =>
         location.pathname.includes(page)
      )
      const isPageNeedRoleSelection = !!pagesNeedRoleSelection.find((page) =>
         location.pathname.includes(page)
      )
      if (isPageNeedAuthToken && isAutTokenMissingOrExpired) {
         gotoLogoutPage("", !isPageNeedRoleSelection)
      }

      // Clear 'onboardingStepState' from sessionStorage when the user navigates away from the onboarding page
      const onboardingStepStateSessionItem = sessionStorage.getItem("onboardingStepState")
      if (!location.pathname.includes(ROUTE.ONBOARDING.ROOT) && onboardingStepStateSessionItem) {
         sessionStorage.removeItem("onboardingStepState")
      }

      // Scroll to top on every route change
      window.scrollTo(0, 0)

      // Set current url to sentry tag
      sentryTag("current-url", window.location.href)
   }, [location])
}

export default useRouteChange
