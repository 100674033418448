import { Checkbox, Form, HiddenField, HorizontalLine, TextField } from "rentpost/components/form"
import { Button, CircularProgress } from "@mui/material"
import * as yup from "yup"
import { ApolloError } from "@apollo/client"
import { OwnerType, UnitFormFields } from "./useUnitAndTenant"
import SelectField from "rentpost/src/components/form/SelectField"
import { LegalEntityTypeKey, LIST_LEGAL_ENTITY_TYPES, LIST_UNIT_TYPES } from "../sharedData"
import _ from "lodash"

interface Props {
   loading: boolean
   error: ApolloError | undefined
   onSubmit: (values: UnitFormFields) => void
   initialValues: UnitFormFields
   availableOwners: OwnerType[]
   onCancel: () => void
}

const validationSchema = yup.object({
   typeKey: yup.string().required("Type is required!"),
   desiredRentAmount: yup.string().required("Desired rent amount is required!"),
   bathrooms: yup.string().required("Required!"),
   bedrooms: yup.string().required("Required!"),
   sqft: yup.string().required("Required!"),
   arePetsAllowed: yup.boolean(),
   address: yup.object().shape({
      countryKey: yup.string().required("Country is required!"),
      state: yup.string().required("State is required!"),
      city: yup.string().required("City is required!"),
      street: yup.string().required("Street is required!"),
      aptSuiteEtc: yup.string(),
      postalCode: yup.string().required("Postal code is required!"),
   }),
   owner: yup.object().shape({
      id: yup.string().required("Owner is required!"),
      typeKey: yup.string().when("id", {
         is: (ownerId: string) => ownerId === "add_new_owner",
         then: yup.string().required("Owner type is required!"),
         otherwise: yup.string().nullable(),
      }),
      firstName: yup.string().when("id", {
         is: (ownerId: string) => ownerId === "add_new_owner",
         then: yup.string().when("typeKey", {
            is: (ownerType: string) =>
               ownerType === "natural_person" || ownerType === "sole_proprietorship",
            then: yup.string().required("First name is required!"),
         }),
         otherwise: yup.string().nullable(),
      }),
      lastName: yup.string().when("id", {
         is: (ownerId: string) => ownerId === "add_new_owner",
         then: yup.string().when("typeKey", {
            is: (ownerType: string) =>
               ownerType === "natural_person" || ownerType === "sole_proprietorship",
            then: yup.string().required("Last name is required!"),
         }),
         otherwise: yup.string().nullable(),
      }),
      companyName: yup.string().when("id", {
         is: (ownerId: string) => ownerId === "add_new_owner",
         then: yup.string().when("typeKey", {
            is: (ownerType: string) =>
               ownerType !== "natural_person" && ownerType !== "sole_proprietorship",
            then: yup.string().required("Company name is required!"),
         }),
         otherwise: yup.string().nullable(),
      }),
   }),
})

const UnitForm: React.FC<Props> = (props) => {
   const { loading, error, onSubmit, initialValues, availableOwners, onCancel } = props
   const availableOwnersMapped = availableOwners
      ? availableOwners?.map((owner) => ({
           value: owner.id,
           label: owner.displayName,
        }))
      : []
   const ownersSelectOptions = [
      ...availableOwnersMapped,
      { value: "add_new_owner", label: "-- ADD NEW OWNER --" },
   ]
   const legalEntityTypeOptions = _.flatMap(LIST_LEGAL_ENTITY_TYPES, (label, value) => {
      return { value, label }
   })

   // create an array of objects from LIST_UNIT_TYPES as object loosk like this {values:nestedKey, label:value, groupName: key }
   const unitTypeOptions = _.flatMap(LIST_UNIT_TYPES, (nestedKey, groupName) => {
      return _.map(nestedKey, (label, value) => {
         return { value, label, groupName }
      })
   })

   const countryKeyOptions = JSON.parse(localStorage.getItem("countries_and_states_list") || "{}")
      .countries as { value: string; label: string }[]

   const stateKeyOptions = JSON.parse(localStorage.getItem("countries_and_states_list") || "{}")
      .states as { value: string; label: string }[]

   return (
      <Form
         initialValues={initialValues}
         validationSchema={validationSchema}
         onSubmit={onSubmit}
         error={error}
         loading={loading}
         showErrorHint
      >
         {({ isValid, dirty, values, errors }) => {
            const disabled = !isValid || loading || !dirty
            const unitId = values?.id
            const isUpdate = !!unitId
            const ownerType = values?.owner?.typeKey as LegalEntityTypeKey
            const showOwnerNameFields =
               ownerType === "natural_person" || ownerType === "sole_proprietorship"
            return (
               <>
                  {unitId && <HiddenField name="id" />}
                  <SelectField label="Type" name="typeKey" options={unitTypeOptions} />
                  <TextField
                     label="Desired Rent Amount"
                     name="desiredRentAmount"
                     numberFormat={{
                        allowNegative: false,
                        allowDecimal: true,
                        decimalScale: 0,
                        thousandSeparator: true,
                        isNumericString: true,
                        prefix: "$ ",
                     }}
                  />
                  <div style={{ display: "flex", gap: "1.2em" }}>
                     <TextField
                        label="Bathrooms"
                        name="bathrooms"
                        type="number"
                        inputProps={{
                           step: 0.5,
                           inputMode: "numeric",
                           pattern: "[0-9]*",
                        }}
                     />
                     <TextField
                        label="Bedrooms"
                        name="bedrooms"
                        type="number"
                        inputProps={{
                           inputMode: "numeric",
                           pattern: "[0-9]*",
                        }}
                     />
                     <TextField
                        label="Area"
                        name="sqft"
                        numberFormat={{
                           allowNegative: false,
                           allowDecimal: false,
                           thousandSeparator: true,
                           isNumericString: true,
                           suffix: " sq.ft.",
                        }}
                     />
                  </div>
                  <Checkbox label="Are Pets Allowed" name="arePetsAllowed" />
                  <HorizontalLine />
                  <h3>Unit Address</h3>
                  <SelectField
                     label="Country"
                     name="address.countryKey"
                     placeholder="United States"
                     options={countryKeyOptions}
                     highlight
                  />
                  <TextField label="Street" name="address.street" />
                  <TextField label="Apt/Suite/Etc" name="address.aptSuiteEtc" />
                  <TextField label="City" name="address.city" />
                  {values.address?.countryKey === "US" ? (
                     <SelectField
                        label="State"
                        name="address.state"
                        placeholder="California"
                        options={stateKeyOptions}
                     />
                  ) : (
                     <TextField label="State" name="address.state" />
                  )}
                  <TextField label="Postal Code" name="address.postalCode" />
                  <HorizontalLine />
                  <h3>Owner</h3>
                  <SelectField
                     label="Owner"
                     name="owner.id"
                     options={ownersSelectOptions}
                     highlight
                  />
                  {values.owner.id === "add_new_owner" && (
                     <>
                        <SelectField
                           label="Type"
                           name="owner.typeKey"
                           options={legalEntityTypeOptions}
                        />
                        {showOwnerNameFields ? (
                           <>
                              <TextField label="First Name" name="owner.firstName" />
                              <TextField label="Last Name" name="owner.lastName" />
                           </>
                        ) : (
                           <TextField label="Company Name" name="owner.companyName" />
                        )}
                     </>
                  )}

                  <div className="action-buttons-holder">
                     <Button
                        variant="text"
                        color="secondary"
                        className="back-button"
                        onClick={onCancel}
                     >
                        Cancel
                     </Button>
                     <Button
                        variant="contained"
                        color="primary"
                        endIcon={loading ? <CircularProgress size={"100%"} /> : null}
                        disabled={disabled}
                        className="next-button"
                        type="submit"
                     >
                        {isUpdate ? "Update Unit" : "Add Unit"}
                     </Button>
                  </div>
               </>
            )
         }}
      </Form>
   )
}

export default UnitForm
