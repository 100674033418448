import { Skeleton } from "@mui/material"
import styled from "@emotion/styled"
import { SkeletonCard } from "rentpost/src/components/other"

const Skeletons = () => {
   return (
      <Holder>
         <Skeleton variant="text" className="skeleton-title" />
         <SkeletonCard />
         <SkeletonCard />
         <SkeletonCard />
      </Holder>
   )
}

const Holder = styled.div`
   position: relative;
   display: grid;
   grid-template-columns: repeat(auto-fill, minmax(20em, 1fr));
   gap: 2em;

   .skeleton-title {
      grid-row: 1;
      grid-column: 1 / -1;
      margin-top: 1em;
      height: 3.5em;
      max-width: 20em;
      width: 70%;
   }
`

export default Skeletons
