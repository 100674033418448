import { Form, PhoneNumberField, TextField } from "rentpost/components/form"
import { Button, CircularProgress } from "@mui/material"
import * as yup from "yup"
import { ApolloError } from "@apollo/client"
import { PersonalInfoFormFields } from "./usePersonalInfo"
import SVG from "assets/svg"
import { space } from "rentpost/src/styles"

interface Props {
   loading: boolean
   error: ApolloError | undefined
   onSubmit: (values: PersonalInfoFormFields) => void
   initialValues: PersonalInfoFormFields
}

const validationSchema = yup.object({
   firstName: yup
      .string()
      .required("First name is required!")
      .min(3, "First name must be at least 3 characters!"),
   lastName: yup
      .string()
      .required("Last name is required!")
      .min(3, "Last name must be at least 3 characters!"),
   phone: yup.string().required("Phone number is required!"),
})

const PersonalInfoForm: React.FC<Props> = (props) => {
   const { loading, error, onSubmit, initialValues } = props
   return (
      <Form
         initialValues={initialValues}
         validationSchema={validationSchema}
         onSubmit={onSubmit}
         error={error}
         loading={loading}
         showErrorHint
      >
         {({ isValid }) => {
            const disabled = !isValid || loading
            return (
               <>
                  <TextField label="First Name" name="firstName" />
                  <TextField label="Last Name" name="lastName" />
                  <PhoneNumberField label="Phone Number" name="phone" />
                  <div
                     className="action-buttons-holder page-buttons"
                     style={{
                        marginTop: space.m,
                     }}
                  >
                     <Button
                        variant="contained"
                        color="primary"
                        endIcon={
                           loading ? <CircularProgress size={"100%"} /> : <SVG.form.arrowRight />
                        }
                        disabled={disabled}
                        className="next-button"
                        type="submit"
                     >
                        Next: Business Info
                     </Button>
                  </div>
               </>
            )
         }}
      </Form>
   )
}

export default PersonalInfoForm
