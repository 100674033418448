import {
   Form,
   TextField,
   PasswordField,
   HorizontalLine,
   SubmitButton,
   MultiChoiceFormContainer,
   Hint,
} from "rentpost/components/form"
import * as yup from "yup"
import useRegister from "./useRegister"
import { Link } from "react-router-dom"
import ROUTE from "routes/ROUTE"
import getApolloErrorDetails from "utils/getApolloErrorDetails"

interface RegisterFormFieldsType {
   firstName: string
   lastName: string
   email: string
   password: string
   passwordConfirm: string
}

interface LoginFormFieldsType {
   username: string
   password: string
}

interface RegisterFormProps {
   registerFormInitialValue: RegisterFormFieldsType
   inviteKey: string
   avatar: string | null
}

const RegisterForm: React.FC<RegisterFormProps> = (props) => {
   const { registerFormInitialValue, inviteKey, avatar } = props
   const {
      register,
      registerError,
      registerLoading,
      uploadAvatarLoading,
      loginForRegister,
      loginError,
      loginLoading,
   } = useRegister({ avatar })

   const handleSubmitRegister = (values: RegisterFormFieldsType) => {
      register(inviteKey, values)
   }

   const handleSubmitLogin = (values: LoginFormFieldsType) => {
      loginForRegister(inviteKey, values)
   }

   const registerFormProps = {
      loading: registerLoading || uploadAvatarLoading,
      initialValues: registerFormInitialValue,
      onSubmit: handleSubmitRegister,
      error: registerError,
   }

   const loginFormProps = {
      loading: loginLoading || uploadAvatarLoading || registerLoading,
      onSubmit: handleSubmitLogin,
      error: loginError,
   }
   const userAlreadyExist = getApolloErrorDetails(registerError).errorMessage?.includes("exist")

   // ===
   // JSX
   // ===
   return (
      <>
         {userAlreadyExist && (
            <Hint
               canHide
               text="Email already exists. Please select existing user option."
               type="warning"
            />
         )}
         {(registerError || loginError) && !userAlreadyExist && (
            <Hint
               text={getApolloErrorDetails(registerError || loginError).errorMessage}
               type={"error"}
            />
         )}
         <MultiChoiceFormContainer
            choices={[
               {
                  label: "New User",
                  render: <RegisterFormContainer {...registerFormProps} />,
               },
               { label: "Existing User", render: <LoginFormContainer {...loginFormProps} /> },
            ]}
            labeWidth={150}
            defaultSelected={userAlreadyExist ? 1 : 0}
         />
      </>
   )
}

// ==========
// LOGIN FORM
// ==========
const LoginFormContainer = ({
   loading,
   onSubmit,
   error,
}: {
   loading: boolean
   onSubmit: (values: LoginFormFieldsType) => void
   error: any
}) => {
   const initialValue = {
      username: "",
      password: "",
   }
   const validationSchema = yup.object().shape({
      username: yup
         .string()
         .required("Username is required!")
         .min(3, "First name must be at least 3 characters!"),
      password: yup.string().required("Password is required!"),
   })

   return (
      <Form
         onSubmit={onSubmit}
         initialValues={initialValue}
         validationSchema={validationSchema}
         error={error}
         loading={loading}
         showErrorHint
      >
         <TextField name="username" label="Username" />
         <PasswordField name="password" label="Password" />
         <span className="remember-login" style={{ display: "flex", justifyContent: "flex-end" }}>
            <Link
               to={ROUTE.SIGN_IN_ASSISTANCE.ROOT + ROUTE.SIGN_IN_ASSISTANCE.PASSWORD}
               style={{ fontStyle: "italic", whiteSpace: "nowrap" }}
            >
               forgot password?
            </Link>
         </span>
         <SubmitButton>Login</SubmitButton>
      </Form>
   )
}

// =============
// REGISTER FORM
// =============
const RegisterFormContainer = ({
   loading,
   initialValues,
   onSubmit,
   error,
}: {
   loading: boolean
   initialValues: RegisterFormFieldsType
   onSubmit: (values: RegisterFormFieldsType) => void
   error: any
}) => {
   const validationSchema = yup.object().shape({
      firstName: yup
         .string()
         .required("First name is required!")
         .min(3, "First name must be at least 3 characters!"),
      lastName: yup
         .string()
         .required("Last name is required!")
         .min(3, "Last name must be at least 3 characters!"),
      email: yup.string().email("Invalid email address").required("Email is required!"),
      password: yup
         .string()
         .required("Password is required!")
         .min(8, "Password must be at least 8 characters!")
         .matches(/[A-Z]/, "Password must contain one uppercase!")
         .matches(/[a-z]/, "Password must contain one lowercase!")
         .matches(/[0-9]/, "Password must contain one number!")
         .matches(
            /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
            "Password must contain one special character!"
         ),
      passwordConfirm: yup
         .string()
         .required("Need to confirm password!")
         .oneOf([yup.ref("password"), null], "Passwords must match!"),
   })

   return (
      <Form
         initialValues={initialValues}
         validationSchema={validationSchema}
         onSubmit={onSubmit}
         error={error}
         loading={loading}
      >
         <TextField name="firstName" label="First Name" />
         <TextField name="lastName" label="Last Name" />
         <HorizontalLine />
         <TextField name="email" label="Email" />
         <PasswordField name="password" label="Password" />
         <PasswordField name="passwordConfirm" label="Confirm Password" />
         <SubmitButton>Get started</SubmitButton>
      </Form>
   )
}

export type { RegisterFormFieldsType, LoginFormFieldsType }
export default RegisterForm
