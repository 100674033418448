import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { TwoFactorMethod } from "pages/Verify/TwoFactorAuth/useTwoFactorAuth"

interface SettingsState {
   twoFactorAuthEnabled: boolean
   twoFactorAuthMethod: keyof typeof TwoFactorMethod
   twoFactorAuthPhoneLastFourDigits?: string
}

const initialState: SettingsState = {
   twoFactorAuthEnabled: false,
   twoFactorAuthMethod: "SMS",
   twoFactorAuthPhoneLastFourDigits: undefined,
}

const settingsSlice = createSlice({
   name: "settings",
   initialState,
   reducers: {
      // ------------------------
      // OPEN ERROR MODAL REDUCER
      // ------------------------
      setSettings: (state, action: PayloadAction<SettingsState>) => {
         state.twoFactorAuthEnabled = action.payload.twoFactorAuthEnabled
         state.twoFactorAuthMethod = action.payload.twoFactorAuthMethod
         state.twoFactorAuthPhoneLastFourDigits = action.payload.twoFactorAuthPhoneLastFourDigits
      },
   },
})

export const { setSettings } = settingsSlice.actions
export default settingsSlice.reducer
