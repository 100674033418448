import { ErrorResponse } from "@apollo/client/link/error"
import * as Sentry from "@sentry/react"
import { graphQlSentryContext, graphQlSentryBreadcrumb } from "configs/sentry"
import store from "redux/store"
import header from "./headers"
import ROUTE from "routes/ROUTE"
import hideLoadingPage from "utils/hideLoadingPage"
import { MaintenanceError } from "configs/error/customErrors"
import { directToLogoutPage } from "utils/redirectTo"

const graphqlErrorHandler = (error: ErrorResponse) => {
   const { graphQLErrors, networkError, operation, response } = error
   // @ts-ignore  - This is correct, but the TS definition is wrong
   const statusCode = networkError?.statusCode
   const operationName = operation?.operationName
   const operationBody = operation?.query?.loc?.source?.body!
   const variables = operation?.variables
   const headers = operation?.getContext().headers
   const responseErrorMessage = graphQLErrors?.[0]?.message!

   // -------------------
   // Headers - - - - - -
   // -------------------
   const {
      emailVerificationRequiredHeader,
      twoFactorAuthRequiredHeader,
      logoutHeader,
      userSuspendedHeader,
      twoFactorAuthMethodHeader,
      twoFactorPhoneLastFourDigitsHeader,
      twoFactorAuthSetupHeader,
   } = header(operation)

   // -----------------
   // Pages - - - - - -
   // -----------------
   const VERIFY_EMAIL_PAGE = ROUTE.VERIFY.ROOT + ROUTE.VERIFY.EMAIL
   const isLogoutPage: boolean = location.pathname === ROUTE.LOGOUT
   const isEmailVerificationPage: boolean = location.pathname === VERIFY_EMAIL_PAGE
   const isMergePage: boolean = location.pathname === ROUTE.MERGE

   // ----------------------
   // Operations - - - - - -
   // ----------------------
   const isLoginOperation = operationName === "Login"
   const isPingOperation = operationName === "Ping"
   const isLogoutOperation = operationName === "Logout"

   // *-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*
   // *-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*
   // ERROR HANDLING LOGIC - 🚧 order matters 🚧
   // *-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*
   // *-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*

   // -------------------
   // 1- Maintenance Mode
   // -------------------
   if (statusCode === 503) {
      // Hide Loading Page
      hideLoadingPage()
      throw new MaintenanceError("Maintenance Mode")
   }

   // ----------------
   // 2- Logout Header
   // ----------------
   if (logoutHeader === "true") {
      !isLogoutPage && directToLogoutPage("")
      return // Don't Report to Sentry
   }

   // ------------------------
   // 3 User Suspended Header
   // ------------------------
   if (typeof userSuspendedHeader === "number" && userSuspendedHeader > 0) {
      !isLogoutPage &&
         store.dispatch({ type: "modal/openLoginSuspendedModal", payload: userSuspendedHeader })
      return // Don't Report to Sentry
   }

   // ----------------------
   // 4- Verify Email Header
   // ----------------------
   if (emailVerificationRequiredHeader === "true") {
      !isEmailVerificationPage && location.replace(VERIFY_EMAIL_PAGE)
      return // Don't Report to Sentry
   }

   // ----------------------
   // 5 2FA Required Header
   // ----------------------
   if (twoFactorAuthRequiredHeader === "true") {
      // check if request is coming from 2FA Setup Page, as we already has a specific modal for that page
      const isIgnoredPage: boolean = isLogoutPage

      // if not, open 2FA Modal
      if (!isIgnoredPage) {
         store.dispatch({ type: "modal/openTwoFactorAuthModal" })
         store.dispatch({
            type: "settings/setSettings",
            payload: {
               twoFactorAuthEnabled: true,
               twoFactorAuthMethod: twoFactorAuthMethodHeader,
               twoFactorAuthPhoneLastFourDigits: twoFactorPhoneLastFourDigitsHeader,
            },
         })
      }

      // check for 2fa-required-operations
      const twoFactorAuthRequiredOperation = operation.getContext().twoFactorAuthRequiredOperation

      if (twoFactorAuthRequiredOperation) {
         store.dispatch({
            type: "graphql_2FA_RequiredOperation/setOperation",
            payload: { operationName, variables },
         })
      }

      return // Don't Report to Sentry
   }

   // -------------------
   // 6 2FA Setup Header
   // -------------------
   if (twoFactorAuthSetupHeader === "true") {
      // if not, open 2FA setup Modal
      store.dispatch({ type: "modal/openTwoFactorAuthSetupModal" })
      store.dispatch({
         type: "settings/setSettings",
         payload: {
            twoFactorAuthEnabled: false,
            twoFactorAuthMethod: twoFactorAuthMethodHeader,
            twoFactorAuthPhoneLastFourDigits: twoFactorPhoneLastFourDigitsHeader,
         },
      })

      return // Don't Report to Sentry
   }

   // --------------
   // 7 IF 401 ONLY
   // --------------
   if (statusCode === 401) {
      /**
       *  This happens when we have invalid/expired accessToken in the cookie, so we need to log out the user and redirect them to the login page to get a fresh accessToken
       */
      // PING REQUEST WHITE LIST - we need to run ping before these pages load to verify accessToken
      const isPageNeedPing = isMergePage || isEmailVerificationPage
      const isPingNotNeeded = isPingOperation && !isPageNeedPing

      // IGNORED OPERATIONS
      const isIgnoredOperation = isLoginOperation || isPingNotNeeded || isLogoutOperation
      // Check that there verify_2FA_Header & verifyEmailHeader are not true, if so, log out user
      if (!twoFactorAuthRequiredHeader && !emailVerificationRequiredHeader && !isIgnoredOperation) {
         directToLogoutPage("Session expired. Please sign in again.", true)
         return // Don't Report to Sentry
      }

      return // Don't Report to Sentry
   }

   // ---------------------------------------------------------
   // 8 Show Error Modal - (based on context "showErrorModal")
   // ---------------------------------------------------------
   if (operation.getContext().showErrorModal) {
      let modalMessage: string | undefined = undefined
      const errorMessage = (error: any) => error?.networkError?.result?.errors[0].message
      // Hide Loading Page - as for some cases the error modal will be shown on top of the loading page
      hideLoadingPage()
      try {
         modalMessage = errorMessage(error)
      } catch (error) {}
      // check that modal "LogoutModal" is not open, to avoid the error modal blocking the logout modal from opening
      const isLogoutModalOpen = store.getState().modal.activeModal === "logout"

      !isLogoutModalOpen &&
         store.dispatch({
            type: "modal/openErrorModal",
            payload: modalMessage,
         })
   }

   // ------------------
   // Sentry - - - - - -
   // ------------------

   // Add breadcrumb to Sentry
   graphQlSentryBreadcrumb({ operationName, statusCode })
   // Add context to Sentry
   graphQlSentryContext({
      label: `GRAPHQL OPERATION`,
      operationName,
      statusCode,
      operationBody,
      payload: variables,
      headers,
      ErrorMessage: responseErrorMessage,
   })

   Sentry.withScope((scope) => {
      const criticalStatusCodes = [400, 404, 500]
      const isCriticalError = criticalStatusCodes.includes(statusCode) || statusCode >= 500
      const level: "debug" | "fatal" | "error" = isCriticalError ? "error" : "debug"
      scope.setLevel(level)
      Sentry.captureMessage(
         `Apollo Network Error - [${statusCode}] - ${responseErrorMessage}`,
         level
      )
   })
   return
}

export default graphqlErrorHandler
