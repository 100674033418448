import { createSlice, PayloadAction } from "@reduxjs/toolkit"

type TwoFactorAuthRequiredMutations = "RemoveUser"

interface graphqlOperationProps {
   operationName: TwoFactorAuthRequiredMutations | undefined
   variables: any
}

const initialState: graphqlOperationProps = {
   operationName: undefined,
   variables: undefined,
}

const graphql_2FA_RequiredOperationSlice = createSlice({
   name: "graphql_2FA_RequiredOperation",
   initialState,
   reducers: {
      // ------------------------
      // OPERATION - REMOVE USER
      // ------------------------
      setOperation: (state, action: PayloadAction<graphqlOperationProps>) => {
         state.operationName = action.payload.operationName
         state.variables = action.payload.variables
      },

      // ---------------
      // CLEAR OPERATION
      // ---------------
      clearOperation: (state) => {
         state.operationName = undefined
         state.variables = undefined
      },
   },
})

export type { graphqlOperationProps as graphql_2FA_RequiredOperationProps }
export const { clearOperation, setOperation } = graphql_2FA_RequiredOperationSlice.actions
export default graphql_2FA_RequiredOperationSlice.reducer
