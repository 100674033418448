import { ApolloError } from "@apollo/client"
import { MiniRoleCardProps } from "rentpost/components/other"
import { AddCustomAPIKeyFormFields, IntegrationTypeEnum } from "../../useIntegrations"
import AddAPIKeyForm from "./AddAPIKeyForm"
import styled from "@emotion/styled"

interface AddCustomApiKeyComponentProps {
   integrationId: string
   integrationName: string
   onSubmit: (value: AddCustomAPIKeyFormFields) => void
   onClose: () => void
   availableRoles: MiniRoleCardProps[]
   loading: boolean
   error: ApolloError | undefined
}

const AddCustomApiKey: React.FC<AddCustomApiKeyComponentProps> = (props) => {
   return (
      <Holder>
         <h2>{props.integrationName} New API Key</h2>
         <p className="main-description">
            To add an API Key, you need to give it a name, a description, then choose a role from
            your RentPost account that the API Key will have access to.
         </p>
         <AddAPIKeyForm {...props} />
      </Holder>
   )
}

const Holder = styled.div`
   display: flex;
   flex-direction: column;

   h2 {
      text-transform: capitalize;
   }

   /* reveal animation */
   opacity: 0;
   transform: translateX(100%);
   animation: fadeIn 0.3s forwards;

   @keyframes fadeIn {
      to {
         opacity: 1;
         transform: translateX(0%);
      }
   }
`

export type { AddCustomApiKeyComponentProps }
export default AddCustomApiKey
