import { gql, useMutation } from "@apollo/client"
import { useNavigate } from "react-router-dom"
import auth from "utils/auth"
import { Logout } from "./__generated__"
import usePOEToken from "hooks/usePOEToken"
import bypassMockError from "utils/bypassMockError"
import ROUTE from "routes/ROUTE"
import { storeParams } from "utils/redirectTo"

const LogoutMutation = gql`
   mutation Logout {
      logout
   }
`

export default function useLogout() {
   const { poeToken, refreshPOEToken } = usePOEToken()
   const [runLogout] = useMutation<Logout>(LogoutMutation)
   // read reason from query string
   const navigate = useNavigate()

   const logout = () => {
      runLogout({
         context: {
            poeToken: poeToken.current,
            skipQueue: true,
         },
      }).catch((e) => bypassMockError(e))
      refreshPOEToken()

      // Clear auth token from local storage
      auth.removeAccessToken()

      // Clear persisted cache for apollo client data to avoid data leak between users
      sessionStorage.removeItem("apollo-cache-persist")

      // Clear error boundary try again count on logout
      sessionStorage.removeItem("unExpectedErrorTryAgainCount")
   }

   const gotoLogoutPage = (reason?: string, redirect?: boolean) => {
      const currentURL = new URL(location.href)
      // So we make sure the redirect_to is clean from any query params before redirecting to the login page
      const currentLocation = currentURL.origin + currentURL.pathname
      const reasonQuery = reason ? `?reason=${reason}` : ""
      const redirectQuery = redirect ? `${reason ? "&" : "?"}return_to=${currentLocation}` : ""
      redirect && storeParams()
      navigate(ROUTE.LOGOUT + reasonQuery + redirectQuery, { replace: true })
   }

   return { logout, gotoLogoutPage }
}
