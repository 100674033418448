import { useOutletContext } from "react-router-dom"
import useOnboarding from "../useOnboarding"
import BusinessInfoForm from "./BussinessInfoForm"
import { ComponentProps } from "react"
import Skeleton from "../Skeleton"

const CompanyInfo: React.FC = () => {
   const {
      companyInfo: { error, loading, companyInfo, updateCompanyInfo },
      goBack,
   } = useOutletContext() as ReturnType<typeof useOnboarding>

   const companyInfoFormProps: ComponentProps<typeof BusinessInfoForm> = {
      loading:
         loading.addAddressLoading ||
         loading.updateCompanyInfoLoading ||
         loading.getCompanyInfoLoading ||
         loading.getCountriesAndStatesListLoading,
      error: error.updateCompanyInfoError,
      initialValues: companyInfo as any,
      onSubmit: updateCompanyInfo,
      goBack,
   }
   if (companyInfo === null) return <Skeleton />

   return (
      <div className="page-content">
         <h2>Your Business</h2>
         <p className="description">
            This information will be used when referring to your business
         </p>
         <BusinessInfoForm {...companyInfoFormProps} />
      </div>
   )
}
export default CompanyInfo
