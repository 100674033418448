import { ApolloError } from "@apollo/client"

interface Props {
   errorMessage: string | undefined
   statusCode: string | undefined
}

const getApolloErrorDetails = (error: ApolloError | undefined): Props => {
   let errorMessage: string | undefined = undefined

   // @ts-ignore - networkError is not well defined in the ApolloError type
   const statusCode = error?.networkError?.statusCode
   if (error) {
      try {
         errorMessage =
            // @ts-ignore - networkError is not well defined in the ApolloError type
            statusCode >= 500
               ? "Something went wrong"
               : // @ts-ignore - networkError is not well defined in the ApolloError type
                 error?.networkError!.result.errors[0].message
      } catch (error) {
         errorMessage = "Something went wrong"
      }
   }
   return {
      errorMessage,
      statusCode,
   }
}

export default getApolloErrorDetails
