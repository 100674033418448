import { Button } from "@mui/material"
import { useState } from "react"
import CloseAccountModal from "./ModalCloseAccount"
import { useNavigate } from "react-router-dom"
import { color } from "rentpost/styles"
import ROUTE from "routes/ROUTE"
import { ApolloError } from "@apollo/client"

interface Props {
   onDeleteAccount: (reason: string) => void
   deleteAccountLoading: boolean
   className?: string
   error: ApolloError | undefined
}

const RightSection: React.FC<Props> = ({
   onDeleteAccount,
   deleteAccountLoading,
   className,
   error,
}) => {
   const [showDeleteAccountModal, setShowDeleteAccountModal] = useState<boolean>(false)
   const navigate = useNavigate()

   const deleteAccountModalProps = {
      visible: showDeleteAccountModal,
      onClose: () => setShowDeleteAccountModal(false),
      onDeleteAccount,
      loading: deleteAccountLoading,
      error,
   }

   const gotoMergeAccounts = () => {
      navigate(ROUTE.MERGE)
   }
   return (
      <div className={className}>
         <CloseAccountModal {...deleteAccountModalProps} />
         <div className="right-section-item">
            <p>
               If you'd like to merge another user account with roles into this one, you can do so
               with our connector tool.
            </p>
            <Button variant="outlined" color="secondary" onClick={gotoMergeAccounts}>
               Merge Accounts
            </Button>
         </div>

         <div className="right-section-item remove">
            <p style={{ color: color.red }}>
               While we'd hate to see you go, should you need to cancel your account, at any time,
               you may do so using our account closure form.
            </p>
            <Button
               variant="outlined"
               color="error"
               onClick={() => setShowDeleteAccountModal(true)}
            >
               Close Account
            </Button>
         </div>
      </div>
   )
}

export default RightSection
