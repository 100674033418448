import { MiniRoleCardProps } from "rentpost/components/other"
import { AddZapierAPIKeyFormFields } from "../../useIntegrations"
import { ApolloError } from "@apollo/client"
import AddZapierAPIKeyForm from "./AddZapierAPIKeyForm"
import styled from "@emotion/styled"

interface AddZapierAPIKeyComponentProps {
   onSubmit: (value: AddZapierAPIKeyFormFields) => void
   onClose: () => void
   availableRoles: MiniRoleCardProps[]
   loading: boolean
   error: ApolloError | undefined
}

const AddZapierAPIKey: React.FC<AddZapierAPIKeyComponentProps> = (props) => {
   return (
      <Holder>
         <h2>Authorize New Role</h2>
         <p className="main-description">
            To add an role to your Zapier Integration, you need to choose a role from your RentPost
            account, then authorize it, using the secret API key that will be provided to you.
         </p>
         <AddZapierAPIKeyForm {...props} />
      </Holder>
   )
}

const Holder = styled.div`
   display: flex;
   flex-direction: column;
`

export type { AddZapierAPIKeyComponentProps }
export default AddZapierAPIKey
