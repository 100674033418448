import { ApolloError } from "@apollo/client"
import { useAppDispatch, useAppSelector } from "redux/hooks"
import {
   openErrorModal as openErrorModalSource,
   closeModal as closeMalSource,
   openTwoFactorAuthModal as openTwoFactorAuthSource,
   openAdBlockerWarningModal as openAdBlockerWarningModalSource,
} from "redux/slices/modalSlice"

// UseModal hook
const useModal = () => {
   const dispatch = useAppDispatch()

   // Open error modal
   const openGraphQlErrorModal = (modalProps: ApolloError) => {
      let message: string | undefined = undefined
      const errorMessage = (error: any) => error?.networkError?.result?.errors[0].message
      try {
         message = errorMessage(modalProps)
      } catch (error) {}

      dispatch(openErrorModalSource(message))
   }

   // Open error modal
   const openErrorModal = (errorMessage: string) => dispatch(openErrorModalSource(errorMessage))

   // Open two factor auth modal
   const openTwoFactorAuthModal = () => dispatch(openTwoFactorAuthSource())

   // Open two factor auth setup modal
   const openTwoFactorAuthSetupModal = () => dispatch(openTwoFactorAuthSource())

   // Open Ad Blocker Warning Modal
   const openAdBlockerWarningModal = () => dispatch(openAdBlockerWarningModalSource())

   // close modal
   const closeModal = () => dispatch(closeMalSource())
   const modalState = useAppSelector((state) => state.modal)

   return {
      openGraphQlErrorModal,
      openErrorModal,
      openTwoFactorAuthModal,
      openTwoFactorAuthSetupModal,
      openAdBlockerWarningModal,
      closeModal,
      modalState,
   }
}

export default useModal
