import { useEffect } from "react"
import useLogout from "./useLogout"
import { useNavigate, useSearchParams } from "react-router-dom"
import auth from "utils/auth"
import ROUTE from "routes/ROUTE"
import useModal from "hooks/useModal"

const Logout: React.FC = () => {
   // read reason from query string
   const [searchParams] = useSearchParams()
   const { logout } = useLogout()
   const navigate = useNavigate()
   const { closeModal } = useModal()
   // Run on mount

   useEffect(() => {
      // Clear auth token from cookies
      closeModal()
      auth.getAccessToken() && logout()
      navigate(ROUTE.LOGIN + `${searchParams ? "?" + searchParams : ""}`, { replace: true })
   }, [])
   return <></>
}

export default Logout
