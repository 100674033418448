import SVG from "assets/svg"
import { Link } from "react-router-dom"
import styled from "@emotion/styled"
import ROUTE from "routes/ROUTE"

interface Props {
   className?: string
}
const LogoLink: React.FC<Props> = (props) => {
   return (
      <Holder className={props.className} to={ROUTE.INDEX}>
         <SVG.logo.word className="logo" />
      </Holder>
   )
}

const Holder = styled(Link)`
   cursor: pointer;
   &:hover {
      .logo {
         filter: drop-shadow(2px 2px 6px #0000002a);
      }
   }
`

export default LogoLink
