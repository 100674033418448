import { ModalProps, Modal } from "layouts"

interface ModalErrorProps {
   onClose: () => void
   activeModal: string | false
}
const ModalAdBlockerWarning: React.FC<ModalErrorProps> = ({ onClose, activeModal }) => {
   const title = "Ad Blocker Detected!"
   const props: ModalProps = {
      visible: activeModal === "adBlockerWarning",
      onClose,
      title,
      description:
         "Ad blockers can cause issues with the website, and may prevent some features from working correctly. Please disable your ad blocker or whitelist this website.",
      type: "warning",
      actionButtons: {
         accept: {
            label: "Okay",
         },
      },
      canIgnore: true,
   }

   return <Modal {...props} />
}

export type { ModalErrorProps }
export default ModalAdBlockerWarning
