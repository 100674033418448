import RoutesIndex from "routes"
import GlobalStyles from "rentpost/styles/GlobalStyles"
import MUITheme from "rentpost/styles/MUITheme"
import ModalProvider from "./ModalProvider"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import { ThemeProvider as ThemeProviderComp } from "rentpost/styles"
import RootErrorBoundary from "configs/error/RootErrorBoundary"
import useLaunch from "hooks/useLaunch"

const RootApp = () => {
   const theme = createTheme(MUITheme)

   // Run launch scripts (ping, get settings)
   useLaunch()

   // JSX
   return (
      <RootErrorBoundary>
         <GlobalStyles />
         <ThemeProviderComp>
            <ThemeProvider theme={theme}>
               <ModalProvider />
               <RoutesIndex />
            </ThemeProvider>
         </ThemeProviderComp>
      </RootErrorBoundary>
   )
}

export default RootApp
