import { MultiSectionsLayout } from "layouts"
import SignUpForm from "./SignUpForm"
import Illustration from "./Illustration"
import styled from "@emotion/styled"
import { color, space, size, font } from "styles"
import { Form, Hint, SubmitButton, TextField } from "components/form"
import { useEffect } from "react"
import { AnimatePresence } from "framer-motion"
import { AnimateWithVariants } from "rentpost/components/functional"
import auth from "utils/auth"
import useProfile from "pages/Profile/useProfile"
import { LoadingSpinner } from "rentpost/components/other"
import useLogout from "pages/Logout/useLogout"
import useSignup from "./useSignUp"
import * as yup from "yup"
import useSlowEffect from "hooks/useSlowEffect"
import hideLoadingPage from "utils/hideLoadingPage"

const SignUp = () => {
   // Hide loading page when component is mounted
   useEffect(() => {
      hideLoadingPage()
   }, [])
   return (
      <MultiSectionsLayout
         htmlTitle="Sign Up | RentPost"
         illustrationSection={<Illustration />}
         mainSection={<MainSection />}
      />
   )
}

const MainSection = () => {
   const {
      getUser,
      data: { userData },
      loading: { getUserLoading },
      error: { getUserError },
   } = useProfile()

   const { signUp, loading, error } = useSignup()
   const { logout } = useLogout()
   const isAuthTokenValid = auth.getAccessToken() && !auth.isTokenExpired()

   useSlowEffect(() => {
      // run getUser() if token is valid
      isAuthTokenValid && getUser()
   })

   const userName = userData ? userData?.firstName + " " + userData?.lastName : null
   const userEmail = userData ? userData?.email : null
   const initials = `${userData?.firstName?.[0]}${userData?.lastName?.[0]}`

   // ---------------------
   // Conditional Rendering
   // ---------------------
   const hasCurrentAccount = isAuthTokenValid && userName && !getUserLoading

   const initialValues = {
      companyName: "",
   }
   const validationSchema = yup.object({
      companyName: yup
         .string()
         .required("Company name is required!")
         .min(3, "Company name must be at least 3 characters!"),
   })
   const onSubmit = (values: { companyName: string }) => {
      signUp(values.companyName, undefined, userEmail)
   }
   // ===
   // JSX
   // ===
   return (
      <Holder>
         {getUserLoading && <LoadingSpinner type="glowing" color={color.green} />}
         <AnimatePresence mode="wait">
            {hasCurrentAccount && (
               <AnimateWithVariants
                  className="content"
                  animate="fadeIn"
                  initial="fadeOut"
                  exit="fadeOut"
                  key={"sign-up-form"}
               >
                  <h1>Let's start managing your property</h1>
                  <div className="box">
                     <div className="avatar-placeholder">
                        <span className="initials">{initials}</span>
                     </div>
                     <p>
                        Active user detected. This new company account and manager role will be
                        added to your, <span className="bold">{userEmail}</span>, user account.
                     </p>
                  </div>
                  <Form
                     loading={loading}
                     error={error}
                     {...{ initialValues, validationSchema, onSubmit }}
                     showErrorHint
                  >
                     <TextField name="companyName" label="Company Name" />
                     <SubmitButton>Add new company</SubmitButton>
                  </Form>
                  <span className="create-new-account">
                     Or you can{" "}
                     <a onClick={() => logout()} className="link">
                        create a new user account
                     </a>
                     .
                  </span>
               </AnimateWithVariants>
            )}
            {!isAuthTokenValid && (
               <AnimateWithVariants
                  className="content"
                  animate="fadeIn"
                  initial="fadeOut"
                  exit="fadeOut"
                  key={"sign-up-form"}
               >
                  <h1>Let's start managing your property</h1>
                  <p className="main-description">Free 30-day trial. No credit card needed.</p>
                  <Hint
                     text="Are you a Tenant or an Owner?"
                     collapsibleDescription="Tenant and Owner accounts are setup through invite only.  Please reach out to your property manager to request an invite link.  This signup form is for new property management companies!"
                  />
                  <SignUpForm />
               </AnimateWithVariants>
            )}
         </AnimatePresence>
      </Holder>
   )
}
const Holder = styled.div`
   display: flex;
   flex-direction: column;
   place-content: center;

   .content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      h1 {
         color: ${color.violet};
         margin-bottom: ${space.xs};
      }
      .description {
         color: ${color.grayLight};
         margin-block: ${space.xs};
         font-size: ${size.m};
         ${font.thinItalic};
      }
   }

   .box {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: ${space.s};
      padding: ${space.l};
      margin-block: ${space.m};
      gap: 2em;

      .avatar-placeholder {
         display: flex;
         justify-content: center;
         align-items: center;
         height: 4em;
         border-radius: 50%;
         background-color: ${color.grayLight};
         margin-bottom: ${space.s};
         border: 4px solid ${color.white};
         outline: 3px solid ${color.grayLight};
         box-sizing: border-box;
         aspect-ratio: 1;
         .initials {
            font-size: ${size.l};
            color: ${color.white};
            ${font.bold};
         }
      }

      .gray {
         color: ${color.grayDark};
         margin-bottom: ${space.s};
         .bold {
            font-weight: 600;
         }
      }
   }

   .create-new-account {
      font-size: ${size.m};
      color: ${color.black};
      text-align: center;
      align-self: center;
      margin-top: 0.5em;
      a {
         color: ${color.green};
         ${font.bold};
         cursor: pointer;
      }
   }

   /* Style for company name box in the signUpForm */
   .company-name {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 1.7em 1.3em;
      gap: 0;
      span.italic {
         font-size: ${size.s};
      }
   }
`

export default SignUp
