import styled from "@emotion/styled"
import { color } from "rentpost/styles"
import { useMemo } from "react"
import { space } from "rentpost/styles"
import { LogoLink } from "components/other"
import { useNavigate } from "react-router-dom"
import SVG from "assets/svg"
import ROUTE from "routes/ROUTE"
import { optimizedImages } from "assets/images/optimized"
import { LazyImage } from "rentpost/src/components/other"

const Illustration = () => {
   // create array of images from 01 to 37
   const images = [
      optimizedImages.pic01,
      optimizedImages.pic02,
      optimizedImages.pic03,
      optimizedImages.pic04,
      optimizedImages.pic05,
      optimizedImages.pic06,
      optimizedImages.pic07,
      optimizedImages.pic08,
      optimizedImages.pic09,
      optimizedImages.pic10,
      optimizedImages.pic11,
      optimizedImages.pic12,
      optimizedImages.pic13,
      optimizedImages.pic14,
      optimizedImages.pic15,
      optimizedImages.pic16,
      optimizedImages.pic17,
      optimizedImages.pic18,
      optimizedImages.pic19,
      optimizedImages.pic20,
      optimizedImages.pic21,
      optimizedImages.pic22,
      optimizedImages.pic23,
      optimizedImages.pic24,
      optimizedImages.pic25,
      optimizedImages.pic26,
      optimizedImages.pic27,
      optimizedImages.pic28,
      optimizedImages.pic29,
      optimizedImages.pic30,
      optimizedImages.pic31,
      optimizedImages.pic32,
      optimizedImages.pic33,
      optimizedImages.pic34,
      optimizedImages.pic35,
      optimizedImages.pic36,
      optimizedImages.pic37,
   ]
   // Get image randomly from 01 to 37 from array of images and store it in randomImage variable using useMemo hook to avoid changing the image on every render
   const randomImage = useMemo(() => images[Math.floor(Math.random() * 37)], [])
   const navigate = useNavigate()
   return (
      <Holder>
         <div className="desktop">
            <LogoLink />
            <LazyImage images={randomImage} className="image" />
         </div>
         <div className="tablet-and-mobile" onClick={() => navigate(ROUTE.INDEX)}>
            <div className="logo">
               <SVG.logo.wordColored className="colored" />
               <SVG.logo.word className="white" />
            </div>
            <div className="image-holder">
               <LazyImage images={randomImage} className="image" />
            </div>
         </div>
      </Holder>
   )
}

const Holder = styled.div`
   .desktop {
      position: relative;
      isolation: isolate;
      height: 100%;
      width: 31vw;
      max-width: 40em;
      overflow: hidden;

      .logo {
         position: absolute;
         top: ${space.xl};
         left: ${space.xl};
         width: 11em;
         z-index: 3;
         fill: ${color.white};
      }

      .image {
         position: absolute;
         bottom: 0;
         height: 100%;
      }
   }

   .tablet-and-mobile {
      display: flex;
      .image-holder {
         display: flex;
         justify-content: center;
         .image > img {
            position: absolute;
            width: 100%;
            object-fit: cover;
         }
      }
   }
`

export default Illustration
