import styled from "@emotion/styled"
import useProfile from "../useProfile"
import AccountForm, { AccountFieldProps } from "./AccountForm"
import { border, screen, size } from "rentpost/styles"
import HeaderSection from "./HeaderSection"
import RightSection from "./RightSection"
import { useOutletContext } from "react-router-dom"
import { useState } from "react"
import Modal from "rentpost/layouts/Modal"
import { maxWidth } from "rentpost/styles/size"

const AccountPage = () => {
   const {
      data,
      loading,
      error,
      updateUser,
      removeUser,
      twoFactorAuthEnabled: isTwoFactorAuthEnabled,
      isUserUpdatedSuccessfully,
   } = useOutletContext() as ReturnType<typeof useProfile>
   const [confirmPhoneUpdateWith2faModalData, setConfirmPhoneUpdateWith2faModalData] = useState<
      AccountFieldProps | undefined
   >(undefined)
   const userData = data?.userData
   const roles = data?.getAvailableRolesData?.roles

   // =====
   // PROPS
   // =====

   // accountFormProps
   const handleUpdateUser = (values: AccountFieldProps) => {
      // check that 2fa is enabled && phone number is changed, then show modal to confirm process
      if (isTwoFactorAuthEnabled && values.phone !== userData?.phone) {
         // show modal
         setConfirmPhoneUpdateWith2faModalData(values)
      }
      // otherwise, update user
      else {
         updateUser(values)
      }
   }
   const accountFormProps = {
      initialValues: {
         firstName: userData?.firstName,
         lastName: userData?.lastName,
         email: userData?.email,
         phone: userData?.phone,
         password: "",
         passwordConfirm: "",
      } as AccountFieldProps,
      handleSubmit: handleUpdateUser,
      loading:
         loading.updateUserLoading ||
         loading.getUserLoading ||
         loading.disableTwoFactorAuthLoading ||
         loading.enableTwoFactorAuthLoading,
      error: error.updateUserError,
      className: "account-form",
      isSubmissionSuccessful: isUserUpdatedSuccessfully,
   }

   // headerSectionProps
   const getMemberSince = () => {
      const date = new Date(userData?.createdAt)
      const now = new Date()
      let years = now.getFullYear() - date.getFullYear()
      let months = now.getMonth() - date.getMonth()
      let days = now.getDate() - date.getDate()
      if (months < 0) {
         years -= 1
         months += 12
      }

      let result: string | null = null
      if (years > 0 && months === 0) {
         result = `${years} year${years > 1 ? "s" : ""}`
      }

      if (years === 0 && months > 0) {
         result = `${months} month${months > 1 ? "s" : ""}`
      }

      if (years > 0 && months > 0) {
         result = `${years} year${years > 1 ? "s" : ""} and ${months} month${months > 1 ? "s" : ""}`
      }

      // if it's less than a month then show days
      if (years === 0 && months === 0 && days > 1) {
         const days = Math.floor((now.getTime() - date.getTime()) / (1000 * 3600 * 24))
         result = `${days} day${days > 1 ? "s" : ""}`
      }

      if (years === 0 && months === 0 && days === 0) {
         result = null
      }
      return result
   }

   const headerSectionProps = {
      memberSince: getMemberSince(),
      numberOfRoles: roles?.length as number,
      className: "header-section",
   }

   // RightSectionProps
   const rightSectionProps = {
      onDeleteAccount: removeUser,
      deleteAccountLoading: loading.removeUserLoading,
      className: "right-section",
      error: error.removeUserError,
   }

   // ===
   // JSX
   // ===
   return (
      <Holder>
         <Modal
            visible={!!confirmPhoneUpdateWith2faModalData}
            onClose={() => setConfirmPhoneUpdateWith2faModalData(undefined)}
            type="warning"
            className="confirm-phone-update-with-2fa-modal"
            title="Confirm Phone Update"
            description="Your are about to update your phone number, this will affect your two factor authentication method, Are you sure you want to continue?"
            actionButtons={{
               accept: {
                  label: "Yes, Update",
                  onClick: () => {
                     updateUser(confirmPhoneUpdateWith2faModalData as AccountFieldProps)
                     setConfirmPhoneUpdateWith2faModalData(undefined)
                  },
               },
               cancel: {
                  label: "Cancel",
                  onClick: () => setConfirmPhoneUpdateWith2faModalData(undefined),
               },
            }}
         />
         <HeaderSection {...headerSectionProps} />
         {userData && <AccountForm {...accountFormProps} />}
         <RightSection {...rightSectionProps} />
      </Holder>
   )
}

const Holder = styled.div`
   display: grid;
   grid-template-columns: 3fr 2fr;
   gap: 3em 6em;

   .header-section {
      grid-column: 1 / 3;
      display: grid;
      grid-template-columns: 3fr 1.8fr;
      /* border-bottom: 1px solid #e6e6e6; */
      padding-bottom: 1em;
      gap: 0em 3em;
      height: 100%;
      ${border.bottom};

      .header-section__svg {
         height: 90%;
         max-width: 100%;
         grid-column: 2 / 3;
         grid-row: 1 / 3;
         justify-self: end;
         align-self: center;
      }
   }

   .account-form {
      grid-column: 1 / 2;
      grid-row: 2 / 3;
      /* max-width: ${maxWidth.mainSection}; */

      .react-tel-input {
         margin-block: 0.5rem -0.4em;
      }

      hr {
         margin-block: 1.5em 0.8em;
      }

      h3 {
         margin-bottom: 1.2em;
      }
   }

   .right-section {
      grid-column: 2 / 3;
      grid-row: 2 / 3;
      justify-self: end;
      width: 100%;
      .right-section-item {
         display: flex;
         flex-direction: column;
         align-items: flex-end;
         margin-block: 4em;
      }
   }

   .confirm-phone-update-with-2fa-modal {
      max-width: 30em;
   }

   @media screen and (max-width: 970px) {
      gap: 2em;
      grid-template-columns: 1.2fr 1fr;
   }

   @media screen and (max-width: 800px) {
      display: flex;
      flex-direction: column;
      gap: 1em;

      .header-section {
         display: flex;
         flex-direction: column;
         align-items: flex-start;
         gap: 1em;
         margin-top: 2em;

         .header-section__svg {
            display: none;
         }
      }

      .account-form {
         max-width: 100%;
         justify-content: center;
      }

      .right-section {
         border-left: none;
         padding-left: 0;
         justify-self: center;

         .right-section-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-block: 2em;
            text-align: center;
            background-color: #f1f1f1;
            width: 100vw;
            padding: 1.3em 1em;
            margin-inline: calc(-1 * var(--padding-inline));
            box-sizing: border-box;

            &.remove {
               background-color: #fff0f0;
            }

            p {
               font-size: ${size.s};
            }

            button {
               border-color: currentColor;
               ${screen.onlyMobile} {
                  width: 100%;
               }
            }
         }
      }
   }
`

export default AccountPage
