import useModal from "hooks/useModal"
import ModalError from "./customModals/ModalError"
import ModalVerifyTwoFactorAuth from "./customModals/ModalVerifyTwoFactorAuth"
import ModalLogout from "./customModals/ModalLogout"
import ModalLoginSuspended from "./customModals/ModalLoginSuspended"
import ModalVerifyTwoFactorAuthSetup from "./customModals/ModalVerifyTwoFactorAuthSetup"
import ModalAdBlockerWarning from "./customModals/ModalAdBlockerWarning"

const ModalProvider: React.FC = () => {
   const { closeModal, modalState } = useModal()

   const { activeModal, errorMessage, logoutReason, timeoutAmount } = modalState

   const modalProps = {
      activeModal,
      onClose: closeModal,
   }

   const modalErrorProps = {
      errorMessage,
      ...modalProps,
   }

   const modalLogoutProps = {
      ...modalProps,
      logoutReason: logoutReason || "missingToken",
   }

   const modalLoginSuspendedProps = {
      ...modalProps,
      timeoutAmount: timeoutAmount || 0,
   }

   return (
      <>
         <ModalError {...modalErrorProps} />
         <ModalVerifyTwoFactorAuth {...modalProps} />
         <ModalVerifyTwoFactorAuthSetup {...modalProps} />
         <ModalLogout {...modalLogoutProps} />
         <ModalLoginSuspended {...modalLoginSuspendedProps} />
         <ModalAdBlockerWarning {...modalProps} />
      </>
   )
}

export default ModalProvider
