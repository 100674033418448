import * as Sentry from "@sentry/react"

// ==========
// SENTRY TAG
// ==========

type TagsName =
   | "2fa-enabled"
   | "selected-role"
   | "error-layout-shown"
   | "current-url"
   | "ad-blocker-detected"
   | "user-missing-email"

const sentryTag = (tag: TagsName, value: string | boolean | number | null | undefined) => {
   Sentry.setTag(tag, value)
}

export default sentryTag
