import { Route } from "react-router-dom"
import EmailVerification from "./Email"
import { SetupTwoFactorAuth } from "./TwoFactorAuth"
import { useEffect } from "react"
import PageNotFoundRouter from "layouts/PageNotFoundRouter"
import ROUTE from "routes/ROUTE"
import hideLoadingPage from "utils/hideLoadingPage"
import { SentryRoutes } from "configs/sentry"

const VerifyRouter: React.FC = () => {
   useEffect(() => {
      hideLoadingPage()
   }, [])
   return (
      <SentryRoutes>
         <Route path={ROUTE.VERIFY.EMAIL} element={<EmailVerification />} />
         <Route path={ROUTE.VERIFY.SETUP_2FA} element={<SetupTwoFactorAuth />} />
         <Route path="*" element={<PageNotFoundRouter />} />
      </SentryRoutes>
   )
}

export default VerifyRouter
