import { Integration } from "../useIntegrations"
import styled from "@emotion/styled"
import IntegrationCard, { IntegrationCardProps } from "./IntegrationCard"
import { RoleCardProps, RoleType } from "rentpost/components/other"
import { useState } from "react"
import { AnimatePresence, motion } from "framer-motion"
import IntegrationDetails from "./IntegrationDetails"
import { Button } from "@mui/material"
import { useOutletContext } from "react-router-dom"
import useProfile from "../../useProfile"
import { GetAvailableRoles_roles } from "pages/Login/__generated__"
import useLogin from "pages/Login/useLogin"
import { screen } from "rentpost/styles"

interface Props {
   integrations: Integration[]
   loading: {
      revokeAPIKey: boolean
      removeIntegration: boolean
      addAPIKey: boolean
      getIntegrations: boolean
   }
   revokeAPIKey: (id: string) => void
   removeIntegration: (id: string) => void
   onAddNewCustomKey: (integrationId: string) => void
   onAddNewZapierKey: () => void
   onAddNewIntegration: () => void
}

const AvailableIntegrations: React.FC<Props> = (props) => {
   const { integrations, onAddNewCustomKey, onAddNewZapierKey, revokeAPIKey, removeIntegration } =
      props

   // --------------- State ---------------
   const [selectedIntegrationId, setSelectedIntegrationId] = useState<string | null>(null)
   const { data } = useOutletContext() as ReturnType<typeof useProfile>
   const { getAvatarImageFromRoleData } = useLogin()

   const getAssociatedRolesFromIntegrationId = (integrationId: string): RoleCardProps["role"][] => {
      const availableRoles = data.getAvailableRolesData?.roles

      const integrationRolesIds = integrations
         .find((integration) => integration.id === integrationId)
         ?.authTokens.map((token) => token.roleId)

      const mapRoles = (role: GetAvailableRoles_roles): RoleCardProps["role"] => {
         const { __typename, type, avatar, address, ...rest } = role
         return {
            avatarImage: getAvatarImageFromRoleData(role),
            ...rest,
            type: type as RoleType,
         }
      }

      const integrationRoles = availableRoles
         ?.filter((role) => integrationRolesIds?.includes(role.id))
         ?.map(mapRoles)

      return integrationRoles as RoleCardProps["role"][]
   }

   const integrationDetailsProps = {
      integration: integrations.find(
         (integration) => integration.id === selectedIntegrationId
      ) as Integration,
      loading: props.loading,
      associatedRoles: getAssociatedRolesFromIntegrationId(selectedIntegrationId as string),
      revokeAPIKey,
      removeIntegration,
      onGoBack: () => setSelectedIntegrationId(null),
      onAddNewCustomKey: () => onAddNewCustomKey(selectedIntegrationId as string),
      onAddNewZapierKey,
   }

   return (
      <Holder>
         <div className="left-section">
            <AnimatePresence mode="popLayout">
               {!selectedIntegrationId && (
                  <motion.div
                     animate={{ opacity: 1 }}
                     initial={{ opacity: 0 }}
                     exit={{ opacity: 0 }}
                     transition={{ duration: 0.4 }}
                     key="integrations-holder"
                  >
                     <div className="integrations-holder">
                        {integrations.map((integration) => {
                           const { name, description, type, authTokens, id } = integration
                           const integrationCardProps: IntegrationCardProps = {
                              name,
                              description,
                              numberOfActiveKeys: authTokens.length,
                              integrationType: type,
                              onClick: () => setSelectedIntegrationId(integration.id),
                              associatedRoles: getAssociatedRolesFromIntegrationId(id),
                           }

                           return <IntegrationCard {...integrationCardProps} key={id} />
                        })}
                     </div>
                     <Button
                        variant="contained"
                        color="primary"
                        onClick={props.onAddNewIntegration}
                        style={{
                           borderColor: "currentcolor",
                           marginTop: "2em",
                        }}
                        className="add-new-integration-button"
                     >
                        + Add New Integration
                     </Button>
                  </motion.div>
               )}
               {selectedIntegrationId && (
                  <motion.div
                     animate={{ x: 0 }}
                     initial={{ x: "-100%" }}
                     exit={{ x: "-100%" }}
                     transition={{
                        duration: 0.4,
                        type: "spring",
                     }}
                     key="integration-details"
                  >
                     <IntegrationDetails {...integrationDetailsProps} />
                  </motion.div>
               )}
            </AnimatePresence>
         </div>
         <div className="marketing-holder">
            {/* //**_O_ TODO add marketing content here!!!!   */}
            {/* //**_O_ TODO add marketing content here!!!!   */}
            {/* //**_O_ TODO add marketing content here!!!!   */}
         </div>
      </Holder>
   )
}

const Holder = styled.div`
   display: grid;
   grid-template-columns: 1fr max(30%, 24em);
   gap: 0em 2em;
   .left-section {
      position: relative;
      overflow: hidden;
      width: 100%;
      box-sizing: border-box;
      .integrations-holder {
         position: relative;
         display: flex;
         flex-direction: column;
         gap: 2em;
      }
   }

   /* Responsiveness */
   @media (max-width: 900px) {
      display: flex;
      flex-direction: column;
      gap: 2em;

      .main-description {
         margin-bottom: 0em;
      }

      ${screen.onlyMobile} {
         .add-new-integration-button {
            width: 100%;
         }
      }
   }
`

export type { Props as AvailableIntegrationsProps }
export default AvailableIntegrations
