import { useMutation, useLazyQuery } from "@apollo/client"
import { gql } from "@apollo/client"
import usePOEToken from "hooks/usePOEToken"
import auth from "utils/auth"
import {
   Login,
   LoginVariables,
   SelectRole,
   SelectRoleVariables,
   GetAvailableRoles,
} from "./__generated__"
import { LoginFieldsProp } from "./LoginForm"
import getPASETOFooter from "utils/getPASETOFooter"
import bypassMockError from "utils/bypassMockError"
import { useState } from "react"
import { RoleCardProps, RoleType } from "rentpost/components/other/RoleCard"
// @ts-ignore
import env from "configs/env"
import { useErrorBoundary } from "react-error-boundary"
import { MissingDataError } from "configs/error/customErrors"
import { getStoredParams } from "utils/redirectTo"
import { useNavigate } from "react-router-dom"
import { setZendeskJWT } from "configs/zendesk/initZendesk"
import { sentryTag } from "configs/sentry"

// ========
// MUTATION
// ========
const LoginMutation = gql`
   mutation Login($input: LoginInput!) {
      login(input: $input) {
         token
      }
   }
`

const SelectRoleMutation = gql`
   mutation SelectRole($id: ID!) {
      assumeRole(id: $id) {
         token
      }
   }
`

// =====
// QUERY
// =====
const GetAvailableRolesQuery = gql`
   query GetAvailableRoles {
      ## Get the current user's role
      roles {
         type
         displayName
         email
         id
         status
         address {
            street
            city
            state
            postalCode
            aptSuiteEtc
         }
         avatar {
            url
            droplet {
               children {
                  url
               }
            }
         }
         badges
         companyName
         isOnboardingRequired
      }
   }
`

export { LoginMutation, GetAvailableRolesQuery, SelectRoleMutation }

// ===============
// USE LOGIN HOOK
// ===============
const useLogin = () => {
   const [multiRoles, setMultiRoles] = useState<RoleCardProps[] | null>(null)
   const [hasNoRoles, setHasNoRoles] = useState<boolean>(false)
   const [showStartOnboardingModal, setShowStartOnboardingModal] = useState<boolean>(false)
   const { showBoundary } = useErrorBoundary()
   const navigate = useNavigate()

   // =========
   // MUTATIONS
   // =========
   const [runLogin, { loading: loginLoading, error: loginError }] = useMutation<
      Login,
      LoginVariables
   >(LoginMutation)

   const [runSelectRole, { loading: selectRoleLoading, error: selectRoleError }] = useMutation<
      SelectRole,
      SelectRoleVariables
   >(SelectRoleMutation)

   // =======
   // QUERIES
   // =======
   const [runGetRoleDetails, { loading: getRoleDetailsLoading, error: getRoleDetailsError }] =
      useLazyQuery<GetAvailableRoles>(GetAvailableRolesQuery)

   // POE Token hook
   const { poeToken, refreshPOEToken } = usePOEToken()

   // -------------------
   // Get available roles
   // -------------------
   const getRoles = () => {
      runGetRoleDetails({ context: { showErrorModal: true } })
         .then(({ data }) => {
            if (!data?.roles) {
               showBoundary(
                  new MissingDataError(
                     `Roles response data has (${data?.roles}) value`,
                     "OperationName: getRoles - useLogin.ts"
                  )
               )
               return
            }
            // Also make sure that user doesn't have onboarding required (ONLY for single role users)
            if (data?.roles.length === 1 && data?.roles[0].isOnboardingRequired) {
               setShowStartOnboardingModal(true)
               return
            }
            // Check for redirect after login
            redirectAfterLogin()

            // ---------------------------
            // Checking roles for the user
            // ---------------------------
            if (data?.roles === null || data?.roles?.length === 0) {
               setHasNoRoles(true)
               return
            }
            const { roles } = data

            // Check if user has multiple roles and set it to state
            if (roles.length === 1) {
               // If only one role, select it automatically and login
               const roleType = roles[0].type as RoleType
               const roleID = roles[0].id
               const isOnboardingRequired = roles[0].isOnboardingRequired

               selectRole(roleID, roleType, isOnboardingRequired)
            } else if (roles.length > 1) {
               const roleItems: RoleCardProps[] = roles
                  .map((role) => {
                     // Ignore roles with status other than "Active"
                     if (role.status !== "Active") return null
                     const {
                        type,
                        id,
                        email,
                        displayName,
                        companyName,
                        badges,
                        isOnboardingRequired,
                     } = role
                     return {
                        role: {
                           id,
                           type,
                           address: getAddressFromRoleData(role),
                           email,
                           avatarImage: getAvatarImageFromRoleData(role),
                           displayName,
                           companyName,
                           badges,
                        },
                        isOnboardingRequired,
                     } as RoleCardProps
                  })
                  .filter((item) => item) as RoleCardProps[]
               setMultiRoles(roleItems)
            }
            refreshPOEToken()
         })
         .catch(bypassMockError)
   }

   // ----------------
   // Select user role
   // ----------------
   const selectRole = (id: string, type: RoleType, isOnboardingRequired: boolean) => {
      const environment = env.TARGET_ENV
      const proAppBaseURL = (() => {
         switch (environment) {
            case "development":
               return "https://pro.rentpost.test"
            case "stage":
               return "https://pro.stage.rentpost.com"
            case "production":
               return "https://pro.rentpost.com"
            default:
               return "https://pro.rentpost.com"
         }
      })()
      const selectedRolePortalURL = (() => {
         switch (type) {
            case "tenant":
               return `${proAppBaseURL}/myrent`
            case "manager":
               return `${proAppBaseURL}/mypost`
            case "owner":
               return `${proAppBaseURL}/myplace`
            case "employee":
               return `${proAppBaseURL}/control`
            default:
               return ""
         }
      })()
      runSelectRole({
         context: {
            poeToken: poeToken.current,
         },
         variables: {
            id,
         },
      })
         .then(({ data }) => {
            if (data?.assumeRole?.token) {
               const token = data.assumeRole.token
               const expiration = getPASETOFooter(token).expiration
               auth.setAccessToken(token, expiration)
            }
            refreshPOEToken()
            if (isOnboardingRequired) {
               setShowStartOnboardingModal(true)
            } else {
               window.location.href = selectedRolePortalURL
            }
         })
         .catch(bypassMockError)
   }

   // -------------------------------
   // Get avatar image from role data
   // -------------------------------
   const getAvatarImageFromRoleData = (
      role: GetAvailableRoles["roles"][0] | undefined
   ): string | undefined => {
      if (!role) return undefined
      const avatarImage = role.avatar?.droplet?.children[1]?.url || role.avatar?.url
      return avatarImage || undefined
   }

   // --------------------------
   // Get address from role data
   // --------------------------
   const getAddressFromRoleData = (
      role: GetAvailableRoles["roles"][0] | undefined
   ): string | null => {
      if (!role) return null

      const address = role.address
         ? role.address?.aptSuiteEtc +
           " " +
           role.address?.street +
           ", " +
           role.address?.city +
           ", " +
           role.address?.state +
           " " +
           role.address?.postalCode
         : null
      return address
   }

   // -----
   // Login
   // -----
   const login = (values: LoginFieldsProp) => {
      const { username, password } = values
      return runLogin({
         context: {
            poeToken: poeToken.current,
         },
         variables: {
            input: {
               password,
               username,
            },
         },
      })
         .then(({ data }) => {
            if (!data || !data.login.token) {
               showBoundary(
                  new MissingDataError(
                     `Login response data has (${data?.login?.token}) value`,
                     "OperationName: login - useLogin.ts"
                  )
               )
               return
            }

            // Set access token in cookies
            const token = data.login.token
            const expiration = getPASETOFooter(token).expiration
            auth.setAccessToken(token, expiration, values.rememberMe)
            // Set Zendesk JWT after login
            setTimeout(() => {
               // Wait for the token to be set in the cookies
               setZendeskJWT()
            }, 500)
            refreshPOEToken()
            // Check for missing email in token and redirect to account page if missing, so user can add it there before proceeding
            checkForMissingEmail(data.login.token)
            getRoles()
         })
         .catch(bypassMockError)
   }

   // --------
   // Redirect
   // --------
   const redirectAfterLogin = () => {
      // This one runs only in `/login` page and ONLY when there is a `return_to` query param in the URL
      const searchParams = new URLSearchParams(location.search)
      const returnToParam = searchParams.get("return_to")

      if (!returnToParam) {
         return // Do nothing if there is no return_to param
      }

      const url = new URL(returnToParam)
      const pathname = url.pathname
      const params = getStoredParams()
      const isInsideAccountApp = new URL(returnToParam).hostname.includes("account")
      isInsideAccountApp
         ? navigate(pathname + (params ? "?" + params : ""), { replace: true })
         : (window.location.href = returnToParam + (params ? "?" + params : ""))
   }

   // -----------------------
   // Check for missing email
   // -----------------------
   const checkForMissingEmail = (token: string) => {
      const emailInToken = getPASETOFooter(token).email
      if (!emailInToken) {
         sentryTag("user-missing-email", true)
         navigate("/account?email-missing=1")
         return
      }
   }

   return {
      login,
      loginLoading,
      loginError,
      selectRoleLoading,
      selectRoleError,
      getRoleDetailsLoading,
      getRoleDetailsError,
      multiRoles,
      hasNoRoles,
      showStartOnboardingModal,
      selectRole,
      getRoles,
      getAvatarImageFromRoleData,
      getAddressFromRoleData,
      setShowStartOnboardingModal,
   }
}

export default useLogin
