import { signUpIllustrationSVG } from "./SignupIllustrationSVG"
import rentpostSVG from "rentpost/assets/svg/index"
import TwoFactorAuthSVG from "./TwoFactorAuthSVG"
import MergeAccountIllustration from "./MergeAccountIllustration"

const SVG = {
   signUpIllustrationSVG,
   TwoFactorAuthSVG,
   MergeAccountIllustration,
   ...rentpostSVG,
}

export default SVG
