import { Modal, ModalProps } from "layouts"
import useTwoFactorAuth from "pages/Verify/TwoFactorAuth/useTwoFactorAuth"
import TwoFactorAuthModal from "pages/Verify/TwoFactorAuth/Setup/TwoFactorAuthModal"
import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { clearOperation } from "redux/slices/graphql_2FA_RequiredOperationSlice"
import TwoFactorAuthSetupModalComponent from "pages/Verify/TwoFactorAuth/Setup/TwoFactorAuthSetupModal"

// ================
// Modal Verify 2FA
// ================
interface ModalVerifyTwoFactorAuthProps {
   activeModal: string | false
   onClose: () => void
}
const ModalVerifyTwoFactorAuthSetup: React.FC<ModalVerifyTwoFactorAuthProps> = ({
   activeModal,
   onClose,
}) => {
   const {
      verifyTwoFactorAuth,
      error: { verifyTwoFactorAuthError, resendTwoFactorAuthCodeError, disableTwoFactorAuthError },
      loading: {
         verifyTwoFactorAuthLoading,
         resendTwoFactorAuthCodeLoading,
         disableTwoFactorAuthLoading,
      },
      verifyOperationStatus,
      resendTwoFactorAuthCode,
      disableTwoFactorAuth,
      attemptCount,
      settings,
   } = useTwoFactorAuth()

   // Data Props
   const twoFactorAuthModalProps = {
      verifyTwoFactorAuth,
      disableTwoFactorAuth,
      resendTwoFactorAuthCode,
      verifyTwoFactorAuthError,
      disableTwoFactorAuthError,
      resendTwoFactorAuthCodeError,
      verifyTwoFactorAuthLoading,
      disableTwoFactorAuthLoading,
      resendTwoFactorAuthCodeLoading,
      settings,
      attemptCount,
      onClose,
   }

   // dispatch clearOperationStatus when the modal is closed
   const dispatch = useDispatch()
   const clearOperationFromReduxStore = () => dispatch(clearOperation())

   const handleCloseModal = () => {
      clearOperationFromReduxStore()
      onClose()
   }

   // Modal Props
   const modalProps: ModalProps = {
      visible: activeModal === "twoFactorAuthSetup",
      onClose: handleCloseModal,
      fullscreenOnMobile: true,
      renderComponent: {
         show: true,
         component: () => <TwoFactorAuthSetupModalComponent {...twoFactorAuthModalProps} />,
      },
   }

   // Close the modal if the user successfully verified the 2FA
   useEffect(() => {
      verifyOperationStatus && onClose()
   }, [verifyOperationStatus])

   // ===
   // JSX
   // ===
   return <Modal {...modalProps} />
}

export default ModalVerifyTwoFactorAuthSetup
