import { Route } from "react-router-dom"
import { useEffect } from "react"
import PageNotFoundRouter from "layouts/PageNotFoundRouter"
import ResetPassword from "./ResetPassword"
import ROUTE from "routes/ROUTE"
import hideLoadingPage from "utils/hideLoadingPage"
import { SentryRoutes } from "configs/sentry"

const SignInAssistanceRouter: React.FC = () => {
   useEffect(() => {
      hideLoadingPage()
   }, [])
   return (
      <SentryRoutes>
         <Route path={ROUTE.SIGN_IN_ASSISTANCE.PASSWORD} element={<ResetPassword />} />
         <Route path="*" element={<PageNotFoundRouter />} />
      </SentryRoutes>
   )
}

export default SignInAssistanceRouter
