import NavigationBar, { NavigationBarProps } from "../../layouts/NavigationBar"
import styled from "@emotion/styled"
import { color, screen, size, space } from "rentpost/styles"
import { Link, Outlet, useLocation } from "react-router-dom"
import { FooterLinks } from "rentpost/components/form"
import backgroundImage from "assets/images/profile-background.png"
import { AnimatePresence } from "framer-motion"
import useProfile from "./useProfile"
import AnimatedRoute from "components/functional/AnimatedRoute"
import Skeleton from "./Skeleton"
import UserMenuComponent from "../../components/webComponents/UserMenu"
import Helmet from "react-helmet"
import { useEffect, useState } from "react"
import { maxWidth } from "rentpost/styles/size"
import ROUTE from "routes/ROUTE"
import hideLoadingPage from "utils/hideLoadingPage"
import { Button } from "@mui/material"

const Profile = () => {
   const location = useLocation()
   const [showIntegrationsPage, setShowIntegrationsPage] = useState(false)

   const links: NavigationBarProps["links"] = [
      {
         label: "Overview",
         to: ROUTE.PROFILE.OVERVIEW,
      },
      {
         label: "Account",
         to: ROUTE.PROFILE.ACCOUNT,
      },
      {
         label: "Security",
         to: ROUTE.PROFILE.SECURITY,
      },
      {
         label: "Roles",
         to: ROUTE.PROFILE.ROLES,
      },
      ...(showIntegrationsPage
         ? [
              {
                 label: "Integrations",
                 to: ROUTE.PROFILE.INTEGRATIONS,
              },
           ]
         : []),
   ]

   // get the last part of the pathname, and use it to determine which page to show in the navigation bar
   const outletContext = useProfile({ runInitialQueries: true })

   // show the skeleton when the outletContext is not ready
   const isDataReady =
      outletContext.data.getAvailableRolesData !== undefined &&
      outletContext.data.userData !== undefined &&
      outletContext.twoFactorAuthEnabled !== undefined &&
      outletContext.data.getTimezonesData !== undefined

   // if the user has the employee role, show the integrations page
   const hasEmployeeRole = outletContext.data.getAvailableRolesData?.roles?.some(
      ({ type }) => type === "employee"
   )
   const hasManagerRole = outletContext.data.getAvailableRolesData?.roles?.some(
      ({ type }) => type === "manager"
   )

   useEffect(() => {
      if (hasEmployeeRole || hasManagerRole) {
         setShowIntegrationsPage(true)
      }
   }, [hasEmployeeRole, hasManagerRole])

   // hide the loading page when the outletContext is ready
   isDataReady && hideLoadingPage()

   // get the last part of the pathname, and use it to determine which page to show in the navigation bar, convert to pascel case
   const profilePortalName = location.pathname.split("/").pop()?.replace(/-/g, " ")
   const profilePortalNamePascalCase = profilePortalName?.replace(/\w\S*/g, (w) =>
      w.replace(/^\w/, (c) => c.toUpperCase())
   )

   return (
      <Holder>
         <Helmet>
            <title>{profilePortalNamePascalCase} | RentPost</title>
         </Helmet>
         <NavigationBar links={links} rightSideComponent={<UserMenu />} />
         <AnimatePresence mode="wait">
            <div className="profile_page_holder">
               {isDataReady && (
                  <AnimatedRoute nestedKey={location.pathname}>
                     <Outlet
                        context={{ ...outletContext } satisfies ReturnType<typeof useProfile>}
                        key={location.pathname}
                     />
                  </AnimatedRoute>
               )}
               {!isDataReady && (
                  <AnimatedRoute nestedKey="skelton">
                     <Skeleton />
                  </AnimatedRoute>
               )}
            </div>
         </AnimatePresence>
         <footer data-testid="footer">
            <div className="footer-container">
               <span className="copy-rights">
                  Copyright © {new Date().getFullYear()} RentPost, Inc. All rights reserved.
               </span>
               <FooterLinks />
            </div>
         </footer>
         <img src={backgroundImage} alt="background-lines" className="background-lines" />
      </Holder>
   )
}

const UserMenu = () => (
   <UserMenuComponent>
      <Link to={ROUTE.LOGIN} slot="not-logged-in">
         <Button variant="outlined" color="secondary" size="small">
            Log In
         </Button>
      </Link>
   </UserMenuComponent>
)

const Holder = styled.div`
   display: flex;
   flex-direction: column;
   overflow-x: hidden;
   overflow-y: auto;
   scrollbar-gutter: stable;
   margin-top: 5em;
   --padding-inline: ${space.l};
   .background-lines {
      position: fixed;
      bottom: 0;
      left: 0;
      height: 90%;
      object-fit: cover;
      z-index: -1;
      max-width: 100%;
      user-select: none;
      opacity: 0.6;
   }

   .profile_page_holder {
      height: 100%;
      max-width: ${maxWidth.profilePages};
      min-width: min(${maxWidth.profilePages}, 100%);
      margin: 0 auto;
      min-height: calc(100dvh - 9.4em);
      padding-inline: var(--padding-inline);
      padding-block: ${space.l};
      box-sizing: border-box;
   }

   footer {
      display: flex;
      justify-content: center;
      border-top: 1px solid #f3f3f3;
      background-color: ${color.white};
      .footer-container {
         flex: 1;
         padding: 1.4em var(--padding-inline);
         max-width: ${maxWidth.profilePages};
         box-sizing: border-box;
         display: flex;
         flex-wrap: wrap;
         justify-content: space-between;
         align-items: center;

         .copy-rights {
            font-size: ${size.xs};
         }

         @media screen and (max-width: 600px) {
            justify-content: space-around;
         }
      }
   }

   ${screen.tabletAndMobile} {
      margin-top: 4em;
      .profile_page_holder {
         min-height: calc(100dvh - 7.6em);
         margin: initial;
      }

      @media screen and (max-width: 770px) {
         margin-top: 3em;
         footer {
            .footer-container {
               gap: 0.4em;
               justify-content: space-around;
            }
         }
      }
   }

   ${screen.onlyMobile} {
      --padding-inline: ${space.m};
   }
`
export default Profile
