import { useNavigate } from "react-router-dom"
import { Avatar, Card } from "rentpost/components/other"

interface Props {
   roles: { displayName: string; avatarImage: string | null }[]
   className?: string
}

const RolesCard = ({ roles, className }: Props) => {
   const navigate = useNavigate()
   return (
      <Card
         content={
            <div
               style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: "1em",
               }}
            >
               <p>
                  Your account can have multiple roles.  Each role gives you access to a specific
                  account/portal and the ability to perform actions within that context, or "role".
               </p>
               <Avatar roles={roles} maxAvatarsToShow={3} isAvatarsGroup />
            </div>
         }
         label="Associated Roles"
         icon={
            <svg viewBox="0 0 50 39" fill="none" xmlns="http://www.w3.org/2000/svg">
               <mask
                  id="mask0_546_1136"
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="50"
                  height="33"
               >
                  <path
                     d="M0.394531 4.06099C0.394531 2.15134 1.9426 0.603271 3.85225 0.603271H46.2093C48.1189 0.603271 49.667 2.15134 49.667 4.06099V28.6972C49.667 30.6069 48.1189 32.1549 46.2093 32.1549H3.85225C1.9426 32.1549 0.394531 30.6069 0.394531 28.6972V4.06099Z"
                     fill="#4485C5"
                  />
               </mask>
               <g mask="url(#mask0_546_1136)">
                  <rect x="-1.51758" y="0.603271" width="53.5422" height="10.5172" fill="#3E7BB8" />
               </g>
               <path
                  d="M0.394531 10.7539C0.394531 8.84422 1.9426 7.29614 3.85225 7.29614H46.2093C48.1189 7.29614 49.667 8.84422 49.667 10.7539V35.3901C49.667 37.2997 48.1189 38.8478 46.2093 38.8478H3.85225C1.9426 38.8478 0.394531 37.2997 0.394531 35.3901V10.7539Z"
                  fill="#4485C5"
               />
               <path d="M26.3281 16.8049H44.4811V20.6949H26.3281V16.8049Z" fill="#A9D4FF" />
               <path d="M26.3281 25.4492H44.4811V29.3392H26.3281V25.4492Z" fill="#A9D4FF" />
               <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M10.0456 18.1734C10.0456 17.1799 10.4403 16.2271 11.1427 15.5246C11.8452 14.8221 12.798 14.4275 13.7915 14.4275C14.7849 14.4275 15.7377 14.8221 16.4402 15.5246C17.1427 16.2271 17.5373 17.1799 17.5373 18.1734C17.5373 19.1668 17.1427 20.1196 16.4402 20.8221C15.7377 21.5246 14.7849 21.9192 13.7915 21.9192C12.798 21.9192 11.8452 21.5246 11.1427 20.8221C10.4403 20.1196 10.0456 19.1668 10.0456 18.1734ZM10.0456 23.7921C8.80378 23.7921 7.61281 24.2855 6.7347 25.1636C5.8566 26.0417 5.36328 27.2326 5.36328 28.4745C5.36328 29.2196 5.65927 29.9341 6.18613 30.461C6.713 30.9879 7.42758 31.2839 8.17268 31.2839H19.4103C20.1554 31.2839 20.8699 30.9879 21.3968 30.461C21.9237 29.9341 22.2197 29.2196 22.2197 28.4745C22.2197 27.2326 21.7263 26.0417 20.8482 25.1636C19.9701 24.2855 18.7792 23.7921 17.5373 23.7921H10.0456Z"
                  fill="white"
               />
            </svg>
         }
         linkLabel="Manage Roles"
         linkIcon="arrow"
         onClick={() => navigate("/roles")}
         className={className}
      />
   )
}

export default RolesCard
