import SVG from "rentpost/assets/svg"
import Modal, { ModalProps } from "rentpost/layouts/Modal"
import styled from "@emotion/styled"
import { Form, SubmitButton, TextField } from "rentpost/components/form"
import * as yup from "yup"
import { color, font, size } from "rentpost/styles"
import { Button } from "@mui/material"
import { ApolloError } from "@apollo/client"

interface DeleteAccountModalProps {
   visible: boolean
   onClose: () => void
   onDeleteAccount: (reason: string) => void
   loading: boolean
   error: ApolloError | undefined
}
const DeleteAccountModal: React.FC<DeleteAccountModalProps> = ({
   visible,
   onClose,
   onDeleteAccount,
   loading,
   error,
}) => {
   const modalProps: ModalProps = {
      visible,
      onClose,
      title: "Delete Account",
      fullscreenOnMobile: true,
      canIgnore: true,
      renderComponent: {
         show: true,
         component: () => {
            const formProps = {
               initialValues: {
                  confirmationText: "",
                  reason: "",
               },
               validationSchema: yup.object({
                  confirmationText: yup
                     .string()
                     .required("Confirmation text is required!")
                     .test("confirmation-text", "Confirmation text does not match!", (value) => {
                        const expectedText = `I UNDERSTAND THAT I AM DELETING MY ACCOUNT`
                        return value === expectedText
                     }),
                  reason: yup.string().required("Reason is required!"),
               }),
               onSubmit: (values: any) => onDeleteAccount(values.reason),
               loading,
               error,
               showErrorHint: true,
            }
            return (
               <Holder>
                  <SVG.modal.warning className="modal-logo" />
                  <h2>Close Account</h2>
                  <p className="modal-description">
                     Are you sure you want to delete your account?
                     <br /> This action cannot be undone.
                  </p>
                  <span className="confirmation-text">
                     Please type the following text to confirm: <br />
                     <strong>I UNDERSTAND THAT I AM DELETING MY ACCOUNT</strong>
                  </span>
                  <Form {...formProps}>
                     <TextField name="confirmationText" label="Confirmation Text" />
                     <TextField name="reason" label="Reason" />
                     <div className="modal-action-buttons">
                        <Button variant="outlined" color="secondary" onClick={onClose}>
                           Cancel
                        </Button>
                        <SubmitButton> Close Account</SubmitButton>
                     </div>
                  </Form>
               </Holder>
            )
         },
      },
   }

   return <Modal {...modalProps} />
}
const Holder = styled.div`
   display: flex;
   flex-direction: column;
   align-items: center;
   gap: 1em;
   max-width: 25em;

   .modal-logo {
      width: 7em;
      margin-block: 1.4em;
   }
   h2 {
      color: ${color.red};
      font-size: ${size.l};
      margin: 0;
   }
   p.modal-description {
      margin-top: 0;
      text-align: center;
   }

   span.confirmation-text {
      font-size: ${size.m};
      padding: 1em 1.5em;
      text-align: center;
      color: ${color.grayDark};
      border: 1px solid #ffd7d4;
      border-radius: 0.7em;
      background-color: #fff2f2;

      user-select: none;

      strong {
         ${font.bold};
         color: ${color.red};
         margin-block: 0.7em;
         display: block;
      }
   }
`

export default DeleteAccountModal
