import { ApolloError } from "@apollo/client"
import useTriggerHint from "hooks/useTriggerHint"
import { Form, Hint, PasswordField, SubmitButton, TextField } from "rentpost/components/form"
import * as yup from "yup"

interface MergeLoginFormFields {
   username: string
   password: string
}

const initialValues: MergeLoginFormFields = {
   username: "",
   password: "",
}

const validationSchema = yup.object({
   username: yup.string().required("Email or Username is required!"),
   password: yup.string().required("Password is required!"),
})

interface MergeLoginFormProps {
   onSubmit: (values: MergeLoginFormFields) => void
   error: ApolloError | undefined
   isUsingSameUsername: boolean | null
   loading: boolean
}

const MergeLoginForm = (props: MergeLoginFormProps) => {
   const allProps = { ...props, initialValues, validationSchema, showErrorHint: true }
   const [triggerErrorHint, onFocus] = useTriggerHint(props.isUsingSameUsername)
   return (
      <Form {...allProps} onFormFocus={onFocus}>
         {props.isUsingSameUsername && (
            <Hint
               text="You cannot merge the same account. Please try again with a different account."
               type="error"
               triggerAutoHide={triggerErrorHint}
            />
         )}
         <TextField name="username" label="Username or Email" />
         <PasswordField name="password" label="Password" />
         <SubmitButton>Sign in</SubmitButton>
      </Form>
   )
}

export type { MergeLoginFormFields }
export default MergeLoginForm
