import * as Sentry from "@sentry/react"
import _ from "lodash"

interface GraphQlBreadcrumbProps {
   operationName: string
   statusCode: number
}

const graphQlSentryBreadcrumb = ({ operationName, statusCode }: GraphQlBreadcrumbProps) => {
   // Add breadcrumb to Sentry
   return Sentry.addBreadcrumb({
      category: "graphql",
      level: statusCode === 200 ? "info" : "error",
      message: `${operationName} - [${statusCode}]`,
   })
}

export { graphQlSentryBreadcrumb }
