import { useOutletContext } from "react-router-dom"
import useProfile from "../useProfile"
import styled from "@emotion/styled"
import UserCard from "./UserCard"
import TimezoneCard from "./TimezoneCard"
import SecurityCard from "./SecurityCard"
import RolesCard from "./RolesCard"
import { GetAvailableRoles_roles_avatar } from "pages/Login/__generated__"
import { color } from "rentpost/styles"

const OverviewPage = () => {
   const {
      twoFactorAuthEnabled: isTwoFactorAuthEnabled,
      data,
      updateUser,
      loading,
      timezone,
   } = useOutletContext() as ReturnType<typeof useProfile>
   const { userData, getAvailableRolesData, getTimezonesData } = data
   const { firstName, email, lastName, phone, createdAt } = userData!

   // =====
   // PROPS
   // =====

   // userCardProps
   const userCardProps = {
      firstName: firstName as string,
      lastName: lastName as string,
      email,
      phone,
      createdAt,
   }

   // timezoneCardProps
   const timezoneCardProps = {
      timezone,
      timezonesArray: getTimezonesData?.timezones as string[],
      onChange: (timezone: string) => updateUser({ timezone }),
      loading: loading.updateUserLoading,
   }

   // securityCardProps
   const securityCardProps = {
      is2FAEnabled: isTwoFactorAuthEnabled as boolean,
   }

   // rolesCardProps
   const getAvatarURL = (avatar: GetAvailableRoles_roles_avatar | null) => {
      if (!avatar) return null
      if (avatar.droplet) return avatar.droplet?.children[0]?.url || avatar?.url || null
      if (avatar.url) return avatar?.url || null
   }
   const rolesFormatted = getAvailableRolesData?.roles.map(({ displayName, avatar }) => {
      return { displayName, avatarImage: getAvatarURL(avatar) }
   })
   const rolesCardProps = {
      roles: rolesFormatted as { displayName: string; avatarImage: string }[],
      onAvatarsLoadComplete: () => console.log("onAvatarsLoadComplete"),
   }

   // ===
   // JSX
   // ===
   return (
      <Holder>
         <h1>Welcome back {firstName}!</h1>
         <UserCard {...userCardProps} className="user-card" />
         <TimezoneCard {...timezoneCardProps} className="timezone-card" />
         <SecurityCard {...securityCardProps} className="security-card" />
         <RolesCard {...rolesCardProps} className="roles-card" />
      </Holder>
   )
}

const Holder = styled.div`
   display: grid;
   grid-template-columns: 1fr 1fr 1.2fr;
   grid-template-rows: 4em 1fr 1fr;
   grid-gap: 2em;

   h1 {
      grid-column: 1 / 4;
      grid-row: 1 / 2;
      color: ${color.violet};
   }

   .user-card {
      grid-column: 3 / 4;
      grid-row: 2 / 4;
   }

   .timezone-card {
      grid-column: 1 / 2;
      grid-row: 2 / 3;
      z-index: 2;
   }

   .security-card {
      grid-column: 2 / 3;
      grid-row: 2 / 3;
   }

   .roles-card {
      grid-column: 1 / 3;
      grid-row: 3 / 4;
   }

   /* Responsiveness layout */
   @media screen and (max-width: 1200px) {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 4em 1fr 1fr 1fr;

      .user-card {
         grid-column: 2 / 3;
         grid-row: 2 / 4;
      }

      .timezone-card {
         grid-column: 1 / 2;
         grid-row: 2 / 3;
      }

      .security-card {
         grid-column: 1 / 2;
         grid-row: 3 / 4;
      }

      .roles-card {
         grid-column: 1 / 3;
         grid-row: 4 / 5;
      }
   }

   @media screen and (max-width: 800px) {
      display: flex;
      flex-direction: column;
   }
`

export default OverviewPage
