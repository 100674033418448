import {
   PasswordField,
   TextField,
   SubmitButton,
   HorizontalLine,
   Form,
} from "rentpost/components/form"
import useSignUp from "./useSignUp"
import * as yup from "yup"

interface signUpFieldsProp {
   firstName: string
   lastName: string
   email: string
   password: string
   passwordConfirm: string
   companyName: string
}
const initialValues: signUpFieldsProp = {
   firstName: "",
   lastName: "",
   email: "",
   password: "",
   passwordConfirm: "",
   companyName: "",
}
const validationSchema = yup.object({
   companyName: yup
      .string()
      .required("Company name is required!")
      .min(3, "Company name must be at least 3 characters!"),
   firstName: yup
      .string()
      .required("First name is required!")
      .min(3, "First name must be at least 3 characters!"),
   lastName: yup
      .string()
      .required("Last name is required!")
      .min(3, "Last name must be at least 3 characters!"),
   email: yup.string().email("Invalid email address").required("Email is required!"),
   password: yup
      .string()
      .required("Password is required!")
      .min(8, "Password must be at least 8 characters!")
      .matches(/[A-Z]/, "Password must contain one uppercase!")
      .matches(/[a-z]/, "Password must contain one lowercase!")
      .matches(/[0-9]/, "Password must contain one number!")
      .matches(
         /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
         "Password must contain one special character!"
      ),
   passwordConfirm: yup
      .string()
      .required("Need to confirm password!")
      .oneOf([yup.ref("password"), null], "Passwords must match!"),
})

const SignUpForm = () => {
   const { signUp, loading, error } = useSignUp()
   const onSubmit = (values: signUpFieldsProp) => {
      const { companyName, ...rest } = values
      signUp(companyName, rest)
   }

   // ===
   // JSX
   // ===
   return (
      <Form
         initialValues={initialValues}
         onSubmit={onSubmit}
         validationSchema={validationSchema}
         error={error}
         loading={loading}
         showErrorHint
      >
         <div className="company-name box">
            <span className="italic gray">
               Please enter the name of your company. This will be used to create your account.
            </span>
            <TextField name="companyName" label="Company Name" />
         </div>
         <TextField name="firstName" label="First Name" />
         <TextField name="lastName" label="Last Name" />
         <HorizontalLine />
         <TextField name="email" type="email" label="Email" />
         <PasswordField name="password" />
         <PasswordField name="passwordConfirm" label="Confirm Password" />
         <SubmitButton>Get Started</SubmitButton>
      </Form>
   )
}

export type { signUpFieldsProp }
export default SignUpForm
