import { AnimateWithVariants } from "rentpost/components/functional"

interface Props {
   children: React.ReactNode
   isFast?: boolean
   className?: string
   nestedKey: string
}

const AnimatedRoute: React.FC<Props> = (props) => {
   return (
      <AnimateWithVariants
         animate={props.isFast ? "pageAnimateFast" : "pageAnimate"}
         initial="pageInitial"
         exit={props.isFast ? "pageInitialFast" : "pageInitial"}
         className={props.className}
         key={props.nestedKey}
      >
         {props.children}
      </AnimateWithVariants>
   )
}

export default AnimatedRoute
