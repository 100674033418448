import { Form } from "rentpost/components/form"
import { AddZapierAPIKeyComponentProps } from "."
import { AddZapierAPIKeyFormFields } from "../../useIntegrations"
import { FormProps } from "rentpost/components/form/Form"
import { MiniRoleCard } from "rentpost/components/other"
import { Button, CircularProgress } from "@mui/material"
import _ from "lodash"
import * as yup from "yup"

const validationSchema = yup.object().shape({
   roleId: yup.string().required("Role is required"),
})

const AddZapierAPIKeyForm: React.FC<AddZapierAPIKeyComponentProps> = (props) => {
   // ----------
   // Form Props
   // ----------
   const initialValues: AddZapierAPIKeyFormFields = {
      roleId: "",
   }

   const formProps: FormProps = {
      initialValues,
      onSubmit: (value: AddZapierAPIKeyFormFields) => {
         props.onSubmit(value)
      },
      validationSchema,
      error: props.error,
      loading: props.loading,
      showErrorHint: true,
   }

   return (
      <Form {...formProps}>
         {({ dirty, isValid }) => {
            return (
               <>
                  <MiniRoleCard roles={props.availableRoles} name="roleId" label="Role" />
                  <div className="modal-action-buttons">
                     <Button
                        type="button"
                        variant="outlined"
                        color="secondary"
                        onClick={props.onClose}
                     >
                        Cancel
                     </Button>
                     <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={props.loading || !dirty || !isValid}
                        endIcon={props.loading ? <CircularProgress size={"100%"} /> : null}
                     >
                        Add New API Key
                     </Button>
                  </div>
               </>
            )
         }}
      </Form>
   )
}

export default AddZapierAPIKeyForm
