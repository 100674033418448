import styled from "@emotion/styled"
import { Button } from "@mui/material"
import SVG from "assets/svg"
import { IntegrationIconKeys } from "./useIntegrations"
import { maxWidth } from "rentpost/styles/size"

interface Props {
   onAddIntegrationClick: () => void
}

const MarketingPage: React.FC<Props> = ({ onAddIntegrationClick }) => {
   return (
      <Holder>
         <h1>Expand your RentPost account</h1>
         <p className="main-description">
            RentPost integrations enable you to connect your account to a wide range of services and
            applications, expanding your functionality and streamlining your operations. We offer a
            variety of pre-built integrations that you can choose from, and we also allow you to add
            your own custom integrations.
         </p>
         <div className="integrations-holder">
            <div className="upper-group integration-group marquee">
               <div className="marquee__group ">
                  <IntegrationGroup order={1} />
               </div>
               <div className="marquee__group">
                  <IntegrationGroup order={1} />
               </div>
            </div>
            <div className="lower-group integration-group marquee">
               <div className="marquee__group ">
                  <IntegrationGroup order={-1} />
               </div>
               <div className="marquee__group">
                  <IntegrationGroup order={-1} />
               </div>
            </div>
         </div>
         <Button
            variant="contained"
            className="add-integration-button"
            onClick={onAddIntegrationClick}
            fullWidth
         >
            Add Integration
         </Button>
      </Holder>
   )
}

const IntegrationItem: React.FC<{ icon: IntegrationIconKeys }> = ({ icon }) => {
   const IconComponent = SVG.integrations[icon]

   return (
      <div className="integration-item">
         <IconComponent />
      </div>
   )
}

const IntegrationGroup = ({ order }: { order: 1 | -1 }) => {
   const iconsArray = Object.keys(SVG.integrations) as IntegrationIconKeys[]
   return (
      <>
         {iconsArray
            .sort(() => -order)
            .map((icon, index) => (
               <IntegrationItem icon={icon} key={index} />
            ))}
      </>
   )
}

const Holder = styled.div`
   display: grid;
   grid-template-columns: 1.2fr 1fr;
   justify-content: center;
   align-content: center;
   gap: 0em 5em;
   min-height: calc(100dvh - 16em);
   margin-top: 2em;

   .integrations-holder {
      position: relative;
      grid-column: 2/3;
      grid-row: 1 / 4;
      display: flex;
      flex-direction: column;
      align-self: center;
      padding-block: 5em;
      overflow: hidden;
      font-size: 1.1em;

      .integration-group {
         position: relative;
         z-index: 1;
         display: flex;
         gap: 1.9em;

         .integration-item {
            border-radius: 0.5em;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 1em;
            width: 3em;
            height: 3em;
            background-color: #ffffff;
            box-shadow: 20px 20px 60px #d9d9d9, -20px -20px 60px #ffffff;

            svg {
               width: 2.5em;
            }
         }
      }

      .upper-group {
         left: -2em;
      }

      /* level limit */
      &:after {
         content: "";
         position: absolute;
         z-index: 2;
         width: 11em;
         height: 100%;
         background: linear-gradient(
            -90deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 1) 100%
         );
         top: 0;
         left: -2em;
      }

      &:before {
         content: "";
         position: absolute;
         z-index: 2;
         width: 14em;
         height: 100%;
         background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
         top: 0;
         right: -2em;
      }
   }
   .add-integration-button {
      grid-column: 1;
      grid-row: 3;
      font-size: 1.1em;
      height: max-content;
      padding-block: 0.6em;
      margin-block: -0.1em;
      max-width: ${maxWidth.mainSection};
   }

   /* marquee animation */
   .marquee {
      position: relative;
      max-width: 100%;
      --space: 2.1em;
      --duration: 100s;
      --gap: var(--space);
      display: flex;
      padding-block: 3em;
      margin-block: -2em;
      user-select: none;
      gap: var(--gap);

      .marquee__group {
         flex-shrink: 0;
         display: flex;
         align-items: center;
         justify-content: space-around;
         gap: var(--gap);
         min-width: 100%;
         animation: scroll var(--duration) linear infinite;
      }

      @keyframes scroll {
         0% {
            transform: translateX(0);
         }

         100% {
            transform: translateX(calc(-100% - var(--gap)));
         }
      }

      &.upper-group .marquee__group {
         animation: scroll var(--duration) linear infinite reverse;
      }
   }

   @media screen and (max-width: 1000px) {
      display: flex;
      flex-direction: column;
      width: 100%;
      min-height: calc(100dvh - 20em);

      .integrations-holder {
         padding-block: 2.9em;
         padding-inline: 4em;
         align-self: flex-start;
         width: calc(100vw - 4em);
         margin-inline: -2em;

         margin-block: -2.6em -1em;
         z-index: -1;
         font-size: 1em;
      }
   }

   @media screen and (max-width: 700px) {
   }
`

export default MarketingPage
