class AuthError extends Error {
   constructor(message: string, cause?: any) {
      super(message)
      this.name = "AuthError"
      this.message = message
      this.cause = cause
   }
}

class MissingDataError extends Error {
   constructor(message: string, cause?: any) {
      super(message)
      this.name = "MissingDataError"
      this.message = message
      this.cause = cause
   }
}

class ConnectionError extends Error {
   constructor(message: string, cause?: any) {
      super(message)
      this.name = "ConnectionError"
      this.message = message
      this.cause = cause
   }
}

class PageNotFoundError extends Error {
   constructor(message: string, cause?: any) {
      super(message)
      this.name = "PageNotFoundError"
      this.message = message
      this.cause = cause
   }
}

class LinkGoneError extends Error {
   constructor(message: string, cause?: any) {
      super(message)
      this.name = "LinkGoneError"
      this.message = message
      this.cause = cause
   }
}

class MaintenanceError extends Error {
   constructor(message: string, cause?: any) {
      super(message)
      this.name = "MaintenanceError"
      this.message = message
      this.cause = cause
   }
}
export {
   AuthError,
   ConnectionError,
   MissingDataError,
   PageNotFoundError,
   LinkGoneError,
   MaintenanceError,
}
