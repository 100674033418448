import { useRef } from "react"
import { v1 as uuidv1 } from "uuid"

const usePOEToken = () => {
   const poeToken = useRef<string>(uuidv1())

   const refreshPOEToken = () => {
      poeToken.current = uuidv1()
   }
   return { poeToken: poeToken, refreshPOEToken }
}

export default usePOEToken
