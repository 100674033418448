import { ApolloClient, ApolloLink, DefaultOptions, HttpLink } from "@apollo/client"
import { onError } from "@apollo/client/link/error"
import { createUploadLink } from "apollo-upload-client"
// import QueueLink from "apollo-link-queue"

// @ts-ignore - this makes tsc happy
import ENV from "configs/env"

import graphqlErrorHandler from "./graphqlErrorHandler"
import {
   authMiddleware,
   poeTokenMiddleware,
   sentrySuccessQueryBreadcrumbMiddleware,
   checkVerificationMiddleware,
   checkLogoutMiddleware,
   currentURLMiddleware,
} from "./middleware"
import queueLink from "./queueLink"

// Default options for all queries, mutations and subscriptions
const defaultOptions: DefaultOptions = {
   watchQuery: {
      fetchPolicy: "cache-and-network",
      errorPolicy: "ignore",
   },
   query: {
      fetchPolicy: "network-only",
      errorPolicy: "all",
   },
   mutate: {
      errorPolicy: "all",
   },
}

// --------------------
// Create Apollo Client
// --------------------
const createApolloClient = (cache: any) => {
   return new ApolloClient({
      cache,
      defaultOptions,
      link: ApolloLink.from([
         onError(graphqlErrorHandler),
         sentrySuccessQueryBreadcrumbMiddleware,
         poeTokenMiddleware,
         authMiddleware,
         currentURLMiddleware,
         checkVerificationMiddleware,
         checkLogoutMiddleware,
         queueLink,
         createUploadLink({
            uri: ENV.GRAPHQL,
         }),
         new HttpLink({ uri: ENV.GRAPHQL }),
      ]),
      connectToDevTools: true,
   })
}

export default createApolloClient
