import styled from "@emotion/styled"
import SVG from "assets/svg"
import { useEffect, useState } from "react"
import { motion } from "framer-motion"
import { color, font, size, space } from "rentpost/styles"
import { TwoFactorMethod } from "../useTwoFactorAuth"

interface Props {
   onChange: (value: TwoFactorMethod) => void
   value: TwoFactorMethod
   hideOption?: TwoFactorMethod
}

interface Option {
   content: React.ReactNode
   value: TwoFactorMethod
}

// ----------------
// METHOD SELECTION
// ----------------
const availableOptions: Option[] = [
   {
      content: (
         <>
            <SVG.form.sms className="icon" />
            <span className="method-name">SMS</span>
         </>
      ),
      value: TwoFactorMethod.SMS,
   },
   {
      content: (
         <>
            <SVG.form.whatsapp className="icon" />
            <span className="method-name">WhatsApp</span>
         </>
      ),
      value: TwoFactorMethod.Whatsapp,
   },
   {
      content: (
         <>
            <SVG.form.email className="icon" />
            <span className="method-name">Email</span>
         </>
      ),
      value: TwoFactorMethod.Email,
   },
]

const TwoFactorAuthMethodSelection = ({ onChange, value, hideOption }: Props) => {
   const options = availableOptions.filter((option) => option.value !== hideOption)
   const initialIndexFilter = options.findIndex((option) => option.value === value)
   const initialIndex = initialIndexFilter === -1 ? 0 : initialIndexFilter
   const [selectedMethodIndex, setSelectedMethodIndex] = useState<number>(initialIndex)
   const handleOnChange = (value: TwoFactorMethod) => {
      setSelectedMethodIndex(options.findIndex((option) => option.value === value))
      onChange(value)
   }

   useEffect(() => {
      setSelectedMethodIndex(options.findIndex((option) => option.value === value))
   }, [value])

   return (
      <Holder className="two-factor-auth-method-selection">
         {options.map((option, index) => (
            <div
               key={option.value}
               className={"method-item " + (index === selectedMethodIndex ? "active" : "")}
               onClick={() => handleOnChange(option.value)}
               style={{ width: 100 / options.length + "%" }}
            >
               {option.content}
            </div>
         ))}
         <motion.span
            className="active-background"
            initial={{ translateX: initialIndex * 100 + "%" }}
            animate={{ translateX: selectedMethodIndex * 100 + "%" }}
            transition={{ duration: 0.5, type: "spring", stiffness: 100, damping: 12 }}
            style={{ width: 100 / options.length + "%" }}
         />
      </Holder>
   )
}

const Holder = styled.div`
   position: relative;
   display: flex;
   align-items: center;
   justify-content: space-between;
   width: 100%;
   height: ${space.xl};
   margin-block: 0.7em;

   border-radius: 3em;
   background-color: ${color.white};
   border: 1px solid ${color.violet};
   overflow: hidden;

   .method-item {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      gap: 0.7em;
      z-index: 1;
      --color: ${color.violet};
      color: var(--color);
      transition: color 0.3s ease-in-out;
      user-select: none;

      .icon {
         width: 1.7em;
         fill: currentColor;
      }

      .method-name {
         ${font.bold};
         font-size: ${size.xs};
         color: inherit;
      }

      &.active {
         --color: ${color.white};
      }
   }

   .active-background {
      position: absolute;
      z-index: 0;
      top: 0;
      border-radius: 3em;
      left: 0;
      height: 100%;
      background-color: ${color.violet};
      box-shadow: 0 0 12px 1px ${color.grayDark};
   }
`
export default TwoFactorAuthMethodSelection
