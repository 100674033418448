import dayjs, { Dayjs } from "dayjs"

const getDateString = (date: Dayjs | null): string => {
   return dayjs(date).format("YYYY-MM-DD")
}

const getDate = (date: string): Dayjs => {
   return dayjs(date)
}

const getToday = (): Dayjs => {
   return dayjs(new Date().toDateString())
}

export { getDateString, getDate, getToday }
