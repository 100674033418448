import { RoleCardProps, RoleType } from "rentpost/components/other/RoleCard"
import useProfile from "../useProfile"
import { MultiRoleSelect } from "components/form"
import { useState } from "react"

interface Props {
   rolesData: ReturnType<typeof useProfile>["data"]["getAvailableRolesData"]
   removeRole: RoleCardProps["onDeleteClick"]
   uploadAvatar: (roleId: string) => void
   selectRole: ReturnType<typeof useProfile>["selectRole"]
   className?: string
}

const RolesList: React.FC<Props> = ({
   rolesData,
   selectRole,
   removeRole,
   uploadAvatar,
   className,
}) => {
   const [selectedRoleId, setSelectedRoleId] = useState<string | null>(null)
   const handleSelectRole = (roleId: string, roleType: RoleType, isOnboardingRequired: boolean) => {
      selectRole(roleId, roleType, isOnboardingRequired)
      setSelectedRoleId(roleId)
   }
   // ==================
   // Roles List Mapping
   // ==================
   const roleItems: RoleCardProps[] = rolesData?.roles
      .map((role) => {
         // Ignore roles with status other than "Active"
         if (role.status !== "Active") return null
         const { type, displayName, companyName, id, badges, email } = role
         const roleAddress = role.address && role.address.city ? role.address : null
         const avatarImage = role.avatar?.droplet?.children[0]?.url || role.avatar?.url
         const address = roleAddress
            ? roleAddress?.aptSuiteEtc +
              " " +
              roleAddress?.street +
              ", " +
              roleAddress?.city +
              ", " +
              roleAddress?.state +
              " " +
              roleAddress?.postalCode
            : null

         const loading = selectedRoleId === null ? false : selectedRoleId === id
         return {
            role: {
               id,
               type,
               address,
               email,
               avatarImage,
               displayName,
               companyName,
               badges,
            },
            showActionMenu: true,
            showEditAvatar: true,
            onDeleteClick: removeRole,
            onAvatarClick: uploadAvatar,
            onGoToPortal: handleSelectRole,
            isOnboardingRequired: role.isOnboardingRequired,
            loading,
         } as RoleCardProps
      })
      .filter((item) => item) as RoleCardProps[]

   return (
      <div className={className}>
         <MultiRoleSelect multiRoles={roleItems} handleChooseRole={() => {}} />
      </div>
   )
}

export default RolesList
