import styled from "@emotion/styled"
import { Hint, SingleFieldForm } from "rentpost/components/form"
import { Button } from "@mui/material"
import MultiSectionsLayout from "rentpost/layouts/MultiSectionsLayout"
import Illustration from "pages/Login/Illustration"
import useVerifyEmail from "./useVerifyEmail"
import useVerifyFromLink from "./useVerifyFromLink"
import { useEffect, useState } from "react"
import { size, space } from "rentpost/styles"
import useTriggerHint from "hooks/useTriggerHint"
import { Link } from "react-router-dom"
import ROUTE from "routes/ROUTE"
import getApolloErrorDetails from "utils/getApolloErrorDetails"

const EmailVerification: React.FC = () => {
   const { loading, showVerificationPage } = useVerifyFromLink()

   return (
      <>
         {!loading && showVerificationPage && (
            <MultiSectionsLayout
               mainSection={<MainSection />}
               htmlTitle="Verify Email | RentPost"
               illustrationSection={<Illustration />}
            />
         )}
      </>
   )
}

const MainSection = () => {
   const {
      verifyEmailManually,
      verifyEmailManuallyLoading,
      verifyEmailManuallyError,
      resendEmailVerification,
      resendEmailVerificationLoading,
      resendEmailVerificationError,
      attemptCount,
   } = useVerifyEmail()
   const [attemptDelay, setAttemptDelay] = useState<number>(0)
   const [triggerAutoHide, onFocus] = useTriggerHint(verifyEmailManuallyError)
   // Start counting the delay after each attempt to resend email, delay is 120 seconds, render the count down
   // if the delay is not 0
   if (attemptDelay > 0) {
      setTimeout(() => {
         // Decrease the delay by 1 second every second
         setAttemptDelay(attemptDelay - 1)
      }, 1000)
   }

   // If the attempt count is less than 5, reset the delay to 120 seconds after each attempt
   useEffect(() => {
      if (attemptCount < 5 && attemptCount !== 0) {
         setAttemptDelay(120)
      }
   }, [attemptCount])

   const handleSubmit = (value: { field: any }) => {
      value.field && verifyEmailManually(value.field as string)
   }

   // ===
   // JSX
   // ===
   return (
      <Holder>
         <div className="content">
            <h1>Verify your email address</h1>
            <p className="main-description">
               To verify your email address, we have sent a link to your email. If the link doesn't
               work, you can paste the code here instead.
            </p>
            {verifyEmailManuallyError && (
               <Hint
                  text={getApolloErrorDetails(verifyEmailManuallyError).errorMessage}
                  type="error"
                  canHide
                  triggerAutoHide={triggerAutoHide}
               />
            )}
            <SingleFieldForm
               initialValue=""
               onSubmit={handleSubmit}
               type="text"
               placeholder="Code"
               loading={verifyEmailManuallyLoading}
               submitButtonText="Verify"
               onFormFocus={onFocus}
            />

            <div className="resend">
               {(resendEmailVerificationError || attemptCount >= 5) && (
                  <Hint
                     text={
                        attemptCount >= 5
                           ? "You have reached the maximum number of attempts. Please try again later."
                           : "Something went wrong, please try again later."
                     }
                     type="error"
                     canHide
                  />
               )}
               {attemptDelay > 0 && (
                  <Hint
                     text="We just sent you an email, check it out!"
                     type="success"
                     canHide
                     autoHideTimeout={5000}
                     triggerAutoHide
                  />
               )}
               <div className="gray-container">
                  <p>
                     Can’t find the link in your email, no problem, you can click the button below
                     to have another email resent.
                  </p>
                  <Button
                     variant="outlined"
                     color="secondary"
                     style={{ borderRadius: "50em", width: "100%", marginTop: "1rem" }}
                     onClick={resendEmailVerification}
                     disabled={
                        resendEmailVerificationLoading || attemptCount >= 5 || attemptDelay !== 0
                     }
                  >
                     {attemptDelay === 0
                        ? "Resend Email"
                        : attemptDelay + " second until next attempt"}
                  </Button>
               </div>
            </div>

            <p className="logout-container">
               Alternatively, you can <Link to={ROUTE.LOGOUT}>sign out</Link> of this account.
            </p>
         </div>
      </Holder>
   )
}

const Holder = styled.div`
   .content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .resend {
         margin-top: ${space.l};
      }

      .logout-container {
         margin-top: ${space.l};
         font-size: ${size.xs};
         text-align: center;
         justify-content: center;
         align-self: center;
      }
   }
`

export default EmailVerification
