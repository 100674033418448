import SVG from "assets/svg"
import gql from "graphql-tag"
import { useMutation, useLazyQuery } from "@apollo/client"
import {
   AddAPIKey,
   AddAPIKeyVariables,
   AddIntegration,
   AddIntegrationVariables,
   GetIntegrations,
   RemoveIntegration,
   RemoveIntegrationVariables,
} from "./__generated__"
import usePOEToken from "hooks/usePOEToken"
import { DateTime } from "luxon"
import { useEffect, useState } from "react"
import bypassMockError from "utils/bypassMockError"
import { RevokeAuthTokenMutation } from "../Security/UseSecurity"
import { RevokeAuthToken, RevokeAuthTokenVariables } from "../Security/__generated__"
import _ from "lodash"
import useSlowEffect from "hooks/useSlowEffect"
import useModal from "hooks/useModal"

type IntegrationIconKeys = keyof typeof SVG.integrations
export enum IntegrationTypeEnum {
   custom = "custom",
   zapier = "zapier",
}
interface Integration {
   id: string
   name: string
   description: string
   type: IntegrationTypeEnum
   authTokens: {
      environment: string
      id: string
      description?: string
      roleId: string
      expiresAt?: string
      actions: {
         id: string
         displayName: string
         createdAt: string
      }[]
      lastUsedAt?: string
   }[]
}

interface NewIntegration {
   id: string
   name: string
   type: IntegrationTypeEnum
}

interface AddIntegrationFormFields {
   type: IntegrationTypeEnum | ""
   name: string
   description: string
   roleId: string
}

interface AddCustomAPIKeyFormFields {
   integrationId: string
   description: string
   roleId: string
   environment: Environment
}

interface AddZapierAPIKeyFormFields {
   roleId: string
}

enum Environment {
   dev = "dev",
   prod = "prod",
   sandbox = "sandbox",
   stage = "stage",
   test = "test",
}

// ========
// MUTATION
// ========
const AddIntegrationMutation = gql`
   mutation AddIntegration(
      $customInput: CustomIntegrationInput
      $zapierInput: ZapierIntegrationInput
   ) {
      addIntegration(custom: $customInput, zapier: $zapierInput) {
         id
         name
         authTokens {
            initiallyVisiblePaseto {
               token
            }
         }
      }
   }
`
const AddAPIKeyMutation = gql`
   mutation AddAPIKey($input: CreateAuthTokenInput!) {
      createAuthToken(input: $input) {
         authClient {
            authTokens {
               initiallyVisiblePaseto {
                  token
               }
            }
         }
      }
   }
`
const RemoveIntegrationMutation = gql`
   mutation RemoveIntegration($id: ID!) {
      removeIntegration(authClientId: $id)
   }
`
// =====
// QUERY
// =====
const GetIntegrationsQuery = gql`
   query GetIntegrations {
      authClients {
         id
         name
         description
         key
         integration {
            name
            description
            docUrl
         }

         authTokens {
            id
            description
            authClient {
               allowedOrigins {
                  uri
                  environment
               }
            }

            role {
               id
            }

            expiresAt

            actions {
               displayName
               createdAt
               id
            }
         }
      }
   }
`

export {
   AddIntegrationMutation,
   AddAPIKeyMutation,
   RemoveIntegrationMutation,
   GetIntegrationsQuery,
}

// =====================
// USE INTEGRATIONS HOOK
// =====================
const useIntegrations = () => {
   // ======
   // STATES
   // ======
   const [newAPIToken, setNewAPIToken] = useState<string | undefined>(undefined)
   const [integrations, setIntegrations] = useState<Integration[] | null>(null)
   const [newIntegration, setNewIntegration] = useState<NewIntegration | null>(null)
   const [isFirstLoad, setIsFirstLoad] = useState(true)

   // ============
   // HELPER HOOKS
   // ============
   const { poeToken, refreshPOEToken } = usePOEToken()
   const {
      modalState: { activeModal },
   } = useModal()

   // ==========
   // OPERATIONS
   // ==========
   const [runAddIntegration, { loading: addIntegrationLoading, error: addIntegrationError }] =
      useMutation<AddIntegration, AddIntegrationVariables>(AddIntegrationMutation)

   const [runAddAPIKey, { loading: addAPIKeyLoading, error: addAPIKeyError }] = useMutation<
      AddAPIKey,
      AddAPIKeyVariables
   >(AddAPIKeyMutation)

   const [runRevokeAPIKey, { loading: revokeAPIKeyLoading }] = useMutation<
      RevokeAuthToken,
      RevokeAuthTokenVariables
   >(RevokeAuthTokenMutation)

   const [runRemoveIntegration, { loading: removeIntegrationLoading }] = useMutation<
      RemoveIntegration,
      RemoveIntegrationVariables
   >(RemoveIntegrationMutation)

   const [runGetIntegrations, { loading: getIntegrationsLoading }] =
      useLazyQuery<GetIntegrations>(GetIntegrationsQuery)

   // =================
   // MAPPERS & HELPERS
   // =================
   const getIntegrationTypeFromName = (name: string): IntegrationTypeEnum => {
      const integrationTypeKey = _.lowerCase(name).includes("zapier")
         ? IntegrationTypeEnum.zapier
         : IntegrationTypeEnum.custom
      return integrationTypeKey
   }

   const mapIntegrationsFromAuthClients = (
      integrations: GetIntegrations["authClients"]
   ): Integration[] => {
      const convertTime = (time: string | null) => {
         if (!time) return null
         const dateTimeFromISO = DateTime.fromISO(time).toFormat("MM/dd/yyyy  (hh:mm a)")
         return dateTimeFromISO
      }

      return integrations.map((integration) => {
         const { id, description, name, authTokens } = integration

         return {
            id,
            name,
            description,
            type: getIntegrationTypeFromName(name),
            authTokens: authTokens.map((authToken) => {
               const { id, description, role, expiresAt, actions } = authToken
               return {
                  id,
                  description,
                  roleId: role?.id,
                  expiresAt: convertTime(expiresAt),
                  lastUsedAt: convertTime(actions[0]?.createdAt),
                  environment: authToken.authClient.allowedOrigins[0]?.environment,
                  actions: actions.map(({ createdAt, displayName, id }) => ({
                     displayName,
                     createdAt: convertTime(createdAt),
                     id,
                  })),
               } as Integration["authTokens"][0]
            }),
         }
      })
   }

   const mapNewIntegration = (integration: AddIntegration): NewIntegration => {
      const { id, name } = integration.addIntegration
      return {
         id,
         name,
         type: getIntegrationTypeFromName(name),
      }
   }

   // ========
   // HANDLERS
   // ========
   const addIntegration = (input: AddIntegrationFormFields) => {
      const { type, name, description, roleId } = input
      const variables: AddIntegrationVariables = {
         customInput: _.toLower(type) === "custom" ? { name, description } : undefined,
         zapierInput: _.toLower(type) === "zapier" ? { roleId } : undefined,
      }

      runAddIntegration({
         variables,
         context: {
            poeToken: poeToken.current,
         },
      })
         .then(({ data }) => {
            const APIToken =
               data?.addIntegration?.authTokens[0]?.initiallyVisiblePaseto?.token || undefined
            setNewAPIToken(APIToken)
            setNewIntegration(mapNewIntegration(data as AddIntegration))
            refreshPOEToken()
            getIntegrations()
         })
         .catch((e) => bypassMockError(e))
   }

   const addCustomAPIKey = (input: AddCustomAPIKeyFormFields) => {
      const { integrationId, description, roleId, environment } = input

      // ==================
      // Custom Integration
      // ==================
      const variables: AddAPIKeyVariables = {
         input: {
            authClientId: integrationId,
            description,
            roleId,
            environment,
         },
      }

      runAddAPIKey({
         variables,
         context: {
            poeToken: poeToken.current,
         },
      })
         .then(({ data }) => {
            const APIToken =
               data?.createAuthToken?.authClient?.authTokens[0]?.initiallyVisiblePaseto?.token
            setNewAPIToken(APIToken)
            getIntegrations()
            refreshPOEToken()
         })
         .catch((e) => bypassMockError(e))
   }

   const addZapierAPIKey = (input: AddZapierAPIKeyFormFields) => {
      const { roleId } = input

      // ================
      // Zapier Integration
      // ================
      const variables: AddIntegrationVariables = {
         zapierInput: { roleId },
      }

      runAddIntegration({
         variables,
         context: {
            poeToken: poeToken.current,
         },
      })
         .then(({ data }) => {
            const APIToken =
               data?.addIntegration?.authTokens[data?.addIntegration?.authTokens.length - 1]
                  ?.initiallyVisiblePaseto?.token
            setNewAPIToken(APIToken)
            getIntegrations()
            refreshPOEToken()
         })
         .catch((e) => bypassMockError(e))
   }

   const revokeAPIKey = (id: string) => {
      runRevokeAPIKey({
         variables: { id },
         context: {
            poeToken: poeToken.current,
            showErrorModal: true,
         },
      })
         .then(() => {
            refreshPOEToken()
            getIntegrations()
         })
         .catch((e) => bypassMockError(e))
   }

   const removeIntegration = (id: string) => {
      runRemoveIntegration({
         variables: { id },
         context: {
            poeToken: poeToken.current,
            showErrorModal: true,
         },
      })
         .then(() => {
            refreshPOEToken()
            getIntegrations()
         })
         .catch((e) => bypassMockError(e))
   }

   const getIntegrations = () => {
      runGetIntegrations({
         context: {
            showErrorModal: true,
         },
      })
         .then(({ data }) => {
            const result = mapIntegrationsFromAuthClients(data?.authClients || [])
            result && setIntegrations(result)
            isFirstLoad && setIsFirstLoad(false)
            refreshPOEToken()
         })
         .catch((e) => bypassMockError(e))
   }
   // =======
   // EFFECTS
   // =======
   // Get Integrations on mount
   useSlowEffect(getIntegrations)

   // clear the newAPIKey after closing the modal
   useEffect(() => {
      if (!activeModal) {
         setNewAPIToken(undefined)
         setNewIntegration(null)
      }
   }, [activeModal])

   // =======
   // RETURNS
   // =======
   return {
      newAPIToken,
      newIntegration,
      integrations,
      isFirstLoad,
      addIntegration,
      addCustomAPIKey,
      addZapierAPIKey,
      revokeAPIKey,
      removeIntegration,
      getIntegrations,
      loading: {
         addIntegration: addIntegrationLoading,
         addAPIKey: addAPIKeyLoading,
         revokeAPIKey: revokeAPIKeyLoading,
         removeIntegration: removeIntegrationLoading,
         getIntegrations: getIntegrationsLoading,
      },
      error: {
         addIntegration: addIntegrationError,
         addAPIKey: addAPIKeyError,
      },
   }
}

export type {
   IntegrationIconKeys,
   AddIntegrationFormFields,
   AddCustomAPIKeyFormFields,
   AddZapierAPIKeyFormFields,
   IntegrationTypeEnum as IntegrationType,
   Integration,
}
export { Environment }
export default useIntegrations
