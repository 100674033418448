import styled from "@emotion/styled"
import userCardBackground from "assets/images/user-card-background.png"
import { color, font, size } from "rentpost/styles"
import { parsePhoneNumber } from "libphonenumber-js"

interface Props {
   firstName: string
   lastName: string
   email: string
   phone: string | null
   createdAt: string
   className?: string
}

const UserCard = ({ firstName, lastName, email, phone, createdAt, className }: Props) => {
   // calculate the difference between the current date and the createdAt date, and formate it to be rendered as "Joined November 2020"
   const createdAtDate = new Date(createdAt)
   const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
   ]
   const createdAtFormatted = `Joined ${
      monthNames[createdAtDate.getMonth()]
   } ${createdAtDate.getFullYear()}`
   return (
      <Holder className={className}>
         <img
            src={userCardBackground}
            alt="user card background"
            className="user-card-background-image"
         />
         <div className="user-card-upper">
            <h2 className="user-card-name">{firstName + " " + lastName}</h2>
            <div className="user-card-created-at">{createdAtFormatted}</div>
         </div>
         <div className="user-card-lower">
            <div className="user-card-item">
               <span className="user-card-item-icon">
                  <svg viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path d="M14.1575 11.3415C14.1575 12.1335 13.8895 13.1705 13.4735 13.9835C12.4645 15.9635 10.4105 15.9505 10.4105 13.8435C10.4105 13.0575 10.6805 12.0445 11.0975 11.2635C12.1185 9.33848 14.1575 9.63948 14.1575 11.3415ZM24.4395 12.8135C24.4395 19.4405 19.0665 24.8135 12.4395 24.8135C5.81245 24.8135 0.439453 19.4405 0.439453 12.8135C0.439453 6.18648 5.81245 0.813477 12.4395 0.813477C19.0665 0.813477 24.4395 6.18648 24.4395 12.8135ZM19.4395 11.6195C19.4395 8.37348 16.8085 6.01848 13.1835 6.01848C8.21645 6.01848 5.43945 9.16748 5.43945 13.0915C5.43945 16.7635 7.90645 19.6085 12.4635 19.6085C14.9835 19.6085 16.5875 18.8825 17.5855 18.3205L16.8985 17.3295C15.8765 17.9225 14.6475 18.4655 12.6425 18.4655C9.21345 18.4655 6.90945 16.2665 6.90945 12.9925C6.90945 7.27848 13.3105 6.23448 16.1235 7.92148C18.7475 9.56348 18.6475 13.4995 16.7055 15.0045C15.6715 15.8305 14.5065 15.8035 14.8845 14.2485C14.8845 14.2485 16.0965 9.75948 16.2385 9.27348H14.8745L14.6035 10.2255C14.3255 9.44048 13.6605 8.93048 12.6925 8.93048C10.6745 8.93048 8.97045 11.1205 8.97045 13.7135C8.97045 15.4435 9.88345 16.5175 11.3505 16.5175C12.6335 16.5175 13.3005 15.7915 13.7145 15.1445C13.4145 18.0425 19.4395 16.7015 19.4395 11.6195Z" />
                  </svg>
               </span>
               <span className="user-card-item-content">{email}</span>
            </div>

            {phone && (
               <div className="user-card-item">
                  <span className="user-card-item-icon">
                     <svg viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.4395 0.597656C6.25912 0.597656 0.4375 6.41928 0.4375 13.5996C0.4375 20.7799 6.25912 26.6016 13.4395 26.6016C20.6198 26.6016 26.4414 20.7799 26.4414 13.5996C26.4414 6.41928 20.6198 0.597656 13.4395 0.597656ZM17.1721 19.9131C13.1805 21.7377 6.98615 9.69577 10.8878 7.66097L12.0287 7.09863L13.9205 10.7923L12.7915 11.3481C11.6051 11.9841 14.0755 16.8089 15.289 16.2206L16.4071 15.6734L18.3141 19.3541L17.1721 19.9131Z" />
                     </svg>
                  </span>
                  <span className="user-card-item-content">
                     {parsePhoneNumber(phone).formatInternational()}
                  </span>
               </div>
            )}
         </div>
      </Holder>
   )
}

const Holder = styled.div`
   position: relative;
   width: 100%;
   height: 100%;
   min-height: 25em;
   padding: 1.5em;
   box-sizing: border-box;
   display: grid;
   grid-template-rows: 1fr 2fr;
   border-radius: 1.5em;
   background-color: #fbfbfb;
   border: 1px solid #f0f0f0;
   overflow: hidden;
   isolation: isolate;

   .user-card-background-image {
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: -1;
   }

   .user-card-upper {
      justify-self: center;
      align-self: center;
      height: 0;
      .user-card-name {
         margin-top: 0em;
         color: ${color.violet};
         /* wrap text  */
         word-break: break-word;
         text-align: center;
      }

      .user-card-created-at {
         margin-top: 0.5em;
         margin-bottom: 15em;
         font-size: ${size.m};
         color: ${color.grayDark};
         text-align: center;
         ${font.regular};
      }
   }

   .user-card-lower {
      align-self: flex-end;
      .user-card-item {
         display: flex;
         align-items: center;
         padding: 0.6em;
         margin-bottom: 1em;
         color: #aca9b2;

         .user-card-item-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            svg {
               width: 2.4em;
               height: 2.4em;
               fill: #aca9b2;
            }
         }

         .user-card-item-content {
            font-size: 1.1em;
            justify-self: center;
            ${font.regular};
            text-align: center;
            margin-left: 1.1em;
         }
      }
   }
`

export default UserCard
