import { useMutation } from "@apollo/client"
import { CreateCompany, CreateCompanyVariables } from "./__generated__"
import { gql } from "@apollo/client"
import { signUpFieldsProp } from "./SignUpForm"
import usePOEToken from "hooks/usePOEToken"
import auth from "utils/auth"
import getPASETOFooter from "utils/getPASETOFooter"
import bypassMockError from "utils/bypassMockError"
import UTMCookies from "utils/UTMCookies"

// @ts-ignore - This makes typescript happy!
import env from "configs/env"
import { useNavigate } from "react-router-dom"
import ROUTE from "routes/ROUTE"
// ========
// MUTATION
// ========
const CreateCompanyMutation = gql`
   mutation CreateCompany($input: CreateCompanyInput!) {
      createCompany(input: $input) {
         token
      }
   }
`

export { CreateCompanyMutation }

// ===============
// USE SIGNUP HOOK
// ===============
const useSignup = () => {
   const [runSignup, { loading, error }] = useMutation<CreateCompany, CreateCompanyVariables>(
      CreateCompanyMutation
   )
   // POE Token hook
   const { poeToken, refreshPOEToken } = usePOEToken()
   const navigate = useNavigate()
   const signUp = (
      companyName: string,
      user?: Omit<signUpFieldsProp, "companyName">,
      email?: string
   ) => {
      runSignup({
         context: {
            poeToken: poeToken.current,
            canWorkWithoutAuthorization: user!!,
         },
         variables: {
            input: {
               legalEntity: {
                  typeKey: "limited_liability_company",
                  companyName,
                  email: user?.email || email,
               },
               user,
               ...(UTMCookies.get("utm_source") && {
                  referrer: {
                     url: UTMCookies.get("utm_source") || "",
                     campaign: UTMCookies.get("utm_campaign") || "",
                     medium: UTMCookies.get("utm_medium") || "",
                     term: UTMCookies.get("utm_term") || "",
                     content: UTMCookies.get("utm_content") || "",
                     source: UTMCookies.get("utm_source") || "",
                  } as CreateCompanyVariables["input"]["referrer"],
               }),
            },
         },
      })
         .then(async (res) => {
            const token = res.data?.createCompany?.token || ""
            const expiration = getPASETOFooter(token).expiration
            auth.setAccessToken(token, expiration)
            refreshPOEToken()

            // If added for an existing user, redirect to the manager
            // TODO - This should be refactored to direct to the onboarding page instead (Update After Onboarding got implemented)
            if (email) {
               navigate(ROUTE.ONBOARDING.ROOT + "/" + ROUTE.ONBOARDING.PERSONAL_INFO)
            }
         })
         .catch(bypassMockError)
   }
   return {
      signUp,
      loading,
      error,
   }
}
export default useSignup
