import { Avatar, KeyValueInfo, KeyValueList, RoleCardProps } from "rentpost/components/other"
import { Integration } from "../useIntegrations"
import { Button, CircularProgress } from "@mui/material"
import { color, size } from "rentpost/styles"
import styled from "@emotion/styled"
import { padding } from "rentpost/styles/size"

interface IntegrationRoleProps {
   authToken: Integration["authTokens"][0]
   associatedRole: RoleCardProps["role"]
   loading: boolean
   revokeAPIKey: (id: string) => void
}

const IntegrationRoleCard: React.FC<IntegrationRoleProps> = (props) => {
   const {
      authToken: { environment, actions, roleId, description, expiresAt, lastUsedAt, id },
      loading,
      revokeAPIKey,
      associatedRole,
   } = props

   const actionsList = actions.map(({ displayName: key, createdAt: value }) => ({ key, value }))
   const roleAvatarInfo = {
      roles: [
         {
            displayName: associatedRole?.displayName,
            avatarImage: associatedRole?.avatarImage,
         },
      ],
   }
   return (
      <Holder className={associatedRole?.type || ""}>
         {roleId && (
            <div className={"role-info " + associatedRole?.type || ""}>
               <Avatar {...roleAvatarInfo} />
               <span className="role-type">{associatedRole?.type}</span>
            </div>
         )}
         {description && <p className="description">{description}</p>}
         <div className="tags-holder">
            {environment && <KeyValueInfo label="Environment" value={environment} />}
            {lastUsedAt && <KeyValueInfo label="Last Active" value={lastUsedAt} />}
            {expiresAt && <KeyValueInfo label="Expires At" value={expiresAt} />}
         </div>
         {actions.length > 1 && <KeyValueList label="Recent Actions" list={actionsList} />}
         <Button
            onClick={() => revokeAPIKey(id)}
            variant="outlined"
            color="error"
            size="small"
            disabled={loading}
            endIcon={loading ? <CircularProgress size={"100%"} /> : null}
         >
            Revoke
         </Button>
      </Holder>
   )
}
// const Holder = styled.div`
//    background-color: #f4f4f4;
//    border: 1px solid #c6c6c6;
//    border-radius: 1em;
//    display: grid;
//    grid-template-columns: 11% 45% 21% 10%;
//    grid-template-rows: auto auto;
//    align-items: center;
//    gap: 1em 2em;
//    padding: 1.5em 1.8em;

//    .role-info {
//       grid-column: 1 / 2;
//       grid-row: 1 / 3;
//       display: flex;
//       flex-direction: column;
//       align-items: center;
//       gap: 0.8em;
//       pointer-events: none;
//       --active-role-color: ${color.black};

//       .avatar-container {
//          border: 2px solid var(--active-role-color);
//          border-radius: 50%;
//       }

//       .role-type {
//          font-size: 0.9em;
//          color: ${color.white};
//          padding: 0.2em 0.8em;
//          border-radius: 1em;
//          background-color: var(--active-role-color);
//       }

//       &.manager {
//          --active-role-color: #000;
//       }

//       &.employee {
//          --active-role-color: #663819;
//       }
//    }

//    .description {
//       grid-column: 2 / 3;
//       grid-row: 1 / 2;
//       font-size: 1em;
//       color: ${color.grayDark};
//       margin-bottom: 0.8em;
//    }

//    .tags-holder {
//       grid-column: 2 / 3;
//       grid-row: 2 / 3;
//       display: flex;
//       flex-wrap: wrap;
//       gap: 1em;
//       pointer-events: none;

//       .information-item {
//          display: flex;
//          gap: 1.8em;
//          border-radius: 3em;
//          padding: 0.3em 0.9em;
//          border: 1px solid ${color.grayLight};
//          font-size: 0.8em;
//          opacity: 0.9;

//          .label {
//             color: ${color.white};
//             background-color: ${color.grayLight};
//             border-radius: 3em;
//             padding: 0.3em 0.9em;
//             margin: -0.3em -0.95em;
//             font-size: 1em;
//          }

//          .value {
//             font-size: 1em;
//             color: ${color.grayDark};
//          }
//       }
//    }

//    .recent-action-holder {
//       grid-column: 3 / 5;
//       grid-row: 1 / 3;

//       h4 {
//          color: ${color.violet};
//          margin-block: 0.6em;
//          font-size: 1em;
//          line-height: 0.6;
//       }

//       .recent-actions-list {
//          display: flex;
//          flex-direction: column;
//          gap: 0.8em;

//          .action-item {
//             display: flex;
//             align-items: center;
//             gap: 1.5em;
//             font-size: 1.1em;
//             color: ${color.grayDark};

//             .name {
//             }
//          }
//       }
//    }

//    button {
//       grid-column: 4 / 5;
//       grid-row: 1 / 3;
//    }

//    &.manager {
//       --active-role-color: #000;
//    }

//    &.employee {
//       --active-role-color: #663819;
//    }
// `

const Holder = styled.div`
   width: 100%;
   display: flex;
   align-items: center;
   justify-content: space-between;
   flex-wrap: wrap;
   box-sizing: border-box;
   gap: ${padding.block};
   background-color: #efefef;
   border-radius: 1em;
   padding: 1em var(--padding-inline);
   position: relative;
   transition: all 0.2s ease-in-out;
   container-type: inline-size;

   button {
      height: max-content;
   }

   .role-info {
      grid-column: 1 / 2;
      grid-row: 1 / 3;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.8em;
      pointer-events: none;

      .avatar-container {
         border: 2px solid var(--active-role-color);
         border-radius: 50%;
      }

      .role-type {
         font-size: 0.9em;
         color: ${color.white};
         padding: 0.2em 0.8em;
         border-radius: 1em;
         background-color: var(--active-role-color);
      }

      &.manager {
         --active-role-color: #000;
      }

      &.employee {
         --active-role-color: #663819;
      }
   }

   button {
      @container (max-width: 450px) {
         width: 100%;
      }
   }
`

export type { IntegrationRoleProps }
export default IntegrationRoleCard
