import { useMutation, useLazyQuery } from "@apollo/client"
import {
   GetRoleDetails,
   RegisterUser,
   RegisterUserVariables,
   UploadAvatar,
   UploadAvatarVariables,
} from "./__generated__"
import { gql } from "@apollo/client"
import { RegisterFormFieldsType, LoginFormFieldsType } from "./RegisterForm"
import usePOEToken from "hooks/usePOEToken"
import auth from "utils/auth"
import getPASETOFooter from "utils/getPASETOFooter"
import bypassMockError from "utils/bypassMockError"
import { LoginMutation } from "pages/Login/useLogin"
import { Login, LoginVariables } from "pages/Login/__generated__"
import dataUrlToFile from "utils/dataURLToFile"
import { useNavigate } from "react-router-dom"
import ROUTE from "routes/ROUTE"
import { useErrorBoundary } from "react-error-boundary"
import { setZendeskJWT } from "configs/zendesk/initZendesk"
import { useState } from "react"
import { MissingDataError } from "configs/error/customErrors"

// ========
// MUTATION
// ========
const RegisterUserMutation = gql`
   mutation RegisterUser($input: RegisterInput!) {
      register(input: $input) {
         token
      }
   }
`
const UploadAvatarMutation = gql`
   mutation UploadAvatar($file: Upload!) {
      uploadAvatar(file: $file) {
         id
      }
   }
`
// =======
// QUERIES
// =======
const GetRoleDetailsQuery = gql`
   query GetRoleDetails {
      ## Get the current user's role
      roles {
         type
         displayName
         email
         id
         address {
            street
            city
            state
            postalCode
            aptSuiteEtc
         }
         status
         badges
         companyName
      }
   }
`

// =================
// USE REGISTER HOOK
// =================
const useRegister = ({ avatar }: { avatar: string | null }) => {
   const navigate = useNavigate()
   const [isValidKey, setIsValidKey] = useState<boolean | null>(null)
   const [inviteKey, setInviteKey] = useState<string | null>(null)
   const { showBoundary } = useErrorBoundary()
   // ========
   // MUTATION
   // ========
   // Login user mutation
   const [runInitialLogin, { loading: initialLoginLoading, error: initialLoginError }] =
      useMutation<Login, LoginVariables>(LoginMutation)

   const [runLogin, { loading: loginLoading, error: loginError }] = useMutation<
      Login,
      LoginVariables
   >(LoginMutation)

   // Register user mutation
   const [runRegister, { loading: registerLoading, error: registerError }] = useMutation<
      RegisterUser,
      RegisterUserVariables
   >(RegisterUserMutation)

   // Upload avatar mutation
   const [uploadAvatar, { loading: uploadAvatarLoading, error: uploadAvatarError }] = useMutation<
      UploadAvatar,
      UploadAvatarVariables
   >(UploadAvatarMutation)

   // =======
   // QUERIES
   // =======
   // Get role details
   const [runGetRoleDetails, { data: getRoleData, loading: getRoleLoading, error: getRoleError }] =
      useLazyQuery<GetRoleDetails>(GetRoleDetailsQuery)

   // POE Token hook
   const { poeToken, refreshPOEToken } = usePOEToken()

   const getRoleDetails = () => {
      runGetRoleDetails()
         .then((data) => {
            if (!data.data?.roles) {
               showBoundary(
                  new MissingDataError(
                     `Roles response data has (${data?.data?.roles}) value`,
                     "OperationName: getRoles - useRegister.ts"
                  )
               )
               return
            }

            if (data.data?.roles[0]?.status === "Active") {
               navigate(ROUTE.LOGIN, { replace: true })
            }
         })
         .catch(bypassMockError)
   }

   // Modal hook

   const runUploadAvatar = async (file: File | null, authorization: string) => {
      if (!file) return
      await uploadAvatar({
         context: {
            poeToken: poeToken.current,
            showErrorModal: true,
            authorization,
         },
         variables: {
            file,
         },
      })
         .then((res) => {
            refreshPOEToken()
         })
         .catch(bypassMockError)
   }

   const register = async (inviteKey: string, values?: RegisterFormFieldsType) => {
      const isNewUser = !!values
      const avatarFile = !!avatar ? await dataUrlToFile(avatar, `avatar_${Date.now()}} `) : null

      runRegister({
         context: {
            poeToken: poeToken.current,
         },
         variables: {
            input: {
               inviteKey,
               user: values || undefined,
            },
         },
      })
         .then(async (res) => {
            const token = res.data?.register?.token
            if (token) {
               await runUploadAvatar(avatarFile, token)
               isNewUser ? navigate(ROUTE.LOGOUT) : navigate(ROUTE.LOGIN)
               refreshPOEToken()
            }
         })
         .catch(bypassMockError)
   }

   const initialLogin = (inviteKey: string) => {
      runInitialLogin({
         context: {
            poeToken: poeToken.current,
         },

         variables: {
            input: {
               inviteKey,
            },
         },
      })
         .then((res) => {
            const token = res.data?.login?.token
            if (token) {
               auth.setAccessToken(token, getPASETOFooter(token).expiration)
               refreshPOEToken()
            }
            getRoleDetails()
            setIsValidKey(true)
            // Set Zendesk JWT for chat
            setTimeout(() => {
               // Wait for the token to be set in the cookies
               setZendeskJWT()
            }, 500)
         })
         .catch((e) => {
            setIsValidKey(false)
            bypassMockError(e)
         })
   }

   const loginForRegister = (inviteKey: string, values: LoginFormFieldsType) => {
      runLogin({
         context: {
            poeToken: poeToken.current,
            showErrorModal: true,
         },
         variables: {
            input: {
               ...values,
            },
         },
      })
         .then((res) => {
            const token = res.data?.login?.token
            if (token) {
               auth.setAccessToken(token, getPASETOFooter(token).expiration)
               refreshPOEToken()
            }
            register(inviteKey as string)
         })
         .catch(bypassMockError)
   }

   return {
      register,
      initialLogin,
      loginForRegister,
      setIsValidKey,
      setInviteKey,
      isValidKey,
      inviteKey,
      registerLoading,
      registerError,
      initialLoginLoading,
      initialLoginError,
      loginError,
      loginLoading,
      getRoleData,
      getRoleLoading,
      getRoleError,
      uploadAvatarLoading,
      uploadAvatarError,
   }
}

export default useRegister
export { RegisterUserMutation, UploadAvatarMutation, GetRoleDetailsQuery }
