import { SeparatorLine } from "rentpost/src/components/other"
import styled from "@emotion/styled"
import SVG from "assets/svg"
import { IconButton, Button } from "@mui/material"
import { color, font } from "rentpost/src/styles"
import { padding, size } from "rentpost/src/styles/size"
import { Unit } from "./useUnitAndTenant"
import { getDateString } from "utils/dateValue"
import { useNumericFormat } from "react-number-format"

interface Props {
   unit: Unit
   onEditClick: (unitId: string) => void
   onTenantEditClick: (unitId: string, tenantId: string) => void
   onAddTenantClick: (unitId: string) => void
}

interface ItemProps {
   label: string
   value: string | number
   icon?: React.ReactNode
}

const UnitDetails: React.FC<Props> = (props) => {
   const { id, address, typeKey: type, sqft, bedrooms, bathrooms, tenants } = props.unit
   const handleEditUnitClick = () => props.onEditClick(id)
   const handleEditTenantClick = (tenantId: string) => props.onTenantEditClick(id, tenantId)
   const addressString =
      address?.aptSuiteEtc +
      " " +
      address?.street +
      ", " +
      address?.city +
      ", " +
      address?.state +
      " " +
      address?.postalCode

   const formattedSqFt =
      useNumericFormat({
         value: sqft,
         thousandSeparator: true,
      }).value || " "

   return (
      <Holder>
         <IconButton onClick={handleEditUnitClick} className="edit-button">
            <SVG.form.edit />
         </IconButton>
         <h3>{type}</h3>
         <p className="address">{addressString}</p>
         <SeparatorLine direction="horizontal" style="solid" className="fine-line" />
         <div className="items">
            <Item label="Area (Sq.ft.)" icon={<SVG.misc.area />} value={formattedSqFt} />
            <Item label="Bedrooms" icon={<SVG.misc.bedroom />} value={bedrooms} />
            <Item label="Bathrooms" icon={<SVG.misc.bathroom />} value={bathrooms} />
         </div>
         {tenants && tenants.length > 0 && (
            <div className="tenants-container">
               <h3 style={{ color: color.grayDark }}>Tenants</h3>
               {tenants.map((tenant) => (
                  <TenantDetails key={tenant.id} {...tenant} onEditClick={handleEditTenantClick} />
               ))}
            </div>
         )}
         <Button
            onClick={() => props.onAddTenantClick(id)}
            variant="outlined"
            color="secondary"
            style={{ width: "max-content" }}
         >
            + Add Tenant
         </Button>
      </Holder>
   )
}

const TenantDetails: React.FC<
   Props["unit"]["tenants"][0] & { onEditClick: (id: string) => void }
> = (tenant) => {
   const handleEditClick = () => tenant.onEditClick(tenant.id)
   const formattedRentAmount = useNumericFormat({
      value: tenant.rentAmount,
      thousandSeparator: true,
      decimalScale: 0,
   }).value

   return (
      <div className="tenant-details-container">
         <IconButton onClick={handleEditClick} className="edit-button">
            <SVG.form.edit />
         </IconButton>
         <p className="full-name">{`${tenant.firstName} ${tenant.lastName}`}</p>
         <p className="email">{tenant.email}</p>
         <SeparatorLine direction="horizontal" style="solid" className="fine-line" />
         <div className="items">
            <Item label="Rent" value={`$ ${formattedRentAmount}`} />
            <Item label="Start Date" value={getDateString(tenant.startDate)} />
            <Item label="End Date" value={getDateString(tenant.endDate)} />
         </div>
      </div>
   )
}

const Item: React.FC<ItemProps> = (props) => {
   const { label, value, icon } = props
   return (
      <div className="item-holder">
         <span className="label">{label}</span>
         {icon && <span className="icon">{icon}</span>}
         <p className="value">{value}</p>
      </div>
   )
}

const Holder = styled.div`
   background-color: #f9f9f9;
   border-radius: 1.5em;
   padding: ${padding.block} ${padding.inline};
   border: 1px solid #e8e8e8;
   display: flex;
   flex-direction: column;
   gap: 1.1em;
   position: relative;
   box-sizing: border-box;

   h3 {
      color: ${color.violet};
      text-transform: capitalize;
      margin-block: 0.2em 0.1em;
      line-height: 1;
   }

   .address {
      color: ${color.violet};
      font-size: ${size.m};
      margin-block: 0;
   }

   .items {
      display: flex;
      gap: 2em;
   }

   .edit-button {
      position: absolute;
      top: 0.7em;
      right: 0.7em;
      padding: 0.5em;

      svg {
         fill: ${color.violet};
         width: 0.9em;
      }
   }

   .fine-line {
      height: 1px;
      opacity: 0.5;
      margin-block: 0 0.4em;
   }

   .item-holder {
      display: grid;
      grid-template-columns: auto auto;
      gap: 0em 0.3em;
      align-items: center;

      .label {
         grid-column: 1/3;
         color: ${color.grayLight};
         font-size: 12px;
         line-height: 0.6;
      }

      .icon {
         min-width: 2em;
         svg {
            fill: ${color.grayLight};
            width: 1.5em;
         }
      }

      .value {
         color: ${color.grayDark};
         font-size: ${size.m};
         ${font.regular};
      }
   }

   .tenant-details-container {
      position: relative;
      background-color: #fff;
      border-radius: 1em;
      padding-inline: ${padding.inline};
      padding-block: 2em 1.3em;
      border: 1px solid #f4f4f4;
      display: flex;
      flex-direction: column;
      gap: 1.1em;
      margin-top: 1.4em;

      .full-name {
         color: ${color.violet};
         font-size: ${size.m};
         ${font.regular};
         margin-block: 0.3em 0;
         line-height: 0;
      }

      .email {
         color: ${color.grayDark};
         font-size: ${size.s};
         margin-block: 0;
         line-height: 0.9;
      }

      .items {
         display: flex;
         align-items: center;
         justify-content: space-between;

         .dates {
            display: flex;
            gap: 2.1em;
            align-items: center;

            svg {
               height: 0.9em;
               margin-top: -2.8em;
               stroke: ${color.grayLight};
               opacity: 0.7;
               display: none;
            }
         }
      }
   }
`

export default UnitDetails
