import React from "react"
import { createComponent } from "@lit-labs/react"
import { PageErrorMaintenanceElement as Component } from "web-components"

const PageErrorMaintenanceElement = createComponent({
   tagName: "page-error-maintenance",
   elementClass: Component,
   react: React,
   displayName: "PageErrorMaintenance",
})

export default PageErrorMaintenanceElement
