import styled from "@emotion/styled"
import { Radio, RadioGroup, FormControl, FormControlLabel, Button } from "@mui/material"
import SVG from "assets/svg"
import React, { Fragment, useState } from "react"
import ResendInvite from "./ResendInvite"
import { Link } from "react-router-dom"
import { border, color, screen, size } from "rentpost/styles"
import { AnimatePresence } from "framer-motion"
import { AnimateWithVariants } from "rentpost/components/functional"
import ROUTE from "routes/ROUTE"

interface OptionProps {
   value: "tenant" | "owner" | "manager" | "vendor"
   label: React.ReactNode
   description: string | React.ReactNode
}

const options: OptionProps[] = [
   {
      value: "tenant",
      label: (
         <span className="role-label-holder">
            <SVG.role.tenant /> <span>Tenant</span>
         </span>
      ),
      description:
         "Your property manager will send you an email invite. This email will contain details about your rental and an invite link. Just follow this link to register your account.",
   },
   {
      value: "owner",
      label: (
         <span className="role-label-holder">
            <SVG.role.owner /> <span>Owner</span>
         </span>
      ),
      description:
         "Your property manager will send you an email invite. This email will contain details about your rental and an invite link. Just follow this link to register your account for your rental.",
   },
   {
      value: "manager",
      label: (
         <span className="role-label-holder">
            <SVG.role.manager />
            <span>Manager</span>
         </span>
      ),
      description:
         "Your management company will send an email invite. This email will contain an invite link. Just follow this link to register your account and join your company.",
   },
   {
      value: "vendor",
      label: (
         <span className="role-label-holder">
            <SVG.role.vendor /> <span>Vendor</span>
         </span>
      ),
      description: (
         <p style={{ margin: "0" }}>
            As a vendor, you can be assigned work orders by a management company. When these are
            assigned to you, you’ll receive an email with a unique link that’ll take you directly to
            that work order.
            <br />
            <br />
            If you have not received this email, please reach out to your management company to have
            it resent.
         </p>
      ),
   },
]
const RoleTypes = () => {
   const [selectedRole, setSelectedRole] = useState<OptionProps["value"] | null>(null)
   const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setSelectedRole(event.target.value as OptionProps["value"])
   }
   return (
      <Holder>
         <FormControl>
            <RadioGroup onChange={handleChange} value={selectedRole}>
               {options.map((option, index) => {
                  return (
                     <FormControlLabel
                        key={index}
                        value={option.value}
                        control={<Radio color="primary" />}
                        label={option.label}
                        checked={selectedRole === option.value}
                        className={`${selectedRole === option.value ? "checked" : ""}`}
                     />
                  )
               })}
            </RadioGroup>
         </FormControl>
         <AnimatePresence mode="wait">
            <Fragment key={selectedRole}>
               <AnimateWithVariants
                  animate="pageAnimate"
                  className="selected-role-details"
                  initial="pageInitial"
               >
                  {selectedRole && (
                     <>
                        <p className="role-description">
                           {options.find((option) => option.value === selectedRole)?.description}
                        </p>
                        {selectedRole !== "vendor" && <ResendInvite />}
                        {selectedRole === "manager" && (
                           <div className="create-new-company box">
                              <p>
                                 If you're not joining another management company already setup on
                                 RentPost, and would like to create a new management company account
                                 of your own, you can do so by clicking the button below.
                              </p>
                              <p>
                                 This will create a new management company and manager role for
                                 yourself, and associated with your same user account.
                              </p>
                              <Link className="create-new-company-link" to={ROUTE.SIGN_UP}>
                                 <Button variant="outlined">Create new Management Company</Button>
                              </Link>
                           </div>
                        )}
                     </>
                  )}
                  {!selectedRole && (
                     <div className="no-role-selected">
                        <SVG.form.info />
                        <span>Please select a role type to get started</span>
                     </div>
                  )}
               </AnimateWithVariants>
            </Fragment>
         </AnimatePresence>
      </Holder>
   )
}

const Holder = styled.div`
   position: relative;
   display: flex;
   flex-direction: column;
   justify-content: space-around;
   gap: 2em;
   margin-top: 1em;
   width: 100%;
   max-width: 40em;
   font-size: 0.9em;
   .MuiFormGroup-root {
      display: flex;
      flex-direction: column;
      gap: 1em;
      ${border.bottom};
      padding-bottom: 3em;

      .MuiFormControlLabel-root {
         --color: #b4b4b4;
         margin-bottom: 1em;
         padding: 0.5em 1.5em;
         background-color: color-mix(in srgb, var(--color), #ffffff 95%);
         border: 1px solid transparent;
         border-radius: 0.6em;
         gap: 1em;
         color: var(--color);
         transition: all 0.2s ease-in;
         margin: 0;
         .MuiRadio-root {
            color: var(--color) !important;
         }

         .role-label-holder {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: 2em;
            span {
               color: inherit;
               font-size: 1.3em;
            }
            svg {
               width: 2em;
               height: 2.1em;
               fill: currentColor;
            }
         }

         /* checked */
         &.checked {
            svg {
               fill: var(--color);
            }
            --color: ${color.green};
            span {
               color: var(--color);
            }
            border-color: color-mix(in srgb, var(--color), #ffffff 20%);
            box-shadow: 0px 0px 8px 1px color-mix(in srgb, var(--color), #ffffff 70%);
         }
      }
   }

   .selected-role-details {
      display: flex;
      flex-direction: column;
      gap: 2em;
      width: 100%;
      min-height: 29em;

      .create-new-company {
         display: flex;
         flex-direction: column;
         align-items: center;
         background-color: #eefff8;
         p {
            color: color-mix(in srgb, ${color.green}, #000 30%);
            text-align: center;
         }
      }
   }
   ${screen.onlyMobile} {
      .box {
         padding-inline: 2em;
         margin-inline: -2em;
         border-radius: 0;
         box-sizing: border-box;
      }
   }

   .no-role-selected {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 2em;
      color: #c5c5c5;
      span {
         font-size: 1.2em;
      }
      svg {
         width: 4em;
         height: 4em;
         fill: currentColor;
         opacity: 0.5;
      }
   }
`

export default RoleTypes
