import { useMutation, useLazyQuery } from "@apollo/client"
import { gql } from "@apollo/client"
import usePOEToken from "hooks/usePOEToken"
import bypassMockError from "utils/bypassMockError"
import { ResendInvite, ResendInviteVariables } from "./__generated__"
import { GetCompanyInfoQuery } from "pages/Onboarding/BusinessInfo/useCompanyInfo"
import { GetCompanyInfo } from "pages/Onboarding/BusinessInfo/__generated__"

interface CompanyInfo {
   email: string
   phone?: string
   phoneTwo?: string
}

// ========
// MUTATION
// ========
const ResendInviteMutation = gql`
   mutation ResendInvite($email: Email!) {
      resendInvite(email: $email)
   }
`

// =======
// QUERIES
// =======
export { ResendInviteMutation }
// ======================
// USE Resend Invite HOOK
// ======================
const useResendInvite = () => {
   const [
      runResendInvite,
      { data: isInviteSent, loading: resendInviteLoading, error: resendInviteError },
   ] = useMutation<ResendInvite, ResendInviteVariables>(ResendInviteMutation)

   const [
      runGetCompanyInfo,
      { data: companyInfo, loading: getCompanyInfoLoading, error: getCompanyInfoError },
   ] = useLazyQuery<GetCompanyInfo>(GetCompanyInfoQuery)

   const { poeToken, refreshPOEToken } = usePOEToken()

   // -------------
   // Resend Invite
   // -------------
   const resendInvite = (email: string) => {
      runResendInvite({
         context: {
            poeToken: poeToken.current,
            showErrorModal: true,
            canWorkWithoutAuthorization: true,
         },
         variables: {
            email,
         },
      })
         .then(refreshPOEToken)
         .catch((err) => {
            bypassMockError(err)
         })
   }

   // ----------------
   // Get Company Info
   // ----------------
   const getCompanyInfo = () => {
      runGetCompanyInfo({
         context: {
            poeToken: poeToken.current,
            showErrorModal: true,
         },
      })
         .then(refreshPOEToken)
         .catch((err) => {
            bypassMockError(err)
         })
   }

   return {
      resendInvite,
      getCompanyInfo,
      data: {
         isInviteSent: isInviteSent?.resendInvite as boolean | undefined,
         companyInfo: companyInfo?.company?.legalEntity as CompanyInfo,
      },
      loading: {
         resendInvite: resendInviteLoading,
         getCompanyInfo: getCompanyInfoLoading,
      },
      error: {
         resendInvite: resendInviteError,
         getCompanyInfo: getCompanyInfoError,
      },
   }
}

export type { CompanyInfo }
export default useResendInvite
