// @ts-ignore
import env from "configs/env"

type UTMCookiesTypes = "utm_source" | "utm_medium" | "utm_campaign" | "utm_content" | "utm_term"
type UTMCookiesValues = { [key in UTMCookiesTypes]: string | null }

const domain = (() => {
   switch (env.TARGET_ENV) {
      case "production":
         return ".rentpost.com"
      case "stage":
         return ".stage.rentpost.com"
      case "development":
         return ".rentpost.test"
      default:
         return ".rentpost.test"
   }
})()

const UTMCookies = {
   get: (name: UTMCookiesTypes): string | null => {
      const cookies = document.cookie.split(";")
      const cookie = cookies.find((c) => c === "utm")
      const cookieValue = cookie?.split("=")[1]
         ? (JSON.parse(cookie.split("=")[1]) as UTMCookiesValues)
         : null
      return cookieValue ? cookieValue[name] : null
   },
   set: (value: UTMCookiesValues): void => {
      document.cookie = `utm=${value}; path=/; domain=${domain}; secure=true; samesite=lax`
   },
}

export default UTMCookies
