import { ShowApiKeyComponentProps } from "."
import { Button } from "@mui/material"
import { useEffect, useState } from "react"
import SVG from "rentpost/assets/svg"

const ShowAPIKeyField: React.FC<ShowApiKeyComponentProps> = ({ newAPIKey, onClose }) => {
   const copyToClipboard = () => {
      navigator.clipboard.writeText(newAPIKey)
      setCopied(true)
   }

   const [copied, setCopied] = useState<boolean>(false)

   useEffect(() => {
      if (copied) {
         setTimeout(() => {
            // Wait for 3 seconds before hiding the copied tag
            setCopied(false)
         }, 3000)
      }
   }, [copied])

   return (
      <div className="show-api_key-holder">
         <div className="api-key">
            <textarea readOnly value={newAPIKey} className="key" />
            <span
               className="copied-tag"
               style={
                  copied
                     ? { opacity: 1, pointerEvents: "initial" }
                     : { opacity: 0, pointerEvents: "none" }
               }
            >
               Copied!
            </span>
         </div>
         <div className="modal-action-buttons">
            <Button type="button" variant="outlined" color="secondary" onClick={onClose}>
               Close
            </Button>
            <Button
               type="button"
               variant="contained"
               color="primary"
               onClick={copyToClipboard}
               startIcon={<SVG.form.copy />}
            >
               Copy Key
            </Button>
         </div>
      </div>
   )
}

export default ShowAPIKeyField
