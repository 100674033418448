import {
   DateField,
   Form,
   HiddenField,
   HorizontalLine,
   PhoneNumberField,
   TextField,
} from "rentpost/components/form"
import { Button, CircularProgress } from "@mui/material"
import * as yup from "yup"
import { ApolloError } from "@apollo/client"
import { TenantFormFields } from "./useUnitAndTenant"
import SelectField from "rentpost/src/components/form/SelectField"
import { LegalEntityTypeKey, LIST_LEGAL_ENTITY_TYPES } from "../sharedData"
import _ from "lodash"
import dayjs, { Dayjs } from "dayjs"
import { getDate } from "utils/dateValue"

interface Props {
   loading: boolean
   error: ApolloError | undefined
   onSubmit: (values: TenantFormFields) => void
   onCancel: () => void
   initialValues: TenantFormFields
}

const validationSchema = yup.object({
   typeKey: yup.string().required("Type is required!"),
   firstName: yup.string().when("typeKey", {
      is: (typeKey: LegalEntityTypeKey) =>
         typeKey === "natural_person" || typeKey === "sole_proprietorship",
      then: yup.string().required("First name is required!"),
      otherwise: yup.string().nullable(),
   }),
   lastName: yup.string().when("typeKey", {
      is: (typeKey: LegalEntityTypeKey) =>
         typeKey === "natural_person" || typeKey === "sole_proprietorship",
      then: yup.string().required("Last name is required!"),
      otherwise: yup.string().nullable(),
   }),
   companyName: yup.string().when("typeKey", {
      is: (typeKey: LegalEntityTypeKey) =>
         typeKey !== "natural_person" && typeKey !== "sole_proprietorship",
      then: yup.string().required("Company name is required!"),
      otherwise: yup.string().nullable(),
   }),
   email: yup.string().email("Invalid email address"),
   phone: yup.string(),
   phoneExt: yup.string(),
   startDate: yup
      .string()
      .nullable()
      .test(
         "startDate-not-empty",
         "Start date is required",
         (value) => value !== undefined && value !== null && value !== ""
      )
      .test("is-valid", "Invalid start date", (value) => dayjs(value).isValid()),
   endDate: yup
      .string()
      .nullable()
      .test(
         "endDate-not-empty",
         "End date is required",
         (value) => value !== undefined && value !== null && value !== ""
      )
      .test("is-valid", "Invalid end date", (value) => dayjs(value).isValid())
      .when("startDate", {
         is: (startDate: Dayjs | null) => startDate && dayjs(startDate).isValid(),
         then: (schema) =>
            schema.test(
               "is-after-startDate",
               "End date must be after start date",
               function (value) {
                  const { startDate } = this.parent
                  return !value || !startDate || dayjs(value).isAfter(dayjs(startDate))
               }
            ),
      }),
   rentDueDay: yup.string().required("Rent due date is required!"),
   rentAmount: yup.string().required("Rent amount is required!"),
   sendInvite: yup.boolean(),
})

const TenantForm: React.FC<Props> = (props) => {
   const { loading, error, onSubmit, initialValues, onCancel } = props
   const legalEntityTypeOptions = _.flatMap(LIST_LEGAL_ENTITY_TYPES, (label, value) => {
      return { value, label }
   })
   const getDaySuffix = (day: string) => {
      switch (+day) {
         case 1:
            return "st"
         case 2:
            return "nd"
         case 3:
            return "rd"
         default:
            return "th"
      }
   }

   const setInitialEndDateAfterSetStartDate = ({
      startDate,
      endDate,
      isFieldTouched,
      setFieldValue,
   }: {
      startDate: Dayjs | null
      endDate: Dayjs | null
      isFieldTouched: boolean
      setFieldValue: (value: any) => void
   }) => {
      if (isFieldTouched || !startDate?.isValid() || (endDate && !endDate?.isValid()))
         return endDate
      const startDateValue = startDate as Dayjs
      const endDateValue = startDateValue.add(1, "year").subtract(1, "day")
      setFieldValue(endDateValue)
   }
   return (
      <Form
         initialValues={initialValues}
         validationSchema={validationSchema}
         onSubmit={onSubmit}
         error={error}
         loading={loading}
         showErrorHint
      >
         {({ isValid, dirty, values, touched }) => {
            const disabled = !isValid || loading || !dirty
            const tenantId = values.id
            const isUpdate = !!tenantId
            const dueDay = values.rentDueDay
            setInitialEndDateAfterSetStartDate({
               startDate: values.startDate,
               endDate: values.endDate,
               isFieldTouched: touched.endDate as boolean,
               setFieldValue: (value) => {
                  values.endDate = value
               },
            })

            const tenantType = values?.typeKey as LegalEntityTypeKey
            const showTenantNameFields =
               tenantType === "natural_person" || tenantType === "sole_proprietorship"

            return (
               <>
                  {tenantId && <HiddenField name="id" />}
                  <HiddenField name="unitId" />
                  <SelectField label="Type" name="typeKey" options={legalEntityTypeOptions} />
                  {showTenantNameFields ? (
                     <>
                        <TextField label="First Name" name="firstName" />
                        <TextField label="Last Name" name="lastName" />
                     </>
                  ) : (
                     <TextField label="Company Name" name="companyName" />
                  )}
                  <TextField label="Email" name="email" />
                  <div
                     style={{
                        display: "flex",
                        gap: "1em",
                        alignItems: "center",
                     }}
                  >
                     <div style={{ flex: 2, marginBlock: "0.4em" }}>
                        <PhoneNumberField label="Phone" name="phone" />
                     </div>
                     {!showTenantNameFields && (
                        <div style={{ flex: 1 }}>
                           <TextField label="Ext." name="phoneExt" />
                        </div>
                     )}
                  </div>
                  <HorizontalLine />
                  <h3>Lease Terms</h3>
                  <DateField label="Start Date" name="startDate" />
                  <DateField
                     label="End Date"
                     name="endDate"
                     minDate={
                        values.startDate ? getDate(values.startDate).add(1, "day") : undefined
                     }
                  />
                  <TextField
                     label="Rent Due Day"
                     name="rentDueDay"
                     numberFormat={{
                        decimalScale: 0,
                        allowNegative: false,
                        allowDecimal: false,
                        thousandSeparator: false,
                        allowLeadingZeros: false,
                        suffix: `${getDaySuffix(dueDay)}`,
                        isAllowed: (values) => {
                           const { value } = values
                           return value === "" || (+value >= 1 && +value <= 28)
                        },
                     }}
                  />
                  <TextField
                     label="Rent Amount"
                     name="rentAmount"
                     numberFormat={{
                        allowNegative: false,
                        allowDecimal: true,
                        decimalScale: 0,
                        thousandSeparator: true,
                        isNumericString: true,
                        prefix: "$ ",
                     }}
                  />
                  <div className="action-buttons-holder">
                     <Button
                        variant="text"
                        color="secondary"
                        className="back-button"
                        onClick={onCancel}
                     >
                        Cancel
                     </Button>
                     <Button
                        variant="contained"
                        color="primary"
                        endIcon={loading ? <CircularProgress size={"100%"} /> : null}
                        disabled={disabled}
                        className="next-button"
                        type="submit"
                     >
                        {isUpdate ? "Update Tenant" : "Add Tenant"}
                     </Button>
                  </div>
               </>
            )
         }}
      </Form>
   )
}

export default TenantForm
