import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { ModalLogoutProps } from "layouts/customModals/ModalLogout"

type ActiveModalType =
   | "error"
   | "twoFactorAuth"
   | "logout"
   | "loginSuspended"
   | "twoFactorAuthSetup"
   | "adBlockerWarning"
   | false

interface ModalStateProps {
   activeModal: ActiveModalType
   errorMessage?: string
   logoutReason?: ModalLogoutProps["logoutReason"]
   timeoutAmount?: number
}

const initialState: ModalStateProps = {
   activeModal: false,
   errorMessage: undefined,
   logoutReason: undefined,
   timeoutAmount: undefined,
}

const modalSlice = createSlice({
   name: "modal",
   initialState,
   reducers: {
      // ------------------------
      // OPEN ERROR MODAL REDUCER
      // ------------------------
      openErrorModal: (state, action: PayloadAction<string | undefined>) => {
         state.activeModal = "error"
         state.errorMessage = action.payload
      },

      // ------------------------------------
      // OPEN TWO FACTOR AUTHENTICATION MODAL
      // ------------------------------------
      openTwoFactorAuthModal: (state) => {
         state.activeModal = "twoFactorAuth"
      },

      // ------------------------------------
      // OPEN TWO FACTOR AUTHENTICATION MODAL
      // ------------------------------------
      openTwoFactorAuthSetupModal: (state) => {
         state.activeModal = "twoFactorAuthSetup"
      },

      // -----------------
      // OPEN LOGOUT MODAL
      // -----------------
      openLogoutModal: (state, action: PayloadAction<ModalLogoutProps["logoutReason"]>) => {
         state.activeModal = "logout"
         state.logoutReason = action.payload
      },

      // --------------------------
      // OPEN LOGIN SUSPENDED MODAL
      // --------------------------
      openLoginSuspendedModal: (state, action: PayloadAction<number>) => {
         state.activeModal = "loginSuspended"
         state.timeoutAmount = action.payload
      },

      // -----------------------------
      // OPEN AD BLOCKER WARNING MODAL
      // -----------------------------
      openAdBlockerWarningModal: (state) => {
         state.activeModal = "adBlockerWarning"
      },

      // -------------------
      // CLOSE MODAL REDUCER
      // -------------------
      closeModal: (state) => {
         state.activeModal = false
         state.errorMessage = undefined
      },
   },
})

export type { ModalStateProps }
export const {
   closeModal,
   openErrorModal,
   openTwoFactorAuthModal,
   openLogoutModal,
   openLoginSuspendedModal,
   openTwoFactorAuthSetupModal,
   openAdBlockerWarningModal,
} = modalSlice.actions
export default modalSlice.reducer
