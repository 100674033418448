import SVG from "rentpost/assets/svg"
import { AuthorizedDevice } from "./UseSecurity"
import styled from "@emotion/styled"

interface Props {
   clientName: string | undefined | null
   className?: string
}

const ClientIcon: React.FC<Props> = (props) => {
   let clientIcon = <SVG.device.unknown style={{ fill: "#CBCBCB" }} />
   const clientName = props.clientName || ""
   const similarTo = (name: string): boolean =>
      clientName.toLowerCase()?.includes(name.toLowerCase())

   switch (true) {
      case similarTo("chrome"):
         clientIcon = <SVG.browser.chrome className={props.className ?? ""} />
         break

      case similarTo("firefox"):
         clientIcon = <SVG.browser.firefox className={props.className ?? ""} />
         break

      case similarTo("safari"):
         clientIcon = <SVG.browser.safari className={props.className ?? ""} />
         break

      case similarTo("edge"):
         clientIcon = <SVG.browser.edge className={props.className ?? ""} />
         break

      case similarTo("opera"):
         clientIcon = <SVG.browser.opera className={props.className ?? ""} />
         break

      case similarTo("uc"):
         clientIcon = <SVG.browser.uc className={props.className ?? ""} />
         break

      case similarTo("samsung"):
         clientIcon = <SVG.browser.samsungInternet className={props.className ?? ""} />
         break

      case similarTo("brave"):
         clientIcon = <SVG.browser.brave className={props.className ?? ""} />
         break

      case similarTo("vivaldi"):
         clientIcon = <SVG.browser.vivaldi className={props.className ?? ""} />
         break

      case similarTo("maxthon"):
         clientIcon = <SVG.browser.maxthon className={props.className ?? ""} />
         break

      case similarTo("qq"):
         clientIcon = <SVG.browser.qq className={props.className ?? ""} />
         break

      case similarTo("ie"):
         clientIcon = <SVG.browser.internetExplorer className={props.className ?? ""} />
         break

      case similarTo("chromium"):
         clientIcon = <SVG.browser.chromium className={props.className ?? ""} />
         break

      case similarTo("tor"):
         clientIcon = <SVG.browser.tor className={props.className ?? ""} />
         break

      default:
         clientIcon = (
            <SVG.device.unknown style={{ fill: "#CBCBCB" }} className={props.className ?? ""} />
         )
   }
   return clientIcon
}

export default ClientIcon
