import { Integration } from "../useIntegrations"
import styled from "@emotion/styled"
import { useEffect, useState } from "react"
import { Button, CircularProgress } from "@mui/material"
import { color } from "rentpost/styles"
import _ from "lodash"
import { Hint } from "rentpost/components/form"
import Modal from "rentpost/layouts/Modal"
import { BackButton, RoleCardProps } from "rentpost/components/other"
import IntegrationRoleCard from "./IntegrationRoleCard"
import { padding } from "rentpost/styles/size"

interface Props {
   integration: Integration
   loading: {
      revokeAPIKey: boolean
      removeIntegration: boolean
      addAPIKey: boolean
      getIntegrations: boolean
   }
   associatedRoles: RoleCardProps["role"][]
   revokeAPIKey: (id: string) => void
   removeIntegration: (id: string) => void
   onGoBack: () => void
   onAddNewCustomKey: (integrationId: string) => void
   onAddNewZapierKey: () => void
}

const IntegrationDetails: React.FC<Props> = (props) => {
   const {
      integration,
      loading,
      removeIntegration,
      revokeAPIKey,
      onGoBack,
      onAddNewCustomKey,
      onAddNewZapierKey,
   } = props

   if (!integration) {
      onGoBack()
      return null // If there is no integration, do nothing
   }

   // ================
   // States & Effects
   // ================
   const [revokeAPIKeyId, setRevokeAPIKeyId] = useState<string | null>(null)
   const [willRemoveIntegration, setWillRemoveIntegration] = useState(false)

   useEffect(() => {
      revokeAPIKeyId && revokeAPIKey(revokeAPIKeyId)
   }, [revokeAPIKeyId])

   const handleRemoveIntegration = () => {
      removeIntegration(integration.id)
   }

   const handleAddNewKey = () => {
      integration.type === "zapier" && onAddNewZapierKey()
      integration.type === "custom" && onAddNewCustomKey(integration.id)
   }

   const isZapier = integration.type === "zapier"

   return (
      <Holder>
         <div className="buttons-holder">
            <BackButton onClick={onGoBack} />
            <Button
               onClick={() => setWillRemoveIntegration(true)}
               color="error"
               variant="outlined"
               disabled={loading.removeIntegration}
               endIcon={loading.removeIntegration ? <CircularProgress size={"100%"} /> : null}
               style={{ borderColor: "currentcolor" }}
               size="small"
            >
               Remove
            </Button>
         </div>
         <div className="integration-details-holder">
            <h2>{_.capitalize(integration.name)}</h2>
            <p>{integration.description}</p>
         </div>
         <Hint
            text="Note"
            type="info"
            canCollapseDescription={false}
            collapsibleDescription={
               <p>
                  {isZapier ? (
                     <>
                        <span>
                           The Zapier integration requires that you provide Zapier with the
                           integration key displayed when you first setup the integration. For more
                           information on this process.{" "}
                           <a
                              href="https://www.fortinet.com/resources/cyberglossary/api-key"
                              className="italic"
                              target="_blank"
                           >
                              please read our support article.
                           </a>
                        </span>
                        <br />
                        <br />
                        <span className="italic">
                           If you forgot to copy the API key, you will need to remove the
                           integration and add it again.
                        </span>
                     </>
                  ) : (
                     <>
                        <span>
                           In order to use this integration, you need to paste the API key in the
                           service, application, or website you want to connect to RentPost.
                        </span>
                        <br />
                        <br />
                        <span className="italic">
                           for more information on what is an API key and how to use it please visit
                           that{" "}
                           <a
                              href="https://www.fortinet.com/resources/cyberglossary/api-key"
                              className="italic"
                              target="_blank"
                           >
                              link
                           </a>
                           .
                        </span>
                     </>
                  )}
               </p>
            }
         />
         <div className="tokens-holder">
            <h3>{isZapier ? "Authorized Roles" : "Issued API Keys"}</h3>
            {integration.authTokens.map((authToken) => {
               return (
                  <IntegrationRoleCard
                     key={authToken.id}
                     authToken={authToken}
                     loading={loading.revokeAPIKey && revokeAPIKeyId === authToken.id}
                     revokeAPIKey={setRevokeAPIKeyId}
                     associatedRole={
                        props.associatedRoles.filter(
                           (role) => role.id === authToken.roleId
                        )[0] as RoleCardProps["role"]
                     }
                  />
               )
            })}
            <Button
               onClick={handleAddNewKey}
               variant="outlined"
               color="primary"
               className="add-new-key-button"
            >
               {isZapier ? "Authorize New Role" : "Add New Key"}
            </Button>
         </div>
         <Modal
            title="Remove Integration!"
            description={
               <p className="main-description">
                  Are you sure you want to remove this integration? <br /> this action cannot be
                  undone.
               </p>
            }
            onClose={() => {
               onGoBack()
               setWillRemoveIntegration(false)
            }}
            visible={willRemoveIntegration}
            canIgnore={true}
            loading={loading.removeIntegration || loading.getIntegrations}
            type="warning"
            actionButtons={{
               accept: {
                  label: "Remove Integration",
                  onClick: handleRemoveIntegration,
               },
               cancel: {
                  label: "Cancel",
                  onClick: () => setWillRemoveIntegration(false),
               },
            }}
         />
      </Holder>
   )
}

const Holder = styled.div`
   display: flex;
   flex-direction: column;
   background-color: #f8f8f8;
   padding: ${padding.block} ${padding.inline};
   border-radius: 1em;

   .buttons-holder {
      display: flex;
      justify-content: space-between;
      align-items: center;
   }

   .integration-details-holder {
      display: flex;
      flex-direction: column;
   }

   .tokens-holder {
      display: flex;
      flex-direction: column;
      gap: 1em;

      h3 {
         /* color: ${color.green}; */
         margin-bottom: 0;
      }

      .add-new-key-button {
         width: max-content;
      }
   }
`

export default IntegrationDetails
