import styled from "@emotion/styled"
import SVG from "assets/svg"
import { color, font, size, space } from "styles"
import { LogoLink } from "components/other"
import { useNavigate } from "react-router-dom"
import ROUTE from "routes/ROUTE"

const Illustration = () => {
   const navigate = useNavigate()
   return (
      <Holder>
         <div className="desktop">
            <span className="background" />
            <span className="logo">
               <LogoLink />
            </span>
            <h2>Get started now, and manage your properties from anywhere</h2>
            <div className="illustration">
               <SVG.signUpIllustrationSVG.buildingBlocks className="building-blocks" />
            </div>
         </div>
         <div className="tablet-and-mobile" onClick={() => navigate(ROUTE.INDEX)}>
            <div className="logo">
               <SVG.logo.wordColored className="colored" />
               <SVG.logo.word className="white" />
            </div>
            <div className="background-holder">
               <div className="illustration">
                  <SVG.signUpIllustrationSVG.buildingBlocks className="building-blocks" />
                  <SVG.signUpIllustrationSVG.sun className="sun-illustration" />
               </div>
            </div>
         </div>
      </Holder>
   )
}
const Holder = styled.div`
   .desktop {
      display: flex;
      flex-direction: column;
      padding-inline: ${space.xl};
      padding-top: ${space.xl};
      padding-bottom: 0;
      font-size: ${size.m};
      position: relative;
      overflow: hidden;
      gap: ${space.m};
      width: 90%;
      height: 100%;
      .background {
         position: absolute;
         top: 0;
         left: 0;
         width: 85%;
         height: 100%;
         background: linear-gradient(180deg, #75d3a8 0%, #05a5a7 100%);
         z-index: -1;
      }
      .logo {
         width: 11em;
         fill: ${color.white};
      }
      h2 {
         color: ${color.white};
         max-width: 86%;
         font-size: 1.4em;
         ${font.bold};
         opacity: 0.6;
         user-select: none;
      }
      .illustration {
         position: absolute;
         bottom: 3em;
         right: 3.4em;
         align-self: flex-end;
         pointer-events: none;
         width: 100%;
         height: 100%;
         --width-factor: min(33vw, 55em);
         svg.building-blocks {
            position: absolute;
            bottom: -1em;
            right: 0;
            width: calc(var(--width-factor) * 1.2);
         }
         img.home-building {
            position: absolute;
            bottom: 0;
            left: -3em;
            width: calc(var(--width-factor) * 0.6);
            z-index: 3;
         }
         svg.rent-sign {
            position: absolute;
            bottom: 0;
            right: 0;
            z-index: 3;
            width: calc(var(--width-factor) * 0.3);
         }
      }
   }
   /* -------------- */
   /* Responsiveness */
   /* -------------- */
   .tablet-and-mobile {
      /* This --animation-factor is updated when the page scrolls (check <MultiSectionsLayout/>) */
      --animation-factor: 0;

      position: relative;
      display: flex;
      width: 100%;
      overflow: hidden;

      .background-holder {
         width: 100%;
         opacity: calc(1 - var(--animation-factor) + 0);
         height: 100%;
         background: linear-gradient(180deg, #91e9c2 0%, #05a5a7 100%);
         &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, max(0, calc(0.3 - var(--animation-factor))));
            z-index: 2;
            backdrop-filter: blur(calc(7px * max(var(--animation-factor), 0.1)));
         }
      }

      .illustration {
         position: relative;
         width: 100%;
         height: 100%;
         bottom: -0.1em;
         left: 0em;
         align-self: flex-start;

         svg.building-blocks {
            position: absolute;
            bottom: -1em;
            left: -3em;
            width: 25em;
         }

         img.home-building {
            position: absolute;
            bottom: 0;
            left: -2em;
            width: 14em;
            z-index: 1;
         }

         svg.sun-illustration {
            position: absolute;
            top: 0;
            right: 0;
            transform: translateY(-10%);
            width: 33em;
            z-index: 1;
         }

         svg.rent-sign {
            position: absolute;
            bottom: 0;
            right: 0em;
            z-index: 2;
            width: 11em;
         }
      }

      .logo {
         position: absolute;
         top: 50%;
         left: 50%;
         width: 14em;
         transform: translate(-50%, -50%) scale(max(calc(1 - var(--animation-factor)), 0.8));
         z-index: 3;
         display: flex;
         justify-content: center;
         align-items: center;

         svg {
            position: absolute;
            width: 100%;
            &.white {
               fill: ${color.white};
               stroke: ${color.violet};
               opacity: calc(1 - var(--animation-factor));
               stroke-width: 1px;
            }
            &.colored {
               opacity: 1;
            }
         }
      }
   }
`

export default Illustration
