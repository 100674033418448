import SVG from "assets/svg"
import { useEffect, useState } from "react"
import { MultiChoiceFormContainer } from "rentpost/components/form"
import { Avatar, ImageUploader, RoleType } from "rentpost/components/other"
import Modal, { ModalProps } from "rentpost/layouts/Modal"
import styled from "@emotion/styled"
import useGenerateAvatar from "rentpost/hooks/useGenerateAvatar"
import { Button, CircularProgress } from "@mui/material"

interface Props {
   visible: boolean
   onClose: () => void
   currentAvatarImageURL: string | undefined
   displayName: string
   roleID: string
   roleType: RoleType
   onSave: (avatarImage: string) => void
   loading: boolean
}

interface AvatarImageSelectorProps {
   onSelectImage: (imageURL: string) => void
   displayName: string
   roleID: string
}

const ModalUpdateAvatar: React.FC<Props> = (props) => {
   const modalProps: ModalProps = {
      visible: props.visible,
      onClose: props.onClose,
      canIgnore: true,
      fullscreenOnMobile: true,
      renderComponent: {
         show: true,
         component: () => <UploadAvatarComponent {...props} />,
      },
   }

   return <Modal {...modalProps} />
}

const UploadAvatarComponent: React.FC<Props> = (props) => {
   const {
      visible,
      onClose,
      currentAvatarImageURL,
      displayName,
      roleID,
      onSave,
      roleType,
      loading,
   } = props
   if (!visible) return null

   const [avatarImage, setAvatarImage] = useState<string | undefined>(currentAvatarImageURL)
   const handleSave = () => {
      if (!avatarImage) return
      onSave(avatarImage)
   }

   const updateDisabled = !avatarImage || loading || avatarImage === currentAvatarImageURL
   // close modal after saving is complete
   useEffect(() => {
      if (!loading && avatarImage && avatarImage !== currentAvatarImageURL) {
         onClose()
      }
   }, [loading])

   const avatarImageSelectorProps = {
      onSelectImage: setAvatarImage,
      displayName,
      roleID,
   }

   return (
      <Holder className={roleType}>
         <h2>Upload Avatar</h2>
         <p className="main-description">
            You can upload your avatar image, or choose from one of the randomly generated ones
            below.
         </p>
         <Avatar roles={[{ displayName, avatarImage }]} className="result-avatar" />
         <AvatarImageSelector {...avatarImageSelectorProps} />
         <div className="modal-action-buttons">
            <Button variant="outlined" color="secondary" onClick={onClose}>
               Cancel
            </Button>
            <Button
               variant="contained"
               color="primary"
               onClick={handleSave}
               disabled={updateDisabled}
               endIcon={loading ? <CircularProgress size={"100%"} /> : null}
            >
               Save
            </Button>
         </div>
      </Holder>
   )
}

const AvatarImageSelector: React.FC<AvatarImageSelectorProps> = (props) => {
   const { onSelectImage, displayName, roleID } = props
   const { generatedAvatars, generateNewAvatars } = useGenerateAvatar({
      roleID,
   })

   return (
      <MultiChoiceFormContainer
         className="avatar-image-options"
         labeWidth={152}
         choices={[
            {
               label: "Generated Avatar",
               render: (
                  <div className="generated-avatar-container">
                     {generatedAvatars.map((image, index) => (
                        <Avatar
                           className="avatar-element"
                           key={index}
                           roles={[{ displayName, avatarImage: image }]}
                           onClick={() => onSelectImage(image)}
                        />
                     ))}
                     <Button
                        onClick={generateNewAvatars}
                        variant="outlined"
                        color="secondary"
                        startIcon={<SVG.form.refresh />}
                        fullWidth
                     >
                        Generate New Avatars
                     </Button>
                  </div>
               ),
            },
            {
               label: "Upload Image",
               render: <ImageUploader maxImageSize={2 * 1024 * 1024} onSaveImage={onSelectImage} />,
            },
         ]}
      ></MultiChoiceFormContainer>
   )
}

// -----------
// Role Colors
// -----------
const managerColor = "#000"
const tenantColor = "#2F2489"
const ownerColor = "#8FAD2A"
const prospectColor = "#721654"
const vendorColor = "#D27638"
const otherColor = "#663819"

const Holder = styled.div`
   display: flex;
   flex-direction: column;
   gap: 0.9em;
   --active-role-color: ${managerColor};
   --holder-background-color: var(--background-color);

   .result-avatar {
      position: relative;
      margin-block: -0.9em 4em;
      align-self: center;
      left: -8%;
      .avatar-item {
         scale: 1.7;
         transform-origin: top left;
      }
   }

   .avatar-image-options {
      .label {
         display: flex;
         align-items: center;
         justify-content: center;
         gap: 0.5em;
         color: inherits;

         svg {
            width: 1.5em;
            height: 1.5em;
            fill: red !important;
         }
      }
   }

   .generated-avatar-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 0.3em;
      align-items: center;
      justify-content: space-around;
      min-height: 10em;
      height: 100%;
      .avatar-element {
         .avatar-item {
            border-color: #c9c9c9;
            opacity: 0.8;
            transition: all 0.1s ease-in-out;
         }
         &:hover .avatar-item {
            opacity: 1;
            border-color: var(--active-role-color) !important;
         }
      }
   }

   /* Role Colors */
   &.manager {
      --active-role-color: ${managerColor};
   }
   &.tenant {
      --active-role-color: ${tenantColor};
   }
   &.owner {
      --active-role-color: ${ownerColor};
   }
   &.prospect {
      --active-role-color: ${prospectColor};
   }
   &.vendor {
      --active-role-color: ${vendorColor};
   }
   &.employee {
      --active-role-color: ${otherColor};
   }
`
export default ModalUpdateAvatar
