import { MultiSectionsLayout } from "layouts"
import Illustration from "pages/Login/Illustration"
import styled from "@emotion/styled"
import { useEffect } from "react"
import RoleTypes from "./RoleTypes"
import { padding } from "rentpost/styles/size"
import hideLoadingPage from "utils/hideLoadingPage"
import { useNavigate } from "react-router-dom"
import { BackButton } from "rentpost/src/components/other"

const Start = () => {
   return (
      <MultiSectionsLayout
         mainSection={<MainSection />}
         htmlTitle="Start | RentPost"
         illustrationSection={<Illustration />}
      />
   )
}

const MainSection = () => {
   useEffect(() => hideLoadingPage(), [])
   const navigate = useNavigate()
   const goBack = () => navigate(-1)

   return (
      <Holder>
         <div className="content-holder">
            <BackButton onClick={goBack} />
            <h1>Get started with RentPost</h1>
            <p className="main-description">
               Getting started with RentPost is easy. First select the role you'll be using RentPost
               as:
            </p>
            <RoleTypes />
         </div>
      </Holder>
   )
}

const Holder = styled.div`
   display: grid;
   place-content: center;
   padding: 0em !important;

   .content-holder {
      padding: 0;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      box-sizing: border-box;
      padding: ${padding.block} ${padding.inline};
   }
`

export default Start
