import { configureStore } from "@reduxjs/toolkit"
import modal from "./slices/modalSlice"
import graphql_2FA_RequiredOperation from "./slices/graphql_2FA_RequiredOperationSlice"
import secondaryAuthToken from "./slices/secondaryAuthTokenSlice"
import settingsSlice from "./slices/settingsSlice"
import { sentryReduxEnhancer } from "configs/sentry"

const store = configureStore({
   reducer: { modal, graphql_2FA_RequiredOperation, secondaryAuthToken, settingsSlice },
   enhancers: [sentryReduxEnhancer],
   // enhancers: (getDefaultEnhancers: any) => {
   //    return getDefaultEnhancers().concat(sentryReduxEnhancer)
   // },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store
