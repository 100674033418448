import { sentryTag } from "configs/sentry"
import useLogout from "pages/Logout/useLogout"
import { useEffect } from "react"
import { FallbackProps } from "react-error-boundary"
import PageErrorUnexpectedElement from "components/webComponents/PageErrorUnexpectedElement"

const MissingDataErrorLayout: React.FC<FallbackProps> = ({ error, resetErrorBoundary }) => {
   const { gotoLogoutPage } = useLogout()
   const onTryAgain = () => {
      const unExpectedErrorTryAgainCount = sessionStorage.getItem("unExpectedErrorTryAgainCount")
      sessionStorage.setItem(
         "unExpectedErrorTryAgainCount",
         unExpectedErrorTryAgainCount ? `${+unExpectedErrorTryAgainCount + 1}` : "1"
      )
      resetErrorBoundary()
   }
   useEffect(() => {
      // Set the tag to show that the UnexpectedErrorLayout has been shown
      sentryTag("error-layout-shown", "Missing Data Error Layout")

      // Check and count for the number of times the user has tried to reload the page after the error
      const unExpectedErrorTryAgainCount = sessionStorage.getItem("unExpectedErrorTryAgainCount")
      if (unExpectedErrorTryAgainCount && +unExpectedErrorTryAgainCount >= 3) {
         gotoLogoutPage()
      }
   }, [])
   return <PageErrorUnexpectedElement onTryAgain={onTryAgain} description={error} />
}

export default MissingDataErrorLayout
