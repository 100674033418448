import ROUTE from "routes/ROUTE"

// This can work as a hook and in normal function as well
const storeParams = () => {
   // So we store the query params in sessionStorage ('redirecting_params') before redirecting to the login page, then we can retrieve it after login to avoid losing/mixing the query params in the URL with the new ones

   const currentPathName = location.href
   const url = new URL(currentPathName)
   const searchParams = url.search
   const redirectingNestedParams = searchParams.split("?")[1]
   searchParams && sessionStorage.setItem("redirecting_params", redirectingNestedParams)
}
const getStoredParams = () => {
   // This one retrieves the stored query params from sessionStorage ('redirecting_params') then removes it from the sessionStorage to avoid using it again

   const storedNestedParams = sessionStorage.getItem("redirecting_params")
   setTimeout(() => {
      // Wait for 1 second before removing the stored query params from the sessionStorage, to avoid losing it before using it
      sessionStorage.removeItem("redirecting_params")
   }, 1000)
   return storedNestedParams
}
const directToLogoutPage = (reason?: string, isRedirect?: boolean) => {
   const currentURL = new URL(location.href)
   const listOfPagesNeedRoleSelection = [ROUTE.ONBOARDING.ROOT]
   // So we make sure the redirect_to is clean from any query params before redirecting to the login page
   const currentLocation = currentURL.origin + currentURL.pathname
   const isPageNeedRoleSelection = !!listOfPagesNeedRoleSelection.find((page) =>
      currentURL.pathname.includes(page)
   )
   const reasonQueryParam = reason ? `?reason=${reason}` : ""
   const redirectQueryParam =
      isRedirect && !isPageNeedRoleSelection
         ? `${reason ? "&" : "?"}return_to=${currentLocation}`
         : ""
   storeParams()
   location.replace(ROUTE.LOGOUT + reasonQueryParam + redirectQueryParam)
}

export { directToLogoutPage, storeParams, getStoredParams }
