import { useMutation, useLazyQuery } from "@apollo/client"
import { gql } from "@apollo/client"
import usePOEToken from "hooks/usePOEToken"
import bypassMockError from "utils/bypassMockError"
import { useEffect, useState } from "react"
import { useErrorBoundary } from "react-error-boundary"
import { MissingDataError } from "configs/error/customErrors"
import {
   GetCompanyInfo,
   AddAddress,
   AddAddressVariables,
   UpdateCompanyInfo,
   UpdateCompanyInfoVariables,
   GetCountriesAndStatesList,
} from "./__generated__"
import _ from "lodash"
import { AddressFragment } from "../UnitAndTenant/useUnitAndTenant"
import { LegalEntityTypeKey } from "../sharedData"

interface CompanyInfo {
   id?: string
   typeKey: LegalEntityTypeKey
   companyName: string
   firstName: string
   lastName: string
   email: string
   phone: string
   phoneExt: string
   legalEntityId: string
   address: {
      id?: string
      countryKey: string
      state: string
      city: string
      street: string
      aptSuiteEtc: string
      postalCode: string
   } | null
}

export interface CompanyInfoFormFields extends CompanyInfo {
   address: {
      id?: string
      countryKey: string
      state: string
      city: string
      street: string
      aptSuiteEtc: string
      postalCode: string
   }
}

// =========
// FRAGMENTS
// =========
const CompanyInfoFragment = gql`
   ${AddressFragment}
   fragment CompanyInfoFragment on Company {
      id
      legalEntity {
         id
         type {
            key
         }
         companyName
         firstName
         lastName
         phone
         phoneExt
         email

         addresses {
            ...AddressFragment
         }
      }
   }
`

// =======
// QUERIES
// =======
export const GetCompanyInfoQuery = gql`
   ${CompanyInfoFragment}
   query GetCompanyInfo {
      company {
         ...CompanyInfoFragment
      }
   }
`
const GetCountriesAndStatesListQuery = gql`
   query GetCountriesAndStatesList {
      countries {
         key
         name
      }

      states {
         key
         name
      }
   }
`
// ========
// MUTATION
// ========
const UpdateCompanyInfoMutation = gql`
   mutation UpdateCompanyInfo($input: UpdateCompanyInput!) {
      updateCompany(input: $input) {
         id
      }
   }
`
const AddAddressMutation = gql`
   mutation AddAddress($input: AddAddressInput!) {
      addAddress(input: $input) {
         id
      }
   }
`

// =====================
// USE COMPANY INFO HOOK
// =====================
const useCompanyInfo = ({
   changeStep,
   setStepCompleted,
}: {
   changeStep: (step: number) => void
   setStepCompleted: (currentStep: number) => void
}) => {
   const { showBoundary } = useErrorBoundary()
   const [companyInfo, setCompanyInfo] = useState<CompanyInfo | null>(null)
   const [isCompleted, setIsCompleted] = useState(false)
   const { poeToken, refreshPOEToken } = usePOEToken()

   // ==========
   // OPERATIONS
   // ==========
   const [runGetCompanyInfo, { loading: getCompanyInfoLoading, error: getCompanyInfoError }] =
      useLazyQuery<GetCompanyInfo>(GetCompanyInfoQuery)

   const [
      runUpdateCompanyInfo,
      { loading: updateCompanyInfoLoading, error: updateCompanyInfoError },
   ] = useMutation<UpdateCompanyInfo, UpdateCompanyInfoVariables>(UpdateCompanyInfoMutation)

   const [runAddAddress, { loading: addAddressLoading, error: addAddressError }] = useMutation<
      AddAddress,
      AddAddressVariables
   >(AddAddressMutation)

   const [
      runGetCountriesAndStatesList,
      { loading: getCountriesAndStatesListLoading, error: getCountriesAndStatesListError },
   ] = useLazyQuery<GetCountriesAndStatesList>(GetCountriesAndStatesListQuery)

   // ================
   // MAPPER FUNCTIONS
   // ================
   const mapCompanyInfo = (data: GetCompanyInfo): CompanyInfo => {
      const { __typename, id, legalEntity } = data.company!
      return {
         id,
         typeKey: legalEntity.type.key as LegalEntityTypeKey,
         companyName: legalEntity.companyName || "",
         phone: legalEntity.phone,
         firstName: legalEntity.firstName || "",
         lastName: legalEntity.lastName || "",
         email: legalEntity.email,
         phoneExt: legalEntity.phoneExt || "",
         address: mapAddress(legalEntity.addresses),
         legalEntityId: legalEntity.id,
      }
   }

   const mapAddress = (
      addresses: GetCompanyInfo["company"]["legalEntity"]["addresses"]
   ): CompanyInfo["address"] => {
      if (!addresses || addresses.length === 0) return null
      const address = addresses[0]
      return {
         id: address.id,
         countryKey: address.country?.key || "",
         state: address.state || "",
         city: address.city || "",
         street: address.street || "",
         aptSuiteEtc: address.aptSuiteEtc || "",
         postalCode: address.postalCode || "",
      }
   }

   // ==================
   // HANDLERS & EFFECTS
   // ==================
   const getCompanyInfo = async () => {
      const { data } = await runGetCompanyInfo({
         context: {
            showErrorModal: true,
         },
      })
      if (!data?.company) {
         showBoundary(
            new MissingDataError(
               `CompanyInfo response data has (${data?.company}) value`,
               "OperationName: getCompanyInfo - useCompanyInfo.ts"
            )
         )
         return
      }
      data && setCompanyInfo(mapCompanyInfo(data))
   }

   const getCountriesAndStatesList = async () => {
      type item = { key: string; name: string }
      type OptionType = { value: string; label: string }
      const countriesAndStates = localStorage.getItem("countries_and_states_list")
      const setItemShape = (items: { key: string; name: string }[]): OptionType[] => {
         return items.map(({ key, name }) => ({ value: key, label: name }))
      }
      if (countriesAndStates) {
         return JSON.parse(countriesAndStates) as { countries: item[]; states: item[] }
      }
      const { data } = await runGetCountriesAndStatesList({
         context: {
            showErrorModal: true,
         },
      })
      if (!data?.countries || !data?.states) {
         showBoundary(
            new MissingDataError(
               `CountriesAndStatesList response data has (${data?.countries}, ${data?.states}) value`,
               "OperationName: getCountriesAndStatesList - useCompanyInfo.ts"
            )
         )
         return
      }
      localStorage.setItem(
         "countries_and_states_list",
         JSON.stringify({
            countries: setItemShape(data.countries),
            states: setItemShape(data.states),
         })
      )
   }

   const addAddress = async (value: CompanyInfoFormFields["address"]) => {
      return runAddAddress({
         context: {
            poeToken: poeToken.current,
         },
         variables: {
            input: {
               typeKey: "legal",
               subjectId: companyInfo?.id as string,
               ...value,
            },
         },
      })
   }

   const updateCompanyInfo = async (value: CompanyInfoFormFields) => {
      const { address, ...companyFields } = value
      const isAddressValueChanged = !_.isEqual(address, companyInfo?.address)
      const isNewAddress = companyInfo?.address === null
      const isUpdateAddress = companyInfo?.address !== null && isAddressValueChanged

      const isCompanyInfoValueChanged = !_.isEqual(companyFields, _.omit(companyInfo, ["address"]))
      try {
         if (isAddressValueChanged && isNewAddress) {
            await addAddress(value.address)
            refreshPOEToken()
            await getCompanyInfo()
         }
         const currentCompanyData = companyInfo as CompanyInfo
         const isCompanyTypeForNaturalPerson =
            companyFields.typeKey === "natural_person" ||
            companyFields.typeKey === "sole_proprietorship"
         if (isCompanyInfoValueChanged || isUpdateAddress) {
            await runUpdateCompanyInfo({
               context: {
                  poeToken: poeToken.current,
               },
               variables: {
                  input: {
                     legalEntity: {
                        typeKey: companyFields.typeKey,
                        email: companyFields.email,
                        ...(isCompanyTypeForNaturalPerson && {
                           firstName: companyFields.firstName,
                        }),
                        ...(isCompanyTypeForNaturalPerson && {
                           lastName: companyFields.lastName,
                        }),
                        ...(!isCompanyTypeForNaturalPerson && {
                           companyName: companyFields.companyName,
                        }),
                        ...(currentCompanyData.email !== companyFields.email && {
                           email: companyFields.email,
                        }),
                        ...(currentCompanyData.phone !== companyFields.phone && {
                           phone: "+" + companyFields.phone?.replace("+", "")?.replace(" ", ""),
                        }),
                        ...(companyFields.phoneExt &&
                           !isCompanyTypeForNaturalPerson && { phoneExt: companyFields.phoneExt }),

                        ...(isUpdateAddress && address.id
                           ? {
                                addresses: [
                                   {
                                      subjectId: companyFields.id as string,
                                      typeKey: "legal",
                                      id: address?.id as string,
                                      ...value.address,
                                   },
                                ],
                             }
                           : {}),
                     },
                  },
               },
            })
            refreshPOEToken()
            console.log("Before getCompanyInfo")
            await getCompanyInfo()
            console.log("After getCompanyInfo")
            changeStep(2)
         } else {
            changeStep(2)
         }
      } catch (error) {}
   }

   useEffect(() => {
      if (companyInfo) {
         if (
            companyInfo.companyName &&
            companyInfo.email &&
            companyInfo.phone &&
            companyInfo.address?.id
         ) {
            setIsCompleted(true)
         } else {
            setIsCompleted(false)
         }
      }
   }, [companyInfo])

   // Check if the companyInfo state is completed and set the step as completed
   useEffect(() => {
      if (isCompleted) {
         setStepCompleted(1)
      }
   }, [isCompleted])

   return {
      companyInfo,
      isCompleted,
      error: {
         getCompanyInfoError,
         updateCompanyInfoError,
         addAddressError,
         getCountriesAndStatesListError,
      },
      loading: {
         getCompanyInfoLoading,
         updateCompanyInfoLoading,
         addAddressLoading,
         getCountriesAndStatesListLoading,
      },
      getCompanyInfo,
      getCountriesAndStatesList,
      updateCompanyInfo,
   }
}

export default useCompanyInfo
