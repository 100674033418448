import { useEffect, useState } from "react"
import Modal, { ModalProps } from "rentpost/layouts/Modal"
import {
   AddCustomAPIKeyFormFields,
   AddIntegrationFormFields,
   AddZapierAPIKeyFormFields,
   IntegrationTypeEnum,
} from "../useIntegrations"
import { ApolloError } from "@apollo/client"
import { GetAvailableRoles } from "pages/Login/__generated__"
import { MiniRoleCardProps } from "rentpost/components/other"
import AddCustomApiKey, { AddCustomApiKeyComponentProps } from "./AddCustomApiKey"
import AddIntegration, { AddIntegrationComponentProps } from "./AddIntegration"
import AddZapierAPIKey, { AddZapierAPIKeyComponentProps } from "./AddZapierAPIKey"
import ShowAPIKey, { ShowApiKeyComponentProps } from "./ShowAPIKey"

type ModalType = "add-integration" | "add-custom-api-key" | "show-api-key" | "add-zapier-api-key"

interface Props {
   integrationIdForNewKey: string | null
   availableRoles: GetAvailableRoles["roles"]
   addIntegration: (input: AddIntegrationFormFields) => void
   addCustomAPIKey: (input: AddCustomAPIKeyFormFields) => void
   addZapierAPIKey: (input: AddZapierAPIKeyFormFields) => void
   newAPIToken: string | undefined
   newAPIKeyIntegrationType: IntegrationTypeEnum | undefined
   newAPIKeyIntegrationName: string | undefined
   loading: {
      addIntegration: boolean
      addAPIKey: boolean
   }
   error: {
      addIntegration: ApolloError | undefined
      addAPIKey: ApolloError | undefined
   }
   activeModal: ModalType | null
   setActiveModal: (value: ModalType | null) => void
}

// ==================
// Roles List Mapping
// ==================
const mapRoleItems = (rolesData: GetAvailableRoles["roles"]): MiniRoleCardProps[] =>
   rolesData
      .map((role) => {
         // Ignore roles with status other than "Active"
         if (role.status !== "Active") return null
         const { id, type, displayName, companyName, email, avatar } = role
         const avatarImage = avatar?.droplet?.children[0]?.url || avatar?.url

         return {
            id,
            type,
            displayName,
            companyName,
            email,
            avatarImage,
         } as MiniRoleCardProps
      })
      .filter((item) => item) as MiniRoleCardProps[]

// ===============
// MODAL COMPONENT
// ===============
const IntegrationModal: React.FC<Props> = (props) => {
   // ----------------
   // States & Effects
   // ----------------
   const [showModal, setShowModal] = useState<boolean>(!!props.activeModal)

   const onClose = () => {
      const isShowingAPIKey = props.activeModal === "show-api-key"
      if (isShowingAPIKey) {
         const confirmed = window.confirm(
            "Are you sure you want to close this modal? You will not be able to see this API Key again."
         )
         if (confirmed) {
            setShowModal(false)
            props.setActiveModal(null)
         }
      } else {
         setShowModal(false)
         props.setActiveModal(null)
      }
   }

   useEffect(() => {
      setShowModal(!!props.activeModal)
   }, [props.activeModal])

   // -----
   // Props
   // -----
   const addIntegrationComponentProps: AddIntegrationComponentProps = {
      onSubmit: (value) => {
         props.addIntegration(value)
      },
      onClose,
      availableRoles: mapRoleItems(props.availableRoles),
      loading: props.loading.addIntegration,
      error: props.error.addIntegration,
   }

   const addCustomApiKeyComponentProps: AddCustomApiKeyComponentProps = {
      integrationId: props.integrationIdForNewKey || "",
      integrationName: props.newAPIKeyIntegrationName || "",
      onSubmit: (value) => {
         props.addCustomAPIKey(value)
      },
      onClose,
      availableRoles: mapRoleItems(props.availableRoles),
      loading: props.loading.addAPIKey,
      error: props.error.addAPIKey,
   }

   const addZapierAPIKeyComponentProps: AddZapierAPIKeyComponentProps = {
      onSubmit: (value) => {
         props.addZapierAPIKey(value)
      },
      onClose,
      availableRoles: mapRoleItems(props.availableRoles),
      loading: props.loading.addIntegration,
      error: props.error.addIntegration,
   }

   const showApiKeyComponentProps: ShowApiKeyComponentProps = {
      onClose,
      newAPIKey: props.newAPIToken || "",
      integrationType: props.newAPIKeyIntegrationType || IntegrationTypeEnum.custom,
   }

   const modalProps: ModalProps = {
      visible: showModal,
      onClose,
      canIgnore: true,
      fullscreenOnMobile: true,
      renderComponent: {
         show: true,
         component: () => (
            <>
               {props.activeModal === "add-integration" && (
                  <AddIntegration {...addIntegrationComponentProps} />
               )}
               {props.activeModal === "add-custom-api-key" && (
                  <AddCustomApiKey {...addCustomApiKeyComponentProps} />
               )}
               {props.activeModal === "add-zapier-api-key" && (
                  <AddZapierAPIKey {...addZapierAPIKeyComponentProps} />
               )}
               {props.activeModal === "show-api-key" && (
                  <ShowAPIKey {...showApiKeyComponentProps} />
               )}
            </>
         ),
      },
   }
   return <Modal {...modalProps} />
}

export type {
   AddIntegrationComponentProps,
   AddCustomApiKeyComponentProps as AddCustomApiKeyComponentProps,
   AddZapierAPIKeyComponentProps,
   Props as IntegrationModalProps,
}
export default IntegrationModal
