import { Hint } from "rentpost/components/form"
import { IntegrationTypeEnum } from "../../useIntegrations"
import ShowAPIKeyField from "./ShowAPIKey"
import styled from "@emotion/styled"
import { color, font } from "rentpost/styles"

interface ShowApiKeyComponentProps {
   onClose: () => void
   newAPIKey: string
   integrationType: IntegrationTypeEnum
}

const ShowAPIKey: React.FC<ShowApiKeyComponentProps> = (props) => {
   const isZapier = props.integrationType === IntegrationTypeEnum.zapier
   return (
      <Holder>
         {isZapier && (
            <>
               <h3>Zapier Integration Key</h3>
               <Hint
                  type="warning"
                  text="Warning!"
                  collapsibleDescription="This integration key provides access to your RentPost account. Keep this API key a secret and only paste it into the Zapier connection setup!"
                  canHide
               />
               <Hint
                  type="info"
                  text="Note"
                  canCollapseDescription={false}
                  collapsibleDescription={
                     <p>
                        The following integration key must be input into Zapier's RentPost
                        connection setup. For more information on this process,{" "}
                        <a
                           href="https://support.rentpost.com/hc/en-us/articles/21874214497549-Integration-with-Zapier"
                           target="_blank"
                        >
                           please read our support article.
                        </a>
                     </p>
                  }
               />
            </>
         )}
         {!isZapier && (
            <>
               <h3>Integration Key</h3>
               <Hint
                  type="warning"
                  collapsibleDescription="The following API key may be used to connect to the specified role. Keep this API key a secret and don't share it with anyone, as this key gives access to your RentPost account."
                  text="Warning!"
                  canHide
               />
               <Hint
                  type="info"
                  text="Note"
                  canCollapseDescription={false}
                  collapsibleDescription={
                     <p>
                        The following integration key must be input into your integration service
                        app. For more information on this process,{" "}
                        <a
                           href="https://support.rentpost.com/hc/en-us/articles/25024143613453-Creating-a-Custom-Integration"
                           target="_blank"
                        >
                           please read our support article.
                        </a>
                     </p>
                  }
               />
            </>
         )}
         <ShowAPIKeyField {...props} />
      </Holder>
   )
}
const Holder = styled.div`
   display: flex;
   flex-direction: column;

   .api-key {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #f6f5f5;
      padding: 0.7em 1em;
      border-radius: 0.5em;
      border: 1px solid #dedede;
      color: ${color.violet};
      margin-block: 0.5em;
      overflow: hidden;
      height: 6em;

      .key {
         font-size: 0.9em;
         height: 100%;
         word-wrap: nowrap;
         color: ${color.violet};
         margin: 0;
         outline: none;
         border: none;
         padding: 0;
         background: none;
         width: 100%;
         ${font.thin};

         &::selection {
            background-color: ${color.blue};
            color: ${color.white};
         }
      }

      .copied-tag {
         position: absolute;
         left: 50%;
         top: 50%;
         transform: translate(-50%, -50%);
         width: 100%;
         height: 100%;
         display: flex;
         justify-content: center;
         align-items: center;
         text-align: center;
         font-size: 2em;
         color: ${color.blue};
         ${font.bold};
         user-select: none;
         opacity: 0;
         transition: all 0.3s ease-in-out;
         background-color: #f6f5f5e7;
      }
   }
`
export type { ShowApiKeyComponentProps }
export default ShowAPIKey
