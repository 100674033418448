import { useLazyQuery } from "@apollo/client"
import gql from "graphql-tag"
import { Ping } from "./__generated__"
import bypassMockError from "utils/bypassMockError"
import { useEffect, useLayoutEffect } from "react"
import { useDetectAdBlock } from "adblock-detect-react"
import useModal from "./useModal"
import { useSearchParams } from "react-router-dom"
import UTMCookies from "utils/UTMCookies"
import ROUTE from "routes/ROUTE"
import auth from "utils/auth"
import initZendesk from "configs/zendesk/initZendesk"
import { sentryTag } from "configs/sentry"

// =====
// QUERY
// =====
const PingQuery = gql`
   query Ping {
      ping
   }
`

const useLaunch = () => {
   const [runPing] = useLazyQuery<Ping>(PingQuery)
   const [searchParams] = useSearchParams()

   const getUTMParams = () => {
      // Get UTM params from URL and set them in cookies
      const utm_source = searchParams.get("utm_source")
      const utm_medium = searchParams.get("utm_medium")
      const utm_campaign = searchParams.get("utm_campaign")
      const utm_term = searchParams.get("utm_term")
      const utm_content = searchParams.get("utm_content")

      utm_source && UTMCookies.set({ utm_source, utm_medium, utm_campaign, utm_term, utm_content })
   }

   // Check if ad blocker is detected, and show warning modal if it is
   const adBlockerDetected = useDetectAdBlock()
   const { openAdBlockerWarningModal } = useModal()

   useEffect(() => {
      adBlockerDetected && openAdBlockerWarningModal()
      // Set tag "ad-blocker-detected" to the value of adBlockerDetected
      sentryTag("ad-blocker-detected", adBlockerDetected)
      getUTMParams()
      initZendesk()

      // Set tag "error-layout-shown" to false when the app is mounted to show that no error layout has been shown yet
      sentryTag("error-layout-shown", false)
   }, [adBlockerDetected])

   // Run ping query to check if the server is up
   const notValidAccessToken = !auth.getAccessToken() || auth.isTokenExpired()
   const ping = () => {
      if (location.pathname === ROUTE.LOGOUT || notValidAccessToken) {
         return
      }
      return runPing({
         context: {
            skipQueue: true,
         },
      }).catch((e) => bypassMockError(e))
   }

   useLayoutEffect(() => {
      ping()
   }, [])
}

export default useLaunch
