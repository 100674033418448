// ------------------------
// String to File Converter
// ------------------------
const dataUrlToFile = async (dataUrl: string, fileName: string): Promise<File> => {
   const res: Response = await fetch(dataUrl)
   // get file type eg: image/png
   const fileType: string = res.headers.get("content-type") || ""
   const blob: Blob = await res.blob()
   return new File([blob], fileName, { type: fileType })
}

export default dataUrlToFile
