import { useNavigate } from "react-router-dom"
import { Card } from "rentpost/components/other"
import ROUTE from "routes/ROUTE"

interface Props {
   is2FAEnabled: boolean
   className?: string
}

const SecurityCard = ({ is2FAEnabled, className }: Props) => {
   const navigate = useNavigate()
   const goto2FASetup = () => navigate(ROUTE.VERIFY.ROOT + ROUTE.VERIFY.SETUP_2FA)

   // =================
   // If 2FA is enabled
   // =================
   if (is2FAEnabled) {
      return (
         <Card
            content={
               <div>
                  Two-factor authentication is <u>enabled</u> for your account.
               </div>
            }
            label="(2FA) Security"
            linkLabel="Configure 2FA"
            onClick={goto2FASetup}
            linkIcon="arrow"
            icon={
               <svg viewBox="0 0 38 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                     d="M19.3461 0.455078L0.869141 8.66708V20.9851C0.869141 32.3792 8.75266 43.0343 19.3461 45.6211C29.9396 43.0343 37.8232 32.3792 37.8232 20.9851V8.66708L19.3461 0.455078Z"
                     fill="#188038"
                  />
                  <path
                     d="M9.72434 23.5757L14.7613 28.5087C15.2298 28.9676 15.7765 29.1971 16.4012 29.1971C17.026 29.1971 17.5726 28.9676 18.0412 28.5087L27.9393 18.7574C28.3688 18.2986 28.5836 17.7632 28.5836 17.1514C28.5836 16.5395 28.3688 16.0041 27.9393 15.5453C27.4708 15.0848 26.9241 14.8455 26.2994 14.8271C25.6747 14.8088 25.128 15.029 24.6595 15.4879L16.3426 23.6331L13.0042 20.3635C12.5747 19.9429 12.0281 19.7326 11.3643 19.7326C10.7005 19.7326 10.1538 19.9429 9.72434 20.3635C9.29483 20.7842 9.08008 21.3195 9.08008 21.9696C9.08008 22.6197 9.29483 23.1551 9.72434 23.5757Z"
                     fill="white"
                  />
               </svg>
            }
            className={className}
         />
      )
   }

   // ==================
   // If 2FA is disabled
   // ==================
   return (
      <Card
         content={
            <div>
               <p>
                  Two-factor authentication is currently <u>disabled</u> for your account.
               </p>
               <p>Please consider activating for more security.</p>
            </div>
         }
         label="(2FA) Security"
         linkLabel="Activate 2FA"
         onClick={goto2FASetup}
         linkIcon="arrow"
         icon={
            <svg viewBox="0 0 37 45" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path
                  d="M18.0312 0.238037L0.03125 8.23804V20.238C0.03125 31.338 7.71125 41.718 18.0312 44.238C28.3512 41.718 36.0312 31.338 36.0312 20.238V8.23804L18.0312 0.238037Z"
                  fill="#801818"
               />
               <path
                  d="M13.0723 13.6453C12.4914 13.0643 11.5407 13.073 10.9596 13.654C10.3786 14.235 10.37 15.1856 10.951 15.7666L15.9095 20.7252L10.9512 25.6835C10.3703 26.2645 10.3788 27.2152 10.9599 27.7962C11.5409 28.3772 12.4915 28.3858 13.0725 27.8048L18.0308 22.8465L22.9891 27.8047C23.5701 28.3858 24.5208 28.3771 25.1017 27.7961C25.6828 27.2151 25.6914 26.2644 25.1104 25.6834L20.1521 20.7252L25.1106 15.7667C25.6917 15.1857 25.683 14.2351 25.102 13.654C24.521 13.073 23.5704 13.0644 22.9893 13.6454L18.0308 18.6039L13.0723 13.6453Z"
                  fill="white"
               />
            </svg>
         }
         className={className}
      />
   )
}

export default SecurityCard
