// Add Sentry to catch errors
import * as Sentry from "@sentry/react"

// @ts-ignore - this makes tsc happy
import env from "configs/env"

const ignoreErrors = [
   "Network failed",
   "Timeout",
   "PageNotFoundError",
   "LinkGoneError",
   "AuthError",
   "Connection",
]
// -----------------
// Initialize Sentry
// -----------------
const sentryInit = () => {
   if (env.TARGET_ENV === "development") return
   return Sentry.init({
      dsn: env.SENTRY_DSN,
      environment: env.TARGET_ENV,
      attachStacktrace: true,
      maxBreadcrumbs: 100,
      debug: true,
      ignoreErrors,
      release: env.RELEASE,
   })
}

export default sentryInit
