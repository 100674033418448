import { SkeletonForm } from "rentpost/src/components/other"
import { Skeleton as SkeletonMUI } from "@mui/material"

const Skeleton = () => {
   return (
      <div className="skeleton-holder">
         <SkeletonMUI variant="text" className="skeleton-title" />
         <div>
            <SkeletonMUI variant="text" className="skeleton-description" />
            <SkeletonMUI variant="text" className="skeleton-description" />
         </div>
         <SkeletonForm />
      </div>
   )
}

export default Skeleton
