import { AuthorizedDevice } from "./UseSecurity"
import styled from "@emotion/styled"
import DeviceIcon from "./DeviceIcon"
import ClientIcon from "./ClientIcon"
import { border, color, font, size } from "rentpost/styles"
import { LoadingSpinner } from "rentpost/components/other"

interface Props {
   device: AuthorizedDevice
   onClick: (deviceId: string) => void
   loading: boolean
}

const DeviceItem = ({ device, onClick, loading }: Props) => {
   const { id, type, isCurrentDevice, operatingSystem, sessions, name } = device

   // ================
   // Handlers & Props
   // ================
   const handleClick = () => {
      onClick(id)
   }

   const deviceTypeProps = { type, operatingSystem }
   const deviceInformationProps = {
      type,
      operatingSystemName: operatingSystem.name || "",
      numberOfSessions: sessions.length,
      isCurrentDevice,
      name: name,
   }

   const classNames = (isCurrentDevice ? "current-device" : "") + (loading ? " loading" : "")
   // ===
   // JSX
   // ===
   return (
      <Holder onClick={handleClick} className={classNames}>
         <div className="container">
            <DeviceIcon {...deviceTypeProps} />
            <DeviceInformation {...deviceInformationProps} />
            <DeviceSessions {...{ sessions }} />
            {loading && (
               <div className="spinner">
                  <LoadingSpinner type="spinner" color={color.green} />
               </div>
            )}
         </div>
      </Holder>
   )
}

// ==================
// Device Information
// ==================
const DeviceInformation = ({
   type,
   operatingSystemName,
   numberOfSessions,
   isCurrentDevice,
   name,
}: {
   type: AuthorizedDevice["type"]
   operatingSystemName: string
   numberOfSessions: number
   isCurrentDevice: boolean
   name: AuthorizedDevice["name"]
}) => {
   return (
      <div className="device-item__information">
         {isCurrentDevice && <p className="device-item__current-device">Current Device</p>}
         <p className="device-item__information__sessions">{`${numberOfSessions} sessions on`}</p>
         {name && <p className="device-item__information__device-name regular violet">{name}</p>}
         {(operatingSystemName || type) && (
            <p className="device-item__information__operating-system regular violet">
               {operatingSystemName || ""} {type || ""}
            </p>
         )}
      </div>
   )
}

// ===============
// Device Sessions
// ===============
const DeviceSessions = ({ sessions }: { sessions: AuthorizedDevice["sessions"] }) => {
   const currentSessionIndex = sessions.findIndex((session) => session.isCurrentSession)
   // get the first three sessions, and make sure the current session is always there (if it's not the third session, the replace the third session with the current session)
   const firstThreeSessions =
      currentSessionIndex >= 2
         ? [sessions[0], sessions[1], sessions[currentSessionIndex]]
         : sessions.slice(0, 3)

   // ============
   // Session Item
   // ============
   const SessionItem = ({ session }: { session: AuthorizedDevice["sessions"][0] }) => {
      return (
         <div
            className={
               "device-item__sessions-item" + (session.isCurrentSession ? " current-session" : "")
            }
         >
            <div className="device-item__sessions-item__client-icon">
               {<ClientIcon clientName={session.client?.name} />}
            </div>
            <p className="device-item__sessions-item__client">{`${session.client?.name} ${session.client?.type}`}</p>
            <p className="device-item__sessions-item__last-active-at">{session.lastActiveAt}</p>
         </div>
      )
   }

   // ===
   // JSX
   // ===
   return (
      <div className="device-item__sessions">
         {firstThreeSessions.map((session) => (
            <SessionItem key={session.id} session={session} />
         ))}
      </div>
   )
}

// ================
// Styled Component
// ================
const Holder = styled.div`
   container-type: inline-size;

   .container {
      position: relative;
      display: grid;
      grid-template-columns: max-content max-content auto;
      justify-content: space-between;
      padding: 1.7em 1.6em;
      border-radius: 1em;
      cursor: pointer;
      background-color: ${color.white};
      transition: all 0.2s ease-in-out;
      border: 1px solid #e7e7e7;
      box-sizing: border-box;
      place-items: center;
      gap: 2em;
      width: 100%;

      p {
         line-height: 1.1;
         overflow: hidden;
         text-overflow: ellipsis;
         white-space: nowrap;
         max-width: 100%;
      }

      .device-item__information__operating-system,
      .device-item__information__device-name,
      .device-item__information__sessions {
         margin-block: 0.3em;
      }
      .device-item__current-device {
         padding: 0.27em 0.7em;
         border-radius: 3em;
         width: max-content;
         background-color: ${color.green};
         color: ${color.white};
         ${font.bold};
         font-size: ${size.xs};
      }

      &:hover {
         box-shadow: 0px 0px 3px 1px #e7e7e7a6;
         background-color: #f6f6f6;
      }

      /* sessions container */
      .device-item__sessions {
         display: flex;
         flex-direction: column;
         place-self: center;
         gap: 0.2em;
         color: ${color.grayDark};

         .device-item__sessions-item {
            display: grid;
            grid-template-columns: 2.3em auto;
            grid-template-rows: 1fr 1fr;
            gap: 0.1em 1em;

            p {
               padding-right: 1em;
               top: 3px;
               position: relative;
               margin-block: 0;
               overflow: hidden;
               text-overflow: ellipsis;
               white-space: nowrap;
            }

            .device-item__sessions-item__client-icon {
               grid-column: 1 / 2;
               grid-row: 1 / 3;
               width: 2em;
               display: flex;
               align-items: center;
               justify-content: center;
               svg {
                  width: 100%;
                  height: 100%;
               }
            }

            .device-item__sessions-item__client {
               grid-column: 2;
               align-self: center;
            }

            .device-item__sessions-item__last-active-at {
               grid-column: 2 / 3;
               font-size: ${size.xs};
               color: ${color.grayLight};
            }
            &.current-session p {
               color: ${color.green};
               opacity: 1;
               ${font.regular};
            }
         }
      }

      .spinner {
         position: absolute;
         top: 37%;
         right: 0%;
         transform: translate(-50%, -50%);
         transform-origin: center center;
         margin-right: 1em;
      }

      /* Container Size variations  */
      @container (max-width: 600px) {
         grid-template-columns: max-content max-content;
         align-items: center;
         justify-items: start;
         gap: 1em 2em;

         .device-item__sessions {
            grid-column: 1 / 3;
            justify-self: start;
            padding-top: 1em;
            width: 100%;
            border-top: 1px solid #e7e7e7;
         }
      }

      @container (max-width: 350px) {
         display: flex;
         flex-direction: column;
         align-items: flex-start;
         gap: 1em;

         & > * {
            width: max-content;
         }
      }
   }
   /* loading */
   &.loading .container {
      pointer-events: none;
      opacity: 0.5;

      &:hover {
         box-shadow: none;
         background-color: ${color.white};
      }
   }
`

export default DeviceItem
