import _ from "lodash"

// ADD HERE THE KEYS YOU WANT TO OBFUSCATE
const obfuscationList = [
   "password",
   "password_confirmation",
   "token",
   "cvv",
   "cardNumber",
   "cardExpiry",
   "cardCVV",
   "taxId",
   "SSN",
   "SocialSecurityNumber",
   "authorization",
   "accessToken",
   "code",
]

// replace characters in string with X characters
const replaceWithX = (value: string | null | undefined) => {
   if (typeof value === "string" && value.length > 0) {
      const length = value.length
      const obfuscated = "x".repeat(length)
      return obfuscated // return obfuscated string
   } else if (value === null || value === "") {
      return null // return null if value is null or empty string
   } else if (value === undefined) {
      return undefined // return undefined if value is undefined
   } else return "xXxXxX"
}

// ------------------
// OBFUSCATE FUNCTION
// ------------------
const obfuscate = (object: { [key: string]: any } | undefined | null | {}) => {
   // return object if it is undefined or null or empty
   if (object === undefined || object === null || _.isEmpty(object)) {
      return object
   }

   const obfuscated: any = {}
   // obfuscate object deep keys if they are in the obfuscationList using lodash and recursion
   _.forIn(object, (value, key) => {
      if (obfuscationList.includes(key)) {
         obfuscated[key] = replaceWithX(value)
      } else if (typeof value === "object") {
         obfuscated[key] = obfuscate(value)
      } else {
         obfuscated[key] = value
      }
   })
   return obfuscated
}

export default obfuscate
