import styled from "@emotion/styled"
import { useEffect, useState } from "react"
import { SingleFieldForm } from "rentpost/components/form"
import useResendInvite from "./useResendInvite"
import SVG from "assets/svg"
import { color, font } from "rentpost/styles"
import { parsePhoneNumber } from "libphonenumber-js"
import auth from "utils/auth"
import getPASETOFooter from "utils/getPASETOFooter"

const ResendInvite = () => {
   const {
      resendInvite,
      getCompanyInfo,
      loading,
      error,
      data: { companyInfo, isInviteSent },
   } = useResendInvite()

   const [isUnknownCompany, setIsUnknownCompany] = useState<boolean>(false)

   useEffect(() => {
      const authToken = auth.getAccessToken()
      const isAuthTokenValid =
         authToken && !auth.isTokenExpired() && getPASETOFooter(authToken).roleId

      //  If the invite is not sent, then we want to load the company info
      if (isInviteSent === false) {
         isAuthTokenValid ? getCompanyInfo() : setIsUnknownCompany(true)
      }
   }, [isInviteSent])

   const handleSubmit = (value: { field: any }) => {
      value.field && resendInvite(value.field as string)
   }

   // ===
   // JSX
   // ===
   return (
      <Holder className="box">
         <div className="resend-invite">
            <p>
               If you haven't received an email, or can't locate it, you can request another by
               providing your email address below.
            </p>
            <SingleFieldForm
               type="email"
               loading={loading.resendInvite || loading.getCompanyInfo}
               onSubmit={handleSubmit}
               placeholder="you@domain.com"
               submitButtonText="Send"
               initialValue=""
            />
         </div>
         {isInviteSent && (
            <div className="invite-sent">
               <SVG.modal.success className="success-icon" />
               <h3>Invitation link sent!</h3>
               <p>Please click the link in the email to register your associated role.</p>
            </div>
         )}
         {companyInfo && (
            <div className="company-contact-info">
               <p>Please contact your management company using the information below</p>
               <div className="info-holder">
                  {companyInfo.email && (
                     <div className="info-item">
                        <SVG.form.email className="info-item-icon" />
                        <span className="info-item-value">{companyInfo.email}</span>
                     </div>
                  )}
                  {companyInfo.phone && (
                     <div className="info-item">
                        <SVG.form.phone className="info-item-icon" />
                        <span className="info-item-value">
                           {parsePhoneNumber(companyInfo.phone).formatInternational()}
                        </span>
                     </div>
                  )}
                  {companyInfo.phoneTwo && (
                     <div className="info-item">
                        <SVG.form.phone className="info-item-icon" />
                        <span className="info-item-value">
                           {parsePhoneNumber(companyInfo.phoneTwo).formatInternational()}
                        </span>
                     </div>
                  )}
               </div>
            </div>
         )}
         {isUnknownCompany && (
            <div className="unknown-company">
               <SVG.form.info className="info-icon" />
               <p>
                  We couldn't find any invitation associated with your email address. Please contact
                  your management company.
               </p>
            </div>
         )}
      </Holder>
   )
}

const Holder = styled.div`
   position: relative;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   gap: 2em;
   overflow: hidden;

   .resend-invite {
      display: flex;
      flex-direction: column;
   }

   .invite-sent {
      display: grid;
      grid-template-columns: 6em auto;
      grid-template-rows: auto auto;
      align-content: center;
      padding: 1em 2em;
      gap: 0.5em 1.5em;
      position: absolute;
      inset: 0;
      background-color: #f9f9f9;
      .success-icon {
         grid-row: 1/3;
         width: 5em;
      }
      h3 {
         grid-column: 2;
         margin: 0;
         color: ${color.green};
      }
      p {
         grid-column: 2;
         max-width: 90%;
         margin: 0;
         color: #b4b4b4;
         ${font.thinItalic};
         font-size: 0.9em;
      }
   }

   .company-contact-info {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      padding: 1.2em 2em;
      position: absolute;
      inset: 0;
      background-color: #f9f9f9;

      .info-icon {
         width: 6em;
         fill: #818181;
         display: none;
      }

      p {
         grid-column: 2;
         font-size: 1em;
         text-align: center;
         margin: 0;
         color: #818181;
         margin-bottom: 1em;
         margin-top: 0.6em;
      }
      .info-holder {
         grid-column: 2;
         display: flex;
         flex-direction: row;
         flex-wrap: wrap;
         gap: 0.5em;
         font-size: 0.9em;
         opacity: 0.7;
         .info-item {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: 0.5em;
            border-radius: 0.5em;
            border: 1px solid #9b9b9b;
            padding: 0 1.1em 0 0;
            box-sizing: border-box;
            overflow: hidden;
            .info-item-icon {
               position: relative;
               left: -0.1em;
               width: 1.5em;
               height: 1.5em;
               fill: ${color.white};
               background-color: #9b9b9b;
               padding: 0.3em 0.6em;
            }
            .info-item-value {
               color: #646464;
               font-size: 1em;
               padding: 0em 0.2em;
            }
         }
      }
   }

   .unknown-company {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      padding: 1.2em 2em;
      gap: 1em;
      position: absolute;
      inset: 0;
      background-color: #f9f9f9;

      .info-icon {
         width: 6em;
         fill: #cacaca;
      }

      p {
         grid-column: 2;
         font-size: 1em;
         text-align: center;
         margin: 0;
         color: #818181;
         margin-bottom: 1em;
         margin-top: 0.6em;
      }
   }
`

export default ResendInvite
