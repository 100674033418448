import { SVGAttributes } from "react"

const signUpIllustrationSVG = {
   buildingBlocks: (props: SVGAttributes<any>) => (
      <svg viewBox="0 0 796 599" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
         <path
            d="M798.489 194.663H718.091V589.475H798.489V194.663Z"
            fill="white"
            stroke="#50A999"
            strokeWidth="3"
            strokeMiterlimit="10"
         />
         <path d="M782.942 393.159H761.516V422.401H782.942V393.159Z" fill="#50A999" />
         <path d="M782.942 393.159H761.516V422.401H782.942V393.159Z" fill="#50A999" />
         <path
            d="M761.516 388.453H782.942V422.4"
            stroke="#50A999"
            strokeWidth="2.84"
            strokeMiterlimit="10"
         />
         <path d="M754.253 393.159H732.827V422.401H754.253V393.159Z" fill="#50A999" />
         <path d="M754.253 393.159H732.827V422.401H754.253V393.159Z" fill="#CDEDE7" />
         <path
            d="M732.827 388.453H754.268V422.4"
            stroke="#50A999"
            strokeWidth="2.84"
            strokeMiterlimit="10"
         />
         <path d="M782.942 450.018H761.516V479.26H782.942V450.018Z" fill="#50A999" />
         <path d="M782.942 450.018H761.516V479.26H782.942V450.018Z" fill="#CDEDE7" />
         <path
            d="M761.516 445.312H782.942V479.26"
            stroke="#50A999"
            strokeWidth="2.84"
            strokeMiterlimit="10"
         />
         <path d="M754.253 450.018H732.827V479.26H754.253V450.018Z" fill="#50A999" />
         <path d="M754.253 450.018H732.827V479.26H754.253V450.018Z" fill="#50A999" />
         <path
            d="M732.827 445.312H754.268V479.26"
            stroke="#50A999"
            strokeWidth="2.84"
            strokeMiterlimit="10"
         />
         <path d="M782.942 506.877H761.516V536.119H782.942V506.877Z" fill="#50A999" />
         <path d="M782.942 506.877H761.516V536.119H782.942V506.877Z" fill="#CDEDE7" />
         <path
            d="M761.516 502.172H782.942V536.119"
            stroke="#50A999"
            strokeWidth="2.84"
            strokeMiterlimit="10"
         />
         <path d="M754.253 506.877H732.827V536.119H754.253V506.877Z" fill="#50A999" />
         <path d="M754.253 506.877H732.827V536.119H754.253V506.877Z" fill="#CDEDE7" />
         <path
            d="M732.827 502.172H754.268V536.119"
            stroke="#50A999"
            strokeWidth="2.84"
            strokeMiterlimit="10"
         />
         <path
            d="M734.481 427.918H782.942"
            stroke="#50A999"
            strokeWidth="2.84"
            strokeMiterlimit="10"
         />
         <path
            d="M734.481 484.988H782.942"
            stroke="#50A999"
            strokeWidth="2.84"
            strokeMiterlimit="10"
         />
         <path
            d="M734.481 542.057H782.942"
            stroke="#50A999"
            strokeWidth="2.84"
            strokeMiterlimit="10"
         />
         <path d="M782.942 222.747H761.516V251.988H782.942V222.747Z" fill="#50A999" />
         <path d="M782.942 222.747H761.516V251.988H782.942V222.747Z" fill="#50A999" />
         <path
            d="M761.516 218.041H782.942V251.988"
            stroke="#50A999"
            strokeWidth="2.84"
            strokeMiterlimit="10"
         />
         <path d="M754.253 222.747H732.827V251.988H754.253V222.747Z" fill="#50A999" />
         <path d="M754.253 222.747H732.827V251.988H754.253V222.747Z" fill="#CDEDE7" />
         <path
            d="M732.827 218.041H754.268V251.988"
            stroke="#50A999"
            strokeWidth="2.84"
            strokeMiterlimit="10"
         />
         <path d="M782.942 279.606H761.516V308.847H782.942V279.606Z" fill="#50A999" />
         <path d="M782.942 279.606H761.516V308.847H782.942V279.606Z" fill="#CDEDE7" />
         <path
            d="M761.516 274.9H782.942V308.848"
            stroke="#50A999"
            strokeWidth="2.84"
            strokeMiterlimit="10"
         />
         <path d="M754.253 279.606H732.827V308.847H754.253V279.606Z" fill="#50A999" />
         <path d="M754.253 279.606H732.827V308.847H754.253V279.606Z" fill="#50A999" />
         <path
            d="M732.827 274.9H754.268V308.848"
            stroke="#50A999"
            strokeWidth="2.84"
            strokeMiterlimit="10"
         />
         <path d="M782.942 336.45H761.516V365.692H782.942V336.45Z" fill="#50A999" />
         <path d="M782.942 336.45H761.516V365.692H782.942V336.45Z" fill="#50A999" />
         <path
            d="M761.516 331.745H782.942V365.707"
            stroke="#50A999"
            strokeWidth="2.84"
            strokeMiterlimit="10"
         />
         <path d="M754.253 336.45H732.827V365.692H754.253V336.45Z" fill="#50A999" />
         <path d="M754.253 336.45H732.827V365.692H754.253V336.45Z" fill="#50A999" />
         <path
            d="M732.827 331.745H754.268V365.707"
            stroke="#50A999"
            strokeWidth="2.84"
            strokeMiterlimit="10"
         />
         <path
            d="M734.481 257.506H782.942"
            stroke="#50A999"
            strokeWidth="2.84"
            strokeMiterlimit="10"
         />
         <path
            d="M734.481 314.575H782.942"
            stroke="#50A999"
            strokeWidth="2.84"
            strokeMiterlimit="10"
         />
         <path
            d="M734.481 371.63H782.942"
            stroke="#50A999"
            strokeWidth="2.84"
            strokeMiterlimit="10"
         />
         <path
            d="M739.127 194.708H448.646V589.49H739.127V194.708Z"
            fill="#50A999"
            stroke="#50A999"
            strokeWidth="2.84"
            strokeMiterlimit="10"
         />
         <g filter="url(#filter0_d_43_284)">
            <path d="M739.127 194.708H602.224V589.49H739.127V194.708Z" fill="white" />
            <path
               d="M739.127 194.708H602.224V589.49H739.127V194.708Z"
               stroke="#50A999"
               strokeWidth="3"
               strokeMiterlimit="10"
            />
         </g>
         <path
            d="M681.479 505.464C677.053 505.456 672.67 506.322 668.58 508.012C664.49 509.701 660.774 512.181 657.645 515.31C654.516 518.439 652.035 522.155 650.346 526.244C648.656 530.333 647.79 534.716 647.798 539.141V588.753H715.159V539.141C715.179 534.713 714.322 530.325 712.636 526.23C710.951 522.135 708.47 518.415 705.339 515.283C702.207 512.152 698.487 509.672 694.391 507.987C690.296 506.302 685.907 505.444 681.479 505.464V505.464Z"
            fill="#CDEDE7"
         />
         <path
            d="M715.31 588.753V539.14C715.318 534.716 714.452 530.333 712.762 526.244C711.072 522.154 708.592 518.439 705.463 515.31C702.333 512.181 698.617 509.701 694.527 508.011C690.438 506.322 686.054 505.456 681.629 505.464V505.464C677.204 505.456 672.821 506.322 668.731 508.011C664.641 509.701 660.925 512.181 657.795 515.31C654.666 518.439 652.186 522.154 650.496 526.244C648.806 530.333 647.94 534.716 647.948 539.14"
            stroke="#50A999"
            strokeWidth="3"
            strokeMiterlimit="10"
         />
         <path
            d="M681.479 505.464V588.753"
            stroke="#50A999"
            strokeWidth="3"
            strokeMiterlimit="10"
         />
         <path
            d="M622.793 427.933H728.707"
            stroke="#50A999"
            strokeWidth="2.84"
            strokeMiterlimit="10"
         />
         <path
            d="M582.151 194.708H501.753V589.49H582.151V194.708Z"
            fill="white"
            stroke="#50A999"
            strokeWidth="3"
            strokeMiterlimit="10"
         />
         <path d="M566.604 393.174H545.177V422.415H566.604V393.174Z" fill="#50A999" />
         <path d="M566.604 393.174H545.177V422.415H566.604V393.174Z" fill="#CDEDE7" />
         <path
            d="M545.177 388.468H566.604V422.415"
            stroke="#50A999"
            strokeWidth="2.84"
            strokeMiterlimit="10"
         />
         <path d="M537.93 393.174H516.503V422.415H537.93V393.174Z" fill="#50A999" />
         <path d="M537.93 393.174H516.503V422.415H537.93V393.174Z" fill="#50A999" />
         <path
            d="M516.503 388.468H537.93V422.415"
            stroke="#50A999"
            strokeWidth="2.84"
            strokeMiterlimit="10"
         />
         <path d="M566.604 450.033H545.177V479.275H566.604V450.033Z" fill="#50A999" />
         <path d="M566.604 450.033H545.177V479.275H566.604V450.033Z" fill="#CDEDE7" />
         <path
            d="M545.177 445.328H566.604V479.275"
            stroke="#50A999"
            strokeWidth="2.84"
            strokeMiterlimit="10"
         />
         <path d="M537.93 450.033H516.503V479.275H537.93V450.033Z" fill="#50A999" />
         <path d="M537.93 450.033H516.503V479.275H537.93V450.033Z" fill="#50A999" />
         <path
            d="M516.503 445.328H537.93V479.275"
            stroke="#50A999"
            strokeWidth="2.84"
            strokeMiterlimit="10"
         />
         <path d="M566.604 506.877H545.177V536.119H566.604V506.877Z" fill="#50A999" />
         <path d="M566.604 506.877H545.177V536.119H566.604V506.877Z" fill="#50A999" />
         <path
            d="M545.177 502.172H566.604V536.134"
            stroke="#50A999"
            strokeWidth="2.84"
            strokeMiterlimit="10"
         />
         <path d="M537.93 506.877H516.503V536.119H537.93V506.877Z" fill="#50A999" />
         <path d="M537.93 506.877H516.503V536.119H537.93V506.877Z" fill="#50A999" />
         <path
            d="M516.503 502.172H537.93V536.134"
            stroke="#50A999"
            strokeWidth="2.84"
            strokeMiterlimit="10"
         />
         <path
            d="M518.142 427.933H566.603"
            stroke="#50A999"
            strokeWidth="2.84"
            strokeMiterlimit="10"
         />
         <path
            d="M518.142 485.002H566.603"
            stroke="#50A999"
            strokeWidth="2.84"
            strokeMiterlimit="10"
         />
         <path
            d="M518.142 542.057H566.603"
            stroke="#50A999"
            strokeWidth="2.84"
            strokeMiterlimit="10"
         />
         <path d="M644.219 393.174H622.793V422.415H644.219V393.174Z" fill="#50A999" />
         <path d="M644.219 450.033H622.793V479.275H644.219V450.033Z" fill="#CDEDE7" />
         <path d="M671.766 393.174H650.339V422.415H671.766V393.174Z" fill="#CDEDE7" />
         <path d="M671.766 450.033H650.339V479.275H671.766V450.033Z" fill="#50A999" />
         <path d="M699.327 393.174H677.9V422.415H699.327V393.174Z" fill="#50A999" />
         <path d="M699.327 450.033H677.9V479.275H699.327V450.033Z" fill="#CDEDE7" />
         <path d="M726.873 393.174H705.446V422.415H726.873V393.174Z" fill="#CDEDE7" />
         <path d="M726.873 450.033H705.446V479.275H726.873V450.033Z" fill="#50A999" />
         <path
            d="M622.793 485.002H728.707"
            stroke="#50A999"
            strokeWidth="2.84"
            strokeMiterlimit="10"
         />
         <path
            d="M705.446 388.468H726.873V422.415"
            stroke="#50A999"
            strokeWidth="2.84"
            strokeMiterlimit="10"
         />
         <path
            d="M677.9 388.468H699.327V422.415"
            stroke="#50A999"
            strokeWidth="2.84"
            strokeMiterlimit="10"
         />
         <path
            d="M650.339 388.468H671.766V422.415"
            stroke="#50A999"
            strokeWidth="2.84"
            strokeMiterlimit="10"
         />
         <path
            d="M622.793 388.468H644.219V422.415"
            stroke="#50A999"
            strokeWidth="2.84"
            strokeMiterlimit="10"
         />
         <path d="M644.219 393.174H622.793V422.415H644.219V393.174Z" fill="#50A999" />
         <path
            d="M622.793 388.468H644.219V422.415"
            stroke="#50A999"
            strokeWidth="2.84"
            strokeMiterlimit="10"
         />
         <path
            d="M705.446 445.328H726.873V479.275"
            stroke="#50A999"
            strokeWidth="2.84"
            strokeMiterlimit="10"
         />
         <path
            d="M677.9 445.328H699.327V479.275"
            stroke="#50A999"
            strokeWidth="2.84"
            strokeMiterlimit="10"
         />
         <path
            d="M650.339 445.328H671.766V479.275"
            stroke="#50A999"
            strokeWidth="2.84"
            strokeMiterlimit="10"
         />
         <path
            d="M622.793 445.328H644.219V479.275"
            stroke="#50A999"
            strokeWidth="2.84"
            strokeMiterlimit="10"
         />
         <path
            d="M622.793 257.506H728.707"
            stroke="#50A999"
            strokeWidth="2.84"
            strokeMiterlimit="10"
         />
         <path d="M566.604 222.747H545.177V251.988H566.604V222.747Z" fill="#50A999" />
         <path d="M566.604 222.747H545.177V251.988H566.604V222.747Z" fill="#50A999" />
         <path
            d="M545.177 218.041H566.604V251.988"
            stroke="#50A999"
            strokeWidth="2.84"
            strokeMiterlimit="10"
         />
         <path d="M537.93 222.747H516.503V251.988H537.93V222.747Z" fill="#50A999" />
         <path d="M537.93 222.747H516.503V251.988H537.93V222.747Z" fill="#CDEDE7" />
         <path
            d="M516.503 218.041H537.93V251.988"
            stroke="#50A999"
            strokeWidth="2.84"
            strokeMiterlimit="10"
         />
         <path d="M566.604 279.606H545.177V308.847H566.604V279.606Z" fill="#50A999" />
         <path d="M566.604 279.606H545.177V308.847H566.604V279.606Z" fill="#50A999" />
         <path
            d="M545.177 274.9H566.604V308.848"
            stroke="#50A999"
            strokeWidth="2.84"
            strokeMiterlimit="10"
         />
         <path d="M537.93 279.606H516.503V308.847H537.93V279.606Z" fill="#50A999" />
         <path d="M537.93 279.606H516.503V308.847H537.93V279.606Z" fill="#CDEDE7" />
         <path
            d="M516.503 274.9H537.93V308.848"
            stroke="#50A999"
            strokeWidth="2.84"
            strokeMiterlimit="10"
         />
         <path d="M566.604 336.45H545.177V365.692H566.604V336.45Z" fill="#50A999" />
         <path d="M566.604 336.45H545.177V365.692H566.604V336.45Z" fill="#CDEDE7" />
         <path
            d="M545.177 331.745H566.604V365.707"
            stroke="#50A999"
            strokeWidth="2.84"
            strokeMiterlimit="10"
         />
         <path d="M537.93 336.45H516.503V365.692H537.93V336.45Z" fill="#50A999" />
         <path d="M537.93 336.45H516.503V365.692H537.93V336.45Z" fill="#50A999" />
         <path
            d="M516.503 331.745H537.93V365.707"
            stroke="#50A999"
            strokeWidth="2.84"
            strokeMiterlimit="10"
         />
         <path
            d="M518.142 257.506H566.603"
            stroke="#50A999"
            strokeWidth="2.84"
            strokeMiterlimit="10"
         />
         <path
            d="M518.142 314.575H566.603"
            stroke="#50A999"
            strokeWidth="2.84"
            strokeMiterlimit="10"
         />
         <path
            d="M518.142 371.63H566.603"
            stroke="#50A999"
            strokeWidth="2.84"
            strokeMiterlimit="10"
         />
         <path d="M644.219 222.747H622.793V251.988H644.219V222.747Z" fill="#50A999" />
         <path d="M644.219 279.606H622.793V308.847H644.219V279.606Z" fill="#50A999" />
         <path d="M644.219 336.45H622.793V365.692H644.219V336.45Z" fill="#50A999" />
         <path d="M671.766 222.747H650.339V251.988H671.766V222.747Z" fill="#CDEDE7" />
         <path d="M671.766 279.606H650.339V308.847H671.766V279.606Z" fill="#CDEDE7" />
         <path d="M671.766 336.45H650.339V365.692H671.766V336.45Z" fill="#CDEDE7" />
         <path d="M699.327 222.747H677.9V251.988H699.327V222.747Z" fill="#50A999" />
         <path d="M699.327 279.606H677.9V308.847H699.327V279.606Z" fill="#50A999" />
         <path d="M699.327 336.45H677.9V365.692H699.327V336.45Z" fill="#CDEDE7" />
         <path d="M726.873 222.747H705.446V251.988H726.873V222.747Z" fill="#CDEDE7" />
         <path d="M726.873 279.606H705.446V308.847H726.873V279.606Z" fill="#50A999" />
         <path
            d="M622.793 372.652H728.707"
            stroke="#50A999"
            strokeWidth="2.84"
            strokeMiterlimit="10"
         />
         <path
            d="M622.793 314.575H728.707"
            stroke="#50A999"
            strokeWidth="2.84"
            strokeMiterlimit="10"
         />
         <path d="M726.873 336.45H705.446V365.692H726.873V336.45Z" fill="#CDEDE7" />
         <path
            d="M705.446 218.041H726.873V251.988"
            stroke="#50A999"
            strokeWidth="2.84"
            strokeMiterlimit="10"
         />
         <path
            d="M677.9 218.041H699.327V251.988"
            stroke="#50A999"
            strokeWidth="2.84"
            strokeMiterlimit="10"
         />
         <path
            d="M650.339 218.041H671.766V251.988"
            stroke="#50A999"
            strokeWidth="2.84"
            strokeMiterlimit="10"
         />
         <path
            d="M622.793 218.041H644.219V251.988"
            stroke="#50A999"
            strokeWidth="2.84"
            strokeMiterlimit="10"
         />
         <path d="M644.219 222.747H622.793V251.988H644.219V222.747Z" fill="#CDEDE7" />
         <path
            d="M622.793 218.041H644.219V251.988"
            stroke="#50A999"
            strokeWidth="2.84"
            strokeMiterlimit="10"
         />
         <path
            d="M705.446 274.9H726.873V308.848"
            stroke="#50A999"
            strokeWidth="2.84"
            strokeMiterlimit="10"
         />
         <path
            d="M677.9 274.9H699.327V308.848"
            stroke="#50A999"
            strokeWidth="2.84"
            strokeMiterlimit="10"
         />
         <path
            d="M650.339 274.9H671.766V308.848"
            stroke="#50A999"
            strokeWidth="2.84"
            strokeMiterlimit="10"
         />
         <path
            d="M622.793 274.9H644.219V308.848"
            stroke="#50A999"
            strokeWidth="2.84"
            strokeMiterlimit="10"
         />
         <path
            d="M705.446 331.654H726.873V365.601"
            stroke="#50A999"
            strokeWidth="2.84"
            strokeMiterlimit="10"
         />
         <path
            d="M677.9 331.654H699.327V365.601"
            stroke="#50A999"
            strokeWidth="2.84"
            strokeMiterlimit="10"
         />
         <path
            d="M650.339 331.654H671.766V365.601"
            stroke="#50A999"
            strokeWidth="2.84"
            strokeMiterlimit="10"
         />
         <path
            d="M622.793 331.654H644.219V365.601"
            stroke="#50A999"
            strokeWidth="2.84"
            strokeMiterlimit="10"
         />
         <path
            d="M602.224 2H662.608V122.754H541.839V62.3772C541.839 46.3642 548.201 31.007 559.525 19.6841C570.85 8.36115 586.209 2 602.224 2V2Z"
            fill="#50A999"
            stroke="#50A999"
            strokeWidth="2.84"
            strokeMiterlimit="10"
         />
         <path
            d="M657.707 2C673.722 2 689.081 8.36115 700.405 19.6841C711.729 31.007 718.091 46.3642 718.091 62.3772V122.754H597.322V62.3772C597.322 46.3642 603.684 31.007 615.008 19.6841C626.332 8.36115 641.691 2 657.707 2V2Z"
            fill="white"
            stroke="#50A999"
            strokeWidth="3"
            strokeMiterlimit="10"
         />
         <path
            d="M571.114 104.699H558.229V126.588H571.114V104.699Z"
            fill="#50A999"
            stroke="#50A999"
            strokeWidth="2.84"
            strokeMiterlimit="10"
         />
         <path
            d="M517.691 104.699H504.805V126.588H517.691V104.699Z"
            fill="#50A999"
            stroke="#50A999"
            strokeWidth="2.84"
            strokeMiterlimit="10"
         />
         <path
            d="M745.878 104.699H732.992V126.588H745.878V104.699Z"
            fill="#50A999"
            stroke="#50A999"
            strokeWidth="2.84"
            strokeMiterlimit="10"
         />
         <path
            d="M741.367 104.699V125.762H579.504V104.699H566.604V125.762V126.588V194.663H754.268V126.588V125.762V104.699H741.367Z"
            fill="white"
            stroke="#50A999"
            strokeWidth="3"
            strokeMiterlimit="10"
         />
         <path
            d="M504.805 194.663H566.604V125.762L504.805 125.762V194.663Z"
            fill="#50A999"
            stroke="#50A999"
            strokeWidth="2.84"
            strokeMiterlimit="10"
         />
         <path
            d="M641.032 144.599H680.501V175.84"
            stroke="#50A999"
            strokeWidth="2.62"
            strokeMiterlimit="10"
         />
         <path d="M678.622 150.733H641.017V175.825H678.622V150.733Z" fill="#CDEDE7" />
         <path
            d="M693.357 144.599H732.827V175.84"
            stroke="#50A999"
            strokeWidth="2.62"
            strokeMiterlimit="10"
         />
         <path d="M730.963 150.733H693.357V175.825H730.963V150.733Z" fill="#CDEDE7" />
         <path
            d="M586.526 144.599H625.996V175.84"
            stroke="#50A999"
            strokeWidth="2.62"
            strokeMiterlimit="10"
         />
         <path d="M624.116 150.733H586.511V175.825H624.116V150.733Z" fill="#CDEDE7" />
         <path
            d="M525.209 104.699H512.323V125.762H525.209V104.699Z"
            fill="white"
            stroke="#50A999"
            strokeWidth="3"
            strokeMiterlimit="10"
         />
         <path
            d="M694.165 61.5525C697.141 43.1394 684.625 25.8003 666.21 22.8245C647.794 19.8486 630.453 32.363 627.477 50.776C624.501 69.189 637.016 86.5281 655.432 89.504C673.847 92.4798 691.188 79.9655 694.165 61.5525Z"
            fill="#CDEDE7"
         />
         <path
            d="M627.079 58.1077C627.079 51.4471 629.054 44.9361 632.755 39.398C636.456 33.8599 641.716 29.5435 647.87 26.9946C654.025 24.4457 660.797 23.7788 667.33 25.0783C673.864 26.3777 679.865 29.585 684.575 34.2948C689.286 39.0045 692.493 45.0051 693.793 51.5377C695.093 58.0703 694.426 64.8415 691.876 70.9951C689.327 77.1487 685.01 82.4083 679.471 86.1087C673.933 89.8091 667.421 91.7842 660.759 91.7842"
            stroke="#50A999"
            strokeWidth="3"
            strokeMiterlimit="10"
         />
         <path
            d="M467.816 159.378H185.74V587.25H467.816V159.378Z"
            fill="#50A999"
            stroke="#50A999"
            strokeWidth="3"
            strokeMiterlimit="10"
         />
         <path
            d="M467.817 159.378H299.714V587.25H467.817V159.378Z"
            fill="#CDEDE7"
            stroke="#50A999"
            strokeMiterlimit="10"
         />
         <path d="M324.974 252.74H455.036" stroke="#50A999" strokeWidth="3" strokeMiterlimit="10" />
         <path d="M351.287 201.624H324.974V244.622H351.287V201.624Z" fill="white" />
         <path d="M351.287 285.214H324.974V328.212H351.287V285.214Z" fill="white" />
         <path d="M351.287 368.804H324.974V411.801H351.287V368.804Z" fill="white" />
         <path d="M385.118 201.624H358.805V244.622H385.118V201.624Z" fill="white" />
         <path d="M385.118 285.214H358.805V328.212H385.118V285.214Z" fill="white" />
         <path d="M385.118 368.804H358.805V411.801H385.118V368.804Z" fill="white" />
         <path d="M418.949 201.624H392.636V244.622H418.949V201.624Z" fill="white" />
         <path d="M418.949 285.214H392.636V328.212H418.949V285.214Z" fill="white" />
         <path d="M418.949 368.804H392.636V411.801H418.949V368.804Z" fill="white" />
         <path d="M452.78 201.624H426.467V244.622H452.78V201.624Z" fill="white" />
         <path d="M452.78 285.214H426.467V328.212H452.78V285.214Z" fill="white" />
         <path
            d="M324.974 422.024H455.036"
            stroke="#50A999"
            strokeWidth="3"
            strokeMiterlimit="10"
         />
         <path d="M324.974 336.63H455.036" stroke="#50A999" strokeWidth="3" strokeMiterlimit="10" />
         <path d="M452.78 368.804H426.467V411.801H452.78V368.804Z" fill="white" />
         <path
            d="M426.467 194.708H452.78V244.621"
            stroke="#50A999"
            strokeWidth="3"
            strokeMiterlimit="10"
         />
         <path
            d="M392.636 194.708H418.949V244.621"
            stroke="#50A999"
            strokeWidth="3"
            strokeMiterlimit="10"
         />
         <path
            d="M358.805 194.708H385.118V244.621"
            stroke="#50A999"
            strokeWidth="3"
            strokeMiterlimit="10"
         />
         <path
            d="M324.974 194.708H351.287V244.621"
            stroke="#50A999"
            strokeWidth="3"
            strokeMiterlimit="10"
         />
         <path
            d="M426.467 278.298H452.78V328.211"
            stroke="#50A999"
            strokeWidth="3"
            strokeMiterlimit="10"
         />
         <path
            d="M392.636 278.298H418.949V328.211"
            stroke="#50A999"
            strokeWidth="3"
            strokeMiterlimit="10"
         />
         <path
            d="M358.805 278.298H385.118V328.211"
            stroke="#50A999"
            strokeWidth="3"
            strokeMiterlimit="10"
         />
         <path
            d="M324.974 278.298H351.287V328.211"
            stroke="#50A999"
            strokeWidth="3"
            strokeMiterlimit="10"
         />
         <path
            d="M426.467 361.738H452.78V411.651"
            stroke="#50A999"
            strokeWidth="3"
            strokeMiterlimit="10"
         />
         <path
            d="M392.636 361.738H418.949V411.651"
            stroke="#50A999"
            strokeWidth="3"
            strokeMiterlimit="10"
         />
         <path
            d="M358.805 361.738H385.118V411.651"
            stroke="#50A999"
            strokeWidth="3"
            strokeMiterlimit="10"
         />
         <path
            d="M324.974 361.738H351.287V411.651"
            stroke="#50A999"
            strokeWidth="3"
            strokeMiterlimit="10"
         />
         <path
            d="M185.74 234.849H71.6167V587.4H185.74V234.849Z"
            fill="#50A999"
            stroke="#50A999"
            strokeWidth="3"
            strokeMiterlimit="10"
         />
         <path
            d="M259.868 234.849H145.745V587.4H259.868V234.849Z"
            fill="white"
            stroke="#50A999"
            strokeWidth="3"
            strokeMiterlimit="10"
         />
         <path
            d="M183.936 249.883H160.931V289.573H183.936V249.883Z"
            fill="white"
            stroke="#50A999"
            strokeWidth="3"
            strokeMiterlimit="10"
         />
         <path
            d="M214.459 249.883H191.454V289.573H214.459V249.883Z"
            fill="white"
            stroke="#50A999"
            strokeWidth="3"
            strokeMiterlimit="10"
         />
         <path
            d="M244.983 249.883H221.978V289.573H244.983V249.883Z"
            fill="white"
            stroke="#50A999"
            strokeWidth="3"
            strokeMiterlimit="10"
         />
         <path
            d="M183.936 296.94H160.931V336.631H183.936V296.94Z"
            fill="white"
            stroke="#50A999"
            strokeWidth="3"
            strokeMiterlimit="10"
         />
         <path
            d="M214.459 296.94H191.454V336.631H214.459V296.94Z"
            fill="white"
            stroke="#50A999"
            strokeWidth="3"
            strokeMiterlimit="10"
         />
         <path
            d="M244.983 296.94H221.978V336.631H244.983V296.94Z"
            fill="white"
            stroke="#50A999"
            strokeWidth="3"
            strokeMiterlimit="10"
         />
         <path
            d="M183.936 344.147H160.931V383.838H183.936V344.147Z"
            fill="white"
            stroke="#50A999"
            strokeWidth="3"
            strokeMiterlimit="10"
         />
         <path
            d="M214.459 344.147H191.454V383.838H214.459V344.147Z"
            fill="white"
            stroke="#50A999"
            strokeWidth="3"
            strokeMiterlimit="10"
         />
         <path
            d="M244.983 344.147H221.978V383.838H244.983V344.147Z"
            fill="white"
            stroke="#50A999"
            strokeWidth="3"
            strokeMiterlimit="10"
         />
         <path
            d="M183.936 391.355H160.931V431.045H183.936V391.355Z"
            fill="white"
            stroke="#50A999"
            strokeWidth="3"
            strokeMiterlimit="10"
         />
         <path
            d="M214.459 391.355H191.454V431.045H214.459V391.355Z"
            fill="white"
            stroke="#50A999"
            strokeWidth="3"
            strokeMiterlimit="10"
         />
         <path
            d="M244.983 391.355H221.978V431.045H244.983V391.355Z"
            fill="white"
            stroke="#50A999"
            strokeWidth="3"
            strokeMiterlimit="10"
         />
         <path
            d="M183.936 438.412H160.931V478.102H183.936V438.412Z"
            fill="white"
            stroke="#50A999"
            strokeWidth="3"
            strokeMiterlimit="10"
         />
         <path
            d="M214.459 438.412H191.454V478.102H214.459V438.412Z"
            fill="white"
            stroke="#50A999"
            strokeWidth="3"
            strokeMiterlimit="10"
         />
         <path
            d="M244.983 438.412H221.978V478.102H244.983V438.412Z"
            fill="white"
            stroke="#50A999"
            strokeWidth="3"
            strokeMiterlimit="10"
         />
         <path
            d="M183.936 485.619H160.931V525.309H183.936V485.619Z"
            fill="white"
            stroke="#50A999"
            strokeWidth="3"
            strokeMiterlimit="10"
         />
         <path
            d="M214.459 485.619H191.454V525.309H214.459V485.619Z"
            fill="white"
            stroke="#50A999"
            strokeWidth="3"
            strokeMiterlimit="10"
         />
         <path
            d="M244.983 485.619H221.978V525.309H244.983V485.619Z"
            fill="white"
            stroke="#50A999"
            strokeWidth="3"
            strokeMiterlimit="10"
         />
         <path
            d="M214.459 537.186H191.454V587.4H214.459V537.186Z"
            fill="white"
            stroke="#50A999"
            strokeWidth="3"
            strokeMiterlimit="10"
         />
         <path d="M183.936 255.897H160.931V289.573H183.936V255.897Z" fill="#50A999" />
         <path d="M214.459 255.897H191.454V289.573H214.459V255.897Z" fill="#50A999" />
         <path d="M244.983 255.897H221.978V289.573H244.983V255.897Z" fill="#50A999" />
         <path d="M183.936 304.458H160.931V336.631H183.936V304.458Z" fill="#50A999" />
         <path d="M214.459 304.458H191.454V336.631H214.459V304.458Z" fill="#50A999" />
         <path d="M244.983 304.458H221.978V336.631H244.983V304.458Z" fill="#50A999" />
         <path d="M183.936 351.665H160.931V383.838H183.936V351.665Z" fill="#50A999" />
         <path d="M214.459 351.665H191.454V383.838H214.459V351.665Z" fill="#50A999" />
         <path d="M244.983 351.665H221.978V383.838H244.983V351.665Z" fill="#50A999" />
         <path d="M183.936 398.872H160.931V431.045H183.936V398.872Z" fill="#50A999" />
         <path d="M214.459 398.872H191.454V431.045H214.459V398.872Z" fill="#50A999" />
         <path d="M244.983 398.872H221.978V431.045H244.983V398.872Z" fill="#50A999" />
         <path d="M183.936 445.929H160.931V478.102H183.936V445.929Z" fill="#50A999" />
         <path d="M214.459 445.929H191.454V478.102H214.459V445.929Z" fill="#50A999" />
         <path d="M244.983 445.929H221.978V478.102H244.983V445.929Z" fill="#50A999" />
         <path d="M183.936 493.136H160.931V525.309H183.936V493.136Z" fill="#50A999" />
         <path d="M214.459 493.136H191.454V525.309H214.459V493.136Z" fill="#50A999" />
         <path d="M244.983 493.136H221.978V525.309H244.983V493.136Z" fill="#50A999" />
         <path
            d="M73.7219 365.646H2V587.4H73.7219V365.646Z"
            fill="#50A999"
            stroke="#50A999"
            strokeWidth="3"
            strokeMiterlimit="10"
         />
         <path
            d="M120.334 365.646H48.6118V587.4H120.334V365.646Z"
            fill="white"
            stroke="#50A999"
            strokeWidth="3"
            strokeMiterlimit="10"
         />
         <path
            d="M54.1753 390.002H72.3689V416.913"
            stroke="#50A999"
            strokeWidth="2"
            strokeMiterlimit="10"
         />
         <path
            d="M69.8124 397.519H57.1821V414.357H69.8124V397.519Z"
            fill="#50A999"
            stroke="#50A999"
            strokeWidth="3"
            strokeMiterlimit="10"
         />
         <path
            d="M54.1753 482.913H72.3689V509.824"
            stroke="#50A999"
            strokeWidth="2"
            strokeMiterlimit="10"
         />
         <path
            d="M69.8124 490.43H57.1821V507.268H69.8124V490.43Z"
            fill="#50A999"
            stroke="#50A999"
            strokeWidth="3"
            strokeMiterlimit="10"
         />
         <path
            d="M88.4575 390.002H106.501V416.913"
            stroke="#50A999"
            strokeWidth="2"
            strokeMiterlimit="10"
         />
         <path
            d="M103.944 397.519H91.314V414.357H103.944V397.519Z"
            fill="#50A999"
            stroke="#50A999"
            strokeWidth="3"
            strokeMiterlimit="10"
         />
         <path
            d="M88.4575 482.462H106.501V509.373"
            stroke="#50A999"
            strokeWidth="2"
            strokeMiterlimit="10"
         />
         <path
            d="M103.944 489.979H91.314V506.817H103.944V489.979Z"
            fill="#50A999"
            stroke="#50A999"
            strokeWidth="3"
            strokeMiterlimit="10"
         />
         <path
            d="M88.4575 436.307H106.501V463.218"
            stroke="#50A999"
            strokeWidth="2"
            strokeMiterlimit="10"
         />
         <path
            d="M103.944 443.824H91.314V460.662H103.944V443.824Z"
            fill="#50A999"
            stroke="#50A999"
            strokeWidth="3"
            strokeMiterlimit="10"
         />
         <path
            d="M88.4575 521.852H106.501V548.763"
            stroke="#50A999"
            strokeWidth="2"
            strokeMiterlimit="10"
         />
         <path
            d="M103.944 529.368H91.314V546.206H103.944V529.368Z"
            fill="#50A999"
            stroke="#50A999"
            strokeWidth="3"
            strokeMiterlimit="10"
         />
         <path
            d="M57.1821 587.401V554.927H81.5405"
            stroke="#50A999"
            strokeWidth="3"
            strokeMiterlimit="10"
         />
         <path d="M79.1348 587.4V561.842" stroke="#50A999" strokeWidth="3" strokeMiterlimit="10" />
         <path d="M148.301 293.332H120.333" stroke="white" strokeWidth="3" strokeMiterlimit="10" />
         <path d="M88.4574 341.141H70.8652" stroke="white" strokeWidth="3" strokeMiterlimit="10" />
         <path d="M148.301 387.446H118.229" stroke="white" strokeWidth="3" strokeMiterlimit="10" />
         <path d="M148.301 436.307H133.265" stroke="white" strokeWidth="3" strokeMiterlimit="10" />
         <path
            d="M424.814 455.852H353.092V587.4H424.814V455.852Z"
            fill="#50A999"
            stroke="#50A999"
            strokeWidth="3"
            strokeMiterlimit="10"
         />
         <path
            d="M531.72 455.852H399.854V587.4H531.72V455.852Z"
            fill="white"
            stroke="#50A999"
            strokeWidth="3"
            strokeMiterlimit="10"
         />
         <path
            d="M405.417 482.913H423.611V509.824"
            stroke="#50A999"
            strokeWidth="2"
            strokeMiterlimit="10"
         />
         <path
            d="M420.904 490.43H408.274V507.268H420.904V490.43Z"
            fill="#50A999"
            stroke="#50A999"
            strokeWidth="3"
            strokeMiterlimit="10"
         />
         <path
            d="M439.549 482.462H457.742V509.373"
            stroke="#50A999"
            strokeWidth="2"
            strokeMiterlimit="10"
         />
         <path
            d="M455.036 489.979H442.406V506.817H455.036V489.979Z"
            fill="#50A999"
            stroke="#50A999"
            strokeWidth="3"
            strokeMiterlimit="10"
         />
         <path
            d="M467.816 482.913H486.01V509.824"
            stroke="#50A999"
            strokeWidth="2"
            strokeMiterlimit="10"
         />
         <path
            d="M483.304 490.43H470.673V507.268H483.304V490.43Z"
            fill="#50A999"
            stroke="#50A999"
            strokeWidth="3"
            strokeMiterlimit="10"
         />
         <path
            d="M501.948 482.462H520.142V509.373"
            stroke="#50A999"
            strokeWidth="2"
            strokeMiterlimit="10"
         />
         <path
            d="M517.435 489.979H504.805V506.817H517.435V489.979Z"
            fill="#50A999"
            stroke="#50A999"
            strokeWidth="3"
            strokeMiterlimit="10"
         />
         <path
            d="M441.955 537.186H418.95V587.4H441.955V537.186Z"
            stroke="#50A999"
            strokeWidth="3"
            strokeMiterlimit="10"
         />
         <path
            d="M191.454 159.378L219.602 109.194H433.068L460.945 159.378H191.454Z"
            fill="#50A999"
            stroke="#50A999"
            strokeWidth="2.84"
            strokeMiterlimit="10"
         />
         <path
            d="M309.878 109.194L288.948 159.378H460.945L433.068 109.194H309.878Z"
            fill="#CDEDE7"
            stroke="#50A999"
            strokeMiterlimit="10"
         />
         <path
            d="M386.065 128.137H425.535V159.378"
            stroke="#50A999"
            strokeWidth="2.62"
            strokeMiterlimit="10"
         />
         <path d="M423.671 134.286H386.065V159.378H423.671V134.286Z" fill="white" />
         <path
            d="M331.56 128.137H371.03V159.378"
            stroke="#50A999"
            strokeWidth="2.62"
            strokeMiterlimit="10"
         />
         <path d="M369.165 134.286H331.56V159.378H369.165V134.286Z" fill="white" />
         <path d="M297.458 161.633H178.237V170.067H297.458V161.633Z" fill="#50A999" />
         <path
            d="M295.203 163.888V167.812H180.493V163.888H295.203ZM299.714 159.378H175.982V172.322H299.714V159.378Z"
            fill="#50A999"
         />
         <path
            d="M299.714 172.322L471.861 172.322V159.378L299.714 159.378V172.322Z"
            fill="#50A999"
            stroke="#50A999"
            strokeMiterlimit="10"
         />
         <path d="M148.301 234.85L128.122 194.107H94.3211L71.6167 234.85H148.301Z" fill="#50A999" />
         <path
            d="M128.123 194.107H237.63L259.868 234.85H148.301L128.123 194.107Z"
            fill="#CDEDE7"
            stroke="#50A999"
            strokeMiterlimit="10"
         />
         <path
            d="M214.309 207.517H191.304V233.677H214.309V207.517Z"
            fill="white"
            stroke="#50A999"
            strokeWidth="3"
            strokeMiterlimit="10"
         />
         <path d="M214.309 213.531H191.304V233.677H214.309V213.531Z" fill="#50A999" />
         <defs>
            <filter
               id="filter0_d_43_284"
               x="596.724"
               y="193.208"
               width="147.903"
               height="405.782"
               filterUnits="userSpaceOnUse"
               colorInterpolationFilters="sRGB"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix" />
               <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
               />
               <feOffset dy="4" />
               <feGaussianBlur stdDeviation="2" />
               <feComposite in2="hardAlpha" operator="out" />
               <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
               <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_43_284" />
               <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow_43_284"
                  result="shape"
               />
            </filter>
         </defs>
      </svg>
   ),
   sun: (props: SVGAttributes<any>) => (
      <svg viewBox="0 0 558 592" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
         <circle cx="528" cy="64" r="528" fill="url(#paint0_radial_43_553)" fillOpacity="0.7" />
         <g>
            <circle cx="523.5" cy="60.5" r="135.5" fill="url(#paint1_radial_43_553)" />
         </g>
         <g clipPath="url(#clip0_43_553)">
            <ellipse cx="523.788" cy="60.7162" rx="101.212" ry="100.716" fill="#FFF9E2" />
            <ellipse cx="523" cy="59.1487" rx="50.0154" ry="49.7703" fill="#FFD740" />
            <path
               d="M523 123.919C558.837 123.919 588.015 94.9897 588.015 59.1487C588.015 23.3076 558.837 -5.62161 523 -5.62161C487.163 -5.62161 457.985 23.3076 457.985 59.1487C457.985 94.9897 487.163 123.919 523 123.919Z"
               stroke="#FFD740"
               strokeOpacity="0.4"
               strokeWidth="30"
            />
            <path
               d="M465.292 -21.3431C480.489 -31.8218 498.278 -37.962 516.731 -39.0984C535.184 -40.2347 553.598 -36.3239 569.977 -27.7897C586.357 -19.2555 600.076 -6.42338 609.65 9.31607C619.223 25.0555 624.285 43.102 624.287 61.5"
               stroke="#3F3350"
               strokeWidth="2"
               strokeLinecap="round"
               strokeLinejoin="round"
            />
            <path
               d="M610.514 112.276C596.976 135.369 574.774 152.164 548.791 158.967C522.809 165.77 495.175 162.024 471.968 148.552C448.762 135.08 431.884 112.987 425.047 87.1315C418.211 61.2764 421.976 33.7778 435.514 10.685C436.182 9.54513 436.873 8.4186 437.586 7.30545"
               stroke="#3F3350"
               strokeWidth="2"
               strokeLinecap="round"
               strokeLinejoin="round"
            />
            <path
               d="M483.754 61.5C483.756 54.4826 485.658 47.5953 489.26 41.5624C492.862 35.5295 498.031 30.5738 504.223 27.2165C510.416 23.8591 517.403 22.2241 524.45 22.4834C531.497 22.7427 538.344 24.8867 544.27 28.69C550.197 32.4932 554.984 37.8152 558.129 44.0964C561.273 50.3775 562.659 57.3858 562.14 64.3841C561.621 71.3825 559.217 78.1125 555.18 83.8664C551.143 89.6203 545.623 94.1855 539.2 97.0821"
               stroke="#3F3350"
               strokeWidth="2"
               strokeLinecap="round"
               strokeLinejoin="round"
            />
         </g>
         <defs>
            <radialGradient
               id="paint0_radial_43_553"
               cx="0"
               cy="0"
               r="1"
               gradientUnits="userSpaceOnUse"
               gradientTransform="translate(528 64) rotate(90) scale(528)"
            >
               <stop stopColor="#FFF3C6" />
               <stop offset="1" stopColor="#D9D9D9" stopOpacity="0" />
            </radialGradient>
            <radialGradient
               id="paint1_radial_43_553"
               cx="0"
               cy="0"
               r="1"
               gradientUnits="userSpaceOnUse"
               gradientTransform="translate(523.5 60.5) rotate(90) scale(135.5)"
            >
               <stop offset="0.686354" stopColor="#FFE78A" />
               <stop offset="1" stopColor="white" stopOpacity="0" />
            </radialGradient>
            <clipPath id="clip0_43_553">
               <rect width="204" height="203" fill="white" transform="translate(421 -40)" />
            </clipPath>
         </defs>
      </svg>
   ),
   rentSign: (props: SVGAttributes<any>) => (
      <svg viewBox="0 0 254 112" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
         <path
            d="M22.9729 97.68C25.9197 97.4572 28.8833 97.6629 31.771 98.2906C32.6294 93.909 34.8964 89.9276 38.227 86.9518C41.5577 83.976 45.7698 82.1687 50.2226 81.8049H50.9558C51.8779 75.8493 54.6575 70.3364 58.8985 66.0519C63.097 61.7378 68.5879 58.9084 74.5396 57.9922C80.4988 56.9927 86.6216 57.894 92.0393 60.5682C97.457 63.2424 101.894 67.5538 104.722 72.8904C108.134 71.8773 111.671 71.343 115.231 71.3029C120.603 71.3129 125.906 72.5229 130.75 74.8443C135.604 77.1503 139.867 80.5334 143.214 84.7357C146.54 88.9401 148.916 93.8143 150.179 99.0233C151.38 104.242 151.422 109.661 150.301 114.898H0C1.79055 110.185 4.88409 106.077 8.92033 103.053C13.0368 100.034 17.8909 98.1776 22.9729 97.68Z"
            fill="#00A1A3"
         />
         <path
            d="M246.616 105.544C249.796 107.938 252.236 111.18 253.654 114.898H135.124C134.239 110.765 134.273 106.488 135.222 102.369C136.216 98.2603 138.087 94.4153 140.708 91.0976C143.353 87.7849 146.717 85.1166 150.545 83.2943C154.359 81.4657 158.534 80.5104 162.765 80.4978C165.573 80.5199 168.366 80.9309 171.062 81.719C173.291 77.5089 176.79 74.1072 181.063 71.9963C185.335 69.8855 190.165 69.1728 194.865 69.9592C199.563 70.6814 203.896 72.9151 207.207 76.3215C210.575 79.7069 212.783 84.0719 213.513 88.7895H214.087C217.6 89.0756 220.923 90.5011 223.55 92.8489C226.177 95.1967 227.965 98.3381 228.64 101.795C230.918 101.293 233.256 101.129 235.581 101.306C239.571 101.713 243.38 103.176 246.616 105.544Z"
            fill="#009194"
         />
         <path
            d="M114.803 26.6691H114.229C113.948 26.6691 113.948 26.376 113.655 26.376C113.5 26.221 113.401 26.0192 113.374 25.8021V25.2403L113.948 8.94999C113.991 8.60996 113.963 8.26462 113.864 7.93641C113.765 7.60819 113.598 7.30446 113.374 7.04498C113.057 6.49007 112.597 6.03037 112.042 5.71392L104.123 0.560607C103.555 0.194616 102.893 0 102.217 0C101.541 0 100.879 0.194616 100.311 0.560607L92.4048 5.44526C91.8479 5.76375 91.3844 6.22272 91.0606 6.77632C90.708 7.3505 90.5141 8.00784 90.4985 8.68134L91.3539 39.0761C91.3736 39.2315 91.3574 39.3893 91.3065 39.5375C91.2557 39.6857 91.1716 39.8203 91.0606 39.9309C91.0606 40.2118 90.7796 40.2118 90.4985 40.5048C90.2052 40.5048 89.9242 40.7857 89.6309 40.5048C89.4159 40.4732 89.2179 40.3699 89.0688 40.2118L74.6742 26.8767C74.5227 26.7172 74.3188 26.6175 74.0998 26.5958H73.2444C73.095 26.6045 72.9539 26.6678 72.848 26.7736C72.7422 26.8794 72.6788 27.0204 72.6701 27.1698C72.5628 27.2825 72.482 27.4177 72.4334 27.5656C72.3848 27.7134 72.3696 27.8702 72.3891 28.0246V43.4601C72.3713 43.6144 72.3873 43.7707 72.4358 43.9183C72.4843 44.0659 72.5642 44.2012 72.6701 44.3149C72.7834 44.4214 72.9188 44.5018 73.0666 44.5503C73.2144 44.5988 73.3711 44.6143 73.5255 44.5958H91.1584L93.0646 112.798H110.172L111.846 62.0828V61.5089C111.846 61.2158 111.565 61.2158 111.565 60.9349L96.1195 46.5496C95.8263 46.2688 95.8263 46.2687 95.8263 45.9757V45.1208C95.8375 44.9724 95.9017 44.8328 96.007 44.7275C96.1124 44.6222 96.252 44.5582 96.4006 44.5469C96.5144 44.4411 96.6498 44.3612 96.7975 44.3127C96.9451 44.2642 97.1015 44.2483 97.256 44.2661H168.753C169.002 44.2632 169.248 44.211 169.476 44.1125C169.705 44.014 169.912 43.8711 170.085 43.6921C170.425 43.3283 170.628 42.8578 170.659 42.361V27.8902C170.648 27.3888 170.442 26.9114 170.085 26.5592C169.724 26.2151 169.251 26.0115 168.753 25.9852H114.706V26.6691H114.803Z"
            fill="#31273E"
         />
         <path
            d="M169.2 46.0002H107.43C107.241 45.9969 107.054 46.0316 106.879 46.1022C106.704 46.1728 106.545 46.278 106.412 46.4113C106.278 46.5446 106.173 46.7035 106.102 46.8783C106.032 47.0531 105.997 47.2404 106 47.429V85.285C105.997 85.4736 106.032 85.6608 106.102 85.8357C106.173 86.0105 106.278 86.1693 106.412 86.3027C106.545 86.436 106.704 86.5411 106.879 86.6118C107.054 86.6824 107.241 86.7171 107.43 86.7138H169.2C169.389 86.7171 169.576 86.6824 169.751 86.6118C169.926 86.5411 170.085 86.436 170.218 86.3027C170.352 86.1693 170.457 86.0105 170.528 85.8357C170.598 85.6608 170.633 85.4736 170.63 85.285V47.429C170.618 47.0539 170.463 46.6976 170.197 46.4322C169.932 46.1669 169.575 46.0124 169.2 46.0002Z"
            fill="white"
         />
         <path
            d="M120.394 63.3408V53.3394C120.367 53.1039 120.392 52.8653 120.467 52.6404C120.542 52.4154 120.665 52.2095 120.828 52.0373C120.991 51.8651 121.19 51.7306 121.411 51.6433C121.631 51.556 121.868 51.5181 122.105 51.5321H128.215C129.168 51.5321 129.742 52.0084 129.742 52.8632C129.742 53.718 129.168 54.1943 128.215 54.1943H123.84V57.2472H127.738C128.092 57.2472 128.431 57.3874 128.68 57.637C128.93 57.8867 129.07 58.2252 129.07 58.5782C129.07 58.9313 128.93 59.2698 128.68 59.5195C128.431 59.7691 128.092 59.9093 127.738 59.9093H123.84V63.3408C123.866 63.577 123.839 63.8158 123.763 64.0407C123.686 64.2656 123.562 64.4712 123.398 64.6432C123.234 64.8152 123.034 64.9494 122.813 65.0366C122.592 65.1238 122.355 65.1619 122.117 65.1481C121.882 65.1558 121.647 65.1136 121.429 65.0243C121.211 64.935 121.014 64.8006 120.851 64.63C120.688 64.4594 120.564 64.2563 120.485 64.0341C120.406 63.812 120.375 63.5757 120.394 63.3408Z"
            fill="#31273E"
         />
         <path
            d="M130.684 58.1999C130.684 53.9136 133.262 51.2393 137.258 51.2393C141.254 51.2393 143.844 53.9136 143.844 58.1999C143.844 62.4862 141.266 65.1483 137.258 65.1483C133.25 65.1483 130.684 62.4862 130.684 58.1999ZM140.459 58.1999C140.459 55.6232 139.237 53.9991 137.307 53.9991C135.376 53.9991 134.166 55.6232 134.166 58.1999C134.166 60.7766 135.388 62.3885 137.307 62.3885C139.225 62.3885 140.41 60.6667 140.41 58.1999H140.459Z"
            fill="#31273E"
         />
         <path
            d="M145.555 63.3408V53.2418C145.527 53.0063 145.552 52.7677 145.627 52.5427C145.702 52.3177 145.825 52.1119 145.988 51.9397C146.152 51.7674 146.35 51.633 146.571 51.5457C146.792 51.4584 147.029 51.4205 147.265 51.4345H151.273C154.609 51.4345 156.418 53.0464 156.418 55.8185C156.43 56.5602 156.242 57.2916 155.875 57.936C155.507 58.5805 154.973 59.1144 154.328 59.482L155.941 62.2418C156.185 62.5752 156.318 62.9768 156.32 63.3897C156.316 63.6043 156.268 63.8158 156.178 64.0107C156.088 64.2057 155.958 64.3799 155.797 64.5222C155.637 64.6645 155.448 64.7719 155.244 64.8377C155.039 64.9034 154.823 64.9259 154.609 64.9039C153.754 64.9039 153.461 64.6231 152.899 63.5729L150.895 59.9094H148.988V63.0478C149.016 63.2844 148.991 63.5241 148.915 63.75C148.839 63.9759 148.715 64.1824 148.551 64.3549C148.386 64.5273 148.186 64.6615 147.964 64.748C147.742 64.8344 147.503 64.871 147.265 64.8551C147.05 64.8895 146.829 64.8757 146.619 64.8147C146.409 64.7537 146.215 64.6469 146.052 64.5021C145.888 64.3573 145.759 64.178 145.673 63.9772C145.587 63.7764 145.546 63.5591 145.555 63.3408ZM150.992 57.809C151.498 57.809 151.983 57.6083 152.34 57.251C152.698 56.8937 152.899 56.4092 152.899 55.904C152.899 55.3987 152.698 54.9142 152.34 54.5569C151.983 54.1996 151.498 53.9989 150.992 53.9989H148.988V57.809H150.992Z"
            fill="#31273E"
         />
         <path
            d="M114.773 79.6314V69.5324C114.746 69.2968 114.771 69.0582 114.846 68.8332C114.921 68.6083 115.044 68.4025 115.207 68.2302C115.37 68.058 115.569 67.9235 115.79 67.8362C116.01 67.7489 116.247 67.711 116.484 67.725H120.517C123.853 67.725 125.661 69.337 125.661 72.0968C125.682 72.8498 125.497 73.5941 125.127 74.2502C124.757 74.9063 124.215 75.4494 123.559 75.8213L125.185 78.5812C125.423 78.9171 125.555 79.3171 125.563 79.7291C125.559 79.9437 125.511 80.1552 125.421 80.3501C125.331 80.5451 125.201 80.7192 125.041 80.8616C124.88 81.0039 124.691 81.1113 124.487 81.177C124.282 81.2428 124.066 81.2653 123.853 81.2433C122.985 81.2433 122.704 80.9624 122.13 79.9122L120.126 76.4808H118.195V79.6314C118.222 79.8669 118.197 80.1055 118.122 80.3305C118.047 80.5554 117.924 80.7613 117.761 80.9335C117.598 81.1057 117.399 81.2402 117.179 81.3275C116.958 81.4148 116.721 81.4527 116.484 81.4387C116.249 81.4446 116.016 81.4011 115.799 81.311C115.583 81.221 115.387 81.0864 115.226 80.9159C115.065 80.7455 114.941 80.543 114.863 80.3217C114.785 80.1004 114.754 79.8652 114.773 79.6314ZM120.199 74.0995C120.704 74.0995 121.189 73.8988 121.547 73.5415C121.904 73.1843 122.105 72.6997 122.105 72.1945C122.105 71.6892 121.904 71.2047 121.547 70.8474C121.189 70.4902 120.704 70.2895 120.199 70.2895H118.195V74.0995H120.199Z"
            fill="#31273E"
         />
         <path
            d="M127.348 79.3383V69.569C127.316 69.3305 127.339 69.088 127.412 68.859C127.486 68.6301 127.61 68.4204 127.775 68.2451C127.94 68.0699 128.142 67.9334 128.366 67.8456C128.59 67.7577 128.831 67.7207 129.071 67.7372H135.181C136.134 67.7372 136.708 68.2135 136.708 69.0683C136.708 69.9231 136.134 70.3994 135.181 70.3994H130.696V73.0615H134.887C135.058 73.0444 135.23 73.0632 135.393 73.1169C135.555 73.1706 135.705 73.2578 135.832 73.373C135.958 73.4881 136.059 73.6286 136.128 73.7853C136.197 73.942 136.232 74.1115 136.231 74.2826C136.232 74.4538 136.197 74.6233 136.128 74.78C136.059 74.9367 135.958 75.0772 135.832 75.1924C135.705 75.3075 135.555 75.3947 135.393 75.4484C135.23 75.5021 135.058 75.5209 134.887 75.5038H130.696V78.2759H135.181C135.37 78.2517 135.563 78.2681 135.746 78.3237C135.93 78.3794 136.099 78.4732 136.243 78.5989C136.387 78.7246 136.503 78.8794 136.584 79.0532C136.664 79.2269 136.706 79.4156 136.708 79.6069C136.708 80.4618 136.134 80.938 135.181 80.938H129.071C128.02 81.17 127.348 80.4862 127.348 79.3383Z"
            fill="#31273E"
         />
         <path
            d="M138.406 79.631V69.1534C138.381 68.934 138.405 68.7116 138.478 68.503C138.551 68.2944 138.67 68.105 138.826 67.9488C138.982 67.7926 139.172 67.6737 139.381 67.6011C139.589 67.5284 139.812 67.5038 140.031 67.5292C140.361 67.5309 140.683 67.62 140.967 67.7874C141.25 67.9548 141.484 68.1944 141.644 68.4817L146.789 75.5279H146.887V69.2389C146.859 69.0151 146.881 68.788 146.952 68.5739C147.023 68.3597 147.14 68.1639 147.295 68.0004C147.451 67.837 147.64 67.71 147.851 67.6285C148.061 67.547 148.287 67.5131 148.512 67.5292C149.563 67.5292 150.125 68.1032 150.125 69.2389V79.7287C150.15 79.9465 150.126 80.1673 150.054 80.3744C149.982 80.5816 149.864 80.7697 149.709 80.9248C149.554 81.0799 149.365 81.1979 149.158 81.2699C148.951 81.3419 148.73 81.3661 148.512 81.3406C148.181 81.3399 147.856 81.2513 147.57 81.0839C147.285 80.9166 147.049 80.6764 146.887 80.3881L141.742 73.2443H141.644V79.631C141.664 79.8553 141.637 80.0812 141.564 80.2944C141.492 80.5075 141.375 80.7033 141.223 80.8692C141.071 81.0352 140.886 81.1677 140.68 81.2584C140.473 81.349 140.251 81.3958 140.025 81.3958C139.8 81.3958 139.577 81.349 139.371 81.2584C139.165 81.1677 138.98 81.0352 138.827 80.8692C138.675 80.7033 138.559 80.5075 138.487 80.2944C138.414 80.0812 138.387 79.8553 138.406 79.631Z"
            fill="#31273E"
         />
         <path
            d="M155.281 79.6313V70.5825H152.899C151.945 70.5825 151.371 70.1063 151.371 69.2393C151.371 68.3722 151.945 67.9082 152.899 67.9082H161C161.953 67.9082 162.528 68.3845 162.528 69.2393C162.528 70.0941 161.953 70.5825 161 70.5825H158.617V79.6313C158.643 79.8675 158.616 80.1064 158.54 80.3313C158.463 80.5562 158.339 80.7618 158.175 80.9338C158.011 81.1058 157.811 81.24 157.59 81.3272C157.369 81.4144 157.132 81.4524 156.894 81.4387C156.67 81.4262 156.449 81.3695 156.247 81.2719C156.044 81.1742 155.862 81.0375 155.712 80.8696C155.563 80.7017 155.447 80.5059 155.373 80.2934C155.299 80.0809 155.268 79.8559 155.281 79.6313Z"
            fill="#31273E"
         />
      </svg>
   ),
}

export { signUpIllustrationSVG }
