import React from "react"
import { createComponent } from "@lit-labs/react"
import { PageErrorNotFoundElement as Component } from "web-components"

const PageErrorNotFoundElement = createComponent({
   tagName: "page-error-not-found",
   elementClass: Component,
   react: React,
   displayName: "PageErrorNotFound",
   events: {
      onGoHome: "click-back",
   },
})

export default PageErrorNotFoundElement
