import { Route, useLocation } from "react-router-dom"
import SignUp from "pages/Signup"
import Login from "pages/Login"
import Register from "pages/Register"
import VerifyRouter from "pages/Verify/Router"
import { AnimatePresence } from "framer-motion"
import AnimatedRoute from "components/functional/AnimatedRoute"
import IndexPage from "pages/IndexPage"
import PageNotFoundRouter from "layouts/PageNotFoundRouter"
import Logout from "pages/Logout"
import Start from "pages/Start"
import Merge from "pages/Merge"
import SignInAssistanceRouter from "pages/SignInAssistance/Router"
import ROUTE from "./ROUTE"
import useRouteChange from "./useRouteChange"
import { SentryRoutes } from "configs/sentry"
import ZendeskSSO from "pages/SSO/Zendesk"

import ProfileOutlet from "pages/Profile"
import Profile_AccountPage from "pages/Profile/Account"
import Profile_RolesPage from "pages/Profile/Roles"
import Profile_OverviewPage from "pages/Profile/Overview"
import Profile_SecurityPage from "pages/Profile/Security"
import Profile_IntegrationsPage from "pages/Profile/Integrations"

import OnboardingOutlet from "pages/Onboarding"
import Onboarding_PersonalInfoPage from "pages/Onboarding/PersonalInfo"
import Onboarding_BusinessInfoPage from "pages/Onboarding/BusinessInfo"
import Onboarding_UnitAndTenantPage from "pages/Onboarding/UnitAndTenant"

const RoutesIndex = () => {
   const location = useLocation()

   // =====================
   // On every route change
   // =====================
   useRouteChange()
   // ---------------------

   // ===
   // JSX
   // ===
   return (
      <AnimatePresence mode="wait">
         <SentryRoutes location={location}>
            <Route path={ROUTE.INDEX} element={<IndexPage />} />
            <Route
               path={ROUTE.SIGN_UP}
               element={
                  <AnimatedRoute nestedKey={ROUTE.SIGN_UP}>
                     <SignUp />
                  </AnimatedRoute>
               }
            />
            <Route
               path={ROUTE.LOGIN}
               element={
                  <AnimatedRoute nestedKey={ROUTE.LOGIN}>
                     <Login />
                  </AnimatedRoute>
               }
            />
            <Route
               path={ROUTE.START}
               element={
                  <AnimatedRoute nestedKey={ROUTE.START}>
                     <Start />
                  </AnimatedRoute>
               }
            />
            <Route
               path={ROUTE.MERGE}
               element={
                  <AnimatedRoute nestedKey={ROUTE.MERGE}>
                     <Merge />
                  </AnimatedRoute>
               }
            />
            <Route
               path={ROUTE.REGISTER}
               element={
                  <AnimatedRoute nestedKey={ROUTE.REGISTER}>
                     <Register />
                  </AnimatedRoute>
               }
            />
            <Route
               path={ROUTE.VERIFY.ROOT + "/*"}
               element={
                  <AnimatedRoute nestedKey={ROUTE.VERIFY.ROOT}>
                     <VerifyRouter />
                  </AnimatedRoute>
               }
            />
            <Route
               path={ROUTE.SIGN_IN_ASSISTANCE.ROOT + "/*"}
               element={
                  <AnimatedRoute nestedKey={ROUTE.SIGN_IN_ASSISTANCE.ROOT}>
                     <SignInAssistanceRouter />
                  </AnimatedRoute>
               }
            />
            <Route path={ROUTE.SSO.ROOT + "/*"} element={<ZendeskSSO />} />
            <Route
               element={
                  <AnimatedRoute nestedKey="profile">
                     <ProfileOutlet />
                  </AnimatedRoute>
               }
            >
               <Route path={ROUTE.PROFILE.ACCOUNT} element={<Profile_AccountPage />} />
               <Route path={ROUTE.PROFILE.ROLES} element={<Profile_RolesPage />} />
               <Route path={ROUTE.PROFILE.OVERVIEW} element={<Profile_OverviewPage />} />
               <Route path={ROUTE.PROFILE.SECURITY} element={<Profile_SecurityPage />} />
               <Route path={ROUTE.PROFILE.INTEGRATIONS} element={<Profile_IntegrationsPage />} />
            </Route>
            <Route
               element={
                  <AnimatedRoute nestedKey="onboarding">
                     <OnboardingOutlet />
                  </AnimatedRoute>
               }
               path="/onboarding"
            >
               <Route
                  path={ROUTE.ONBOARDING.PERSONAL_INFO}
                  element={<Onboarding_PersonalInfoPage />}
               />
               <Route
                  path={ROUTE.ONBOARDING.COMPANY_INFO}
                  element={<Onboarding_BusinessInfoPage />}
               />
               <Route
                  path={ROUTE.ONBOARDING.UNIT_AND_TENANT}
                  element={<Onboarding_UnitAndTenantPage />}
               />
               <Route path="*" element={<PageNotFoundRouter />} />
            </Route>
            <Route path={ROUTE.LOGOUT} element={<Logout />} />
            <Route path="*" element={<PageNotFoundRouter />} />
         </SentryRoutes>
      </AnimatePresence>
   )
}

export default RoutesIndex
